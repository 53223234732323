<script lang="ts">
  import { type TileInfoResponse } from "src/global/types";
  import { _ } from "src/services/i18n";

  export let info: TileInfoResponse | undefined = undefined;
  
  $: rotation = info?.rotation === 90 ? "-rotate-90" : info?.rotation === 180 ? "rotate-180" : info?.rotation === 270 ? "rotate-90" : ""
  
</script>
{#if info}    
<div class="w-80 bg-white border-2 border-gray-300 rounded-lg p-2">
  <!-- <div class="w-6 h-6 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer">
    <i class="fa-solid fa-heart text-input-label" />
  </div> -->
  <div class="w-[300px] h-[300px]">
    <img src={info.images[0]} alt={info.name} class="object-contain w-full h-full {rotation}"/>
  </div>
  <div class="my-2.5 flex items-center gap-2.5">
    {#if info.producer?.thumbnail}
      <div class="w-9 h-9">
        <img src={info.producer.thumbnail} alt={info.producer.name} />
      </div>
    {/if}
    <div class="text-sm font-medium text-input-label">{info.name}</div>
  </div>
  <div class="w-full bg-gray-100 rounded-lg p-2.5 divide-y divide-gray-200">
    {#if info.producer}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.brand")}:</div>
      <div class="text-xs text-input-label">{info.producer.name}</div>
    </div>
    {/if}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.collection")}:</div>
      <div class="text-xs text-input-label">{info.collection}</div>
    </div>
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.product_code")}:</div>
      <div class="text-xs text-input-label">{info.code}</div>
    </div>
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.size")}:</div>
      <div class="text-xs text-input-label">{info.width}x{info.height}cm</div>
    </div>
    {#if info?.square_meters && info?.pieces}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.quantity_box")}:</div>
      <div class="text-xs text-input-label">{info.square_meters}m²-{info.pieces} pcs</div>
    </div>
    {/if}
    {#if info.color}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.color")}:</div>
      <div class="flex items-center gap-1">
        <div class="text-xs text-input-label">{info.color.name}</div>
        <div class="w-3 h-3 rounded-full" style="background-color: #{info.color.code}" />
      </div>
    </div>
    {/if}
    {#if info.tile_type}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.product_type")}:</div>
      <div class="text-xs text-input-label">{info.tile_type}</div>
    </div>
    {/if}
    {#if info.tile_effect}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.effect")}:</div>
      <div class="text-xs text-input-label">{info.tile_effect}</div>
    </div>
    {/if}
    {#if info.tile_finish}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.finish")}:</div>
      <div class="text-xs text-input-label">{info.tile_finish}</div>
    </div>
    {/if}
    {#if info.materials && info.materials.length}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.materials")}:</div>
      <div class="text-xs text-input-label">{info.materials.join(", ")}</div>
    </div>
    {/if}
    {#if info.tile_suitabilities && info.tile_suitabilities.length}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.suitable")}:</div>
      <div class="text-xs text-input-label">{info.tile_suitabilities.join(", ")}</div>
    </div>
    {/if}
    {#if info.tech_features && info.tech_features.length}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.tech_features")}:</div>
      <div class="text-xs text-input-label">{info.tech_features.join(", ")}</div>
    </div>
    {/if}
  </div>
</div>
{/if}