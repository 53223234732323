<script lang="ts">
  import { _ } from "../../services/i18n";
  import { drawState } from "../../layout.store";
  import {
    centroid,
    convertPointerToClientBox,
    getSquareWithUnit,
    isClosedArea,
  } from "../../helpers";
  import type { ClosedArea, TileWrapper } from "src/model";
  import Loading from "../util/Loading.svelte";
  import LongText from "../base/LongText.svelte";

  export let tileWrapper: TileWrapper;
  export let scale: number;

  $: room = $drawState.context.current.segments.find((segment) =>
    isClosedArea(segment) && segment.id === tileWrapper.closedAreaId
  ) as ClosedArea;
  $: loadingLayout = tileWrapper.loadingLayout;

  $: centerPointer = convertPointerToClientBox(
    centroid(tileWrapper.shape),
    $drawState.context.svgElement
  );

  $: if (!isNaN(scale)) {
    centerPointer = convertPointerToClientBox(
      centroid(tileWrapper.shape),
      $drawState.context.svgElement
    );
  }
</script>

<svelte:window
  on:resize={(e) => {
    centerPointer = convertPointerToClientBox(
      centroid(tileWrapper.shape),
      $drawState.context.svgElement
    );
  }}
/>

<div
  class="info-text text-sm text-center absolute border-0 p-0"
  style={`left: ${centerPointer.x}px; top: ${centerPointer.y}px; transform: translate(-50%, -50%); pointer-events: none`}
>
  {#if loadingLayout}
    <Loading />
  {:else if room && tileWrapper}
    <div class="flex items-center font-bold gap-1"><LongText text={room.getName($_)} maxLen={14} /> {'>'} <LongText text={tileWrapper.getName($_)} maxLen={14}/></div>
    <div>{@html getSquareWithUnit(tileWrapper.getArea(), $drawState.context.currentMetricUnit)}</div>
  {/if}
</div>

<style lang="scss" scoped>
  .info-text {
    user-select: none;
    // text-shadow: 0px 0px 10px white;
    color: black;
    text-shadow:
       1px 1px 0 white,
      -1px -1px 0 white,
      1px -1px 0 white,
      -1px 1px 0 white,
       1px 1px 0 white;
  }
</style>