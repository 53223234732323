import Vector2 from '../../model/tile/Vector2';
export function pannable(node, enabled) {
    let dragging = false;
    let initScreen;
    let initSVG;
    const screenCTM = node.ownerSVGElement?.getScreenCTM();
    let svgPoint = node.ownerSVGElement?.createSVGPoint();
    function handleMousedown(event) {
        event.preventDefault();
        dragging = true;
        initScreen = new Vector2(event instanceof MouseEvent ? event.pageX : event.touches[0].pageX, event instanceof MouseEvent ? event.pageY : event.touches[0].pageY);
        initSVG = convertToSVGCoordinates(initScreen);
        node.dispatchEvent(new CustomEvent('panstart', {
            detail: initSVG,
        }));
        window.addEventListener('mousemove', handleMousemove);
        window.addEventListener('mouseup', handleMouseup);
        window.addEventListener('touchmove', handleMousemove);
        window.addEventListener('touchend', handleMouseup);
    }
    function handleMousemove(event) {
        if (dragging && initScreen && initSVG) {
            const coor = convertToSVGCoordinates(new Vector2(event instanceof MouseEvent ? event.clientX : event.touches[0].clientX, event instanceof MouseEvent ? event.clientY : event.touches[0].clientY));
            node.dispatchEvent(new CustomEvent('panmove', {
                detail: {
                    coor,
                    initCoor: initSVG,
                },
            }));
        }
    }
    function handleMouseup(event) {
        if (dragging) {
            event.preventDefault();
            event.stopPropagation();
            const coor = convertToSVGCoordinates(new Vector2(event instanceof MouseEvent ? event.clientX : event.touches[0].clientX, event instanceof MouseEvent ? event.clientY : event.touches[0].clientY));
            node.dispatchEvent(new CustomEvent('panend', {
                detail: {
                    coor,
                    initCoor: initSVG,
                },
            }));
            dragging = false;
            initScreen = undefined;
        }
        window.removeEventListener('mousemove', handleMousemove);
        window.removeEventListener('mouseup', handleMouseup);
        window.removeEventListener('touchmove', handleMousemove);
        window.removeEventListener('touchend', handleMouseup);
    }
    function convertToSVGCoordinates(pt) {
        let svgCoordinates = undefined;
        if (node && svgPoint && screenCTM) {
            svgPoint.x = pt.x;
            svgPoint.y = pt.y;
            // based on https://stackoverflow.com/questions/10298658/mouse-position-inside-autoscaled-svg
            svgPoint = svgPoint.matrixTransform(screenCTM.inverse());
            svgCoordinates = new Vector2(svgPoint.x, svgPoint.y);
        }
        return svgCoordinates;
    }
    if (enabled) {
        node.addEventListener('mousedown', handleMousedown);
        node.addEventListener('touchstart', handleMousedown);
    }
    return {
        destroy() {
            if (enabled) {
                node.removeEventListener('mousedown', handleMousedown);
                node.removeEventListener('touchstart', handleMousedown);
            }
        },
    };
}
