<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let className = "";
  export let options: {label: string, value: number | string}[];
  export let value: number | string;
  export {className as class};

  const dispatch = createEventDispatcher();

  const handleChange = (newValue) => {
    if( value === newValue ) return;

    dispatch('change', { value: newValue })
    value = newValue;
  }
</script>

<div class={`border border-gray-300 flex rounded-full overflow-hidden ${className}`}>
  {#each options as option, idx (option.value)}
    <div 
      class={`${option.value === value ? 'bg-primary-500/30 text-primary-500 font-bold' : 'bg-gray-100 text-input-label font-medium'} w-full px-2 py-1 text-sm text-center cursor-pointer ${idx > 0 ? "border-l border-gray-300" : "" }`} 
      on:click={() => handleChange(option.value)}
    >
      {option.label}
    </div>
  {/each}
</div>
