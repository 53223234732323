<script lang="ts">
  import { ClosedArea, Door, Pointer, Segment, TileWrapper } from "../../model";
  import {
    convertPointerToViewBox,
    getHelpAnglePath,
    getHelpAngleText,
    getParentClosedArea,
    getSegmentsOfPoint,
    isArc,
    isBuildingPart,
    isClosedArea,
    isDoor,
    isLine,
    isTileWrapper,
    isWallProject,
    makePointerInSvgBox,
  } from "../../helpers";
  import { drawState, drawSend } from "../../layout.store";
  import { MIN_RESIZE_SCALE } from "src/global/variable";

  export let segments: Segment[] | undefined;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;

  $: filteredSegments = $drawState.context.current.segments.filter(
    (s) => !isTileWrapper(s)
  );
  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: drawingPoints = () => {
    const result = selectedPoints();
    const drawingObject = $drawState.context.drawContext.drawingObject;

    if (drawingObject) {
      return [ ...result, {
        pointer: drawingObject.startPointer
      }];
    }
    return result;
  };

  $: selectedPoints = () => 
    (segments ?? [$drawState.context.dragContext.selectedObject]).map((selectedObject) => {
      let result: {
        pointer: Pointer,
        fill?: string,
        stroke?: string,
        r?: number
      }[] = [];
      if (isBuildingPart(selectedObject) || (isWall && isDoor(selectedObject))) {
        return result;
      }
      if (
        selectedObject &&
        (!isDoor(selectedObject) || (selectedObject as Door).parentId) 
      ) {
        if( isClosedArea(selectedObject) ) {
          const closedArea = selectedObject as ClosedArea;
          result = closedArea.shape.results.map((seg)=> ({
            pointer: seg.startPointer
          }));
        } else if ( isTileWrapper(selectedObject) ) {
          const tileWrapper = selectedObject as TileWrapper;
          const closedArea = $drawState.context.current.segments.find((_seg) => _seg.id === tileWrapper.closedAreaId &&
            isClosedArea(_seg)
          ) as ClosedArea;
          if( closedArea)
            result = closedArea.shape.results.map((seg)=> ({
              pointer: seg.startPointer
            }));
        } else if ( isLine(selectedObject) || isArc(selectedObject) ) {
          result = [{
            pointer: selectedObject.startPointer,
            fill: 'red',
            stroke: 'red',
            r: 3,
          }, {
            pointer: selectedObject.endPointer,
            fill: 'green',
            stroke: 'green',
            r: 3,
          }];
        } else {
          result = [{
            pointer: selectedObject.startPointer
          }, {
            pointer: selectedObject.endPointer
          }];
        }
      }
      return result;
    }).flat()

  const segmentDown = (e: any, item: Pointer) => {
    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
    drawSend({ type: "HIDE_LINE_TOOL" });

    if ($drawState.context.dragContext.selectedCPObj !== item) {
      drawSend({ type: "SELECT_POINTER", pointer: item });
    }
  };

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;
    if ( isDoor($drawState.context.dragContext.selectedObject) ) {
      drawSend({ type: "MOUSE_RESIZE", index: 8 })
    } else {
      drawSend({ type: "MOUSE_HOVER" });
    }
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) {
      return;
    }
    drawSend({ type: "MOUSE_LEAVE" });
  };

  //computed
  $: middlePoints = () => {
    const result: Pointer[] = [];
    if ($drawState.context.splitContext.splitedLineArray) {
      $drawState.context.splitContext.splitedLineArray.map(
        (item: Segment, index: number) => {
          if (
            index <
            $drawState.context.splitContext.splitedLineArray.length - 1
          ) {
            result.push(item.endPointer);
          }
        }
      );
    }
    return result;
  };

  $: pointsArray = () => {
    const result: Pointer[] = [];
    filteredSegments.map((segment: Segment) => {
      if (!isBuildingPart(segment) || isDoor(segment)) {
        if (!result.find((pointer) => pointer.equals(segment.startPointer))) {
          result.push(segment.startPointer);
        }
        if (!result.find((pointer) => pointer.equals(segment.endPointer))) {
          result.push(segment.endPointer);
        }
      }
    });

    return result;
  };

  const getHelpAnglePathOfPoint = (pointer: Pointer): string => {
    return getHelpAnglePath(
      pointer,
      getSegmentsOfPoint(filteredSegments, pointer)
    );
  };

  const getHelpAngleTextOfPoint = (
    pointer: Pointer
  ): {
    angle: number;
    textPointer: Pointer;
    angleText: string;
  }[] => {
    return getHelpAngleText(
      pointer,
      getSegmentsOfPoint(filteredSegments, pointer)
    );
  };
</script>

<g>
  {#if $drawState.matches("helper.showHelper")}
    {#each drawingPoints() as item}
      <g>
        <path
          d={getHelpAnglePathOfPoint(item.pointer)}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
        {#each getHelpAngleTextOfPoint(item.pointer) as itemText}
          <text
            class="stroke-text"
            transform={"translate(" +
              itemText.textPointer.x +
              "," +
              itemText.textPointer.y +
              ")"}
            style={`font-size: ${16 / scale}px;`}
          >
            {itemText.angleText}°
          </text>
        {/each}
      </g>
    {/each}
  {/if}
  {#if !$drawState.matches("main.savePreview") && !$drawState.matches("main.printPreview") && !$drawState.matches("main.printing") && scale > MIN_RESIZE_SCALE}
  {#each selectedPoints() as {pointer: item, fill, stroke, r}, i}
    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
    <circle
      cx={item.x}
      cy={item.y}
      class="circle-pointer"
      r={r ?? 2}
      fill={fill ?? "white"}
      stroke-dasharray="1.5 0.2"
      stroke={stroke ?? "green"}
      stroke-width="0.5px"
      on:mousedown={(e) => segmentDown(e, item)}
      on:touchstart={(e) => segmentDown(e, item)}
      on:mouseover={segmentHovered}
      on:mouseleave={segmentMouseLeave}
    />
  {/each}
  {#each middlePoints() as item}
    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
    <circle
      cx={item.x}
      cy={item.y}
      class="circle-pointer"
      r="2"
      fill="white"
      stroke-dasharray="1.5 0.2"
      stroke="green"
      stroke-width="0.5px"
      on:mousedown={(e) => segmentDown(e, item)}
      on:mouseover={segmentHovered}
      on:mouseleave={segmentMouseLeave}
    />
  {/each}
  {#if $drawState.context.snapContext.snapPointer && $drawState.context.snapContext.snapType > -1}
    <circle
      cx={$drawState.context.snapContext.snapPointer.x}
      cy={$drawState.context.snapContext.snapPointer.y}
      r="2"
      fill="white"
      stroke-dasharray="1.5 0.2"
      stroke={$drawState.context.snapContext.snapType === 0
        ? "red"
        : $drawState.context.snapContext.snapType === 1
        ? "blue"
        : "saddlebrown"}
      stroke-width="0.5px"
    />
  {/if}
  {/if}
</g>

<style>
  text {
    font-family: sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
