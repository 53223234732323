import { Pointer } from './Pointer';
import { Segment, SegmentClass } from './Segment';
import { dist, getAngleBetweenTwoLinesInPI, getDistanceLineAndPointer, getDistancePointer, getHelperPath, getHelperTextAngle, getParentLine, getTextPointer, } from '../helpers';
import { Line } from '.';
import { v4 as uuidv4 } from 'uuid';
import { TOLERANCE_ANGLE } from 'src/global/variable';
export class BuildingPart extends Segment {
    startPointer;
    endPointer;
    width;
    length;
    image;
    parentId;
    rotation;
    buildingType;
    buildingId;
    closedAreaId;
    id;
    zIndex;
    name;
    printLabel;
    constructor(startPointer, endPointer, width, length, image, parentId = 0, rotation = 0, buildingType = '', buildingId, closedAreaId, id = uuidv4(), zIndex = 1, name = '') {
        super(startPointer, endPointer, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.width = width;
        this.length = length;
        this.image = image;
        this.parentId = parentId;
        this.rotation = rotation;
        this.buildingType = buildingType;
        this.buildingId = buildingId;
        this.closedAreaId = closedAreaId;
        this.id = id;
        this.zIndex = zIndex;
        this.name = name;
        this.class = SegmentClass.BUILDING_PART;
    }
    generatePath(isFromShape = false, startPointer = undefined) {
        return '';
    }
    generateHelperPath() {
        if (this.startPointer.equals(this.endPointer)) {
            return [];
        }
        const result = [];
        const [pointer1, pointer2, pointer3, _] = this.getRectPoints();
        result.push(getHelperPath(pointer1, pointer2));
        result.push(getHelperPath(pointer3, pointer1));
        return result;
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx: dx, dy: dy };
    }
    generateHelperTextData() {
        const [pointer1, pointer2, pointer3, _] = this.getRectPoints();
        return [
            {
                x: getTextPointer(pointer1, pointer2).x,
                y: getTextPointer(pointer1, pointer2).y,
                angle: getHelperTextAngle(pointer1, pointer2),
                length: this.width,
            },
            {
                x: getTextPointer(pointer3, pointer1).x,
                y: getTextPointer(pointer3, pointer1).y,
                angle: getHelperTextAngle(pointer3, pointer1),
                length: this.length,
            },
        ];
    }
    getPointsArray() {
        return [];
    }
    translate(dx, dy, segments) {
        this.startPointer = this.startPointer.translate(dx, dy);
        this.endPointer = this.endPointer.translate(dx, dy);
    }
    getLineLength() {
        return dist(this.startPointer, this.endPointer);
    }
    isInSegment(pointer, acceptInPath = true) {
        return false;
    }
    getSnapInfos = (segments) => {
        let resultSnapPointer, resultSnapLinePointer;
        let resultPointer;
        if (this.parentId) {
            const parentLine = getParentLine(segments, this.parentId);
            const [pointer1, pointer2, pointer3, pointer4] = this.getRectPoints();
            let distance = Infinity;
            let dPointer = getDistancePointer(parentLine, pointer1);
            let d = getDistanceLineAndPointer(parentLine, pointer1);
            if (dPointer.x >= Math.min(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.x <= Math.max(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.y >= Math.min(parentLine.startPointer.y, parentLine.endPointer.y) &&
                dPointer.y <= Math.max(parentLine.startPointer.y, parentLine.endPointer.y)) {
                distance = d;
                resultPointer = dPointer;
                resultSnapPointer = pointer1;
                if (getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer1, pointer2)) <
                    getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer1, pointer3))) {
                    resultSnapLinePointer = pointer2;
                }
                else {
                    resultSnapLinePointer = pointer3;
                }
            }
            dPointer = getDistancePointer(parentLine, pointer2);
            d = getDistanceLineAndPointer(parentLine, pointer2);
            if (dPointer.x >= Math.min(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.x <= Math.max(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.y >= Math.min(parentLine.startPointer.y, parentLine.endPointer.y) &&
                dPointer.y <= Math.max(parentLine.startPointer.y, parentLine.endPointer.y) &&
                d < distance) {
                distance = d;
                resultPointer = dPointer;
                resultSnapPointer = pointer2;
                if (getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer2, pointer1)) <
                    getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer2, pointer4))) {
                    resultSnapLinePointer = pointer1;
                }
                else {
                    resultSnapLinePointer = pointer4;
                }
            }
            dPointer = getDistancePointer(parentLine, pointer3);
            d = getDistanceLineAndPointer(parentLine, pointer3);
            if (dPointer.x >= Math.min(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.x <= Math.max(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.y >= Math.min(parentLine.startPointer.y, parentLine.endPointer.y) &&
                dPointer.y <= Math.max(parentLine.startPointer.y, parentLine.endPointer.y) &&
                d < distance) {
                distance = d;
                resultPointer = dPointer;
                resultSnapPointer = pointer3;
                if (getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer3, pointer1)) <
                    getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer3, pointer4))) {
                    resultSnapLinePointer = pointer1;
                }
                else {
                    resultSnapLinePointer = pointer4;
                }
            }
            dPointer = getDistancePointer(parentLine, pointer4);
            d = getDistanceLineAndPointer(parentLine, pointer4);
            if (dPointer.x >= Math.min(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.x <= Math.max(parentLine.startPointer.x, parentLine.endPointer.x) &&
                dPointer.y >= Math.min(parentLine.startPointer.y, parentLine.endPointer.y) &&
                dPointer.y <= Math.max(parentLine.startPointer.y, parentLine.endPointer.y) &&
                d < distance) {
                distance = d;
                resultPointer = dPointer;
                resultSnapPointer = pointer4;
                if (getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer4, pointer2)) <
                    getAngleBetweenTwoLinesInPI(parentLine, new Line(pointer4, pointer3))) {
                    resultSnapLinePointer = pointer2;
                }
                else {
                    resultSnapLinePointer = pointer3;
                }
            }
            if (resultSnapPointer && resultPointer) {
                let lineAngle = parentLine.getLineAngle();
                if (lineAngle < 0)
                    lineAngle += Math.PI;
                const snapElementLine = new Line(resultSnapPointer, resultSnapLinePointer);
                let centerAngle = snapElementLine.getLineAngle();
                if (centerAngle < 0)
                    centerAngle += Math.PI;
                let deltaAngle = Math.abs((((lineAngle - centerAngle) * 180) / Math.PI) % 90);
                if (deltaAngle > 45) {
                    deltaAngle = 90 - deltaAngle;
                }
                const deltaX = resultSnapLinePointer.x - resultSnapPointer.x;
                const deltaY = -(resultSnapLinePointer.y - resultSnapPointer.y);
                const snapRotatedPointer = new Pointer(deltaX * Math.cos((deltaAngle * Math.PI) / 180) +
                    deltaY * Math.sin((deltaAngle * Math.PI) / 180) +
                    resultSnapPointer.x, -(deltaY * Math.cos((deltaAngle * Math.PI) / 180) - deltaX * Math.sin((deltaAngle * Math.PI) / 180)) +
                    resultSnapPointer.y);
                let snappedAngle = new Line(resultSnapPointer, snapRotatedPointer).getLineAngle();
                if (snappedAngle < 0)
                    snappedAngle += Math.PI;
                if (Math.abs(snappedAngle - lineAngle) > TOLERANCE_ANGLE) {
                    deltaAngle *= -1;
                }
                return {
                    snapPointer: resultPointer,
                    snapLinePointer1: resultSnapPointer,
                    snapLinePointer2: resultSnapLinePointer,
                    deltaAngle,
                    lineAngle,
                    distance: {
                        deltaX: resultPointer.x - resultSnapPointer.x,
                        deltaY: resultPointer.y - resultSnapPointer.y,
                    },
                };
            }
        }
        return {
            snapPointer: new Pointer(0, 0),
            snapLinePointer1: new Pointer(0, 0),
            snapLinePointer2: new Pointer(0, 0),
            deltaAngle: 0,
            lineAngle: 0,
            distance: {
                deltaX: 0,
                deltaY: 0,
            },
        };
    };
    getRectPoints() {
        const length = this.length / 2;
        const width = this.width / 2;
        const angle = (this.rotation * Math.PI) / 180;
        const centerPointer = new Pointer(this.startPointer.x + width, this.startPointer.y + length);
        const pointer1 = new Pointer(-width * Math.cos(angle) + length * Math.sin(angle) + centerPointer.x, -(length * Math.cos(angle) - -width * Math.sin(angle)) + centerPointer.y);
        const pointer2 = new Pointer(width * Math.cos(angle) + length * Math.sin(angle) + centerPointer.x, -(length * Math.cos(angle) - width * Math.sin(angle)) + centerPointer.y);
        const pointer3 = new Pointer(-width * Math.cos(angle) + -length * Math.sin(angle) + centerPointer.x, -(-length * Math.cos(angle) - -width * Math.sin(angle)) + centerPointer.y);
        const pointer4 = new Pointer(width * Math.cos(angle) + -length * Math.sin(angle) + centerPointer.x, -(-length * Math.cos(angle) - width * Math.sin(angle)) + centerPointer.y);
        return [pointer1, pointer2, pointer3, pointer4];
    }
    setPrintLabel(label) {
        this.printLabel = label;
    }
    clone() {
        return new BuildingPart(new Pointer(this.startPointer.x, this.startPointer.y), new Pointer(this.endPointer.x, this.endPointer.y), this.width, this.length, this.image, this.parentId, this.rotation, this.buildingType, this.buildingId, this.closedAreaId, this.id, this.zIndex, this.name);
    }
    toJSON() {
        return {
            ...super.toJSON(),
            width: this.width,
            length: this.length,
            image: this.image,
            parentId: this.parentId,
            rotation: this.rotation,
            buildingType: this.buildingType,
            buildingId: this.buildingId,
            closedAreaId: this.closedAreaId,
        };
    }
}
