<!-- <svelte:options immutable={true} /> -->

<script lang="ts">
  import { createEventDispatcher } from "svelte";  
  import { drawState,  drawSend } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import { type LayoutGeometry } from "src/store/drawMachine";
  import type { TileWrapper } from "src/model";
  import Input from "../../../components/base/Input.svelte";
  import ColorPicker from "../../../components/base/ColorPicker.svelte";
  import Notifications from "../../util/Notifications.svelte"
  import Button from "../../../components/base/Button.svelte";
  import Tooltip from "../../../components/base/Tooltip.svelte";
  import PassiveTile from "../../../components/tiles/PassiveTile.svelte";
  import { getMetricWithUnit, getLayoutShapes } from "src/helpers";
  import { TOOLS } from "src/global/types";
  import { debounce, isEqual } from "lodash";
  import type Shape from 'src/model/tile/Shape';
  import { INCH, METRIC_UNITS, TILE_TRANSFORM_SCALE, TILES_SHAPES } from "src/global/variable";
  import { currentTool } from "src/store";
  import { getNotificationsContext } from 'svelte-notifications';
  import TileInfo from "./TileInfo.svelte";

  export let tileWrapper: TileWrapper;
  const dispatch = createEventDispatcher();

  const { addNotification } = getNotificationsContext();

  let notifications: Notifications;
  let layoutShapes: Shape[] = [];
  let groutColor: string;
  let currentGeometry: LayoutGeometry;
 
  $: {
    if (tileWrapper) {    
      layoutShapes = [];
      groutColor = tileWrapper.tileLayout.groutColor;
      if (tileWrapper.layoutGeometryId !== undefined && tileWrapper.layoutGeometryId > 0)
      {
        const baseShapes = $drawState.context.layoutContext.baseShapes;
        currentGeometry = $drawState.context.layoutContext.layoutGeometries[tileWrapper.layoutGeometryId]

        if( currentGeometry )
          layoutShapes = getLayoutShapes(tileWrapper.tileLayout, currentGeometry, baseShapes);
      } else {
        currentGeometry = undefined;
      }
    }
    else
      dispatch("back");
  }

  $: {
    if( !$drawState.context.current.segments.find((seg) => seg.id === tileWrapper?.id) )
      dispatch("back");
  }

  $: isMetricMeter = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  const minGap = 0
  $: maxGap = isMetricMeter ? 20 : 3 / 4
  let rotation: number;
  $: {
    $drawState.context.currentMetricUnit,
    rotation = Math.round(tileWrapper?.rotation % 360)
  }

  const highlightTiles = (index) => {
    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });
    //highlight tiles only if the layout has at least two different ones
    // if (layoutShapes.length > 1)
    {
      drawSend({
        type: "HIGHLIGHT_TILES",
        highlightTile: { index: index, on: true },
        segment: tileWrapper
      });
    }
  };

  const removeHighlight = (index) => {
    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });
    // if (layoutShapes.length > 1)
    {
      drawSend({
        type: "HIGHLIGHT_TILES",
        highlightTile: { index: index, on: false },
        segment: tileWrapper
      });
    }
  };

  const replaceTile = (shape: Shape, idx: number) => {

    const geometry = currentGeometry;
    const svgPath = JSON.parse(geometry?.svg_path);
    const baseShapes = $drawState.context.layoutContext.baseShapes;
    const tileShape = baseShapes.find((bs) => bs.tileId === shape.shapeId);
    let width, height;

    if (tileShape.shapeId !== TILES_SHAPES.SQUARE)
    {
      const allSquaresReplaced = !svgPath.shape_index.some((tileId, index) => {

        const baseShape = baseShapes.find((bs) => bs.tileId === tileId);

        return baseShape?.tileId === TILES_SHAPES.SQUARE && !tileWrapper.tileLayout.overrideAspectRatio.find(oar => oar.shapeIndex === index);
      });

      if (!allSquaresReplaced)
      {
        // clearNotifications();
        addNotification({
          type: 'warning',
          removeAfter: 3000,
          text: $_("notification.pick_tile"),
          position: 'bottom-center',
        })
        return;
      }
    }

    if (tileWrapper.tileLayout.overrideAspectRatio.length > 1 ||
       (tileWrapper.tileLayout.overrideAspectRatio.length === 1 && tileWrapper.tileLayout.overrideAspectRatio[0].shapeIndex !== idx))
    {
      // const isReplacedTile = tileWrapper.overrideAspectRatio.find(el => el.shapeIndex === e.detail.index);
      const shapeToOverride = svgPath.shape_index[idx];
      const dimensionsShape = Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > idx ? svgPath.dimensions[idx] : undefined;
      const geometryShape = geometry.tile_shapes.find((gs) => gs.id === shapeToOverride);

      const factors = tileWrapper.tileLayout.getMultiplyFactors(geometry, idx);
      let widthMultiplyFactor  = factors.widthMultiplyFactor;
      let heightMultiplyFactor = factors.heightMultiplyFactor;

      if (dimensionsShape)
      {
        width  = widthMultiplyFactor  * dimensionsShape[0];
        height = heightMultiplyFactor * dimensionsShape[1];
      }
      else if (geometryShape)
      {
        width  = widthMultiplyFactor  * geometryShape.default_width;
        height = heightMultiplyFactor * geometryShape.default_height;
      }
      width = Math.round(width * 10) / 10;
      height = Math.round(height * 10) / 10;
    }

    const arrTileIndices = [];
    layoutShapes.forEach((_shape, index) => {
      if( idx === index )
        arrTileIndices.push(index)
      else if (shape.shapeId === _shape.shapeId && shape.shapeId === shape.tileId) {
        if ( Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > Math.max(idx, index) ) {
          if( isEqual(svgPath.dimensions[idx], svgPath.dimensions[index]) )
            arrTileIndices.push(index)
        }
      }
    })
    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });

    drawSend({
      type: "REPLACE_TILE",
      replaceTileContext: {
        replaceTileIndices: arrTileIndices,
        lockShape: svgPath.shape_index[idx],
        lockWidth: width,
        lockHeight: height,
        layoutShapes: layoutShapes
      }
    });

    drawSend({
      type: "HIGHLIGHT_TILES",
      highlightTile: { index: idx, on: false },
      segment: tileWrapper
    });
    
    dispatch('go', { path: 'select-tile'})
  };

  const handleChangeRotation = debounce((e) => {
    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });
    rotation = Math.round(parseFloat(e.target.value))
    drawSend({
      type: "CHANGE_ROTATION",
      newAngle: rotation,
      segment: tileWrapper
    });
  }, 300);

  
  const handleChangeGap = debounce((e) => {
    let gap = parseFloat(e.target.value);
    if (gap < minGap || gap > maxGap) return;

    const newGap = isMetricMeter
        ? gap * 0.1
        : gap * INCH;

    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });
    drawSend({
      type: "CHANGE_LAYOUT_GAP",
      newGap: newGap / TILE_TRANSFORM_SCALE,
      segment: tileWrapper
    });
  }, 300);

  const handleChangeColor = (v: string) => {
    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });
    drawSend({
      type: "CHANGE_GROUT_COLOR",
      newGroutColor: v,
      segment: tileWrapper
    });
  }
  
  const handleClickEyeDropper = () => {
    drawSend({
      type: "ENTER_SELECT",
      segment: tileWrapper
    });
    drawSend({ type: "EYEDROPPER" });
    currentTool.set(TOOLS.EYE_DROPPER);
  }

  const gotoListPattern = () => {
    dispatch('go', { path: 'list-pattern'})
  }

  const gotoCopyLayout = () => {
    dispatch('go', { path: 'copy-layout'})
  }
</script>

<Notifications bind:this={notifications} />
<div class="w-toolbox flex flex-col gap-3 px-2.5">
  <div class="px-1">
    <Tooltip tooltip={$_("side_menu.layout.copy_layout_tooltip")}>
      <Button
        variant="primary-hover"
        title={$_("side_menu.layout.copy_layout")}
        icon="fa-solid fa-brush fa-lg"
        iconPos="center"
        class="w-full rounded-full"
        size="sm"
        on:click={gotoCopyLayout}
      />
    </Tooltip>
  </div>
  <div class="px-1 py-7 border-t border-gray-100">
    <div class="text-base font-semibold text-title mb-4">
      {$_("side_menu.layout.pattern")}
    </div>
    <div 
      class="group w-full flex items-center text-input-label p-2.5 gap-2 border border-gray-100 hover:border-primary-600 rounded-lg cursor-pointer"
      on:click={gotoListPattern}
    >
      {#if currentGeometry?.preview_image || currentGeometry?.svg_path}
      <img
        src={currentGeometry.preview_image || currentGeometry.svg_path}
        alt={currentGeometry.name}
        width="40px"
        height="40px"
      />
      {:else}
      <div class="w-10 h-10 bg-gray-300" />
      {/if}
      <p class="flex-1 font-medium text-sm truncate">{currentGeometry?.name || $_("side_menu.layout.no_pattern")}</p>
      <i class="fa-solid fa-chevron-right fa-custom-lg group-hover:text-primary-600">
    </div>
  </div>
  
  {#if currentGeometry}
  <div class="px-1 py-7 border-t border-gray-100">
    <div class="text-base font-semibold text-title mb-4">
      {$_("side_menu.layout.materials")}
    </div>
    <div class="flex flex-col gap-4">
      {#each layoutShapes as shape, idx}
        <div class="flex items-center gap-1">
          {#if shape.tileData?.images}
          <div class="group">
            <div class="w-6 h-6 rounded-full shrink-0 bg-gray-100 hover:bg-primary-600 text-input-label hover:text-white flex items-center justify-center mb-1">
              <i class="fa-solid fa-info fa-xs" />
            </div>
            <div class="hidden group-hover:block fixed bottom-2 left-3 transform translate-x-toolbox">
              <TileInfo info={shape.tileData.info} />
            </div>
          </div>
          {:else}
          <Tooltip tooltip={$_("side_menu.layout.material_tooltip")} placement="right">
            <div class="w-6 h-6 rounded-full shrink-0 bg-gray-100 hover:bg-primary-600 text-input-label hover:text-white flex items-center justify-center">
              <i class="fa-solid fa-info fa-xs" />
            </div>
          </Tooltip>
          {/if}
          <div 
            class="group w-full flex items-center text-input-label p-2.5 gap-2 border border-gray-100 hover:border-primary-600 rounded-lg cursor-pointer  overflow-hidden"
            on:mouseenter={() => highlightTiles(idx)}
            on:mouseleave={() => removeHighlight(idx)}
            on:click={() => replaceTile(shape, idx)}
          >
            <svg viewBox="-1 -1 2 2" width="40px" height="40px">
              <PassiveTile shape={shape} tileID={shape.tileId} strokeWidth={0.1}/>
            </svg>
            <div class="flex-1 overflow-hidden">
              <p class="font-medium text-sm truncate">{shape.tileData?.filterId ? shape.name : $_("side_menu.layout.no_material")}</p>
              <p class="text-sm text-gray-300">Size: {getMetricWithUnit(shape.width, $drawState.context.currentMetricUnit, false, true)} x {getMetricWithUnit(shape.height, $drawState.context.currentMetricUnit, false, true)}</p>
            </div>
            <i class="fa-solid fa-chevron-right fa-custom-lg group-hover:text-primary-600">
          </div>
        </div>
      {/each}
    </div>
  </div>

  <div class="px-1 py-7 border-t border-gray-100">
    <div class="flex items-center">
      <div class="w-full text-base font-semibold text-title">
        {$_("side_menu.rotation")}
      </div>
      <Input
        type="number"
        min={0}
        max={360}
        value={rotation}
        unit="°"
        on:input={handleChangeRotation}
        fullWidth
      />
    </div>
  </div>

  <div class="px-1 py-7 border-t border-gray-100">
    <p class="text-base font-semibold text-title mb-4">{$_("side_menu.layout.grout")}</p>
    <div class="flex items-center gap-2 mb-2.5">
      <div class="w-full text-sm text-input-label">
        {$_("side_menu.thickness")}
      </div>
      <Input
        type="number"
        min={minGap}
        max={maxGap}
        step={isMetricMeter ? 1 : 1 / 16}
        value={getMetricWithUnit(
          (tileWrapper?.tileLayout.gapSize * TILE_TRANSFORM_SCALE) * (isMetricMeter ? 10 :  1),
          $drawState.context.currentMetricUnit,
          $drawState.context.currentMetricUnit === METRIC_UNITS[0]
        )}
        unit={isMetricMeter ? "mm" : METRIC_UNITS[1] }
        on:input={handleChangeGap}
        fullWidth
      />
    </div>
    <div class="flex items-center gap-2">
      <div class="w-full flex items-center justify-between gap-2">
        <div class="text-sm text-input-label">
          {$_("side_menu.color")}
        </div>
        <Tooltip tooltip={$_("side_menu.layout.grout_color_tooltip")}>
          <button class="w-9 h-9 flex items-center justify-center border-0 bg-gray-100 rounded-full" on:click={handleClickEyeDropper}>
            <i class="fa fa-eye-dropper font-black text-secondary-500 fa-custom-lg" />
          </button>
        </Tooltip>
      </div>
      <ColorPicker hex={groutColor} onChange={handleChangeColor} />
    </div>
  </div>
  {:else}
  <div class="px-1 py-7 border-t border-gray-100">
    <div class="flex items-center gap-2">
      <div class="w-full flex items-center justify-between gap-2">
        <p class="text-base font-semibold text-title">{$_("side_menu.color")}</p>
        <Tooltip tooltip={$_("side_menu.layout.grout_color_tooltip")}>
          <button class="w-9 h-9 flex items-center justify-center border-0 bg-gray-100 rounded-full" on:click={handleClickEyeDropper}>
            <i class="fa fa-eye-dropper font-black text-secondary-500 fa-custom-lg" />
          </button>
        </Tooltip>
      </div>
      <ColorPicker hex={groutColor} onChange={handleChangeColor} />
    </div>
  </div>
  {/if}
</div>