import Vector2 from "./Vector2";
import BoundingBox from "./BoundingBox";
import Matrix2D from "./Matrix2D";
import Intersection from "./Intersections";
import { PathParser } from "kld-path-parser";
import { PathHandler } from "./PathHandler";
import { GaussLegendre } from "./GaussLegendre";
import { TOLERANCE_ANGLE } from "src/global/variable";
const MAX_RESOLUTION = 100;
const TOLERANCE_LENGTH = 0.1;
export class Path {
    static fromSvg(path) {
        const handler = new PathHandler();
        const parser = new PathParser();
        parser.setHandler(handler);
        parser.parseData(path);
        const segments = handler.getResult();
        const validSegments = segments.filter((s) => s.length > TOLERANCE_LENGTH);
        return new Path(validSegments);
    }
    static isCounterClockwise(segments) {
        let sum = 0;
        for (let i = 0; i < segments.length; i++) {
            const p = segments[i].start;
            const np = segments[(i + 1) % segments.length].start;
            sum += p.x * np.y - np.x * p.y;
        }
        return sum <= 0;
    }
    static flipCWToCCW(segments) {
        return segments.map((s) => s.flip()).reverse();
    }
    bounds;
    center;
    segments;
    constructor(segments) {
        const finalSegments = Path.isCounterClockwise(segments)
            ? segments
            : Path.flipCWToCCW(segments);
        this.segments = finalSegments;
        this.bounds = BoundingBox.fromBoundingBoxes(finalSegments.map((s) => s.bounds));
        let center = new Vector2(0, 0);
        for (const segment of finalSegments) {
            center = center.add(segment.start);
        }
        center = center.mul(1.0 / finalSegments.length);
        this.center = center;
    }
    get diameter() {
        return this.bounds.maxExtent * 2;
    }
    contains(point) {
        if (!this.bounds.contains(point)) {
            return false;
        }
        const len = this.segments.length;
        let counter = 0;
        const ray = new LineSegment(point, new Vector2(Math.max(point.x, this.bounds.max.x + 1), point.y));
        for (let i = 0; i < len; i++) {
            const intersection = Intersection.intersectSegments(this.segments[i], ray);
            counter += intersection.points.length;
        }
        return counter % 2 === 1;
    }
    intersects(other) {
        if (!this.bounds.intersects(other.bounds)) {
            return false;
        }
        if (other.contains(this.center) || this.contains(other.center)) {
            return true;
        }
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.segments.length; i++) {
            // tslint:disable-next-line: prefer-for-of
            for (let j = i; j < other.segments.length; j++) {
                const s1 = this.segments[i];
                const s2 = other.segments[j];
                const intersection = Intersection.intersectSegments(s1, s2);
                if (intersection.points.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    get segmentCount() {
        return this.segments.length;
    }
    offset(by) {
        if (this.segments.length === 0) {
            return this;
        }
        // TODO: imprecise but good enough for now, the correct solution is to use proper offset curves
        const offsetPoints = this.offsetPoints(by);
        const offsetSegments = [];
        for (let i = 0; i < this.segments.length; i++) {
            offsetSegments.push(offsetPoints.offsetSegment(i));
        }
        return new Path(offsetSegments);
    }
    offsetPoints(by) {
        let previous = this.segments[this.segments.length - 1];
        const offsetPoints = [];
        for (const segment of this.segments) {
            const t1 = previous.tangentAt(1);
            const t2 = segment.tangentAt(0);
            const p = segment.start;
            const offsetP = insetCorner(p.sub(t1), p, p.add(t2), -by);
            offsetPoints.push(offsetP);
            previous = segment;
        }
        return new OffsetPoints(offsetPoints, by, this.segments);
    }
    closestPoint(to) {
        let closest = this.segments[0].closestPoint(to);
        for (const segment of this.segments) {
            const c = segment.closestPoint(to);
            if (c.distance < closest.distance) {
                closest = c;
            }
        }
        return closest;
    }
    transformed(m) {
        if (m === null || m === undefined || m === Matrix2D.IDENTITY) {
            return this;
        }
        const newSegments = [];
        for (const segment of this.segments) {
            newSegments.push(segment.transform(m));
        }
        return new Path(newSegments);
    }
    toSvgPath() {
        if (this.segments.length === 0) {
            return "";
        }
        let result = "M" + this.segments[0].start.x + "," + this.segments[0].start.y;
        for (const segment of this.segments) {
            result += " " + segment.toSvgPathCommand();
        }
        result += " Z";
        return result;
    }
    toPoints() {
        if (this.segments.length === 0) {
            return [];
        }
        let result = [];
        result.push(new Vector2(this.segments[0].start.x, this.segments[0].start.y));
        for (const segment of this.segments) {
            result.push(...segment.toPoints());
        }
        return result;
    }
    drawToCanvas(ctx) {
        ctx.beginPath();
        for (const segment of this.segments) {
            segment.drawToCanvas(ctx);
        }
        ctx.closePath();
    }
    debugTangentsPath() {
        let result = "";
        for (const segment of this.segments) {
            const t = segment.tangentAt(0.75).mul(0.25);
            const a1 = t.normalVector().add(t).mul(-0.5).mul(0.5);
            const a2 = t.normalVector().negated().add(t).mul(-0.5).mul(0.5);
            const p = segment.pointAt(0.75);
            result +=
                "M " +
                    p.x +
                    " " +
                    p.y +
                    " l " +
                    t.x +
                    " " +
                    t.y +
                    " l " +
                    a1.x +
                    " " +
                    a1.y +
                    " M " +
                    (p.x + t.x) +
                    " " +
                    (p.y + t.y) +
                    " l " +
                    a2.x +
                    " " +
                    a2.y;
        }
        return result;
    }
    resizeAndCenter(targetSize) {
        const baseSize = [this.bounds.size.x, this.bounds.size.y];
        let finalTargetSize = baseSize.slice();
        if (targetSize[0] === undefined) {
            finalTargetSize = [
                (targetSize[1] * baseSize[0]) / baseSize[1],
                targetSize[1],
            ];
        }
        else if (targetSize[1] === undefined) {
            finalTargetSize = [
                targetSize[0],
                (targetSize[0] * baseSize[1]) / baseSize[0],
            ];
        }
        else {
            finalTargetSize = targetSize;
        }
        const propX = finalTargetSize[0] / this.bounds.size.x;
        const propY = finalTargetSize[1] / this.bounds.size.y;
        const offset = this.bounds.center.negated();
        // if( propX !== propY ) { // pointless control at the moment
        if (true) { // this "if" has the sole purpose of maintaining the branch and making commenting/uncommenting faster
            // find horizontal parallel lines with pair.
            const mx = Matrix2D.scale(new Vector2(propY, propY)).mulMat(Matrix2D.translation(offset));
            const scaledXPath = this.transformed(mx);
            const hzLines = scaledXPath.segments.filter((segment) => segment.start.y === segment.end.y);
            const upLines = [];
            const downLines = [];
            hzLines.forEach((line, idx) => {
                for (let i = 0; i < hzLines.length; i++) {
                    if (idx === i)
                        continue;
                    const minX = Math.min(hzLines[i].start.x, hzLines[i].end.x);
                    const maxX = Math.max(hzLines[i].start.x, hzLines[i].end.x);
                    if ((line.start.x >= minX && line.start.x <= maxX) || (line.end.x >= minX && line.end.x <= maxX)) {
                        if (line.start.y > hzLines[i].start.y)
                            upLines.push(line);
                        else
                            downLines.push(line);
                        break;
                    }
                }
            });
            const my = Matrix2D.scale(new Vector2(propX, propX)).mulMat(Matrix2D.translation(offset));
            const scaledYPath = this.transformed(my);
            const vtLines = scaledYPath.segments.filter((segment) => segment.start.x === segment.end.x);
            const leftLines = [];
            const rightLines = [];
            vtLines.forEach((line, idx) => {
                for (let i = 0; i < vtLines.length; i++) {
                    if (idx === i || i >= hzLines.length)
                        continue;
                    const minY = Math.min(vtLines[i].start.y, vtLines[i].end.y);
                    const maxY = Math.max(vtLines[i].start.y, vtLines[i].end.y);
                    if ((line.start.y >= minY && line.start.y <= maxY) || (line.end.y >= minY && line.end.y <= maxY)) {
                        if (line.start.x > hzLines[i].start.x)
                            rightLines.push(line);
                        else
                            leftLines.push(line);
                        break;
                    }
                }
            });
            if (upLines.length > 0 && downLines.length > 0) {
                const segments = scaledXPath.segments;
                const increaseAmount = finalTargetSize[0] - this.bounds.size.x * propY;
                const startIdx = segments.findIndex((segment) => (segment.start.x <= scaledXPath.center.x && segment.end.x >= scaledXPath.center.x) || (segment.start.x >= scaledXPath.center.x && segment.end.x <= scaledXPath.center.x));
                const newSegments = [];
                for (let i = 0; i < segments.length; i++) {
                    const currentIdx = (startIdx + i) % segments.length;
                    const currentSeg = segments[currentIdx];
                    const prevSeg = i > 0 ? newSegments[newSegments.length - 1] : null;
                    let scaleX = 1;
                    if (upLines.find((line) => line.start.x === currentSeg.start.x && line.start.y === currentSeg.start.y)) {
                        scaleX = 1 + (increaseAmount / upLines.length) / currentSeg.length;
                    }
                    else if (downLines.find((line) => line.start.x === currentSeg.start.x && line.start.y === currentSeg.start.y)) {
                        scaleX = 1 + (increaseAmount / downLines.length) / currentSeg.length;
                    }
                    const m = Matrix2D.scale(new Vector2(scaleX, 1));
                    const transformed = currentSeg.transform(m);
                    const offset = prevSeg ? new Vector2(prevSeg.end.x - transformed.start.x, 0) : new Vector2(0, 0);
                    newSegments.push(transformed.transform(Matrix2D.translation(offset)));
                }
                const interPath = new Path(newSegments);
                return interPath.transformed(Matrix2D.translation(interPath.center.negated()));
            }
            else if (leftLines.length > 0 && rightLines.length > 0) {
                const segments = scaledYPath.segments;
                const increaseAmount = finalTargetSize[1] - this.bounds.size.y * propX;
                const startIdx = segments.findIndex((segment) => (segment.start.y <= scaledYPath.center.y && segment.end.y >= scaledYPath.center.y) || (segment.start.y >= scaledYPath.center.y && segment.end.y <= scaledYPath.center.y));
                const newSegments = [];
                for (let i = 0; i < segments.length; i++) {
                    const currentIdx = (startIdx + i) % segments.length;
                    const currentSeg = segments[currentIdx];
                    const prevSeg = i > 0 ? newSegments[newSegments.length - 1] : null;
                    let scaleY = 1;
                    if (rightLines.find((line) => line.start.x === currentSeg.start.x && line.start.y === currentSeg.start.y)) {
                        scaleY = 1 + (increaseAmount / rightLines.length) / currentSeg.length;
                    }
                    else if (leftLines.find((line) => line.start.x === currentSeg.start.x && line.start.y === currentSeg.start.y)) {
                        scaleY = 1 + (increaseAmount / leftLines.length) / currentSeg.length;
                    }
                    const m = Matrix2D.scale(new Vector2(1, scaleY));
                    const transformed = currentSeg.transform(m);
                    const offset = prevSeg ? new Vector2(prevSeg.end.y - transformed.start.y, 0) : new Vector2(0, 0);
                    newSegments.push(transformed.transform(Matrix2D.translation(offset)));
                }
                const interPath = new Path(newSegments);
                return interPath.transformed(Matrix2D.translation(interPath.center.negated()));
            }
        }
        const scale = new Vector2(propX, propY);
        const m = Matrix2D.scale(scale).mulMat(Matrix2D.translation(offset));
        return this.transformed(m);
    }
}
export class OffsetPoints {
    points;
    by;
    originalSegments;
    constructor(points, by, originalSegments) {
        this.points = points;
        this.by = by;
        this.originalSegments = originalSegments;
    }
    offsetSegment(atIndex) {
        return this.originalSegments[atIndex].offset(-this.by, this.points[atIndex], this.points[(atIndex + 1) % this.points.length]);
    }
}
function insetCorner(p1, p2, p3, margin) {
    const n1 = p2.sub(p1).normalVector().normalized().mul(margin);
    const n2 = p3.sub(p2).normalVector().normalized().mul(margin);
    const o1 = p1.add(n1);
    const o2 = p2.add(n1);
    const o3 = p3.add(n2);
    const o4 = p2.add(n2);
    const d = (o1.x - o2.x) * (o3.y - o4.y) - (o1.y - o2.y) * (o3.x - o4.x);
    if (Math.abs(d) <= TOLERANCE_LENGTH) {
        return o2; // parallel, just move in the direction of the normal
    }
    const t = ((o1.x - o3.x) * (o3.y - o4.y) - (o1.y - o3.y) * (o3.x - o4.x)) / d;
    return o2.sub(o1).mul(t).add(o1);
}
export function normalizeAngle(radians) {
    const normal = radians % (Math.PI * 2);
    return normal < 0.0 ? normal + Math.PI * 2 : normal;
}
export class ClosestPoint {
    point;
    t;
    distance;
    signedDistance;
    debug;
    constructor(point, t, distance, signedDistance, debug = "") {
        this.point = point;
        this.t = t;
        this.distance = distance;
        this.signedDistance = signedDistance;
        this.debug = debug;
    }
}
class BasePathSegment {
    get center() {
        return this.pointAt(0.5);
    }
    get quarter() {
        return this.pointAt(0.25);
    }
    get quarter3() {
        return this.pointAt(0.75);
    }
    get third() {
        return this.pointAt(1 / 3);
    }
    get third2() {
        return this.pointAt(2 / 3);
    }
    normalAt(t) {
        return this.tangentAt(t).normalVector().negated();
    }
    angle(at = 0.5) {
        const tangent = this.tangentAt(at);
        const dot = tangent.dot(Vector2.UNIT_X);
        const determinant = tangent.crossMagnitude(Vector2.UNIT_X);
        return Math.atan2(determinant, dot);
    }
    angleDegrees(at = 0.5) {
        return (this.angle(at) * 180) / Math.PI;
    }
    signedDistance(point, closestPoint, normal) {
        const p = point.sub(closestPoint);
        return normal.dot(p);
    }
}
export class CanonicalArcSegment {
    center;
    radiusX;
    radiusY;
    angleStartRad;
    angleEndRad;
    constructor(center, radiusX, radiusY, angleStartRad, angleEndRad) {
        this.center = center;
        this.radiusX = radiusX;
        this.radiusY = radiusY;
        this.angleStartRad = angleStartRad;
        this.angleEndRad = angleEndRad;
    }
}
export class ArcSegment extends BasePathSegment {
    start;
    end;
    radiusX;
    radiusY;
    angleRadians;
    arcFlag;
    sweepFlag;
    length;
    bounds;
    canonical;
    constructor(start, end, radiusX, radiusY, angleRadians, arcFlag, sweepFlag) {
        super();
        this.start = start;
        this.end = end;
        this.radiusX = radiusX;
        this.radiusY = radiusY;
        this.angleRadians = angleRadians;
        this.arcFlag = arcFlag;
        this.sweepFlag = sweepFlag;
        this.canonical = this.toCanonical();
        this.length = this.computeLength();
        this.bounds = this.computeBbox();
    }
    tangentAt(t) {
        const sign = Math.sign(this.canonical.angleEndRad - this.canonical.angleStartRad);
        const a = this.angleAt(t);
        const dx = -this.canonical.radiusX * Math.sin(a);
        const dy = this.canonical.radiusY * Math.cos(a);
        return new Vector2(dx * sign, dy * sign).normalized();
    }
    pointAt(t) {
        const a = this.angleAt(t);
        return this.pointAtAngle(a);
    }
    toSvgPathCommand() {
        return ("A" +
            this.radiusX +
            "," +
            this.radiusY +
            "," +
            (this.angleRadians * 180) / Math.PI +
            "," +
            (this.arcFlag ? "1" : "0") +
            "," +
            (this.sweepFlag ? "1" : "0") +
            "," +
            this.end.x +
            "," +
            this.end.y);
    }
    toPoints() {
        let result = [];
        for (let i = 0; i <= MAX_RESOLUTION; i++) {
            result.push(this.pointAt(i / MAX_RESOLUTION));
        }
        return result;
    }
    transform(m) {
        const newStart = m.mulVec(this.start);
        const newEnd = m.mulVec(this.end);
        const s = m.lossyScale;
        const newRx = this.radiusX * s.x;
        const newRy = this.radiusY * s.y;
        return new ArcSegment(newStart, newEnd, newRx, newRy, this.angleRadians, this.arcFlag, this.sweepFlag);
    }
    offset(by, newStart, newEnd) {
        const sign = Math.sign(this.canonical.angleEndRad - this.canonical.angleStartRad);
        return new ArcSegment(newStart, newEnd, this.radiusX + by * sign, this.radiusY + by * sign, this.angleRadians, this.arcFlag, this.sweepFlag);
    }
    // https://stackoverflow.com/questions/22959698/distance-from-given-point-to-given-ellipse
    closestPoint(to) {
        const px = Math.abs(to.x - this.canonical.center.x);
        const py = Math.abs(to.y - this.canonical.center.y);
        let tx = 0.707;
        let ty = 0.707;
        const a = this.canonical.radiusX;
        const b = this.canonical.radiusY;
        let angle = 0;
        if (a !== b) {
            for (let i = 0; i < 3; i++) {
                const x = a * tx;
                const y = b * ty;
                const ex = ((a * a - b * b) * tx * tx * tx) / a;
                const ey = ((b * b - a * a) * ty * ty * ty) / b;
                const rx = x - ex;
                const ry = y - ey;
                const qx = px - ex;
                const qy = py - ey;
                const r = Math.hypot(ry, rx);
                const q = Math.hypot(qy, qx);
                tx = Math.min(1, Math.max(0, ((qx * r) / q + ex) / a));
                ty = Math.min(1, Math.max(0, ((qy * r) / q + ey) / b));
                const ht = Math.hypot(ty, tx);
                tx /= ht;
                ty /= ht;
            }
            const closestToEllipse = new Vector2(Math.abs(a * tx) * Math.sign(to.x - this.canonical.center.x), Math.abs(a * ty) * Math.sign(to.y - this.canonical.center.y));
            angle = Math.atan2(closestToEllipse.y, closestToEllipse.x);
        }
        else {
            // Circle
            const v = to.sub(this.canonical.center);
            if (v.lengthSquared() < TOLERANCE_LENGTH * TOLERANCE_LENGTH) {
                // If the point is the center of the circle, any point on the circle will do
                return new ClosestPoint(this.start, 0, to.distance(this.start), this.signedDistance(to, this.start, this.normalAt(0)));
            }
            angle = Math.atan2(v.y, v.x);
        }
        const startRadians = this.canonical.angleStartRad;
        const endRadians = this.canonical.angleEndRad;
        const clampAngle = (start, end, mid) => {
            if (start > end) {
                const result = clampAngle(-start, -end, mid);
                result[1] = 1 - result[1];
                return result;
            }
            const newEnd = end - start < 0.0 ? end - start + Math.PI * 2 : end - start;
            const newMid = mid - start < 0.0 ? mid - start + Math.PI * 2 : mid - start;
            if (newMid < newEnd) {
                return [mid, newMid / newEnd];
            }
            else if (this.start.distanceSquared(to) < this.end.distanceSquared(to)) {
                return [start, 0];
            }
            else {
                return [end, 1];
            }
        };
        const [clampedAngle, t] = clampAngle(startRadians, endRadians, angle);
        const closest = this.pointAtAngle(clampedAngle);
        return new ClosestPoint(closest, t, closest.sub(to).length(), this.signedDistance(to, closest, this.normalAt(t)));
    }
    flip() {
        return new ArcSegment(this.end, this.start, this.radiusX, this.radiusY, this.angleRadians, this.arcFlag, !this.sweepFlag);
    }
    drawToCanvas(ctx) {
        ctx.arc(this.canonical.center.x, this.canonical.center.y, this.canonical.radiusX, this.canonical.angleStartRad, this.canonical.angleEndRad);
    }
    toString() {
        return ("Arc[start=" +
            this.start.toString() +
            ", end=" +
            this.end.toString() +
            ", rx=" +
            this.canonical.radiusX +
            ", ry=" +
            this.canonical.radiusY +
            ", center=" +
            this.canonical.center.toString() +
            ", arcFlag=" +
            this.arcFlag +
            ", sweepFlag=" +
            this.sweepFlag +
            ", angleStart=" +
            (this.canonical.angleStartRad * 180) / Math.PI +
            ", angleEnd=" +
            (this.canonical.angleEndRad * 180) / Math.PI +
            "]");
    }
    angleAt(t) {
        return (this.canonical.angleStartRad * (1.0 - t) + this.canonical.angleEndRad * t);
    }
    pointAtAngle(angleRad) {
        const x = this.canonical.radiusX * Math.cos(angleRad);
        const y = this.canonical.radiusY * Math.sin(angleRad);
        return this.canonical.center.add(new Vector2(x, y));
    }
    computeBbox() {
        const points = [];
        points.push(this.start);
        points.push(this.end);
        let startRadians = this.canonical.angleStartRad;
        let endRadians = this.canonical.angleEndRad;
        // swap if end is lower, so start is always the lower one
        if (endRadians < startRadians) {
            [startRadians, endRadians] = [endRadians, startRadians];
        }
        // move everything to the positive domain, simultaneously
        if (startRadians < 0 || endRadians < 0) {
            startRadians += Math.PI * 2;
            endRadians += Math.PI * 2;
        }
        for (let i = 0; i <= 4; i++) {
            let a = (Math.PI / 2) * i;
            if (a < startRadians) {
                a += Math.PI * 2;
            }
            if (startRadians <= a && a <= endRadians) {
                points.push(this.pointAtAngle(a));
            }
        }
        return BoundingBox.fromPoints(points);
    }
    computeLength() {
        if (Math.abs(this.canonical.radiusX - this.canonical.radiusY) < TOLERANCE_ANGLE) {
            // circle is easy
            const fraction = Math.abs(this.canonical.angleEndRad - this.canonical.angleStartRad);
            return fraction * this.canonical.radiusX;
        }
        const self = this;
        function segmentLength(from, to) {
            const midAngle = (from + to) * 0.5;
            const midPoint = self.pointAtAngle(midAngle);
            const fromPoint = self.pointAtAngle(from);
            const toPoint = self.pointAtAngle(to);
            const lineLength = fromPoint.sub(toPoint).length();
            const polyLineLength = fromPoint.sub(midPoint).length() + midPoint.sub(toPoint).length();
            if (Math.abs(lineLength - polyLineLength) < TOLERANCE_LENGTH) {
                return polyLineLength;
            }
            return segmentLength(from, midAngle) + segmentLength(midAngle, to);
        }
        if (this.canonical.angleStartRad > this.canonical.angleEndRad) {
            return segmentLength(this.canonical.angleStartRad, this.canonical.angleEndRad + 2 * Math.PI);
        }
        else {
            return segmentLength(this.canonical.angleStartRad, this.canonical.angleEndRad);
        }
    }
    /**
     * Based on the SVG 1.1 specification, Appendix F: Implementation Requirements,
     * Section F.6 "Elliptical arc implementation notes"
     * {@see https://www.w3.org/TR/SVG11/implnote.html#ArcImplementationNotes}
     */
    toCanonical() {
        const angle = this.angleRadians;
        const c = Math.cos(angle);
        const s = Math.sin(angle);
        const TOLERANCE = 1e-6;
        // Section (F.6.5.1)
        const halfDiff = this.start.sub(this.end).mul(0.5);
        const x1p = halfDiff.x * c + halfDiff.y * s;
        const y1p = halfDiff.x * -s + halfDiff.y * c;
        // Section (F.6.6.1)
        let rx = Math.abs(this.radiusX);
        let ry = Math.abs(this.radiusY);
        // Section (F.6.6.2)
        const x1px1p = x1p * x1p;
        const y1py1p = y1p * y1p;
        const lambda = x1px1p / (rx * rx) + y1py1p / (ry * ry);
        // Section (F.6.6.3)
        if (lambda > 1) {
            const f = Math.sqrt(lambda);
            rx *= f;
            ry *= f;
        }
        // Section (F.6.5.2)
        const rxrx = rx * rx;
        const ryry = ry * ry;
        const rxy1 = rxrx * y1py1p;
        const ryx1 = ryry * x1px1p;
        let factor = (rxrx * ryry - rxy1 - ryx1) / (rxy1 + ryx1);
        if (Math.abs(factor) < TOLERANCE) {
            factor = 0;
        }
        let sq = Math.sqrt(factor);
        if (this.arcFlag === this.sweepFlag) {
            sq = -sq;
        }
        // Section (F.6.5.3)
        const mid = this.start.add(this.end).mul(0.5);
        const cxp = (sq * rx * y1p) / ry;
        const cyp = (sq * -ry * x1p) / rx;
        // Section (F.6.5.5 - F.6.5.6)
        const xcr1 = (x1p - cxp) / rx;
        const xcr2 = (x1p + cxp) / rx;
        const ycr1 = (y1p - cyp) / ry;
        const ycr2 = (y1p + cyp) / ry;
        const theta1 = new Vector2(1, 0).angleBetween(new Vector2(xcr1, ycr1));
        // let deltaTheta = normalizeAngle(new Vector2D(xcr1, ycr1).angleBetween(new Vector2D(-xcr2, -ycr2)));
        let deltaTheta = new Vector2(xcr1, ycr1).angleBetween(new Vector2(-xcr2, -ycr2));
        deltaTheta %= Math.PI * 2;
        if (this.sweepFlag === false && deltaTheta > 0) {
            deltaTheta -= 2 * Math.PI;
        }
        else if (this.sweepFlag === true && deltaTheta < 0) {
            deltaTheta += 2 * Math.PI;
        }
        return new CanonicalArcSegment(new Vector2(cxp * c - cyp * s + mid.x, cxp * s + cyp * c + mid.y), rx, ry, theta1, theta1 + deltaTheta);
    }
}
class BezierUtil {
    static derivativePoints(points) {
        const dpoints = [];
        const c = points.length - 1;
        for (let j = 0, dpt; j < c; j++) {
            dpt = new Vector2(c * (points[j + 1].x - points[j].x), c * (points[j + 1].y - points[j].y));
            dpoints.push(dpt);
        }
        return dpoints;
    }
}
export class QuadraticBezierSegment extends BasePathSegment {
    start;
    controlPoint;
    end;
    static fromPoints(start, controlPoint, end) {
        if (start.sub(controlPoint).lengthSquared() <= 1e-10 ||
            end.sub(controlPoint).lengthSquared() <= 1e-10) {
            return new LineSegment(start, end);
        }
        return new QuadraticBezierSegment(start, controlPoint, end);
    }
    static fromPointsInternal(start, controlPoint, end) {
        return new QuadraticBezierSegment(start, controlPoint, end);
    }
    static computeBoundingBox(start, cp, end) {
        const x0 = start.x;
        const y0 = start.y;
        const x1 = cp.x;
        const y1 = cp.y;
        const x2 = end.x;
        const y2 = end.y;
        let minx = Math.min(x0, x2);
        let maxx = Math.max(x0, x2);
        let miny = Math.min(y0, y2);
        let maxy = Math.max(y0, y2);
        if (x1 < minx || x1 > maxx) {
            const tx = (x1 - x0) / (2 * x1 - x0 - x2);
            const xe = (1 - tx) * (1 - tx) * x0 + 2 * (1 - tx) * tx * x1 + tx * tx * x2;
            minx = Math.min(minx, xe);
            maxx = Math.max(maxx, xe);
        }
        if (y1 < miny || y1 > maxy) {
            const ty = (y1 - y0) / (2 * y1 - y0 - y2);
            const ye = (1 - ty) * (1 - ty) * y0 + 2 * (1 - ty) * ty * y1 + ty * ty * y2;
            miny = Math.min(miny, ye);
            maxy = Math.max(maxy, ye);
        }
        return new BoundingBox(new Vector2(minx, miny), new Vector2(maxx, maxy));
    }
    length;
    bounds;
    derivativePoints;
    constructor(start, controlPoint, end, length, bounds) {
        super();
        this.start = start;
        this.controlPoint = controlPoint;
        this.end = end;
        this.derivativePoints = BezierUtil.derivativePoints([
            this.start,
            this.controlPoint,
            this.end,
        ]);
        this.bounds =
            bounds ??
                QuadraticBezierSegment.computeBoundingBox(this.start, this.controlPoint, this.end);
        this.length =
            length ??
                GaussLegendre.default.integrate((t) => {
                    const xy = this.derivative(t);
                    return Math.sqrt(xy.x * xy.x + xy.y * xy.y);
                }, 0, 1);
    }
    tangentAt(t) {
        const tangent = this.derivative(t);
        return new Vector2(tangent.x, tangent.y).normalized();
    }
    pointAt(t) {
        const p4 = this.start.lerp(this.controlPoint, t);
        const p5 = this.controlPoint.lerp(this.end, t);
        return p4.lerp(p5, t);
    }
    transform(m) {
        const newStart = m.mulVec(this.start);
        const newCp1 = m.mulVec(this.controlPoint);
        const newEnd = m.mulVec(this.end);
        const det = m.determinant2D;
        let newLength;
        if (Math.abs(Math.abs(det) - 1) <= 1e-6) {
            newLength = this.length;
        }
        return new QuadraticBezierSegment(newStart, newCp1, newEnd, newLength);
    }
    offset(by, newStart, newEnd) {
        // TODO: imprecise but good enough for now
        const offsetControl = insetCorner(this.start, this.controlPoint, this.end, by);
        return new QuadraticBezierSegment(newStart, offsetControl, newEnd);
    }
    closestPoint(to) {
        // TODO: create a simplified quadraticBezierPointDistance to save CPU
        const p = Intersection.cubicBezierPointDistance([this.start, this.controlPoint, this.controlPoint, this.end], to);
        return new ClosestPoint(p.point, p.t, p.distance, this.signedDistance(to, p.point, this.normalAt(p.t)));
    }
    flip() {
        return QuadraticBezierSegment.fromPointsInternal(this.end, this.controlPoint, this.start);
    }
    toSvgPathCommand() {
        return ("Q" +
            this.controlPoint.x +
            "," +
            this.controlPoint.y +
            "," +
            this.end.x +
            "," +
            this.end.y);
    }
    toPoints() {
        let result = [];
        for (let i = 0; i <= MAX_RESOLUTION; i++) {
            result.push(this.pointAt(i / MAX_RESOLUTION));
        }
        return result;
    }
    drawToCanvas(ctx) {
        ctx.bezierCurveTo(this.controlPoint.x, this.controlPoint.y, this.controlPoint.x, this.controlPoint.y, this.end.x, this.end.y);
    }
    toString() {
        return ("Quadratic[start=" +
            this.start.toString() +
            ", cp=" +
            this.controlPoint.toString() +
            ", end=" +
            this.end.toString() +
            "]");
    }
    derivative(t) {
        const a = 1 - t;
        const p = this.derivativePoints;
        const b = t;
        return new Vector2(a * p[0].x + b * p[1].x, a * p[0].y + b * p[1].y);
    }
}
export class CubicBezierSegment extends BasePathSegment {
    start;
    controlPoint1;
    controlPoint2;
    end;
    static fromPoints(start, controlPoint1, controlPoint2, end) {
        if (start.sub(controlPoint1).lengthSquared() <= 1e-10) {
            return QuadraticBezierSegment.fromPoints(start, controlPoint2, end);
        }
        if (controlPoint1.sub(controlPoint2).lengthSquared() <= 1e-10) {
            return QuadraticBezierSegment.fromPoints(start, controlPoint1, end);
        }
        if (controlPoint2.sub(end).lengthSquared() <= 1e-10) {
            return QuadraticBezierSegment.fromPoints(start, controlPoint1, end);
        }
        return new CubicBezierSegment(start, controlPoint1, controlPoint2, end);
    }
    static computeBoundingBox(start, cp1, cp2, end) {
        const x0 = start.x;
        const y0 = start.y;
        const x1 = cp1.x;
        const y1 = cp1.y;
        const x2 = cp2.x;
        const y2 = cp2.y;
        const x3 = end.x;
        const y3 = end.y;
        const tvalues = [];
        const xvalues = [];
        const yvalues = [];
        let a;
        let b;
        let c;
        for (let i = 0; i < 2; ++i) {
            if (i === 0) {
                b = 6 * x0 - 12 * x1 + 6 * x2;
                a = -3 * x0 + 9 * x1 - 9 * x2 + 3 * x3;
                c = 3 * x1 - 3 * x0;
            }
            else {
                b = 6 * y0 - 12 * y1 + 6 * y2;
                a = -3 * y0 + 9 * y1 - 9 * y2 + 3 * y3;
                c = 3 * y1 - 3 * y0;
            }
            if (Math.abs(a) < 1e-12) {
                if (Math.abs(b) < 1e-12) {
                    continue;
                }
                const t = -c / b;
                if (0 < t && t < 1) {
                    tvalues.push(t);
                }
                continue;
            }
            const b2ac = b * b - 4 * c * a;
            if (b2ac < 0) {
                if (Math.abs(b2ac) < 1e-12) {
                    const t = -b / (2 * a);
                    if (0 < t && t < 1) {
                        tvalues.push(t);
                    }
                }
                continue;
            }
            const sqrtb2ac = Math.sqrt(b2ac);
            const t1 = (-b + sqrtb2ac) / (2 * a);
            if (0 < t1 && t1 < 1) {
                tvalues.push(t1);
            }
            const t2 = (-b - sqrtb2ac) / (2 * a);
            if (0 < t2 && t2 < 1) {
                tvalues.push(t2);
            }
        }
        let j = tvalues.length;
        while (j--) {
            const t = tvalues[j];
            const mt = 1 - t;
            xvalues[j] =
                mt * mt * mt * x0 +
                    3 * mt * mt * t * x1 +
                    3 * mt * t * t * x2 +
                    t * t * t * x3;
            yvalues[j] =
                mt * mt * mt * y0 +
                    3 * mt * mt * t * y1 +
                    3 * mt * t * t * y2 +
                    t * t * t * y3;
        }
        xvalues.push(x0, x3);
        yvalues.push(y0, y3);
        const minPoint = new Vector2(Math.min(...xvalues), Math.min(...yvalues));
        const maxPoint = new Vector2(Math.max(...xvalues), Math.max(...yvalues));
        return new BoundingBox(minPoint, maxPoint);
    }
    length;
    bounds;
    derivativePoints;
    /**
     * Private constructor that assumes a correct non-degenerate curve. Use the fromPoints static
     * method to create a new instance of this bezier curve.
     */
    constructor(start, controlPoint1, controlPoint2, end, length, bounds) {
        super();
        this.start = start;
        this.controlPoint1 = controlPoint1;
        this.controlPoint2 = controlPoint2;
        this.end = end;
        this.derivativePoints = BezierUtil.derivativePoints([
            this.start,
            this.controlPoint1,
            this.controlPoint2,
            this.end,
        ]);
        this.length =
            length ??
                GaussLegendre.default.integrate((t) => {
                    const xy = this.derivative(t);
                    return Math.sqrt(xy.x * xy.x + xy.y * xy.y);
                }, 0, 1);
        this.bounds =
            bounds ??
                CubicBezierSegment.computeBoundingBox(this.start, this.controlPoint1, this.controlPoint2, this.end);
    }
    tangentAt(t) {
        const tangent = this.derivative(t);
        return new Vector2(tangent.x, tangent.y).normalized();
    }
    pointAt(t) {
        // first round of lerps
        const p5 = this.start.lerp(this.controlPoint1, t);
        const p6 = this.controlPoint1.lerp(this.controlPoint2, t);
        const p7 = this.controlPoint2.lerp(this.end, t);
        // second round of lerps
        const p8 = p5.lerp(p6, t);
        const p9 = p6.lerp(p7, t);
        return p8.lerp(p9, t);
    }
    transform(m) {
        const newStart = m.mulVec(this.start);
        const newCp1 = m.mulVec(this.controlPoint1);
        const newCp2 = m.mulVec(this.controlPoint2);
        const newEnd = m.mulVec(this.end);
        const det = m.determinant2D;
        let newLength;
        if (Math.abs(Math.abs(det) - 1) < 1e-6) {
            newLength = this.length; // No scale, we can keep the length
        }
        return new CubicBezierSegment(newStart, newCp1, newCp2, newEnd, newLength);
    }
    offset(by, newStart, newEnd) {
        // TODO: imprecise but good enough for now
        const offsetControl1 = insetCorner(this.start, this.controlPoint1, this.controlPoint2, by);
        const offsetControl2 = insetCorner(this.controlPoint1, this.controlPoint2, this.end, by);
        return new CubicBezierSegment(newStart, offsetControl1, offsetControl2, newEnd);
    }
    closestPoint(to) {
        const p = Intersection.cubicBezierPointDistance([this.start, this.controlPoint1, this.controlPoint2, this.end], to);
        return new ClosestPoint(p.point, p.t, p.distance, this.signedDistance(to, p.point, this.normalAt(p.t)));
    }
    flip() {
        return new CubicBezierSegment(this.end, this.controlPoint2, this.controlPoint1, this.start);
    }
    toSvgPathCommand() {
        return ("C" +
            this.controlPoint1.x +
            "," +
            this.controlPoint1.y +
            "," +
            this.controlPoint2.x +
            "," +
            this.controlPoint2.y +
            "," +
            this.end.x +
            "," +
            this.end.y);
    }
    toPoints() {
        let result = [];
        for (let i = 0; i <= MAX_RESOLUTION; i++) {
            result.push(this.pointAt(i / MAX_RESOLUTION));
        }
        return result;
    }
    drawToCanvas(ctx) {
        ctx.bezierCurveTo(this.controlPoint1.x, this.controlPoint1.y, this.controlPoint1.x, this.controlPoint1.y, this.end.x, this.end.y);
    }
    toString() {
        return ("Cubic[start=" +
            this.start.toString() +
            ", cp1=" +
            this.controlPoint1.toString() +
            ", cp2=" +
            this.controlPoint2.toString() +
            ", end=" +
            this.end.toString() +
            "]");
    }
    derivative(t) {
        const mt = 1 - t;
        const p = this.derivativePoints;
        const a = mt * mt;
        const b = mt * t * 2;
        const c = t * t;
        const ret = new Vector2(a * p[0].x + b * p[1].x + c * p[2].x, a * p[0].y + b * p[1].y + c * p[2].y);
        return ret;
    }
}
export class PolyBezierSegment extends BasePathSegment {
    segments;
    start;
    end;
    length;
    bounds;
    constructor(segments) {
        super();
        this.segments = segments;
        this.start = segments[0].start;
        const last = segments.length - 1;
        this.end = segments[last].end;
        let length = 0;
        let min = this.start;
        let max = this.start;
        for (const bezier of segments) {
            const currentLength = bezier.length;
            length += currentLength;
            const bb = bezier.bounds;
            min = min.min(bb.min);
            max = max.max(bb.max);
        }
        this.length = length;
        this.bounds = new BoundingBox(min, max);
    }
    tangentAt(t) {
        const c = this.paramToChildParam(t);
        return c.bezier.tangentAt(c.t);
    }
    pointAt(t) {
        const c = this.paramToChildParam(t);
        return c.bezier.pointAt(c.t);
    }
    transform(m) {
        const transformed = this.segments.map((b) => {
            return b.transform(m);
        });
        return new PolyBezierSegment(transformed);
    }
    offset(by, newStart, newEnd) {
        if (this.segments.length === 0) {
            return this;
        }
        // TODO: imprecise but good enough for now
        const resultSegments = [];
        let previous = this.segments[0];
        let previousStart = newStart;
        for (let i = 1; i < this.segments.length; i++) {
            const t1 = previous.tangentAt(1);
            const t2 = this.segments[i].tangentAt(0);
            const p = this.segments[i].start;
            const offsetP = insetCorner(p.sub(t1), p, p.add(t2), by);
            resultSegments.push(previous.offset(by, previousStart, offsetP));
            previous = this.segments[i];
            previousStart = offsetP;
        }
        resultSegments.push(previous.offset(by, previousStart, newEnd));
        return new PolyBezierSegment(resultSegments);
    }
    closestPoint(to) {
        let result = new ClosestPoint(this.start, 0, this.start.sub(to).length(), this.signedDistance(to, this.start, this.normalAt(0)));
        let sum = 0;
        for (const segment of this.segments) {
            const cp = segment.closestPoint(to);
            if (cp.point.sub(to).lengthSquared() < result.point.sub(to).lengthSquared()) {
                result = new ClosestPoint(cp.point, (sum + segment.length * cp.t) / this.length, cp.distance, cp.signedDistance);
            }
            sum += segment.length;
        }
        return result;
    }
    flip() {
        const newSegments = this.segments.map((s) => s.flip());
        return new PolyBezierSegment(newSegments);
    }
    toSvgPathCommand() {
        let result = "";
        for (const bezier of this.segments) {
            result += bezier.toSvgPathCommand() + " ";
        }
        return result;
    }
    toPoints() {
        let result = [];
        for (let i = 0; i <= MAX_RESOLUTION; i++) {
            result.push(this.pointAt(i / MAX_RESOLUTION));
        }
        return result;
    }
    drawToCanvas(ctx) {
        for (const segment of this.segments) {
            segment.drawToCanvas(ctx);
        }
    }
    toString() {
        return "Poly[" + this.segments.map((s) => s.toString()).join(", ") + "]";
    }
    paramToChildParam(t) {
        const l = t * this.length;
        let sumL = 0;
        for (let i = 0; i < this.segments.length; i++) {
            const startL = sumL;
            sumL += this.segments[i].length;
            if (sumL >= l) {
                const childT = (l - startL) / this.segments[i].length;
                return { bezier: this.segments[i], index: i, t: childT };
            }
        }
        return {
            bezier: this.segments[this.segments.length - 1],
            index: this.segments.length - 1,
            t: 1,
        };
    }
}
// tslint:disable-next-line: max-classes-per-file
export class LineSegment extends BasePathSegment {
    start;
    end;
    length;
    bounds;
    constructor(start, end) {
        super();
        this.start = start;
        this.end = end;
        this.length = this.end.sub(this.start).length();
        this.bounds = BoundingBox.fromPoints([start, end]);
    }
    tangentAt(t) {
        return this.end.sub(this.start).normalized();
    }
    pointAt(t) {
        return this.start.mul(1 - t).add(this.end.mul(t));
    }
    transform(m) {
        const newStart = m.mulVec(this.start);
        const newEnd = m.mulVec(this.end);
        return new LineSegment(newStart, newEnd);
    }
    offset(by, newStart, newEnd) {
        return new LineSegment(newStart, newEnd);
    }
    closestPoint(to) {
        const l2 = this.length * this.length;
        if (l2 === 0) {
            return new ClosestPoint(this.start, 0, this.start.sub(to).length(), this.signedDistance(to, this.start, this.normalAt(0)));
        }
        let t = ((to.x - this.start.x) * (this.end.x - this.start.x) +
            (to.y - this.start.y) * (this.end.y - this.start.y)) /
            l2;
        t = Math.max(0, Math.min(1, t));
        const closest = new Vector2(this.start.x + t * (this.end.x - this.start.x), this.start.y + t * (this.end.y - this.start.y));
        return new ClosestPoint(closest, t, closest.sub(to).length(), this.signedDistance(to, closest, this.normalAt(t)));
    }
    flip() {
        return new LineSegment(this.end, this.start);
    }
    toSvgPathCommand() {
        return "L" + this.end.x + "," + this.end.y;
    }
    toPoints() {
        return [new Vector2(this.end.x, this.end.y)];
    }
    drawToCanvas(ctx) {
        ctx.lineTo(this.end.x, this.end.y);
    }
    toString() {
        return ("Line[start=" +
            this.start.toString() +
            ", end=" +
            this.end.toString() +
            "]");
    }
}
