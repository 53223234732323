<script lang="ts">
  import { METRIC_UNITS } from "src/global/variable";
  import { createCustomEventDispatcher, parseImperialUnit } from "src/helpers";
  import { createEventDispatcher } from "svelte";

  export let type = "text",
    className = "",
    inputProps = "text-sm",
    label = "",
    value : string | number = "",
    icon = undefined,
    iconPos: "left" | "right" = "right",
    iconProps = "",
    iconFilled: boolean = true,
    iconTooltip : string | undefined = undefined,
    min : number | undefined = undefined,
    max : number | undefined = undefined,
    step = 1,
    onClick = undefined,
    onClickIcon = undefined,
    fullWidth = false,
    roundedFull = false,
    unit : string | undefined = undefined,
    error: boolean | undefined = undefined,
    disabled: boolean = false,
    spin: boolean = true;
  export {className as class};

  export const getWidth = () => inputEl.clientWidth

  const realDispatch = createCustomEventDispatcher();
  const dispatch = createEventDispatcher();
  let focused = false;
  let fValue = 0;
  let inputEl;
  let inputError: boolean;

  $: {
    if( error !== undefined ) {
      inputError = error;
    } else {
      if (min !== undefined && +value < min) {
        inputError = true;
      } else if (max !== undefined && +value > max) {
        inputError = true;
      } else {
        inputError = false;
      }
    }
  }
  // const handleKeyDown = (e) => {
  //   e.stopPropagation();
  // }
  // const handleInput = e => {
  //   // in here, you can switch on type and implement
  //   // whatever behaviour you need
  //   value = type.match(/^(number|range)$/)
  //     ? +e.target.value
  //     : e.target.value;
  // };

  $: {
    fValue = parseFloat(value?.toString());
    if (unit === METRIC_UNITS[1] && fValue.toString() !== value?.toString()) {
      fValue = parseImperialUnit(value?.toString())
    }
  }

  const handleIncrease = () => {

    if (disabled) return;

    let newValue = 
      unit === METRIC_UNITS[1] ? 
        Math.round((fValue + step) * 10000) / 10000 :
        Math.round((fValue + step) * 100) / 100;

    if( newValue < min )
      newValue = min;
    if( newValue > max )
      newValue = max;

    if( fValue === newValue ) return;

    value = newValue
    // inputEl.value = unit === METRIC_UNITS[1] ? getMetricWithUnit(value, METRIC_UNITS[1]) : value;
    if( inputEl ) {
      inputEl.value = newValue;
      realDispatch('input', inputEl, null)
    }
  }

  const handleDecrease = () => {

    if (disabled) return;

    let newValue = 
      unit === METRIC_UNITS[1] ? 
        Math.round((fValue - step) * 10000) / 10000 :
        Math.round((fValue - step) * 100) / 100;

    if( newValue < min )
      newValue = min;
    if( newValue > max )
      newValue = max;

    if( fValue === newValue ) return;

    value = newValue
    // inputEl.value = unit === METRIC_UNITS[1] ? getMetricWithUnit(value, METRIC_UNITS[1]) : value;
    if( inputEl ) {
      inputEl.value = newValue;
      realDispatch('input', inputEl, null)
    }
  }

</script>

<div class={`${fullWidth ? 'w-full' : 'w-fit'} ${className}`}>
  {#if label}
    <div class="text-sm font-bold text-black mb-1">{label}</div>
  {/if}
  <div class={`group relative bg-gray-200 focus-within:bg-primary-600 text-secondary-500 ${iconFilled ? "focus-within:text-white" : ""} ${roundedFull ? "rounded-full" : "rounded-xl"} ${(onClick ? "cursor-pointer" : "")}`} on:click={onClick} on:keydown>
    <input
      bind:this={inputEl}
      class={`${inputProps} ${fullWidth ? 'w-full' : ''} text-black flex items-center ${roundedFull ? "rounded-full" : "rounded-xl"} py-2 px-5 border ${inputError ? 'border-red-700' : 'border-gray-200 focus:border-primary-600 group-hover:border-primary-600'} focus-visible:outline-none ${
        (icon || $$slots.icon || (type === "number" && spin)) ? (iconPos === "left" ? "pl-14" : "pr-14") : ""}`}
      {step}
      {min}
      {max}
      type={unit && !focused ? 'text' : type}
      value={unit && !focused && unit !== METRIC_UNITS[1] ? value + unit : (unit === METRIC_UNITS[1] && focused) ? parseImperialUnit(value?.toString()) : value}
      {...$$restProps}
      disabled={disabled}
      on:keydown
      on:focus={() => {
        focused = true
        dispatch("focus")
      }}
      on:blur={() => {
        focused = false
        dispatch("blur")
      }}
      on:input
    />
    {#if icon || $$slots.icon}
      <div
        class={`absolute top-0 w-10 h-full p-px ${iconPos === "right" ? "right-0" : "left-0"} ${iconProps} ${onClickIcon ? "cursor-pointer" : ""}`}
        on:click={onClickIcon} on:keydown
      >
        <div class={`w-full h-full flex align-center relative
          ${iconFilled ? "bg-gray-200 group-focus-within:bg-primary-600 group-hover:bg-primary-600 group-hover:text-white" : "bg-transparent"} 
          flex items-center justify-center shrink-0 ${
          iconPos === "right" ? `${roundedFull ? "rounded-r-full" : "rounded-r-[10px]"}` : `${roundedFull ? "rounded-l-full" : "rounded-l-[10px]"}`}`}>
        {#if icon}
          <i class={`${icon}`} />
        {:else if $$slots.icon}
          <slot name="icon" />
        {/if}
        {#if iconTooltip}
          <span class="bg-primary-300 color-white py-1 px-2 rounded-lg pointer-events-none absolute -top-12 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100">
            {iconTooltip}
          </span>
        {/if}
        </div>
      </div>
    {/if}
    {#if type === 'number' && spin && !((icon || $$slots.icon) && iconPos === "right")}
      <div
        class="absolute top-0 right-0 h-full w-10 p-px flex items-center justify-center shrink-0"
      >
        <div class="flex flex-col w-full h-full bg-gray-200 group-focus-within:bg-primary-600 group-hover:bg-primary-600 rounded-r-[10px]">
          <div class="w-full flex items-center justify-center flex-1 hover:bg-primary-700 rounded-tr-[10px] {disabled || fValue >= max ? '' : 'cursor-pointer'}" on:click={handleIncrease} on:keydown>
            <i class={`fa-light fa-chevron-up ${disabled || fValue >= max ? 'text-gray-400' : 'group-hover:text-white'}`} />
          </div>
          <div class="w-full flex items-center justify-center flex-1 hover:bg-primary-700 rounded-br-[10px] {disabled || fValue <= min ? '' : 'cursor-pointer'}" on:click={handleDecrease} on:keydown>
            <i class={`fa-light fa-chevron-down ${disabled || fValue <= min ? 'text-gray-400' : 'group-hover:text-white'}`} />
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

