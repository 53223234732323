<svelte:options immutable={true} />

<script lang="ts">
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import { debounce } from "lodash";
  import { type Line } from "src/model";
  import Input from "../../../components/base/Input.svelte";
  import Button from "../../../components/base/Button.svelte";
  import Tooltip from "../../../components/base/Tooltip.svelte";
  import { convertUnit, getMetricWithUnit } from "src/helpers";
  import { METRIC_SMALL_UNITS, METRIC_UNITS } from "src/global/variable";

  export let wall: Line | undefined = undefined;

  $: isMetric = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: minLen = isMetric ? 0.1 : 1 / 16;
  $: step = isMetric ? 0.1 : 1;
  // $: {
  //   if( !$drawState.context.dragContext.selectedObject && wall ) {
  //     drawSend({
  //       type: "ENTER_SELECT",
  //       segment: wall,
  //     });
  //   }
  // }

  $: height = getMetricWithUnit(
    Math.round(wall?.height * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: length = getMetricWithUnit(
    Math.round(wall?.getLineLength() * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: thickness = getMetricWithUnit(
    Math.round(wall?.thick * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );

  const handleChangeWidth = debounce((e) => {
    const v = parseFloat(e.target.value)
    if ( v >= minLen ) {
      const newWidth = convertUnit(v, $drawState.context.currentMetricUnit, true)
      if( wall?.getLineLength() === newWidth ) return;

      drawSend({
        type: "ENTER_SELECT",
        segment: wall
      });
      drawSend({
        type: "CHANGE_WIDTH",
        newWidth: newWidth,
        segment: wall
      });
    }
  }, 300)

  const handleSwitchPoints = () => {
    drawSend({
      type: "ENTER_SELECT",
      segment: wall
    });
    drawSend({
      type: "SWITCH_LINE_POINTS",
      segment: wall,
    });
  }

</script>

<div class="w-full">
  <div class="flex flex-col w-full py-7 px-1 border-t border-gray-100 gap-4">
    
    <Tooltip tooltip={$_("side_menu.edge.change_vertex_tooltip")}>
      <Button
        variant="primary-hover"
        title={$_("side_menu.edge.change_vertex")}
        icon="fa-solid fa-arrow-right-arrow-left"
        iconPos="center"
        class="w-full rounded-full"
        size="sm"
        on:click={handleSwitchPoints}
      />
    </Tooltip>

    <div class="w-full flex items-center gap-2">
      <div class="w-full text-base font-semibold text-input-label">
        {$_("side_menu.length")}
      </div>
      <Input
        type="number"
        value={length}
        on:input={handleChangeWidth}
        unit={isMetric ? METRIC_SMALL_UNITS[0]  : METRIC_SMALL_UNITS[1] }
        step={step}
        fullWidth
      />
    </div>
  </div>
</div>
