<script lang="ts">
  import type { FurnitureInfoResponse } from "src/global/types";
  import { _ } from "../../../services/i18n";
  import { getFurniture } from "src/services/api";
  import FurnitureDetails from "./FurnitureDetails.svelte";
  import { BuildingPart } from "src/model";

  export let segment: BuildingPart;

  let furnitureDetails: FurnitureInfoResponse | undefined;

  const triggerDetails = () => {
    if ( furnitureDetails ) return;

    getFurniture(segment.buildingId).then((info) => {
      furnitureDetails = info as FurnitureInfoResponse;
    })
  }
</script>

<div class="flex justify-end">
  <div class="group/info">
    <div class="w-6 h-6 rounded-full shrink-0 bg-gray-100 hover:bg-primary-600 text-input-label hover:text-white flex items-center justify-center mb-1" on:mouseover={triggerDetails}>
      <i class="fa-solid fa-info fa-xs" />
    </div>
    <div class="hidden group-hover/info:block fixed bottom-2 left-2 transform translate-x-toolbox">
      <FurnitureDetails info={furnitureDetails} />
    </div>
  </div>
</div>
