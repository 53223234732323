<!-- <svelte:options immutable={true} /> -->

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";  
  import { drawState, drawSend } from "src/layout.store";
  import { debounce } from "lodash";
  import { _ } from "src/services/i18n";
  import { emptyLayout, type LayoutGeometry } from "src/store/drawMachine";
  import type { TileWrapper } from "src/model";
  import Input from "../../base/Input.svelte";
  import Notifications from "../../util/Notifications.svelte"
  import Button from "../../base/Button.svelte";
  import { getShapeId, isTileWrapper } from "src/helpers";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE } from "src/global/types";
  import { currentUser, recentLayouts } from "src/store";
  import InfiniteScroll from "svelte-infinite-scroll";
  import { getLayoutGeometries } from "src/services/api";
  import Loading from "../../util/Loading.svelte";

  export let tileWrapper: TileWrapper;
  export let visible: boolean;

  let notifications: Notifications;
  let filterName: string = '';
  let elementScroll: HTMLDivElement;

  let currentPage = 1;
  let pageSize = 12;
  let layoutsLoading: boolean = false;

  const EMPTY_LAYOUT_GEOMETRY = {
    id: 0,
    name: $_('side_menu.layout.no_pattern'),
    svg_path: undefined,
    preview_image: undefined,
    min_gap_size: 0,
    num_shapes: 0,
    tile_shapes: []
  }

  let layoutsList: {
    total: number,
    total_pages: number,
    layouts: LayoutGeometry[]
  } = {
    total: 0,
    total_pages: 0,
    layouts: [EMPTY_LAYOUT_GEOMETRY]
  };

  onMount(() => {
    updateLayoutGeometriesList();
  })

  const dispatch = createEventDispatcher();

  const loadLayoutGeometry = async (layoutGeometry: LayoutGeometry) => {
    if( layoutGeometry.id ) {
      const recentLayout = $recentLayouts.find((v) => v.geometryId === layoutGeometry.id && v.layout.overrideAspectRatio.length === 0 )
      if( !recentLayout ) {
        drawSend({
          type: "LOAD_GEOMETRY",
          layoutGeometry,
          savedGeometryLayoutId: layoutGeometry.id,
        });
        drawSend({
          type: "SHOW_LAYOUT_GEOMETRY",
          tileData: $drawState.context.layoutContext.layout,
          savedGeometryLayoutId: layoutGeometry.id,
          segment: tileWrapper,
          isNewLayout: true,
        });
      } else {
        drawSend({
          type: "LOAD_TILE_LAYOUT",
          tileData: recentLayout.layout,
          savedGeometryLayoutId: recentLayout.geometryId,
          duplicate: false
        });
      }
    } else {
      drawSend({
        type: "SHOW_LAYOUT_GEOMETRY",
        tileData: emptyLayout,
        savedGeometryLayoutId: 0,
        segment: tileWrapper,
        isNewLayout: true,
      });
    }
    dispatch("back");
  };

  const updateLayoutGeometriesList = debounce((resetPagination: boolean = true) => {
    let page = currentPage + 1;
    if( resetPagination ) {
      page = 1;
    }
    layoutsLoading = true;
    getLayoutGeometries({
      limit: pageSize,
      page: page,
      search: filterName
    }).then((res) => onSuccess(res)).finally(() => layoutsLoading = false)

    async function onSuccess ({ data }) {
      const layoutGeometries : LayoutGeometry[] = data.layout_geometries.map((layout) => ({
          ...layout,
          tile_shapes: layout.tile_shapes.map((tileShapeId) => {
            const baseShape = $drawState.context.layoutContext.baseShapes.find((v) => v.shapeId === tileShapeId)
            if (!baseShape) return null
            return {
              id: tileShapeId,
              name: baseShape.name,
              svg_path: baseShape.path,
              default_width: baseShape.width,
              default_height: baseShape.height
            }
          }).filter((v) => !!v)
      }))

      layoutGeometries.forEach((geometry) => {
        drawSend({
          type: 'ADD_LAYOUT_GEOMETRY',
          layoutGeometry: geometry,
        })
      })

      currentPage = page;
      layoutsList = resetPagination ? 
        {
          total: data.total,
          total_pages: data.total_pages,
          layouts: [EMPTY_LAYOUT_GEOMETRY, ...layoutGeometries]
        } : {
          total: data.total,
          total_pages: data.total_pages,
          layouts: [...layoutsList.layouts, ...layoutGeometries]
        }
    }
  }, 300)

  const handleLoadMore = () => {
    updateLayoutGeometriesList(false)
  }

  const handleUpdateFilterName = (e) => {
    filterName = e.target.value
    updateLayoutGeometriesList()
  }

  const handleNewLayout = () => {
    // visibleSideMenu.set(false)
    if( $drawState.matches("main.selectState.replacingTile") )
      drawSend("CANCEL_TILE_SELECTION")

    drawSend({
      type: "EDIT_TILE_LAYOUT",
      shapeId: getShapeId(tileWrapper.shape)
    });
    drawSend({
      type: "LOAD_CONTEXT",
      tileContext: null
    });
  };

</script>

{#if visible}
<Notifications bind:this={notifications} />
<div class="w-full h-full flex flex-col gap-3 min-h-0">
  <div class="px-2.5">
    <div class="px-1 py-4 border-t border-gray-100 flex flex-col gap-4">
      {#if $currentUser?.is_admin}
        <Button
          variant="primary-hover"
          title={$_("side_menu.layout.new_geometry")}
          icon="fa-solid fa-plus fa-lg"
          iconPos="center"
          class="w-full rounded-full"
          size="sm"
          on:click={handleNewLayout}
        />  
      {/if}
      <Input
        on:input={handleUpdateFilterName} 
        value={filterName} 
        placeholder={$_("side_menu.layout.search_by_name")}
        icon="fa-light fa-magnifying-glass" 
        iconFilled={false}
        roundedFull
        fullWidth
      />
    </div>
  </div>
  <div class="px-2.5 flex-1 overflow-y-auto" bind:this={elementScroll}>
    <SegmentList 
      type={SEGMENT_LIST_TYPE.LAYOUT} 
      segments={layoutsList.total_pages > currentPage ? layoutsList.layouts.slice(0, -1) : layoutsList.layouts}
      on:select={(e) => loadLayoutGeometry(e.detail.segment)}
    />
    <InfiniteScroll 
      elementScroll={elementScroll} 
      threshold={200} 
      on:loadMore={handleLoadMore} 
      hasMore={!layoutsLoading && layoutsList?.total_pages > currentPage} 
    />
    {#if layoutsLoading}
      <div class="flex justify-center items-center mt-4">
        <Loading />
      </div>
    {/if}
  </div>
</div>
{/if}