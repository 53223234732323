import { DOORTYPE, WINDOWTYPE } from 'src/model';
export const DEFAULT_ACCESS_TOKEN = '4W9JLhdEgLmu879E0K1y3MAhjLDW4w2Fso-zvRo082I';
export const DEFAULT_TILE_FILL_COLOR = 'white';
export const DEFAULT_PREVIEW_IMAGE_FILL_COLOR = '#C1BDBC';
export const DEFAULT_PREVIEW_IMAGE_BORDER_COLOR = '#565D68';
export const DEFAULT_ROOM_WIDTH = 300;
export const DEFAULT_ROOM_HEIGHT = 300;
export const DEFAULT_WALL_WIDTH = 300;
export const DEFAULT_WALL_HEIGHT = 270;
export const PROJECT_PREVIEW_WIDTH = 250;
export const PROJECT_PREVIEW_HEIGHT = 250;
export const PRECISION = 5;
export const MAX_ZOOM_RATE = 6;
export const MIN_ZOOM_RATE = 1;
export const TILE_TRANSFORM_SCALE = 20;
export const TILE_SCALE = TILE_TRANSFORM_SCALE / 2;
export const METRIC_UNITS = ['m', 'ft'];
export const METRIC_SMALL_UNITS = ['cm', 'ft'];
export const PRECISION_UNITS = {
    [METRIC_UNITS[0]]: 10,
    [METRIC_UNITS[1]]: 5.08, // 2 inch
};
export const INCH = 2.54;
export const FEET = 30.48;
export const STEP_UNITS = {
    [METRIC_UNITS[0]]: 1,
    [METRIC_UNITS[1]]: 1.27, // 1/2 inch
};
export const DEFAULT_PAGE_WIDTH = 3840;
export const DEFAULT_PAGE_HEIGHT = 1920;
export const DEFAULT_ROOM_SQUARE_METER = 9;
export const DEFAULT_ROOM_SQUARE_FOOT = 100;
export const TOLERANCE_DISTANCE = 0.5;
export const TOLERANCE_LAYOUT_DISTANCE = 0.001;
export const TOLERANCE_ANGLE = 1e-3;
export const HELPER_MIN_LENGTH = 20;
export const MIN_RESIZE_SCALE = 1.3;
export const MIN_RESIZE_LENGTH = 20;
export const BuildingJson = [
    {
        name: 'BathTub',
        ids: [25],
        path: "<rect x='0' y='0' width='900' height='400' rx='20' stroke-width='1.5' fill='aliceblue' class='segment'/> <circle cx='800' cy='200' r='25' stroke-width='1.5' class='segment'/>",
        width: 900.0,
        height: 400.0,
        x: 150,
        y: 75,
        zIndex: 1,
    },
    {
        name: 'ArmChair',
        ids: [81],
        path: '<polygon points="420.29 428.29 57.72 428.29 94.28 138.83 383.73 138.83 420.29 428.29" stroke-width="1.5" fill="aliceblue" class="segment"/><rect x="17.9" y="130.96" width="58.38" height="289.46" rx="8" transform="translate(35.31 -3.74) rotate(7.28)" stroke-width="1.5" fill="aliceblue" class="segment"/><rect x="401.53" y="130.96" width="58.38" height="289.46" rx="8" transform="translate(-31.46 56.79) rotate(-7.28)" stroke-width="1.5" fill="aliceblue" class="segment"/><path d="M54.36,130.72v-112a18,18,0,0,1,18-18H392.79a18,18,0,0,1,18,18V132.33" stroke-width="1.5" fill="aliceblue" class="segment"/>',
        width: 478,
        height: 430,
        x: 80,
        y: 80,
        zIndex: 1,
    },
    {
        name: 'Bed',
        ids: [64],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="0.75" width="953.63" height="1277.05"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M935.05,583.67v645.22A37.08,37.08,0,0,1,898,1266H57.16a37.08,37.08,0,0,1-37.08-37.07V349.41a37.08,37.08,0,0,1,37.08-37.08H545.32Z"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M935.05,583.67H582.39a37.08,37.08,0,0,1-37.07-37.08V312.33"/><line x1="545.32" y1="486.83" x2="20.08" y2="486.83"/><line x1="0.75" y1="35.26" x2="954.38" y2="35.26"/><rect stroke-width="1.5" fill="aliceblue" class="segment" x="50.21" y="59.23" width="398.84" height="232.98" rx="3.96"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="506.09" y="59.23" width="398.84" height="232.98" rx="3.96"/>',
        width: 956,
        height: 1279,
        x: 140,
        y: 190,
        zIndex: 1,
    },
    {
        name: 'Bidet',
        ids: [20, 21],
        path: '<path stroke-width="1.5" fill="aliceblue" class="segment" d="M2.88.75H135a2.13,2.13,0,0,1,2.13,2.13v136.5a68.17,68.17,0,0,1-68.17,68.17h0A68.17,68.17,0,0,1,.75,139.39V2.88A2.13,2.13,0,0,1,2.88.75Z"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="68.92" cy="26.38" r="6.96"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="68.92" cy="82.88" r="15.68"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="68.92" cy="82.88" r="8.37"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M21.84,51.48h94.87a4.88,4.88,0,0,1,4.88,4.88V138A52.32,52.32,0,0,1,69.28,190.3h0A52.32,52.32,0,0,1,17,138V56.37a4.88,4.88,0,0,1,4.88-4.88Z"/>',
        width: 138,
        height: 208,
        x: 40,
        y: 60,
        zIndex: 1,
    },
    {
        name: 'Chair',
        ids: [95],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="0.75" width="283.76" height="28.13"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M67.91,72.8H217.36a60,60,0,0,1,60,60V322a0,0,0,0,1,0,0H7.91a0,0,0,0,1,0,0V132.8A60,60,0,0,1,67.91,72.8Z"/><line stroke-width="1.5" fill="aliceblue" class="segment" x1="0.75" y1="28.88" x2="29.46" y2="86.01"/><line stroke-width="1.5" fill="aliceblue" class="segment" x1="23.24" y1="28.72" x2="47.06" y2="76.12"/><line stroke-width="1.5" fill="aliceblue" class="segment" x1="284.51" y1="29.04" x2="255.8" y2="86.18"/><line stroke-width="1.5" fill="aliceblue" class="segment" x1="262.03" y1="28.88" x2="238.21" y2="76.28"/>',
        width: 285,
        height: 323,
        x: 40,
        y: 40,
        zIndex: 1,
    },
    {
        name: 'ChestOfDrawers',
        ids: [75],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="0.75" width="501.09" height="250.8"/><rect  x="5.98" y="251.55" width="240.54" height="10.34"/><rect x="256.07" y="251.55" width="240.54" height="10.34"/><rect x="95.4" y="261.89" width="61.7" height="12.68"/><rect x="99.55" y="261.89" width="53.64" height="8.77"/><rect  x="345.49" y="261.89" width="61.7" height="12.68"/><rect  x="349.64" y="261.89" width="53.64" height="8.77"/>',
        width: 503,
        height: 275,
        x: 160,
        y: 45,
        zIndex: 2,
    },
    {
        name: 'FloorCabinet',
        ids: [49, 91],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="5.89" width="362.25" height="245.88"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="9.18" y="14.33" width="345.39" height="237.45"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="4.97" y="251.77" width="353.82" height="7.98"/> \
    <path d="M7.11,259.75l172.06,30.81h0a175.74,175.74,0,0,0,2.7-30.81" class="segment" fill="aliceblue"/> \
    <path d="M356.64,259.75,184.58,290.56h0a175.74,175.74,0,0,1-2.71-30.81" class="segment" fill="aliceblue"/> \
    <line style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" x1="181.87" y1="259.75" x2="181.87" y2="251.77"/>',
        width: 364,
        height: 291,
        x: 70,
        y: 50,
        zIndex: 2,
    },
    {
        name: 'Shower',
        ids: [40],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="0.75" width="406.47" height="406.47"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="38.82" y="46.32" width="330.32" height="315.32"/><path style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" d="M72.39,315.15a12.77,12.77,0,1,1-12.76,12.77,12.77,12.77,0,0,1,12.76-12.77m0-1.5a14.27,14.27,0,1,0,14.27,14.27,14.28,14.28,0,0,0-14.27-14.27Z"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="0.75" y="9.62" width="17.4" height="17.4"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="379.7" y="9.62" width="17.4" height="17.4"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="182.59" y="9.62" width="17.4" height="17.4"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="379.7" y="198.22" width="17.4" height="17.4"/><rect stroke-width="1.5" fill="aliceblue" class="segment"  x="379.7" y="389.82" width="17.4" height="17.4"/><line style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" x1="18.15" y1="18.32" x2="379.7" y2="18.32"/><line style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" x1="379.7" y1="9.62" x2="185.41" y2="9.62"/><line style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" x1="18.15" y1="27.02" x2="199.99" y2="27.02"/><line style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" x1="397.09" y1="27.02" x2="397.09" y2="213.79"/><line style="fill: rgb(255,255,255)" stroke-width="0.25px" class="segment" x1="388.65" y1="27.02" x2="388.65" y2="389.45"/><line stroke-width="1.5" fill="aliceblue" class="segment" x1="379.7" y1="389.82" x2="379.7" y2="214.05"/>',
        width: 408,
        height: 408,
        x: 90,
        y: 90,
        zIndex: 0,
    },
    {
        name: 'Sofa',
        ids: [86],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="4.26" width="136.91" height="416.01" rx="18"/><rect stroke-width="1.5" fill="aliceblue" class="segment" x="806.44" y="4.26" width="136.91" height="416.01" rx="18"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M472.05,141.18H806.44a0,0,0,0,1,0,0V414.61a18,18,0,0,1-18,18H472.05a0,0,0,0,1,0,0V141.18A0,0,0,0,1,472.05,141.18Z"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M137.66,141.18H472.05a0,0,0,0,1,0,0V432.61a0,0,0,0,1,0,0H155.66a18,18,0,0,1-18-18V141.18A0,0,0,0,1,137.66,141.18Z"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M155.66.75H472.05a0,0,0,0,1,0,0V141.18a0,0,0,0,1,0,0H137.66a0,0,0,0,1,0,0V18.75A18,18,0,0,1,155.66.75Z"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M472.05.75H788.44a18,18,0,0,1,18,18V141.18a0,0,0,0,1,0,0H472.05a0,0,0,0,1,0,0V.75A0,0,0,0,1,472.05.75Z"/>',
        width: 944.1,
        height: 433.36,
        x: 220,
        y: 90,
        zIndex: 1,
    },
    {
        name: 'Table',
        ids: [88],
        path: '<path stroke-width="1.5" fill="aliceblue" class="segment" d="M949.62,417.5,643.91,431.92H313.68L8,417.5a7.21,7.21,0,0,1-7.21-7.21V22.38A7.21,7.21,0,0,1,8,15.17L315.84.75H646.07L949.62,15.17a7.21,7.21,0,0,1,7.21,7.21V410.29A7.21,7.21,0,0,1,949.62,417.5Z"/>',
        width: 957.58,
        height: 432.67,
        x: 220,
        y: 90,
        zIndex: 2,
    },
    {
        name: 'Toilet',
        ids: [16, 17],
        path: '<path stroke-width="1.5" fill="aliceblue" class="segment" d="M2.88.75H135a2.13,2.13,0,0,1,2.13,2.13v136.5a68.17,68.17,0,0,1-68.17,68.17h0A68.17,68.17,0,0,1,.75,139.39V2.88A2.13,2.13,0,0,1,2.88.75Z"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M51.72,116.81V100.75h0a25.25,25.25,0,0,1,34.41,0h0v16.06a17.21,17.21,0,0,1-34.41,0Z"/><path stroke-width="1.5" fill="aliceblue" class="segment" d="M121.59,79.6A134.18,134.18,0,0,0,17,79.2v59.35A51.74,51.74,0,0,0,68.7,190.3h1.15a51.74,51.74,0,0,0,51.74-51.75Z"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="36.32" cy="51.07" r="4.23"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="101.53" cy="51.07" r="4.23"/><rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="32.99" width="136.35" height="7.63"/>',
        width: 137.85,
        height: 208.31,
        x: 40,
        y: 60,
        zIndex: 1,
    },
    {
        name: 'WallCabinet',
        ids: [35],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="5.89" width="185.34" height="245.88"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="9.18" y="14.32" width="168.48" height="237.45"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="4.97" y="251.77" width="176.91" height="7.98"/> \
    <path stroke-width="1.5" fill="aliceblue" class="segment" d="M7.11,259.75l172.06,30.81h0a175.74,175.74,0,0,0,2.7-30.81"/> \
    <line stroke-width="1.5" fill="aliceblue" class="segment" x1="181.87" y1="259.75" x2="181.87" y2="251.77"/>',
        width: 186.84,
        height: 291.43,
        x: 35,
        y: 50,
        zIndex: 4,
    },
    {
        name: 'Wardrobe',
        ids: [80],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="5.89" width="362.25" height="245.88"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="9.18" y="14.32" width="345.39" height="237.45"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="4.97" y="251.77" width="353.82" height="7.98"/> \
    <path stroke-width="1.5" fill="aliceblue" class="segment" d="M7.11,259.75l172.06,30.81h0a175.74,175.74,0,0,0,2.7-30.81"/> \
    <path stroke-width="1.5" fill="aliceblue" class="segment" d="M356.64,259.75,184.58,290.56h0a175.74,175.74,0,0,1-2.71-30.81"/> \
    <line stroke-width="1.5" fill="aliceblue" class="segment" x1="181.87" y1="259.75" x2="181.87" y2="251.77"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="9.18" y="125.34" width="345.38" height="6.99"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="51.26" y="30.21" width="11.05" height="199.81" transform="translate(-29.48 17.37) rotate(-13.84)"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="143.04" y="30.21" width="11.05" height="199.81" transform="translate(33.3 -30.25) rotate(13.08)"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="212.48" y="30.21" width="11.05" height="199.81" transform="translate(27.73 -37.96) rotate(10.62)"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="91.32" y="30.21" width="11.05" height="199.81" transform="translate(-15.05 12.69) rotate(-6.96)"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="306.43" y="30.21" width="11.05" height="199.81" transform="translate(37.54 -67.23) rotate(13.08)"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="242.08" y="30.68" width="11.05" height="199.81" transform="translate(32.91 -49.05) rotate(12.11)"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="9.18" y="119.96" width="2.39" height="17.74"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="352.17" y="119.96" width="2.39" height="17.74"/> \
    <rect stroke-width="1.5" fill="aliceblue" class="segment" x="11.58" y="126.98" width="340.6" height="3.7"/>',
        width: 363.75,
        height: 291.43,
        x: 80,
        y: 60,
        zIndex: 3,
    },
    {
        name: 'WashBasin',
        ids: [12, 46, 131],
        path: '<rect stroke-width="1.5" fill="aliceblue" class="segment" x="0.75" y="0.75" width="229.75" height="151.58"/><rect stroke-width="1.5" fill="aliceblue" class="segment" x="16.86" y="17.65" width="197.53" height="116.19"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="115.62" cy="43.07" r="9.51"/>',
        width: 231.25,
        height: 153.08,
        x: 70,
        y: 45,
        zIndex: 3,
    },
    {
        name: 'VanityUnit',
        ids: [34],
        path: '<polyline stroke-width="1.5" fill="aliceblue" class="segment" points="50.13 134.37 0.75 134.37 0.75 0.75 329.26 0.75 329.26 134.37 279.88 134.37"/><rect stroke-width="1.5" fill="aliceblue" class="segment" x="50.13" y="17.12" width="229.75" height="151.58"/><rect stroke-width="1.5" fill="aliceblue" class="segment" x="66.24" y="34.02" width="197.53" height="116.19"/><circle stroke-width="1.5" fill="aliceblue" class="segment" cx="165.01" cy="59.44" r="9.51"/>',
        width: 330.01,
        height: 169.45,
        x: 100,
        y: 50,
        zIndex: 2,
    },
];
export const FixedBuildingJson = [
    {
        staticPath: '<rect width="90" height="1.5" style="fill: #F2F2F2" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="1.5" y1="0" x2="1.5" y2="1.5" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="88.5" y1="0" x2="88.5" y2="1.5" stroke-width="0.25px" class="segment" /> ',
        path: '<path style="fill: rgba(255,255,255,0.5);" d="M 88.5 1.5 L 1.5 1.5 L 1.5 88.5 A 88.5 88.5 0 0 0 88.5 1.5" stroke-width="0.25px" class= "segment" />',
        width: 90,
        height: 210,
        thicker: 1.5,
        type: DOORTYPE.DOOR,
    },
    {
        staticPath: '<rect width="90" height="1.5" style="fill: #F2F2F2" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="1.5" y1="0" x2="1.5" y2="1.5" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="88.5" y1="0" x2="88.5" y2="1.5" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="45" y1="0" x2="45" y2="1.5" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="1.5" y1="0.75" x2="88.5" y2="0.75" stroke-width="0.25px" class= "segment" />',
        path: '<path style="fill: rgba(255,255,255,0.5);" d="M1.5 1.25 L39.17 23.25 A 43.5 43.5 0 0 0 45 1.25" stroke-width="0.25px" class="segment" /> \
          <path style="fill: rgba(255,255,255,0.5);" d="M88.5 1.25 L 50.82 23.25 A 43.5 43.5 0 0 1 45 1.25" stroke-width="0.25px" class="segment" />',
        width: 90,
        height: 210,
        thicker: 1.5,
        type: DOORTYPE.WINDOW,
        windowType: WINDOWTYPE.DOUBLE,
    },
    {
        staticPath: '<rect width="90" height="1.5" style="fill: #F2F2F2" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="1.5" y1="0" x2="1.5" y2="1.5" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="88.5" y1="0" x2="88.5" y2="1.5" stroke-width="0.25px" class="segment" />\
      <line style="fill: none" x1="1.5" y1="0.75" x2="88.5" y2="0.75" stroke-width="0.25px" class= "segment" />',
        path: '<path style="fill: rgba(255,255,255,0.5);" d="M1.5 1.25 L76.84 45 A 87 87 0 0 0 88.5 1.25" stroke-width="0.25px" class="segment" />',
        width: 90,
        height: 210,
        thicker: 1.5,
        type: DOORTYPE.WINDOW,
        windowType: WINDOWTYPE.SINGLE,
    },
];
export const WallBuildingJson = [
    {
        staticPath: '',
        path: '<path d="M344 2H2V884H344V2Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class="segment"/> \
      <path d="M309.3 29.7798H35.0399V884H309.3V29.7798Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class="segment"/> \
      <path d="M279.52 435.48H246.22C242.58 435.48 239.63 438.428 239.63 442.065C239.63 445.702 242.58 448.65 246.22 448.65H279.52C283.16 448.65 286.11 445.702 286.11 442.065C286.11 438.428 283.16 435.48 279.52 435.48Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class="segment"/>',
        width: 344,
        height: 882,
        thicker: 1.5,
        type: DOORTYPE.DOOR,
    },
    {
        path: '<path d="M738 2H0V740H738V2Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class="segment"/>\
      <path d="M704.25 33.2402H33.98V703.51H704.25V33.2402Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class="segment"/>\
      <path d="M369.11 31.4399V702.17" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class="segment"/>\
      <path d="M654.09 80.1001L592.7 141.49" stroke="#686968" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" class="segment"/>\
      <path d="M582.71 91.0898L521.32 152.48" stroke="#686968" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" class="segment"/>\
      <path d="M654.09 144.14L539.08 259.15" stroke="#686968" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" class="segment"/>',
        staticPath: '',
        width: 738,
        height: 738,
        thicker: 1.5,
        type: DOORTYPE.WINDOW,
        windowType: WINDOWTYPE.DOUBLE,
    },
    {
        path: '<path d="M738 2H0V740H738V2Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class= "segment"/> \
      <path d="M704.31 33.2402H34.04V703.51H704.31V33.2402Z" fill="#F2F2F2" stroke="#686968" stroke-width="5" stroke-miterlimit="10" class= "segment"/> \
      <path d="M654.15 80.1001L592.76 141.49" stroke="#686968" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" class= "segment"/> \
      <path d="M582.77 91.0898L521.38 152.48" stroke="#686968" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" class= "segment"/> \
      <path d="M654.15 144.14L539.14 259.15" stroke="#686968" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" class= "segment"/>',
        staticPath: '',
        width: 738,
        height: 738,
        thicker: 1.5,
        type: DOORTYPE.WINDOW,
        windowType: WINDOWTYPE.SINGLE,
    },
];
const path = {
    L: `points="0 0 0 100 100 100 100 ${(100 * 1) / 2} 
        ${(100 * 1) / 2} ${(100 * 1) / 2} ${(100 * 1) / 2} 0 0 0"`,
    Pentagon: `points="0 0 0 100 100 100 100 ${(100 * 2) / 5} 
        ${(100 * 3) / 5} 0 0 0"`,
    Convex: `points="0 ${(100 * 1) / 3} 0 100 100 100 
        100 ${(100 * 1) / 3} ${(100 * 2) / 3} ${(100 * 1) / 3} 
        ${(100 * 2) / 3} 0 ${(100 * 1) / 3} 0 
        ${(100 * 1) / 3} ${(100 * 1) / 3} 0 ${(100 * 1) / 3}"`,
    Concave: `points="0 0 0 100 100 100 100 0
        ${(100 * 2) / 3} 0 ${(100 * 2) / 3} ${(100 * 1) / 3} 
        ${(100 * 1) / 3} ${(100 * 1) / 3} ${(100 * 1) / 3} 0 0 0"`,
    Trapezium: `points="0 0 0 100 100 100 ${(100 * 2) / 3} 0 0 0"`,
};
export const PROJECT_SHAPE = [
    {
        name: 'rectangle',
        type: 1,
        path: "<rect x='0' y='0' width='100' height='100'/>",
        width: 100,
        height: 100,
    },
    {
        name: 'L',
        type: 1,
        path: `<polygon ${path['L']}/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'L',
        type: 2,
        path: `<polygon ${path['L']} transform="rotate(90 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'L',
        type: 3,
        path: `<polygon ${path['L']} transform="rotate(180 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'L',
        type: 4,
        path: `<polygon ${path['L']} transform="rotate(270 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'pentagon',
        type: 1,
        path: `<polygon ${path['Pentagon']} />`,
        width: 100,
        height: 100,
    },
    {
        name: 'pentagon',
        type: 2,
        path: `<polygon ${path['Pentagon']} transform="rotate(90 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'pentagon',
        type: 3,
        path: `<polygon ${path['Pentagon']} transform="rotate(180 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'pentagon',
        type: 4,
        path: `<polygon ${path['Pentagon']} transform="rotate(270 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'convex',
        type: 1,
        path: `<polygon ${path['Convex']}/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'convex',
        type: 2,
        path: `<polygon ${path['Convex']} transform="rotate(90 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'convex',
        type: 3,
        path: `<polygon ${path['Convex']} transform="rotate(180 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'convex',
        type: 4,
        path: `<polygon ${path['Convex']} transform="rotate(270 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'concave',
        type: 1,
        path: `<polygon ${path['Concave']}/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'concave',
        type: 2,
        path: `<polygon ${path['Concave']} transform="rotate(90 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'concave',
        type: 3,
        path: `<polygon ${path['Concave']} transform="rotate(180 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'concave',
        type: 4,
        path: `<polygon ${path['Concave']} transform="rotate(270 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'trapezium',
        type: 1,
        path: `<polygon ${path['Trapezium']}/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'trapezium',
        type: 2,
        path: `<polygon ${path['Trapezium']} transform="rotate(90 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'trapezium',
        type: 3,
        path: `<polygon ${path['Trapezium']} transform="rotate(180 50 50)"/>`,
        width: 100,
        height: 100,
    },
    {
        name: 'trapezium',
        type: 4,
        path: `<polygon ${path['Trapezium']} transform="rotate(270 50 50)"/>`,
        width: 100,
        height: 100,
    },
];
export var SORT_TYPES;
(function (SORT_TYPES) {
    SORT_TYPES["NEWEST"] = "newest";
    SORT_TYPES["POPULAR"] = "popular";
    SORT_TYPES["LARGE_TO_SMALL"] = "large_to_small";
    SORT_TYPES["SMALL_TO_LARGE"] = "small_to_large";
})(SORT_TYPES || (SORT_TYPES = {}));
export var TILES_FILTERS;
(function (TILES_FILTERS) {
    TILES_FILTERS["SORT"] = "sort";
    TILES_FILTERS["COLORS"] = "colors";
    TILES_FILTERS["CHILDREN_TYPES"] = "children_types";
    TILES_FILTERS["PRODUCERS"] = "producers";
    TILES_FILTERS["TILE_SHAPES"] = "tile_shapes";
    TILES_FILTERS["DIMENSIONS"] = "dimensions";
})(TILES_FILTERS || (TILES_FILTERS = {}));
export var TILES_SHAPES;
(function (TILES_SHAPES) {
    TILES_SHAPES[TILES_SHAPES["RECTANGLE"] = 1] = "RECTANGLE";
    TILES_SHAPES[TILES_SHAPES["SQUARE"] = 2] = "SQUARE";
    TILES_SHAPES[TILES_SHAPES["RHOMBUS_A"] = 3] = "RHOMBUS_A";
    TILES_SHAPES[TILES_SHAPES["RHOMBUS_B"] = 4] = "RHOMBUS_B";
    TILES_SHAPES[TILES_SHAPES["TRAPEZOID"] = 5] = "TRAPEZOID";
    TILES_SHAPES[TILES_SHAPES["ARABESQUE_A"] = 6] = "ARABESQUE_A";
    TILES_SHAPES[TILES_SHAPES["ARABESQUE_B"] = 7] = "ARABESQUE_B";
    TILES_SHAPES[TILES_SHAPES["FISH_SCALE"] = 8] = "FISH_SCALE";
    TILES_SHAPES[TILES_SHAPES["OGEE"] = 10] = "OGEE";
    TILES_SHAPES[TILES_SHAPES["WAVE"] = 11] = "WAVE";
    TILES_SHAPES[TILES_SHAPES["TRIANGLE"] = 12] = "TRIANGLE";
    TILES_SHAPES[TILES_SHAPES["PENTAGON"] = 13] = "PENTAGON";
    TILES_SHAPES[TILES_SHAPES["HEXAGON"] = 14] = "HEXAGON";
    TILES_SHAPES[TILES_SHAPES["OCTAGON"] = 16] = "OCTAGON";
})(TILES_SHAPES || (TILES_SHAPES = {}));
export var FURNITURE_FILTERS;
(function (FURNITURE_FILTERS) {
    FURNITURE_FILTERS["SORT"] = "sort";
    FURNITURE_FILTERS["CHILDREN_TYPES"] = "children_types";
    FURNITURE_FILTERS["ELEMENT_CATEGORIES"] = "element_categories";
    FURNITURE_FILTERS["PRODUCERS"] = "producers";
})(FURNITURE_FILTERS || (FURNITURE_FILTERS = {}));
export var QUERY_PARAMS;
(function (QUERY_PARAMS) {
    QUERY_PARAMS["LOCALE"] = "locale";
    QUERY_PARAMS["ACCESS_TOKEN"] = "access_token";
    QUERY_PARAMS["TOKEN_EXPIRY"] = "expires_in";
})(QUERY_PARAMS || (QUERY_PARAMS = {}));
export var ACTION_TYPES;
(function (ACTION_TYPES) {
    ACTION_TYPES["PROJECT_SAVE"] = "project-save";
    ACTION_TYPES["PROJECT_SAVEAS"] = "project-saveas";
    ACTION_TYPES["PROJECT_EXPORT"] = "project-export";
})(ACTION_TYPES || (ACTION_TYPES = {}));
