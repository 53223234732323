import { Pointer } from './Pointer';
import { Segment, SegmentClass } from './Segment';
import { area, dist, getShapeBoundingRect, getTileSnapPointers } from '../helpers';
import { v4 as uuidv4 } from 'uuid';
import Layout from './tile/Layout';
export class TileWrapper extends Segment {
    startPointer;
    endPointer;
    id;
    closedAreaId;
    parentId;
    shape;
    tileLayout;
    rotation;
    offset;
    snapTile;
    zIndex;
    name;
    updateLayout;
    loadingLayout;
    highlightTile;
    addNewFurniture;
    layoutGeometryId;
    tiles;
    update = false;
    constructor(startPointer, endPointer, id = uuidv4(), closedAreaId = 0, parentId = 0, shape = {
        results: [],
        points: [],
    }, tileLayout, rotation = 0, offset = new Pointer(0, 0), snapTile = undefined, zIndex = 0, name = '', updateLayout = false, loadingLayout = false, highlightTile, addNewFurniture) {
        super(startPointer, endPointer, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.id = id;
        this.closedAreaId = closedAreaId;
        this.parentId = parentId;
        this.shape = shape;
        this.tileLayout = tileLayout;
        this.rotation = rotation;
        this.offset = offset;
        this.snapTile = snapTile;
        this.zIndex = zIndex;
        this.name = name;
        this.updateLayout = updateLayout;
        this.loadingLayout = loadingLayout;
        this.highlightTile = highlightTile;
        this.addNewFurniture = addNewFurniture;
        // this.tileLayout = this.tileLayout.withGapSize(this.gap);
        // this.tileLayout.overrideAspectRatio = overrideAspectRatio ?? [];
        this.class = SegmentClass.TILE_WRAPPER;
        this.layoutGeometryId = -1;
    }
    initializeOverrideAspectRatio(layoutGeometries, shapes) {
        const geometry = layoutGeometries[this.layoutGeometryId];
        if (!geometry)
            return;
        const svgPath = JSON.parse(geometry.svg_path);
        svgPath.shape_index.forEach((shapeToOverride, shapeIndex) => {
            const dimensionsShape = Array.isArray(svgPath.dimensions) && svgPath.dimensions.length > shapeIndex
                ? svgPath.dimensions[shapeIndex]
                : undefined;
            const geometryShape = geometry.tile_shapes.find((gs) => gs.id === shapeToOverride);
            const shape = shapes[shapeIndex];
            let widthMultiplyFactor = 1, heightMultiplyFactor = 1;
            if (shape && !shape.isBaseShape()) {
                if (dimensionsShape) {
                    widthMultiplyFactor = shape.width / dimensionsShape[0];
                    heightMultiplyFactor = shape.height / dimensionsShape[1];
                }
                else if (geometryShape) {
                    widthMultiplyFactor = shape.width / geometryShape.default_width;
                    heightMultiplyFactor = shape.height / geometryShape.default_height;
                }
                this.tileLayout.overrideAspectRatio.push({
                    shapeIndex: shapeIndex,
                    tile: shape,
                    widthMultiplyFactor: widthMultiplyFactor,
                    heightMultiplyFactor: heightMultiplyFactor,
                });
            }
        });
    }
    setLayoutInfo(layoutGeometryId, tiles) {
        this.layoutGeometryId = layoutGeometryId;
        this.tiles = tiles;
    }
    generatePath(isFromShape = false, startPointer = undefined) {
        return '';
    }
    generateHelperPath() {
        return [];
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx: dx, dy: dy };
    }
    generateHelperTextData() {
        return [];
    }
    getPointsArray() {
        return [];
    }
    translate(dx, dy, segments) {
        this.offset = this.offset.translate(dx, dy);
        const bounding = getShapeBoundingRect(this.shape);
        this.offset = new Pointer(Math.sign(this.offset.x) * Math.min(Math.abs(this.offset.x), bounding[2] / 2), Math.sign(this.offset.y) * Math.min(Math.abs(this.offset.y), bounding[3] / 2));
    }
    getLineLength() {
        return dist(this.startPointer, this.endPointer);
    }
    isInSegment(pointer, acceptInPath = true) {
        return false;
    }
    getSnapInfos = () => {
        if (this.parentId && this.snapTile) {
            const snapInfos = getTileSnapPointers(this, this.snapTile);
            if (snapInfos.lines.length === 1) {
                let lineAngle = snapInfos.lines[0].getLineAngle();
                if (lineAngle < 0)
                    lineAngle += Math.PI;
                return {
                    snapPointer: snapInfos.snapTilepointers[0],
                    snapLinePointer1: snapInfos.snapLinePointers[0],
                    snapLinePointer2: snapInfos.resultElementLinePointer,
                    deltaAngle: snapInfos.deltaAngle,
                    lineAngle,
                    distance: {
                        deltaX: snapInfos.snapLinePointers[0].x - snapInfos.snapTilepointers[0].x,
                        deltaY: snapInfos.snapLinePointers[0].y - snapInfos.snapTilepointers[0].y,
                    },
                };
            }
        }
        return {
            snapPointer: new Pointer(0, 0),
            snapLinePointer1: new Pointer(0, 0),
            snapLinePointer2: new Pointer(0, 0),
            deltaAngle: 0,
            lineAngle: 0,
            distance: {
                deltaX: 0,
                deltaY: 0,
            },
        };
    };
    getArea() {
        let points = [];
        this.shape.results.forEach((sh, index) => {
            if (sh.class === SegmentClass.ARC) {
                points.push(...sh.getPointsArray());
            }
            else {
                if (index === 0 ||
                    (!sh.startPointer.equals(this.shape.results[index - 1].startPointer) &&
                        !sh.startPointer.equals(this.shape.results[index - 1].endPointer)))
                    points.push(sh.startPointer);
                if (index === 0 ||
                    (!sh.endPointer.equals(this.shape.results[index - 1].startPointer) &&
                        !sh.endPointer.equals(this.shape.results[index - 1].endPointer)))
                    points.push(sh.endPointer);
                if (index === 0 && this.shape.results.length > 1) {
                    if (!points[1].equals(this.shape.results[1].startPointer) &&
                        !points[1].equals(this.shape.results[1].endPointer)) {
                        const temp = points[0];
                        points[0] = points[1];
                        points[1] = temp;
                    }
                }
            }
        });
        return Math.abs(area(points));
    }
    clone() {
        const result = new TileWrapper(this.startPointer?.translate(0, 0), this.endPointer?.translate(0, 0), this.id, this.closedAreaId, this.parentId, {
            results: this.shape.results.map((r) => r.clone()),
            points: this.shape.points.map((p) => p.translate(0, 0)),
        }, this.tileLayout.resize(this.tileLayout.width, this.tileLayout.height), this.rotation, this.offset.translate(0, 0), this.snapTile, this.zIndex, this.name);
        result.setLayoutInfo(this.layoutGeometryId, this.tiles ? [...this.tiles] : []);
        // result.overrideAspectRatio = this.overrideAspectRatio
        result.altName = this.altName;
        return result;
    }
    toJSON() {
        return {
            ...super.toJSON(),
            closedAreaId: this.closedAreaId,
            parentId: this.parentId,
            shape: {
                results: this.shape.results.map((r) => r.id),
                points: this.shape.points.map((p) => p.toJSON()),
            },
            tileLayout: {
                id: this.tileLayout.id,
                layoutGeometry: this.layoutGeometryId,
                // tiles: this.tileLayout.getGraph().tiles,
                tiles: this.tiles,
                gap: this.tileLayout.gap,
                groutColor: this.tileLayout.groutColor,
                overrideAspectRatio: this.tileLayout.overrideAspectRatio.map((e) => {
                    return {
                        shapeIndex: e.shapeIndex,
                        tile: e.tile.tileId ?? e.tile,
                        widthMultiplyFactor: e.widthMultiplyFactor,
                        heightMultiplyFactor: e.heightMultiplyFactor,
                    };
                }),
            },
            rotation: this.rotation,
            offset: this.offset.toJSON(),
        };
    }
}
