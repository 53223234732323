<script lang="ts">
  import { activeMenu, editSegment, selectedRoom, selectedSegment, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import LongText from "../../base/LongText.svelte";
  import { isClosedArea, isTileWrapper } from "src/helpers";
  import type { ClosedArea, TileWrapper } from "src/model";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import AreaInfo from "./AreaInfo.svelte";
  import { onDestroy, onMount } from "svelte";
  import ListPattern from "./ListPattern.svelte";
  import CopyLayout from "./CopyLayout.svelte";
  import SelectTile from "./SelectTile.svelte";
  
  enum AREA_VIEW {
    INDEX,
    AREA_DETAIL,
    LIST_PATTERN = "layout.choose_pattern",
    COPY_LAYOUT = "layout.copy_layout",
    SELECT_TILE = "area.select_tile"
  }

  let elementScroll: HTMLDivElement;
  let routes : AREA_VIEW[] = [AREA_VIEW.INDEX];

  let currentView: AREA_VIEW = AREA_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: [],
    editable: false,
  };
  let currentArea: TileWrapper;
  let unsubscribe;

  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.AREA )
        return;
      if( !visible ) {
        handleBack();
        routes = [AREA_VIEW.INDEX];
        currentView = AREA_VIEW.INDEX;
      }
    })
  })
  
  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ).sort((a, b) => a.name.localeCompare(b.name)) as ClosedArea[];

  // undo/redo
  $: {
    if( currentArea ) {
      currentArea = $drawState.context.current.segments.find((segment) => currentArea.id === segment.id) as TileWrapper
    }
  }
  // $: areas = $drawState.context.current.segments.filter((segment) =>
  //   isTileWrapper(segment)
  // ).sort((a, b) => a.name.localeCompare(b.name)) as TileWrapper[];
  
  const handleSelectArea = (e: CustomEvent) => {
    currentArea = e.detail.segment
    selectedSegment.set(currentArea);
    handleNext(AREA_VIEW.AREA_DETAIL);
  };

  const handleNext = (view: AREA_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    routes.pop()
    if( routes.length )
      currentView = routes[routes.length - 1]
  };

  $: {
    switch (currentView) {
      case AREA_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.area.list_area"),
          info: "",
          path: [$drawState.context.projectBaseInfo?.name ? $drawState.context.projectBaseInfo.name : $_("project_wizard.untitled")],
          editable: false,
        };
        break;
      case AREA_VIEW.AREA_DETAIL:
        headerInfo = {
          title: currentArea?.getName($_),
          info: "",
          path: [$_("side_menu.areas"),$_("side_menu.area.edit_area")],
          editable: true,
        };
        break;
      case AREA_VIEW.LIST_PATTERN:
      case AREA_VIEW.COPY_LAYOUT:
        headerInfo = {
          title: $_(`side_menu.${currentView}`),
          info: "",
          path: [$_("side_menu.areas"), currentArea.getName($_)],
          editable: false,
        };
        break;
      case AREA_VIEW.SELECT_TILE:
        headerInfo = {
          title: $_(`tile.title`),
          info: "",
          path: [$_("side_menu.areas"), currentArea.getName($_)],
          editable: false,
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    switch(currentView) {
      case AREA_VIEW.AREA_DETAIL:
        drawSend({
          type: "CHANGE_NAME",
          segment: currentArea,
          name: e.detail.value,
        });
      break;
    }
  }
  
  const handleGoto = (e: CustomEvent) => {
    if( e.detail.path === 'list-pattern' )
      handleNext(AREA_VIEW.LIST_PATTERN)
    else if( e.detail.path === 'copy-layout' )
      handleNext(AREA_VIEW.COPY_LAYOUT)
    else if( e.detail.path === 'select-tile' )
      handleNext(AREA_VIEW.SELECT_TILE)
  }
  
  onMount(() => {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if (selectedObj && isTileWrapper(selectedObj))
      currentArea = selectedObj as TileWrapper;
  })

  onDestroy(() => {
    if (unsubscribe) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header 
    {...headerInfo} 
    on:change={handleTitleChange}
    hasBack={currentView !== AREA_VIEW.INDEX}
    on:back={handleBack}
  />

  <div class="w-full flex flex-col flex-1 py-3 overflow-auto gap-4" bind:this={elementScroll}>
    {#if currentView === AREA_VIEW.INDEX}
      <div class="w-full px-2.5">
        {#each rooms as room}
          <div class="w-full py-7 px-1 border-t border-gray-100">
            <LongText text={room.getName($_)} class="text-base text-title font-semibold mb-3" />
            <SegmentList type={SEGMENT_LIST_TYPE.AREA} segments={room.tileWrappers} on:select={handleSelectArea} />
          </div>
        {/each}
      </div>
    {:else if currentView === AREA_VIEW.AREA_DETAIL}
      <AreaInfo 
        tileWrapper={currentArea} 
        on:go={handleGoto} 
        on:back={handleBack} 
      />
    {:else if currentView === AREA_VIEW.COPY_LAYOUT}
      <CopyLayout elementScroll={elementScroll} on:back={handleBack} />
    {:else if currentView === AREA_VIEW.SELECT_TILE}
      <SelectTile tileWrapper={currentArea} on:back={handleBack} />
    {/if}
    <ListPattern tileWrapper={currentArea} on:back={handleBack} visible={currentView === AREA_VIEW.LIST_PATTERN}/>
  </div>
</div>
