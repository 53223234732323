<script lang="ts">
  export let title = "";
  export let description = "";
  export let className = "";
  export let active = false;
  export let special = false;
  export let size: "sm" | "md" | "lg" | "xl" | "2xl" = "xl";

  export { className as class };

  let sizeCls = "";
  let wSize = "";
  $: {
    switch (size) {
      case "2xl":
        sizeCls = "w-grid-item-2xl h-grid-item-2xl";
        wSize = "w-grid-item-2xl";
        break;
      case "xl":
        sizeCls = "w-grid-item-xl h-grid-item-xl";
        wSize = "w-grid-item-xl";
        break;
      case "lg":
        sizeCls = "w-grid-item-lg h-grid-item-lg";
        wSize = "w-grid-item-lg";
        break;
      case "md":
        sizeCls = "w-grid-item-md h-grid-item-md";
        wSize = "w-grid-item-md";
        break;
      case "sm":
        sizeCls = "w-grid-item-sm h-grid-item-sm";
        wSize = "w-grid-item-sm";
        break;
    }
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class={`${className} relative flex flex-col justify-self-center group rounded-card cursor-pointer
      ${active ? "border-2 border-secondary-500 text-secondary-500" : 
       special ? "border-2 border-dashed border-primary-600 text-primary-500" : 
                 "p-px border border-gray-300 text-gray-300"} 
      hover:p-0 hover:border-2 hover:border-primary-500 hover:text-primary-500`}
  on:click
  on:mouseenter
  on:mouseleave
>
  <div class="p-2.5 pb-1 flex flex-col items-center">
    <slot name="header" />
    <div
      class={`flex items-center justify-center self-center transition-colors duration-300 ${sizeCls}`}
    >
      <slot />
    </div>
    {#if title}
      <div
        class={`${wSize} truncate text-center text-xs text-input-label group-hover:font-bold mt-2.5`}
      >
        {title}
      </div>
    {/if}
    {#if description}
      <div class={`${wSize} truncate text-center text-xs text-gray-400`}>
        {description}
      </div>
    {/if}
  </div>
</div>
