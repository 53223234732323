<script lang="ts">
  import { onMount } from "svelte";
  import { _ } from "../../services/i18n";
  
  let isMaximized = false;
  
  onMount(() => {
    checkFullscreen();
    document.addEventListener('fullscreenchange', checkFullscreen);
    return () => {
        document.removeEventListener('fullscreenchange', checkFullscreen);
    };
  })

  function checkFullscreen() {
    isMaximized = !!document.fullscreenElement;
  }

  const openFullscreen = () => {
    const elem = document.getElementById("main-container");
    if( !isMaximized ) {      
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }

    isMaximized = !isMaximized;
  }
</script>
<button class="w-12 h-12 flex items-center justify-center border-none hover:bg-gray-200 rounded-lg"
        on:click={() => openFullscreen()}>
  <i class={`fa-light ${isMaximized ? "fa-minimize" : "fa-maximize"} fa-custom-lg text-secondary-500`}
    title={$_(isMaximized ? "action_menu.exit_full_screen" : "action_menu.full_screen")} />
</button>
  