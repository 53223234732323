<script lang="ts">
  import { visibleSideMenu, activeMenu, editSegment } from "src/store";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import Button from "../../base/Button.svelte";
  import { checkBuildingPartInRoom, isBuildingPart, isClosedArea } from "src/helpers";
  import { type BuildingPart, ClosedArea } from "src/model";
  import SegmentList from "./SegmentList.svelte";
  import FurnitureInfo from "./FurnitureInfo.svelte";
  import { SEGMENT_LIST_TYPE, SIDE_MENUS } from "src/global/types";
  import { onMount, onDestroy } from "svelte";
  import LongText from "../../base/LongText.svelte";
  import SelectFurniture from "./SelectFurniture.svelte";

  enum FURNITURE_VIEW {
    INDEX,
    ADD_FURNITURE,
    EDIT_FURNITURE,
    SELECT_FURNITURE
  }

  let routes : FURNITURE_VIEW[] = [FURNITURE_VIEW.INDEX];

  let currentView: FURNITURE_VIEW = FURNITURE_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: [],
    editable: false
  };

  let isValidFurniture: boolean;
  let choosedRoom: ClosedArea;
  let currentFurniture: BuildingPart;

  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.FURNITURE )
        return;

      if( !visible ) {
        handleBack();
        routes = [FURNITURE_VIEW.INDEX];
        currentView = FURNITURE_VIEW.INDEX;
      }
    })
  })
  
  $: rooms = $drawState.context.current.segments.filter((segment) =>
    isClosedArea(segment)
  ) as ClosedArea[];
  
  // for undo/redo
  $: {
    if( choosedRoom )
      choosedRoom = $drawState.context.current.segments.find((segment) => choosedRoom.id === segment.id) as ClosedArea
    if( currentFurniture )
      currentFurniture = $drawState.context.current.segments.find((segment) => currentFurniture.id === segment.id) as BuildingPart
  }

  $: furnituresWithRoom = rooms.map((room) => {
    return {
      room: room,
      furnitures: $drawState.context.current.segments.filter((segment) => 
        isBuildingPart(segment) && 
        room.id === (segment as BuildingPart).closedAreaId
      ).sort((a, b) => a.name.localeCompare(b.name))
    }
  })

  const handleNext = (view: FURNITURE_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  $: {
    const selectedObj = $drawState.context.dragContext.selectedObject;
    if ( selectedObj && isBuildingPart(selectedObj) ) {
      const buildingPart = selectedObj as BuildingPart;
      const closedArea = $drawState.context.current.segments.find(
          (s) =>
            s instanceof ClosedArea &&
            s.id === buildingPart.closedAreaId
        ) as ClosedArea;
        isValidFurniture = closedArea && checkBuildingPartInRoom(buildingPart, closedArea)
    } else 
      isValidFurniture = false
  }

  const handleBack = () => {
    routes.pop();
    if (currentView === FURNITURE_VIEW.EDIT_FURNITURE) {
      if( routes.length === 0 ) {
        visibleSideMenu.set(false);
      }
    }
    if (routes.length) currentView = routes[routes.length - 1];
  };

  const handleSelectFurniture = (e: CustomEvent) => {
    currentFurniture = e.detail.segment;
    if (currentFurniture) {
      // selectedSegment.set(currentFurniture);
      drawSend({
        type: "ENTER_SELECT",
        segment: currentFurniture,
      });
      routes = [FURNITURE_VIEW.INDEX]
      handleNext(FURNITURE_VIEW.EDIT_FURNITURE);
    }
  }

  const handleAddFurniture = (room: ClosedArea) => {
    choosedRoom = room;
    handleNext(FURNITURE_VIEW.SELECT_FURNITURE);
  }

  const handleAdd3DFurniture = (room: ClosedArea) => {
    choosedRoom = room;
    drawSend({ type: "ADD_FURNITURE" });
    // handleNext(FURNITURE_VIEW.FURNITURE_CATEGORY_LIST);
  }

  $: {
    switch (currentView) {
      case FURNITURE_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.furniture.list_furniture"),
          info: "",
          path: [$drawState.context.projectBaseInfo?.name ? $drawState.context.projectBaseInfo.name : $_("project_wizard.untitled")],
          editable: false,
        };
        break;
      case FURNITURE_VIEW.ADD_FURNITURE:
      case FURNITURE_VIEW.EDIT_FURNITURE:
        headerInfo = {
          title: currentFurniture?.getName($_),
          info: "",
          path: [$_("side_menu.furnitures"), currentView === FURNITURE_VIEW.ADD_FURNITURE ? $_("side_menu.furniture.add_furniture") : $_("side_menu.furniture.edit_furniture")],
          editable: true,
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    drawSend({
      type: "CHANGE_NAME",
      segment: currentFurniture,
      name: e.detail.value,
    });
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })
</script>

<div class="flex flex-col h-full">
  <Header 
    {...headerInfo} 
    on:change={handleTitleChange} 
    hasBack={currentView !== FURNITURE_VIEW.INDEX}
    on:back={handleBack}
  />

  <div class="w-full flex flex-col flex-1 py-3 overflow-auto gap-4">
    {#if currentView === FURNITURE_VIEW.INDEX}
      <div class="w-full px-2.5">
        {#each furnituresWithRoom as item}
          <div class="w-full py-7 px-1 border-t border-gray-100">
            <LongText text={item.room.getName($_)} class="text-base text-title font-semibold mb-3" />
            <SegmentList type={SEGMENT_LIST_TYPE.BUILDING_PART} segments={item.furnitures} on:select={handleSelectFurniture} />
            <Button
              variant="primary-hover"
              title={$_("side_menu.furniture.add_furniture")}
              icon="fa-light fa-plus"
              iconPos="center"
              class="w-full rounded-full mt-5"
              on:click={() => handleAddFurniture(item.room)}
            />
          </div>
        {/each}
      </div>
    {:else if currentView === FURNITURE_VIEW.SELECT_FURNITURE}
      <SelectFurniture room={choosedRoom} on:select={handleSelectFurniture}/>
    {:else if currentView === FURNITURE_VIEW.EDIT_FURNITURE}
      <FurnitureInfo buildingPart={currentFurniture} on:back={handleBack} />
    {/if}
  </div>
</div>
