import Shape from './Shape';
import Layout from './Layout';
import Neighbor from './Neighbor';
import { Path } from './Path';
import { TILE_TRANSFORM_SCALE } from 'src/global/variable';
import { getMyWishList, getTile, getTileShapes, getWishList } from 'src/services/api';
export function createSampleLayout() {
    const rect = Shape.createRegular(4);
    const rectLayout = new Layout();
    rectLayout.add(new Neighbor(rect.edge(0), rect.edge(2)));
    rectLayout.add(new Neighbor(rect.edge(1), rect.edge(3)));
    rectLayout.add(new Neighbor(rect.edge(2), rect.edge(0)));
    rectLayout.add(new Neighbor(rect.edge(3), rect.edge(1)));
    return rectLayout;
}
export async function createSampleShapes(isLoggedIn = false) {
    const result = new Array();
    try {
        const tileShapes = await getTileShapes();
        for (const tile_shape of tileShapes) {
            const svg = Path.fromSvg(tile_shape.svg_path).resizeAndCenter([
                (tile_shape.default_width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
                (tile_shape.default_height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
            ]);
            if (svg) {
                result.push(new Shape(svg, tile_shape.id, tile_shape.id, tile_shape.name, {}, tile_shape.slug, tile_shape.default_width, tile_shape.default_height, 0));
            }
        }
        // const wishLists = await (isLoggedIn ? getMyWishList() : getWishList());
        // for (const tile of wishLists.tiles) {
        //   const detailedTile = await getTile(tile.id);
        //   // const detailedTile = null;
        //   const baseTileShape = tileShapes.find(
        //     (s) => s.id === tile.tile_shape.id
        //   );
        //   if (baseTileShape) {
        //     const svg = Path.fromSvg(baseTileShape.svg).resizeAndCenter([
        //       (tile.width ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
        //       (tile.height ?? TILE_TRANSFORM_SCALE) / TILE_TRANSFORM_SCALE,
        //     ]) as Path;
        //     if (svg) {
        //       result.push(
        //         new Shape(svg, baseTileShape.id, tile.id, tile.name, {
        //           images: detailedTile ? detailedTile.images : tile.thumbnails,
        //           filterId: `tile-fill-${baseTileShape.id}-${tile.id}`,
        //         }, undefined)
        //       );
        //     }
        //   }
        // }
        return result;
    }
    catch (e) {
        return result;
    }
}
export function uniqueNumbers(arr, extractNum) {
    if (arr.length === 0) {
        return arr;
    }
    const result = [];
    const sorted = arr.slice().sort((a, b) => extractNum(a) - extractNum(b));
    let last = sorted[0];
    result.push(last);
    for (let i = 1; i < sorted.length; i++) {
        if (Math.abs(extractNum(sorted[i]) - extractNum(last)) > 1e-3) {
            result.push(sorted[i]);
            last = sorted[i];
        }
    }
    return result;
}
export function bboxToSVGViewBox(bbox) {
    return `${bbox.min.x} ${bbox.min.y} ${bbox.extents.x * 2} ${bbox.extents.y * 2}`;
}
