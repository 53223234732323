<script lang="ts">
  export let className = "";
  export let title = "";
  export let icon = undefined;
  export let iconPos: "left" | "center" | "right" = "left";
  export let variant: "primary" | "primary-hover" | "outline-secondary" | "secondary" | "neutral" | "gray" = "primary";
  export let disabled: boolean = false;
  export let size: "sm" | "md" | "lg" = "md";
  export let roundedFull = false;

  export { className as class };

  let btnStyles = "";
  let iconStyles = "";
  let textStyles = "";

  $: {
    switch (variant) {
      case "primary":
        btnStyles = `border-2 transition-colors duration-300
                     ${disabled ? 'bg-primary-300 border-primary-300 cursor-default' : 'border-primary-500 bg-primary-500'}`;
        iconStyles = `text-white ${disabled ? 'cursor-default' : ''}`;
        textStyles = `text-white ${disabled ? 'cursor-default' : ''}`;
        break;
      case "primary-hover":
        btnStyles = `group border-2 transition-colors duration-300
                     ${disabled ? 'bg-primary-300 border-primary-300 cursor-default' : 'bg-gray-100 border-gray-100 hover:border-primary-500 hover:bg-primary-500'}`;
        iconStyles = `text-input-label group-hover:text-white ${disabled ? 'cursor-default' : ''}`;
        textStyles = `text-input-label group-hover:text-white ${disabled ? 'cursor-default' : ''}`;
        break;
      case "outline-secondary":
        btnStyles = `border-2 transition-colors duration-300
                     ${disabled ? 'border-gray-300 cursor-default' : 'hover:bg-secondary-500 hover:border-secondary-500 border-secondary-500'}`;
        iconStyles = `transition-colors duration-300 ${disabled ? 'text-gray-300 cursor-default' : 'text-secondary-500 group-hover:text-white'}`;
        textStyles = `transition-colors duration-300 ${disabled ? 'text-gray-300 cursor-default' : 'text-secondary-500 group-hover:text-white'}`;
        break;
      case "secondary":
        btnStyles = `border-2 transition-colors duration-300
                     ${disabled ? 'bg-secondary-300 border-secondary-300 cursor-default' : 'border-secondary-500 bg-secondary-500'}`;
        iconStyles = `transition-colors duration-300 text-white ${disabled ? 'cursor-default' : 'group-hover:text-primary-500'}`;
        textStyles = `transition-colors duration-300 text-white ${disabled ? 'cursor-default' : 'group-hover:text-primary-500'}`;
        break;
      case "neutral":
        btnStyles = `group border-2 transition-colors duration-300
                     ${disabled ? 'bg-primary-300 border-primary-300 cursor-default' : 'bg-white border-white hover:border-primary-600 hover:bg-primary-600'}`;
        iconStyles = `text-logo-blue group-hover:text-white ${disabled ? 'cursor-default' : ''}`;
        textStyles = `text-logo-blue group-hover:text-white ${disabled ? 'cursor-default' : ''}`;
        break;
      case "gray":
        btnStyles = `group border-2 transition-colors duration-300 bg-gray-100 border-gray-100
                     ${disabled ? ' cursor-default' : ''}`;
        iconStyles = `${disabled ? 'text-input-label/50 cursor-default' : 'text-input-label group-hover:text-primary-600'}`;
        textStyles = `${disabled ? 'text-input-label/50 cursor-default' : 'text-input-label group-hover:text-primary-600'}`;
        break;
    }
  }
</script>

<button
  class={`${className} ${btnStyles} flex ${iconPos === "center" ? "justify-center" : iconPos === "right" ? "justify-center flex-row-reverse" : ""} items-center gap-2.5 ${roundedFull ? "rounded-full" : "rounded-xl"} py-1 px-3 group ${size === "sm" ? "h-10" : size === "lg" ? "h-14" : "h-12"}`}
  disabled={disabled}
  on:click
>
  {#if icon}
    <i class="{icon} {iconStyles}" />
  {/if}
  {#if title}
    <div class={`${textStyles} ${icon ? '' : 'w-full'} text-center ${size === "sm" ? "text-sm font-semibold" : "font-bold"}`}>
      {title}
    </div>
  {/if}
  <slot />
</button>
