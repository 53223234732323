<script lang="ts">
  import Modal from "./Modal.svelte";
  import { _ } from "../../services/i18n";
  import Loading from "../util/Loading.svelte";
  import { drawService, drawSend } from "src/layout.store";
  import { useSelector } from "@xstate/svelte";
  import { previewImage } from "src/store";
  import { ACTION_TYPES } from "src/global/variable";
  import Button from "../base/Button.svelte";
  import Input from "../base/Input.svelte";
  
  export let openModal;
  export let save: Function;
  let name: string = '';
  let nameErr: boolean = false;
  let onSaving = false;

  const projectBaseInfo = useSelector(drawService, (state) => state.context.projectBaseInfo);
  $: $projectBaseInfo, reactiveProjectBaseInfo()
  function reactiveProjectBaseInfo() {
    name = $projectBaseInfo?.name ?? '';
  }

  function replaceUrl() {

    const url = new URL(window.location.href);
    const action = url.pathname.match(/[^\/]+/g).at(-1);

    if (action && action === ACTION_TYPES.PROJECT_SAVE)
      window.history.replaceState(window.history.state, "", window.location.href.replace("/" + ACTION_TYPES.PROJECT_SAVE, ""));
  }

  const handleNameBlur = () => {
    nameErr = name.length < 3 || name.length > 100;
  }

</script>

<Modal bind:openModal width="w-[680px]" containerClass="rounded-3xl" onClose={replaceUrl}>
  <div slot="header" let:close>
    <div class="flex justify-between items-center gap-2 px-8 py-4">
      <div class="text-heading-4 font-bold text-title">{$_(`action_menu.file.save_project`)}</div>
      <i
        class="fa-solid fa-xmark cursor-pointer fa-2x text-title"
        on:click={() => {
          if( onSaving ) return;
          nameErr = false;
          // restore name
          name = $projectBaseInfo?.name ?? '';
          drawSend({ type: "CANCEL_PREVIEW" });
          close()
        }}
        on:keydown
      />
    </div>
  </div>
  <div slot="content" class="px-8 py-4">
    <div class="flex gap-8">
      <div class="flex items-center justify-center w-grid-item-2xl h-grid-item-2xl shrink-0 border border-gray-300 rounded-xl p-2.5">
      {#if $previewImage}
        <img src={$previewImage} class="w-full h-full object-contain" alt="preview" />
      {:else}
        <div class="flex justify-center items-center gap-3">
          <Loading />
        </div>
      {/if}
      </div>
      <div class="mt-3 flex-1">
        <div class="text-base text-title font-bold mb-2.5">{$_("action_menu.file.project_name")}</div>
        <Input 
          value={name}
          error={nameErr}
          on:input={(e) => { name = e.target.value }}
          on:blur={handleNameBlur}
          fullWidth
        />
        <div class="text-sm font-medium mt-2.5 {nameErr ? "text-red-400" : "text-gray-400"}">{$_("action_menu.file.project_name_info")}</div>
      </div>
    </div>
  </div>
  <div slot="footer" let:close>
    <div class="flex justify-end items-center gap-5 px-8 pb-8 pt-4">      
      <Button 
        variant="gray" 
        title={$_("common.cancel")} 
        disabled={onSaving}
        class="w-[130px] justify-center"
        on:click={() => {
          if ( onSaving ) return;
          
          // restore name
          name = $projectBaseInfo?.name ?? '';
          nameErr = false;
          close()
        }}
        roundedFull
      />
      <Button 
        variant="primary" 
        title={$_("action_menu.save")} 
        disabled={!$previewImage || onSaving}
        class="w-[130px] justify-center"
        on:click={() => {
          nameErr = name.length < 3 || name.length > 100
          if (nameErr) return;
          onSaving = true;
          save(name).finally(() => {
            onSaving = false;
            close();
          });
        }}
        roundedFull
      />
    </div>
  </div>
</Modal>
