<script lang="ts">
  import { activeMenu, editSegment, visibleSideMenu } from "src/store";
  import { drawSend } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Header from "./Header.svelte";
  import { isLine, isArc } from "src/helpers";
  import { Line } from "src/model";
  import WallInfo from "./WallInfo.svelte";
  import { SIDE_MENUS } from "src/global/types";
  import { onMount, onDestroy } from "svelte";
  
  enum WALL_VIEW {
    INDEX,
    WALL_DETAIL,
    ADD_DOOR,
    EDIT_DOOR,
    ADD_WINDOW,
    EDIT_WINDOW,
  }

  let routes : WALL_VIEW[] = [WALL_VIEW.INDEX];

  let currentView: WALL_VIEW = WALL_VIEW.INDEX;
  let headerInfo = {
    title: "",
    info: "",
    path: [],
    editable: false,
  };
  let currentWall: Line;

  let unsubscribe
  onMount(() => {
    unsubscribe = visibleSideMenu.subscribe((visible) => {
      if( $activeMenu !== SIDE_MENUS.WALL )
        return;

      if( !visible ) {
        handleBack();
        routes = [WALL_VIEW.INDEX];
        currentView = WALL_VIEW.INDEX;
        currentWall = undefined;
      }
    })
  })
  
  const handleNext = (view: WALL_VIEW) => {
    currentView = view;
    routes.push(currentView);
  };

  const handleBack = () => {
    routes.pop();
    if (routes.length) currentView = routes[routes.length - 1];
  };

  $: {
    switch (currentView) {
      case WALL_VIEW.INDEX:
        headerInfo = {
          title: $_("side_menu.walls"),
          info: "",
          path: [],
          editable: false
        };
        break;
      case WALL_VIEW.WALL_DETAIL:
        headerInfo = {
          title: $_("side_menu.edge.edit_edge"),
          info: "",
          path: [],
          editable: false,
        };
        break;
    }
  }

  const handleTitleChange = (e) => {
    switch(currentView) {
      case WALL_VIEW.WALL_DETAIL:
        currentWall.name = e.detail.value;
        drawSend({
          type: "CHANGE_NAME",
          segment: currentWall,
          name: e.detail.value,
        });
        break;
    }
  }

  
  $: {
    if ($editSegment) {
      updatePanel();
    }
  }

  const updatePanel = () => {
    if( !$editSegment ) return;
    
    if( isLine($editSegment) || isArc($editSegment) ) {
      currentWall = $editSegment as Line;
      routes = [WALL_VIEW.WALL_DETAIL];
      currentView = WALL_VIEW.WALL_DETAIL
    }
    // editSegment.set(undefined);
  }

  onDestroy(() => {
    if( unsubscribe ) unsubscribe();
  })

</script>

<div class="flex flex-col h-full">
  <Header {...headerInfo} on:change={handleTitleChange} />

  <div class="w-full flex flex-col flex-1 p-2.5 overflow-auto gap-4">
    <WallInfo 
      wall={currentWall}
    />
  </div>
</div>
