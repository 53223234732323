<script lang="ts">
  import { convertPointerToClientBox } from "src/helpers";
  import { drawSend, drawState } from "src/layout.store";
  import { Pointer } from "src/model";
  import Input from "../base/Input.svelte";
  import { _ } from "../../services/i18n";
  
  export let svgRef: SVGSVGElement;
  
  let splitCount = $drawState.context.splitContext.splitCount;

  $: toolPosition =
    $drawState.context.dragContext.offset && svgRef
      ? convertPointerToClientBox($drawState.context.dragContext.offset, svgRef)
      : new Pointer(70, 8);

  $: if (splitCount !== null) {
    drawSend({ type: "SPLIT_LINE", splitCount: splitCount });
  }
</script>

<div
  class="line-spliter"
  style="position: absolute; left: {toolPosition.x}px; top: {toolPosition.y}px;"
>
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label id="label-for-split">{$_("app.split_count")}</label>
  <Input
    type="number"
    placeholder="1"
    value={splitCount}
    inputProps="w-24"
    on:input={(e) => (splitCount = e.target.value)}
    min={1}
  />
</div>

<style lang="scss" scoped>
  .line-spliter {
    background: white;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  #label-for-split {
    margin-right: 5px;
  }
</style>