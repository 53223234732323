<!-- <svelte:options immutable={true} /> -->

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";  
  import { drawState, drawService, drawSend } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import { type RecentLayout } from "src/store/drawMachine";
  import type { TileWrapper } from "src/model";
  import Notifications from "../../util/Notifications.svelte"
  import { isTileWrapper, getLayoutShapes, createPreviewLayout, getGeometryForTileWrapper } from "src/helpers";
  import SegmentList from "./SegmentList.svelte";
  import { SEGMENT_LIST_TYPE } from "src/global/types";
  import { debounce } from "lodash";
  import { recentLayouts, currentUser, cachedUserLayouts } from "src/store";
  import { limitLayoutTileDepthForDrag } from "src/tools/LayoutTools";
  import { getUserLayouts } from "src/services/api";
  import type RealizedLayout from "src/model/tile/RealizedLayout";
  import { useSelector } from "@xstate/svelte";
  import InfiniteScroll from "svelte-infinite-scroll";
  import Loading from "../../util/Loading.svelte";

  export let elementScroll: HTMLDivElement;
  let notifications: Notifications;
  let tileWrapper: TileWrapper;
  let currentPage = 1;
  let pageSize = 8;

  let userLayoutsLoading: boolean = true;
  let userLayoutsList: {
    total: number,
    total_pages: number,
    layouts: RecentLayout[]
  };
  const dispatch = createEventDispatcher();

  $: tileWrapper = isTileWrapper($drawState.context.dragContext.selectedObject) ? $drawState.context.dragContext.selectedObject as TileWrapper : null;

  const paginatedUserLayouts = useSelector(drawService, (state) => state.context.paginatedUserLayouts);
  const loadingAsyncUserLayouts = useSelector(drawService, (state) => state.context.loadingAsyncUserLayouts);

  $: $currentUser, currentUserChanged()
  function currentUserChanged() {

    if (!$currentUser) {

      userLayoutsList = undefined;
      currentPage = 1;
    }
    else
      updateUserLayoutsList();
  }

  $: $loadingAsyncUserLayouts, $paginatedUserLayouts, reactiveUserLayouts()
  function reactiveUserLayouts() {

    if (!$loadingAsyncUserLayouts)
    {
      calcUserLayouts();
      userLayoutsLoading = false;
    }
    else
      userLayoutsLoading = true;
  }

  const calcUserLayouts = async () => {

    if (userLayoutsList !== undefined && userLayoutsList.total > 0 && $paginatedUserLayouts.length > 0 && $cachedUserLayouts?.size > 0)
    {
      const length = $paginatedUserLayouts.length;
      for (let i = 0; i < length; ++i)
      {
        if (!$paginatedUserLayouts[i] || typeof $paginatedUserLayouts[i] === 'number')
          continue;

        const userLayout = $paginatedUserLayouts[i] as RealizedLayout;
        const geometry = await getGeometryForTileWrapper(userLayout.layoutGeometryId, $drawState.context.layoutContext.layoutGeometries, $drawState.context.layoutContext.baseShapes, drawSend);
        const tilesToRender = limitLayoutTileDepthForDrag(userLayout, false);
        const preview = await createPreviewLayout(
          tilesToRender,
          userLayout,
          userLayout.shapes,
          true,
          92,
          true
        );

        const newLayout: RecentLayout = {
          layout: userLayout,
          geometryId: userLayout.layoutGeometryId,
          name: geometry?.name ?? "Layout" + i,
          preview: URL.createObjectURL(preview.blob),
          active: false,
          deletable: false,
          clonable: false,
        }
        userLayoutsList.layouts[(currentPage - 1) * pageSize + i] = newLayout;
      }
    }
  }

  const updateUserLayoutsList = () => {

    userLayoutsLoading = true;
    let queryParams : { limit?: number, page?: number } = {
      limit: pageSize,
      page: currentPage
    };

    getUserLayouts(queryParams).then((res) => updateUserLayoutsListSuccess(res)).catch(() => userLayoutsLoading = false)

    async function updateUserLayoutsListSuccess (res: any) {

      let list: {
          total: number,
          total_pages: number,
          layouts: { id: number, updated_at: string, layout_geometry: number, tiles: number[] }[]
        } = res?.data;

      userLayoutsList = !userLayoutsList ? 
        {
          total: list.total,
          total_pages: list.total_pages,
          layouts: []
        } : {
          ...userLayoutsList,
          total: list.total,
          total_pages: list.total_pages,
        }

      drawSend({
        type: "LOAD_USER_LAYOUTS",
        
        userLayouts: list.layouts,
        onError: notifications?.handleError
      });
    }
  }
  
  const handleLoadMore = () => {
    currentPage++
    updateUserLayoutsList()
  }

  let previousReplaceContext = $drawState.context.layoutContext.replaceTileContext;

  $: {
    if (previousReplaceContext?.replaceTileIndices !== undefined && $drawState.context.layoutContext.replaceTileContext?.replaceTileIndices === undefined) {
      calcRecentLayouts();
    }
    previousReplaceContext = $drawState.context.layoutContext.replaceTileContext
  }
  
  const sortLayouts = () => {
    $recentLayouts.sort((a, b) => (b.active ? 1 : 0) - (a.active ? 1 : 0))
  }

  const calcRecentLayouts = async () => {
    const newLayouts: RecentLayout[] = [] //$recentLayouts.map((v) => ({...v, active: false, deletable: true, clonable: true}))
    const tileWrappers = $drawState.context.current.segments.filter((segment) => isTileWrapper(segment))
    for (const [index, wrapper] of tileWrappers.entries()) {
      const _tileWrapper = wrapper as TileWrapper;
      const tilesToRender = limitLayoutTileDepthForDrag(_tileWrapper.tileLayout, false);
      const geometry = await getGeometryForTileWrapper(_tileWrapper.layoutGeometryId, $drawState.context.layoutContext.layoutGeometries, $drawState.context.layoutContext.baseShapes, drawSend);
      if( !geometry )
        continue;
      const shapes = getLayoutShapes(_tileWrapper.tileLayout, geometry, $drawState.context.layoutContext.baseShapes);

      const preview = (await createPreviewLayout(
        tilesToRender, 
        _tileWrapper.tileLayout, 
        shapes, 
        true,
        300,
        true
      ));

      const layoutIndex = newLayouts.findIndex((v) => v.layout.id === _tileWrapper.tileLayout.id)
      const newLayout = {
        layout: _tileWrapper.tileLayout,
        geometryId: _tileWrapper.layoutGeometryId,
        name: geometry?.name ?? "Layout" + index,
        preview: URL.createObjectURL(preview.blob),
        active: tileWrapper?.tileLayout?.id === _tileWrapper.tileLayout.id,
        deletable: false,
        clonable: true,
      }

      if (layoutIndex === -1)
        newLayouts.push(newLayout);
      else
        newLayouts[layoutIndex] = newLayout;
    }
    $recentLayouts = newLayouts;
  }

  onMount(() => {
    calcRecentLayouts();
    sortLayouts();
  })

  const loadLayout = async (recentLayout: RecentLayout, duplicate?: boolean) => {
    if ( recentLayout.active && !duplicate ) return;
    if ( recentLayout.layout.id === tileWrapper?.tileLayout.id ) return;
    
    drawSend({
      type: "LOAD_TILE_LAYOUT",
      tileData: recentLayout.layout,
      savedGeometryLayoutId: recentLayout.geometryId,
      duplicate: duplicate
    });
    dispatch("back");
    // await calcRecentLayouts();
  }

  const isUsedLayout = (layout: RecentLayout) => {
    return !layout.deletable;
  }
  
</script>

<Notifications bind:this={notifications} />
<div class="w-full flex flex-col gap-3 overflow-auto px-2.5">
  <div class="px-1 py-7 border-t border-gray-100">
    <div class="text-base font-semibold text-title mb-4">
      {$_("side_menu.layout.from_this_project")}
    </div>
    <SegmentList 
      type={SEGMENT_LIST_TYPE.TILED_LAYOUT} 
      segments={$recentLayouts}
      on:select={(e) => {
        loadLayout(e.detail.segment, isUsedLayout(e.detail.segment))
      }}
    />
  </div>
  <div class="px-1 py-7 border-t border-gray-100">
    <div class="text-base font-semibold text-title mb-4">
      {$_("side_menu.layout.from_other_project")}
    </div>
    {#if userLayoutsList}
      <SegmentList 
        type={SEGMENT_LIST_TYPE.TILED_LAYOUT} 
        segments={userLayoutsList.layouts}
        on:select={(e) => {
          loadLayout(e.detail.segment, true)
        }}
      />
      <InfiniteScroll elementScroll={elementScroll} threshold={200} on:loadMore={handleLoadMore} hasMore={!userLayoutsLoading && userLayoutsList?.total_pages > currentPage} />
    {/if}
    {#if userLayoutsLoading}
      <div class="flex justify-center items-center">
        <Loading />
      </div>
    {/if}
  </div>
</div>