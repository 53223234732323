<script lang="ts">
    import { onMount } from "svelte";
  import { centroid, getDistancePointer, isBuildingPart, isTileWrapper } from "../../helpers";
  import { drawSend, drawState } from "../../layout.store";
  import { BuildingPart, Pointer, TileWrapper } from "../../model";

  export let scale: number;

  let centerPointer: Pointer | undefined;
  let initialAngle: number = 0;
  let deltaAngle: number = 0;
  let absAngle: number = 0;
  let arcPath: string;
  let textPos: Pointer;

  $: if ($drawState.context.dragContext.selectedObject) {
    centerPointer = undefined
    if (isBuildingPart($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as BuildingPart;
      centerPointer = new Pointer(
        selectedObject.startPointer.x + selectedObject.width / 2,
        selectedObject.startPointer.y + selectedObject.length / 2
      );
      deltaAngle = Math.round((selectedObject.rotation - initialAngle + 360) % 360);
      absAngle = Math.round(selectedObject.rotation)
    } else if (isTileWrapper($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as TileWrapper;
      centerPointer = centroid(selectedObject.shape);
      deltaAngle = Math.round((selectedObject.rotation - initialAngle + 360) % 360);
      absAngle = Math.round(selectedObject.rotation)
    }
  }
  
  $: radius = Math.sqrt(Math.pow($drawState.context.dragContext.offset.x - centerPointer.x, 2) + Math.pow($drawState.context.dragContext.offset.y - centerPointer.y, 2))

  onMount(() => {
    if (isBuildingPart($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as BuildingPart;
      initialAngle = selectedObject.rotation;
    } else if (isTileWrapper($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as TileWrapper;
      centerPointer = centroid(selectedObject.shape);
      initialAngle = selectedObject.rotation;
    }
  })

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = angleInDegrees * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  $: {
    const startAngle = 90
    const endAngle = startAngle + deltaAngle
    let start = polarToCartesian(0, 0, radius, startAngle);
    let end = polarToCartesian(0, 0, radius, endAngle);

    let angleRadius = 8
    let angleStart = polarToCartesian(0, 0, angleRadius, startAngle);
    let angleEnd = polarToCartesian(0, 0, angleRadius, endAngle);

    const arcSweep = 0;
    if( deltaAngle < 180 ) {
      let temp = start;
      start = end;
      end = temp;

      temp = angleStart;
      angleStart = angleEnd;
      angleEnd = temp;
    }
    let textRadius = 20
    let pos = polarToCartesian(0, 0, textRadius, (deltaAngle < 180 ? 1 : -1) * ((startAngle + endAngle) / 2 + 10));
    textPos = new Pointer(deltaAngle < 180 ? pos.x : -pos.x, pos.y);

    arcPath = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
        "L", 0, 0,
        "L", start.x, start.y,        
        "M", angleStart.x, angleStart.y, 
        "A", angleRadius, angleRadius, 0, arcSweep, 0, angleEnd.x, angleEnd.y,
    ].join(" ");
  }
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if centerPointer && arcPath && textPos}
<g transform={`translate(${centerPointer.x}, ${centerPointer.y})`}>
  <path
    d={arcPath}
    stroke-width={`${2 / scale}px`}
    fill="#E7D4AF"
    stroke="#C29436"
    stroke-linecap="round"
    opacity="0.7"
  />
  <text
    class="stroke-text"
    transform="translate({textPos.x}, {textPos.y}) rotate({(deltaAngle < 180 ? deltaAngle : deltaAngle - 360) / 2})"
    fill="#C29436"
    style={`font-size: ${16 / scale}px;`}
  >
    {absAngle < 180 ? absAngle : absAngle - 360}°
  </text>
</g>
{/if}