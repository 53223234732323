<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import EditableLabel from "../../base/EditableLabel.svelte";
  import LongText from "../../base/LongText.svelte";

  export let title = "";
  export let path : string[] = [];
  export let info : string = undefined;
  export let editable: boolean = false;
  export let hasBack: boolean = false;
  const dispatch = createEventDispatcher();
  
  const handleBack = () => {
    dispatch('back')
  }

</script>

<div class="relative min-w-40 text-center pt-5 pb-3 px-12">
  {#if hasBack}
  <div class="absolute left-2.5 top-3.5 cursor-pointer text-gray-400 hover:text-gray-700" on:click={handleBack}>
    <i class="fa-light fa-arrow-left fa-xl">
  </div>
  {/if}

  <div class="flex items-center justify-center gap-1 mb-1">
  {#each path as p, idx}
    {#if idx > 0}
      <div class="text-sm leading-4 text-gray-400">{'>'}</div>
    {/if}
    <LongText text={p} maxLen={16} class="text-sm leading-4 text-gray-400" />
  {/each}
  </div>

    

  {#if editable}
  <EditableLabel value={title} on:change>
    <LongText text={title} maxLen={12} class="text-secondary-500 text-xl font-bold" />
  </EditableLabel>
  {:else}
    <div class="text-secondary-500 text-xl font-bold">{title}</div>
  {/if}

  {#if info}
    <div class="text-xxs text-gray-400 mt-1">{info}</div>
  {/if}
</div>