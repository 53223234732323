<script lang="ts">

  import { _ } from "../../services/i18n";
  import OkCancelDialog from "../base/OkCancelDialog.svelte";
  import { getProject, updateProject, deleteProject } from "src/services/api";
  import { format } from "date-fns";
  import GridItem from "../toolbox/SubPanels/GridItem.svelte";

  export let project: any = {};
  export let onLoad: Function;
  export let onDelete: Function | undefined = undefined;

  let showDeleteDialog = false;
  let showFloatingMenu = false;
  let centerX = 0;
  let centerY = 0;
  let loading = false;

  function showMenu(event: MouseEvent) {
    centerX = event.clientX;
    centerY = event.clientY;
    showFloatingMenu = true;
  };

  function closeMenu() {
    showFloatingMenu = false;
  };

  function loadProject() {
    loading = true;
    getProject(project.slug)
      .then((res) => {
        loading = false;
        if (res !== undefined)
          onLoad(res.data);
      })
  }

  function setShowDeleteDialog(show: boolean = false) {
    showDeleteDialog = show;
  }

  function onRemove() {
    deleteProject(project.slug)
      .then(() => {
        onDelete();
      });
  }

  function clickOutside(event: MouseEvent) {

    if (showFloatingMenu)
    {
      var parent = document.getElementById(project.slug);
      let target: HTMLElement = event.target as HTMLElement;

      if (target !== parent && !parent.contains(target))
        closeMenu();
    }
  };
</script>

<svelte:body
  on:click={clickOutside}
/>

<GridItem
  title={project?.name || ''}
  description={project ? format(project.updated_at, "d MMM yyyy") : ''}
  on:click={loadProject}
>
  <div class="w-full p-2">
    <div class="relative aspect-square h-full overflow-hidden rounded-md bg-white">
      {#if project && project.preview_image}
        <img 
          src={project.preview_image} 
          class="absolute inset-0 h-full w-full transform-gpu transition-transform duration-300 scale-100 transition-all 
                  group-hover:scale-110 object-contain translate-x-0"
          alt={project.name}
        />
      {/if}
      {#if loading}
        <img
          id="cursor-load"
          alt="cursor"
          src="/cursor-load.jpg"
        />
      {/if}
    </div>
  </div>
  {#if onDelete}
  <div class="absolute top-2 right-2">
    <i 
      class="fa-light fa-trash text-red-300 hover:text-red-500"
      on:click={(e) => {
        e.stopPropagation();
        setShowDeleteDialog(true);
      }}
    />
  </div>
  {/if}
</GridItem>
<OkCancelDialog
  toggle={showDeleteDialog}
  okText={$_("common.delete")}
  on:clickOk={onRemove}
  on:clickCancel={() => setShowDeleteDialog()}
>
  <span slot="header">{$_("home.delete_project")}</span>
  <span slot="message">{$_("home.delete_project_message", { values: { name: project.name } })}</span>
</OkCancelDialog>