<script lang="ts">
  import { type FurnitureInfoResponse } from "src/global/types";
  import { _ } from "src/services/i18n";

  export let info: FurnitureInfoResponse | undefined = undefined;
</script>
{#if info}    
<div class="w-80 bg-white border-2 border-gray-300 rounded-lg p-2">
  <!-- <div class="w-6 h-6 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer">
    <i class="fa-solid fa-heart text-input-label" />
  </div> -->
  <div class="w-[300px] h-[300px]">
    {#if info.images}
      <img src={info.images[0]} alt={info.name} class="object-contain w-full h-full"/>
    {:else}
      <img src={info.view_top} alt={info.name} class="object-contain w-full h-full"/>
    {/if}
  </div>
  <div class="my-2.5 flex items-center gap-2.5">
    <!-- {#if info.producer?.thumbnail}
      <div class="w-9 h-9">
        <img src={info.producer.thumbnail} alt={info.producer.name} />
      </div>
    {/if} -->
    <div class="text-sm font-medium text-input-label">{info.name}</div>
  </div>
  <div class="w-full bg-gray-100 rounded-lg p-2.5 divide-y divide-gray-200">
    <!-- <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.brand")}:</div>
      <div class="text-xs text-input-label">{info.producer.name}</div>
    </div> -->
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.collection")}:</div>
      <div class="text-xs text-input-label">{info.collection}</div>
    </div>
    {#if info?.code}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.product_code")}:</div>
      <div class="text-xs text-input-label">{info.code}</div>
    </div>
    {/if}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.tile_info.size")}:</div>
      <div class="text-xs text-input-label">{info.width}x{info.height}cm</div>
    </div>
    {#if info?.element_category}
    <div class="flex justify-between px-1 py-2">
      <div class="text-xs text-gray-400">{$_("side_menu.layout.furniture_info.category")}:</div>
      <div class="text-xs text-input-label">{info.element_category}</div>
    </div>
    {/if}
  </div>
</div>
{/if}