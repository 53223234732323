const isProduction = process.env.NODE_ENV;
export var ROUTE;
(function (ROUTE) {
    ROUTE[ROUTE["ROOT"] = 0] = "ROOT";
    ROUTE[ROUTE["HOME"] = 1] = "HOME";
    ROUTE[ROUTE["NEW_PROJECT"] = 2] = "NEW_PROJECT";
    ROUTE[ROUTE["AUTH_CALLBACK"] = 3] = "AUTH_CALLBACK";
    ROUTE[ROUTE["AUTH_CALLBACK_ACTION"] = 4] = "AUTH_CALLBACK_ACTION";
    ROUTE[ROUTE["BOARD"] = 5] = "BOARD";
    ROUTE[ROUTE["BOARD_ACTION"] = 6] = "BOARD_ACTION";
    ROUTE[ROUTE["FURNITURES_SCREENSHOT"] = 7] = "FURNITURES_SCREENSHOT";
    ROUTE[ROUTE["OTHER"] = 8] = "OTHER";
})(ROUTE || (ROUTE = {}));
export default [
    { path: '/' },
    { path: '/home' },
    { path: '/new-project' },
    { path: `/design-oauth/callback` },
    { path: `/design-oauth/callback/:action` },
    { path: '/board' },
    { path: '/board/:action' },
    { path: '/furnitures-screenshot' },
    { path: '/projects-list' },
    { path: '/*' },
];
