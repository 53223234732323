<script lang="ts">
  import { afterUpdate, onMount, tick } from "svelte";
  import type { Content, ContentColumns, ContentStack, CustomTableLayout, TDocumentDefinitions } from "pdfmake/interfaces";
  import htmlToCanvas from 'html2canvas';
  import { merge, debounce, isEqual } from 'lodash';
  import { editTool, currentTool, accessToken, expireTime, visibleSideMenu, selectedRoom, selectedSegment, previewImage, errMissingParts, shiftKey, isPanning, ctrlKey, tileFilterCategories, wishlistSlug, currentUser, editSegment, furnitureFilterCategories, activeMenu } from "../store";
  import ActionMenu from "./toolbox/ActionMenu.svelte";
  import EditToolbox from "./toolbox/EditToolbox.svelte";
  import SegmentSvg from "./buildings/SegmentSvg.svelte";
  import PointSvg from "./buildings/PointSvg.svelte";
  import DoorSvg from "./buildings/DoorSvg.svelte";
  import BuildingPartSvg from "./buildings/BuildingPartSvg.svelte";
  import TileWrapperSvg from "./buildings/TileWrapperSvg.svelte";
  import TransformIndicator from "./buildings/TransformIndicator.svelte";
  import ResizeIndicator from "./buildings/ResizeIndicator.svelte";
  import LayoutRendering from "./tiles/LayoutRendering.svelte";
  import HomePage from "./Home.svelte";
  import { type FurnitureInfoResponse, PAGES, PROJECT_TYPE, TOOLS, type TileInfoResponse } from "../global/types";
  import {
    Line,
    Arc,
    Pointer,
    Segment,
    Door,
    BuildingPart,
    TileWrapper,
    ClosedArea,
  } from "../model";
  import type { CategoryElement } from "../model/CategoryElement";
  import {
    checkValidMovePointer,
    checkValidMoveSegment,
    convertPointerToViewBox,
    makePointerInSvgBox,
    getGlobalHelperPath,
    getSegmentsOfPoint,
    convertPointerToClientBox,
    getShapeId,
    getShapePath,
    getTileLayouts,
    convertMousePointerToToolPosition,
    getMetricWithUnit,
    isBuildingPart,
    isLine,
    isDoor,
    isArc,
    isTileWrapper,
    castTileWrapper,
    getRoundedAngle,
    convertUnit,
    isClosedArea,
    rgbToHex,
    getShapeBoundingRect,
    getLayoutShapes,
    tileInShape,
    area,
    mergeBoundingRect,
    getBoundingRect,
    getSquareWithUnit,
    getBBFromDHidden,
    isWallProject,
    getBuildingElement,
    getAlphaIndex,
    isInShape,
    createShape,
    getGeometryForTileWrapper,
  } from "../helpers";
  import {
    MAX_ZOOM_RATE,
    MIN_ZOOM_RATE,
    METRIC_UNITS,
    PRECISION_UNITS,
    TILE_TRANSFORM_SCALE,
    TILES_FILTERS,
    PROJECT_PREVIEW_WIDTH,
    PROJECT_PREVIEW_HEIGHT,
    FURNITURE_FILTERS
  } from "../global/variable";
  import { drawSend, drawService, drawState, storedDrawState } from "../layout.store";
  import { _ } from "../services/i18n";
  import { createSampleShapes } from "src/model/tile/Tools";
  import { getAllTileFilters, getDefaultGeometry, getDefaultTiles, getAllFurnituresFilters, getFurniture, getLayoutGeometries, getLayoutGeometry, getMyWishListSlug, getTile, getElementCategories } from "src/services/api";
  import TileWrapperInfo from "./toolbox/TileWrapperInfo.svelte";
  import SidePanel from "./toolbox/SidePanel.svelte";
  import { createRealizedLayoutFromGeometry, emptyLayout, type LayoutGeometry } from "../store/drawMachine"
  import WebGLRenderer from "src/model/WebGLRenderer";
  import { limitLayoutTileDepthForDrag } from "../tools/LayoutTools";
  import router from "page";
  import routes, { ROUTE } from "../router";
  import type Notifications from "./util/Notifications.svelte";
  import Cursor from "./Cursor.svelte";
  import SplitToolbox from "./toolbox/SplitToolbox.svelte";
  import LineLengthToolbox from "./toolbox/LineLengthToolbox.svelte";
  import { FilterCategory } from "src/model/tile/Filter";
  import RealizedLayout from "src/model/tile/RealizedLayout";
  import Loading from "./util/Loading.svelte";
  
  export let page : PAGES = PAGES.HOME;
  export let notifications: Notifications;

  let svgRef: SVGSVGElement;
  let rootRef: HTMLDivElement;
  let svgWrapperRef: any;
  let svgSize = { w: 0, h: 0 };
  let viewBox = { x: 0, y: 0, w: 0, h: 0 }
  let savedViewBox = null
  let scale = 1.0;
  let prevStateOfPrint: boolean = false;
  let prevStateOfSave: boolean = false;
  let keyPressed = {};

  $: isWallType = isWallProject($drawState.context.projectBaseInfo);
  $: startPointer = $drawState.context.drawContext.startPointer;
  $: drawingObject = $drawState.context.drawContext.drawingObject;
  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: selectedCPObj = $drawState.context.dragContext.selectedCPObj;
  $: offset = $drawState.context.dragContext.offset;
  $: splitedLineArray = $drawState.context.splitContext.splitedLineArray;
  $: snapPointer = $drawState.context.snapContext.snapPointer;
  $: snapType = $drawState.context.snapContext.snapType;
  $: isPrintPreview = $drawState.matches("main.printPreview")
  let segmentArray: Segment[] = [];
  let lineLength: string | number | null = null;

  let lineInputRef: any = undefined;
  let isFirstLengthInput = true;
  let bMoused = false;
  
  let eyedropperRef: any;
  let eyedropperZoomRef: any;
  let isEyeDropper = false;

  let categories: CategoryElement[];
  let showCursor: boolean = true;  
  let layout: WebGLRenderer = new WebGLRenderer();
  let tileWrappers: TileWrapper[] = [];

  let defaultLayoutGeometryId: number | undefined;
  let defaultLayouts: {
    [PROJECT_TYPE.ROOM_PLAN]: RealizedLayout,
    [PROJECT_TYPE.WALL_PLAN]: RealizedLayout,
  };

  $: {
    if( lineInputRef )
      lineInputRef.value = $drawState.context.lineLength
  }

  $: {
    if( defaultLayouts ) {
      drawSend({
        type: "SET_DEFAULT_LAYOUT",
        tileData: defaultLayouts[isWallType ? PROJECT_TYPE.WALL_PLAN : PROJECT_TYPE.ROOM_PLAN],
        savedGeometryLayoutId: defaultLayoutGeometryId
      });
    }
  }

  $: loadingAsyncSegments = $drawState.context.loadingAsyncSegments;

  $: getGlobalHelpPath = (): string => {
    if (drawingObject) {
      return getGlobalHelperPath(
        segmentArray,
        [drawingObject],
        [drawingObject.endPointer],
        viewBox
      );
    } else if (selectedCPObj && bMoused) {
      return getGlobalHelperPath(
        segmentArray,
        getSegmentsOfPoint(segmentArray, selectedCPObj),
        [selectedCPObj],
        viewBox
      );
    } else if (selectedObject && isLine(selectedObject) && bMoused) {
      return getGlobalHelperPath(
        segmentArray,
        [selectedObject],
        [selectedObject.startPointer, selectedObject.endPointer],
        viewBox
      );
    }

    return "";
  };

  $: isEditArc =
    $editTool === TOOLS.EDIT_ARC &&
    $drawState.context.dragContext.selectedObject &&
    isArc($drawState.context.dragContext.selectedObject);

  $: isSplitMode =
    $editTool === TOOLS.EDIT_SPLIT &&
    $drawState.context.dragContext.selectedObject &&
    isLine($drawState.context.dragContext.selectedObject);

  $: {
    segmentArray = $drawState.context.current.segments
    .filter((s) => {
      if( !$drawState.matches("main.printing")) return true;
      if( !$selectedRoom ) return true;
      if( isTileWrapper(s) ) {
        return (s as TileWrapper).closedAreaId === $selectedRoom.id
      } else if( isLine(s) || isArc(s) ) {
        if( isLine(s) && (s as Line).parentId === $selectedRoom.id ) return true;
        return $selectedRoom.shape.results.includes(s)
      } else if( isDoor(s) ) {
        return $selectedRoom.shape.results.some((line) => line.id === (s as Door).parentId)
      } else if( isBuildingPart(s) ) {
        return (s as BuildingPart).closedAreaId === $selectedRoom.id
      }

      return false;
    })

    segmentArray.sort((a, b) => isLine(a) ? 1 : isLine(b) ? -1 : a.zIndex - b.zIndex)
    
    const _tileWrappers = segmentArray.filter((s) => isTileWrapper(s)) as TileWrapper[]
    if (!isEqual(tileWrappers, _tileWrappers) || _tileWrappers.some((tw) => tw.updateLayout || tw.update)) {
      _tileWrappers.forEach((tw) => { tw.update = false })
      tileWrappers = _tileWrappers;
    }
  }
  
  $: {
    $selectedSegment
    focusSegment();
  }

  const focusSegment = () => {
    if (!$selectedSegment)
      return;
    if (isTileWrapper($selectedSegment)) {
      const boundingRect = getShapeBoundingRect(($selectedSegment as TileWrapper).shape);
      setViewBox(boundingRect, true);
    } else if (isClosedArea($selectedSegment)) {
      const boundingRect = getShapeBoundingRect(($selectedSegment as ClosedArea).shape);
      setViewBox(boundingRect, true);
    } else if (isBuildingPart($selectedSegment)) {
      const rectPoints = ($selectedSegment as BuildingPart).getRectPoints();
      const left = Math.min(rectPoints[0].x, rectPoints[1].x, rectPoints[2].x, rectPoints[3].x)
      const right = Math.max(rectPoints[0].x, rectPoints[1].x, rectPoints[2].x, rectPoints[3].x)
      const top = Math.min(rectPoints[0].y, rectPoints[1].y, rectPoints[2].y, rectPoints[3].y)
      const bottom = Math.max(rectPoints[0].y, rectPoints[1].y, rectPoints[2].y, rectPoints[3].y)
      setViewBox([left, top, right - left, bottom - top], true);
    } else if (isDoor($selectedSegment)) {
      const startPointer = ($selectedSegment as Door).startPointer;
      const endPointer = ($selectedSegment as Door).endPointer;
      const left = Math.min(startPointer.x, endPointer.x)
      const right = Math.max(startPointer.x, endPointer.x)
      const top = Math.min(startPointer.y, endPointer.y)
      const bottom = Math.max(startPointer.y, endPointer.y)
      setViewBox([left, top, right - left, bottom - top], true);
    }
  }

  const setViewBox = (bounding: number[], isPanelOpen: boolean = false) => {
    let w = bounding[2] * 1.3
    let h = bounding[3] * 1.3
    let centerX = bounding[0] + bounding[2] / 2
    let centerY = bounding[1] + bounding[3] / 2
    const LEFT_SIDEBAR_WIDTH = 425;
    const viewWidth = isPanelOpen ? svgSize.w - LEFT_SIDEBAR_WIDTH : svgSize.w;
    const viewHeight = svgSize.h;
    let newScale = Math.min((viewWidth / w), (viewHeight / h)) * window.devicePixelRatio;
    const currentRatio = w / h;
    const ratio = viewWidth / viewHeight;
    if (newScale > MAX_ZOOM_RATE) {
      w = viewWidth * window.devicePixelRatio / MAX_ZOOM_RATE;
      h = w / ratio;
      newScale = MAX_ZOOM_RATE
    } else if (newScale < MIN_ZOOM_RATE) {
      w = viewWidth * window.devicePixelRatio / MIN_ZOOM_RATE;
      h = w / ratio;
      newScale = MIN_ZOOM_RATE
    } else if( currentRatio < ratio ) {
      w = h * ratio;
    } else {
      h = w / ratio;
    }

    viewBox = {
      x: centerX - w / 2 - (isPanelOpen ? LEFT_SIDEBAR_WIDTH * window.devicePixelRatio / newScale : 0),
      y: centerY - h / 2,
      w: w + (isPanelOpen ? LEFT_SIDEBAR_WIDTH * window.devicePixelRatio / newScale : 0) ,
      h: h,
    }
  }

  const changeAllInView = () => {
    const rooms = $drawState.context.current.segments.filter((segment) => isClosedArea(segment)) as ClosedArea[]
    if( rooms.length > 0 ) {
      let bounding = getShapeBoundingRect(rooms[0].shape)
      for(let i = 1; i < rooms.length; i++)
        bounding = mergeBoundingRect(bounding, getShapeBoundingRect(rooms[i].shape));
      setViewBox(bounding);
    }
  }

  const resizeWindow = () => {
    if (!svgRef) return;
    const centerX = viewBox.x + viewBox.w / 2
    const centerY = viewBox.y + viewBox.h / 2

    const orgScale = svgSize.w / viewBox.w;

    viewBox = {
      x: centerX - svgRef.clientWidth / orgScale / 2,
      y: centerY - svgRef.clientHeight / orgScale / 2,
      w: svgRef.clientWidth / orgScale,
      h: svgRef.clientHeight / orgScale,
    }

    svgSize = {
      w: svgRef.clientWidth,
      h: svgRef.clientHeight,
    };

    zoomProcess(0);
    drawSend({
      type: "SET_SVG",
      svg: svgRef,
      svgSize: svgSize,
    });
  }

  $: {
    if( prevStateOfPrint !== $drawState.matches("main.printPreview") ) {
      prevStateOfPrint = $drawState.matches("main.printPreview");
      if( $drawState.matches("main.printPreview") )
        savedViewBox = viewBox
        changeAllInView();
    }
  }

  $: {
    if( prevStateOfSave !== $drawState.matches("main.savePreview") ) {
      prevStateOfSave = $drawState.matches("main.savePreview");
      if( $drawState.matches("main.savePreview") ) {
        savedViewBox = viewBox
        changeAllInView();
        savePreviewImage();
      }
    }
  }

  $: {
    if( page === PAGES.BOARD ) {
      changeAllInView();
    } else {
      drawSend({ type: "MOUSE_DOWN" });
      drawSend({ type: "CANCEL_DRAWING" });
      visibleSideMenu.set(false);
    }
  }

  // $: gridUnit = PRECISION_UNITS[$drawState.context.currentMetricUnit];
  // $: gridUnitScale =
  //   $drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 1 : 2;
  $: gridUnit = PRECISION_UNITS[$drawState.context.currentMetricUnit];
  $: gridUnitScale = 1;

  $: {
    scale = svgSize.w / viewBox.w;
  }

  //method
  const zoomProcess = (rate: number) => {
    const w = viewBox.w;
    const h = viewBox.h;
    const mx = svgRef.clientWidth / 2;
    const my = svgRef.clientHeight / 2;
    const dw = w * rate;
    const dh = h * rate;
    const dx = (dw * mx) / svgSize.w;
    const dy = (dh * my) / svgSize.h;
    const newScale = svgSize.w / (w - dw);
    if (newScale > MAX_ZOOM_RATE || newScale < MIN_ZOOM_RATE) {
      return;
    }
    viewBox = {
      x: viewBox.x + dx,
      y: viewBox.y + dy,
      w: viewBox.w - dw,
      h: viewBox.h - dh,
    };
  };

  const initSvg = () => {
    viewBox = {
      x: -1 * svgRef.clientWidth / 2,
      y: -1 * svgRef.clientHeight / 2,
      w: svgRef.clientWidth,
      h: svgRef.clientHeight,
    };
    svgSize = {
      w: svgRef.clientWidth,
      h: svgRef.clientHeight,
    };

    zoomProcess(0.6);
    drawSend({
      type: "SET_SVG",
      svg: svgRef,
      svgSize: svgSize,
    });
  };

  const deSelect = () => {
    if ($drawState.matches("lineTool.shown"))
      drawSend({ type: "HIDE_LINE_TOOL" });
    editTool.set(undefined);
    drawSend({ type: "ENTER_SELECT", segment: undefined });
    if ($currentTool === TOOLS.EDIT_ARC || $currentTool === TOOLS.EDIT_SPLIT) {
      currentTool.set(TOOLS.SELECTION);
    }
  };

  const updateMetric = (newUnit: string) => {
    drawSend({ type: "CHANGE_METRIC", newMetricUnit: newUnit });
  };

  const open = (saved) => {

    if (saved === undefined) return;

    errMissingParts.set({ layout: [], tile: [] });
    drawSend({ 
      type: "LOAD_PROJECT", 
      savedData: saved, 
      categories: categories, 
      onError: notifications?.handleError
    });
    // loadTiles();
    tick().then(checkAsyncSegments);

    router.show(routes[ROUTE.BOARD].path)
  };

  const undo = () => {
    if( !$drawState.context.past.length )
      return;

    deSelect();
    drawSend({ type: "UNDO" });
  };

  const redo = () => {
    if( !$drawState.context.future.length )
      return;

    deSelect();
    drawSend({ type: "REDO" });
  };

  const cancelDrawing = () => {
    if ($drawState.matches("main.drawingState")) {
      drawSend({ type: "CANCEL_DRAWING" });
      deSelect();
    }

    bMoused = false;
  };

  const zoomOut = () => {
    zoomProcess(-0.05);
  };

  const zoomIn = () => {
    zoomProcess(0.05);
  };

  const updateLinesWithSplit = () => {
    if (splitedLineArray.length > 0) {
      drawSend({ type: "CONFIRM" });
    }
  };

  const onInputLength = (e: any) => {
    if (e.keyCode === 13) {
      if (isEditArc) {
        drawSend({ type: "DRAG_END" });
        deSelect();
      } else {
        if (drawingObject) {
          const pointer = drawingObject.endPointer;
          drawSend({ type: "MOUSE_DOWN", pointer });
        }
      }
    } else if (isFirstLengthInput) {
      const key = Number(e.key);
      if (!isNaN(key) && e.key !== null && e.key !== " ") {
        lineLength = "";
      }
    }
    isFirstLengthInput = false;
  };

  const keyDown = (e: any) => {
    switch(e.code) {
      case 'Escape':
        currentTool.set(TOOLS.SELECTION);
        break;
      case 'Delete':
        if (!selectedObject) return;
        drawSend({ type: "DELETE" });
        // visibleSideMenu.set(false);
        // activeMenu.set(undefined);
        // selectedRoom.set(undefined);
        // editSegment.set(undefined);
        break;
      case 'KeyY':
        if( e.ctrlKey ) redo();
        break;
      case 'KeyZ':
        if( e.ctrlKey ) undo();
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
        if ((isDoor(selectedObject) && isWallType) || isBuildingPart(selectedObject)) {
          const amount = e.ctrlKey || e.shiftKey ? 1 : 5;
          drawSend({
            type: keyPressed[e.code] ? "MOVE_SEGMENT" : "MOVE_SEGMENT_START",
            movementX: e.code === 'ArrowLeft' ? -amount : e.code === 'ArrowRight' ? amount : 0,
            movementY: e.code === 'ArrowUp' ? -amount : e.code === 'ArrowDown' ? amount : 0
          })
        }
        break;
    }
    
    if (e.shiftKey) {
      shiftKey.set(true);
    } 
    
    if (e.ctrlKey) {
      ctrlKey.set(true);
    }

    keyPressed[e.code] = true;
  };

  const keyUp = (e: any) => {
    if (!e.shiftKey) {
      shiftKey.set(false);
    } 
    
    if (!e.ctrlKey) {
      ctrlKey.set(false);
    }
    keyPressed[e.code] = false;
  };

  const mouseDown = (e: any) => {
    if (!svgRef) return;
    updateLinesWithSplit();
    bMoused = true;
    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );

    let pointer = convertPointerToViewBox(clientPointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);

    // if (
    //   pointer.x < 0 ||
    //   pointer.x > svgSize.w ||
    //   pointer.y < 0 ||
    //   pointer.y > svgSize.h
    // ) {
    //   drawSend({ type: "MOUSE_DOWN" });
    //   return;
    // }

    if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
      if (e.target.classList.contains("resize-indicator")) {
        const id = e.target.id?.replace("indicator_", "");
        drawSend({ type: "RESIZING", pointer, index: Number(id) });
      } else if (
        // !e.target.classList.contains("segment") &&
        !e.target.classList.contains("circle-pointer")
      ) {
        if (selectedObject) {
          const segmentDom = document.getElementById(selectedObject.id)
          if( segmentDom && segmentDom.contains(e.target))
            return;

          // to cancel select segment
          drawSend({ type: "MOUSE_DOWN", pointer: clientPointer });  
        }

        drawSend({ type: "MOUSE_DOWN", pointer: clientPointer });
        $isPanning = true;
      }
      return;
    }

    if (!startPointer) {
      drawSend({ type: "MOUSE_DOWN", pointer });
    } else {
      drawSend({ type: "MOUSE_DOWN" });
    }
  };
  
  const mouseDownEyeDropper = (e: any) => {
    drawSend({ type: "CANCEL_EYEDROPPER" });

    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    const canvas = eyedropperRef;
    const context = canvas.getContext('2d');
    const imgData = context.getImageData(clientPointer.x, clientPointer.y, 1, 1);
    const pixels = imgData.data;
    
    const hex = "#" + ("000000" + rgbToHex(pixels[0], pixels[1], pixels[2])).slice(-6);
    drawSend({
      type: "CHANGE_GROUT_COLOR",
      newGroutColor: hex,
    });
    
    currentTool.set(TOOLS.SELECTION)
    return;
  }

  const mouseMove = async (e: any) => {
    isFirstLengthInput = true;
    $isPanning = false;
    if (!svgRef) return;
    if (bMoused) {
      if ($drawState.matches("lineTool.shown"))
        drawSend({ type: "HIDE_LINE_TOOL" });
    }
    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );

    let pointer = convertPointerToViewBox(clientPointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    
    if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE && $currentTool !== TOOLS.EYE_DROPPER) {
      if (bMoused || $editTool === TOOLS.EDIT_ARC) {
        if ($drawState.matches("main.selectState.resizing")) {
          if (!offset) return;

          drawSend({ type: "RESIZING", pointer });
          return;
        }
        if ($editTool === TOOLS.EDIT_ARC) {
          if (isArc(selectedObject)) {
            drawSend({ type: "CHANGE_ARC", pointer: pointer.translate(offset.x, offset.y), disableSnapping: $ctrlKey });
          }

          return;
        }
        if (selectedCPObj || selectedObject) {
          drawSend({ type: "DRAGGING", pointer, disableSnapping: $ctrlKey });
        } else if (offset) {
          viewBox = {
            x: viewBox.x - (clientPointer.x - offset.x) / scale,
            y: viewBox.y - (clientPointer.y - offset.y) / scale,
            w: viewBox.w,
            h: viewBox.h,
          };
          //update mouse pointer and offset
          drawSend({
            type: "MOUSE_MOVE",
            offset: clientPointer,
            pointer: pointer,
          });
          $isPanning = true;
        }
      } else {
        //update mouse pointer
        drawSend({ type: "MOUSE_MOVE", pointer, disableSnapping: $ctrlKey });
      }
      return;
    }
    if ($drawState.context.drawContext.lastPointer) {
      if (drawingObject) {
        drawSend({ type: "MOUSE_MOVE", pointer: pointer, shiftKey: $shiftKey, disableSnapping: $ctrlKey });
      } else {
        drawSend({ type: "MOUSE_MOVE", pointer: pointer, shiftKey: $shiftKey, disableSnapping: $ctrlKey });
        tick().then(() => lineInputRef?.focus());
      }
    } else {
      drawSend({ type: "MOUSE_MOVE", pointer: pointer, disableSnapping: $ctrlKey });
    }
  };

  const mouseMoveEyeDropper = (e: any) => {
    if(!svgRef) return;
    const ORIG_R = 10;
    const RATIO = 4;
    const clientPointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );

    let pointer = convertPointerToViewBox(clientPointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);

    const origW = ORIG_R * 2 + 1;
    const sourceCanvas = eyedropperRef;
    const targetCanvas = eyedropperZoomRef;
    const ctxSource = sourceCanvas.getContext('2d');
    const ctxTarget = targetCanvas.getContext('2d');

    const imgData = ctxSource.getImageData(pointer.x - ORIG_R, pointer.y - ORIG_R, origW, origW);
    const imgDataTarget = ctxTarget.createImageData(origW * RATIO, origW * RATIO);

    const data = imgData.data;
    for(let i = 0; i < origW * RATIO; i ++) {
      for(let j = 0; j < origW * RATIO; j ++) {
        for(let k = 0; k < 4; k++) {
          const x = Math.floor(j / RATIO);
          const y = Math.floor(i / RATIO);

          imgDataTarget.data[i * origW * RATIO * 4 + j * 4 + k] = data[y * origW * 4 + x * 4 + k];
        }
      }
    }
    ctxTarget.putImageData(imgDataTarget, 0, 0);
    // ctxTarget.drawImage(sourceCanvas, pointer.x - ORIG_R, pointer.y - ORIG_R, origW, origW, 
    //         0, 0, origW * 8, origW * 8);
    
    ctxTarget.strokeRect(0, 0, origW * RATIO, origW * RATIO);

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 - 10, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 - 10)
    ctxTarget.stroke();

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 + 10, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 + 10)
    ctxTarget.stroke();

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 - 10, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 + 2)
    ctxTarget.lineTo(origW * RATIO / 2 - 2, origW * RATIO / 2 + 10)
    ctxTarget.stroke();

    ctxTarget.beginPath();
    ctxTarget.moveTo(origW * RATIO / 2 + 10, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 - 2)
    ctxTarget.lineTo(origW * RATIO / 2 + 2, origW * RATIO / 2 - 10)
    ctxTarget.stroke();

    drawSend({ type: "MOUSE_MOVE", pointer: pointer, disableSnapping: $ctrlKey });
  }

  const mouseUp = async (_: any) => {
    bMoused = false;
    $isPanning = false;
    if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
      if ($drawState.matches("main.selectState.dragging")) {
        if (selectedCPObj) {
          if (
            !isDoor(selectedObject) &&
            ((snapPointer && snapType === -1) ||
              !checkValidMovePointer(segmentArray, selectedCPObj))
          ) {
            drawSend({ type: "POP" });
            return;
          }
        } else if (selectedObject) {
          if (
            !isBuildingPart(selectedObject) &&
            !isTileWrapper(selectedObject) &&
            !isDoor(selectedObject)
          ) {
            let movingSegments: Segment[] = [];
            movingSegments = [
              ...movingSegments,
              ...getSegmentsOfPoint(segmentArray, selectedObject.startPointer),
            ];
            movingSegments = [
              ...movingSegments,
              ...getSegmentsOfPoint(segmentArray, selectedObject.endPointer),
            ];

            movingSegments = [...new Set(movingSegments)];

            if (!checkValidMoveSegment(segmentArray, movingSegments)) {
              drawSend({ type: "POP" });
              return;
            }
          }
        }
        drawSend({ type: "DRAG_END" });
      } else if ($drawState.matches("main.selectState.resizing")) {
        drawSend({ type: "DRAG_END" });
      }
    }

    if (isEditArc) {
      drawSend({ type: "DRAG_END" });
      deSelect();
    }
  };

  const mouseWheel = (e: any) => {
    if( $drawState.matches("main.savePreview") || $drawState.matches("main.printPreview") || $drawState.matches("main.printing")) return;

    const w = viewBox.w;
    const h = viewBox.h;
    const mx = e.offsetX; //mouse x
    const my = e.offsetY;
    const dw = w * Math.sign(-e.deltaY) * 0.05;
    const dh = h * Math.sign(-e.deltaY) * 0.05;
    const dx = (dw * mx) / svgSize.w;
    const dy = (dh * my) / svgSize.h;

    const newScale = svgSize.w / (w - dw);
    if (newScale > MAX_ZOOM_RATE || newScale < MIN_ZOOM_RATE) {
      return;
    }
    viewBox = {
      x: viewBox.x + dx,
      y: viewBox.y + dy,
      w: viewBox.w - dw,
      h: viewBox.h - dh,
    };
  };

  //watch
  $: if (!!$currentTool) {
    cancelDrawing();
    if ($currentTool === TOOLS.POLYLINE || $currentTool === TOOLS.DRAW_LINE) {
      drawSend({ type: "ENTER_DRAWING", tool: $currentTool });
    }
  }

  const watchEditTool = (nv: TOOLS | undefined) => {
    if (isEditArc) {
      drawSend({
        type: "UPDATE_LINE_LENGTH",
        newWidth: +getMetricWithUnit(
          selectedObject
            ? Math.round((selectedObject.getLineLength() || 0) * 100) / 100
            : 0,
          $drawState.context.currentMetricUnit,
          true,
          true
        )
      })
      lineInputRef?.focus()
    } else {
      if (nv === TOOLS.EDIT_ARC && selectedObject && isLine(selectedObject)) {
        const selectedLineObject = selectedObject as Line;
        const midPointer = new Pointer(
          (selectedLineObject.startPointer.x +
            selectedLineObject.endPointer.x) /
            2,
          (selectedLineObject.startPointer.y +
            selectedLineObject.endPointer.y) /
            2
        );
        const length = selectedLineObject.getLineLength() / 3;
        let angle = selectedLineObject.getLineAngle();
        const areas = $drawState.context.current.segments.filter((segment) => isTileWrapper(segment)) as TileWrapper[]
        const area = areas.find((area) => area.shape.results.find((seg) => seg.id === selectedLineObject.id))
        if( area ) {
          const outPointer = midPointer.translate(-Math.cos(angle + Math.PI / 2), Math.sin(angle + Math.PI / 2))
          if( isInShape(outPointer, area.shape) ) {
            angle -= Math.PI / 2
          } else {
            angle += Math.PI / 2
          }
        } else {
          angle += Math.PI / 2
        }
        const heightPointer = midPointer.translate(-length * Math.cos(angle), length * Math.sin(angle) )
        let mousePointer = convertPointerToViewBox($drawState.context.snapContext.mousePointer, svgRef);
        mousePointer = makePointerInSvgBox(mousePointer, svgSize);
        const offset = new Pointer(heightPointer.x - mousePointer.x, heightPointer.y - mousePointer.y);

        drawSend({ type: "MOUSE_MOVE", offset: offset, pointer: heightPointer });
        const selectedArcObj = new Arc(
          selectedLineObject.startPointer,
          selectedLineObject.endPointer,
          heightPointer,
          selectedLineObject.id
        );
        drawSend({ type: "CREATE_ARC", segment: selectedArcObj });

        drawSend({
          type: "UPDATE_LINE_LENGTH",
          newWidth: +getMetricWithUnit(
            Math.round((selectedArcObj.getLineLength() || 0) * 100) / 100,
            $drawState.context.currentMetricUnit,
            true,
            true
          )
        })
        lineInputRef?.focus();
      } else if (nv === TOOLS.EDIT_SPLIT) {
        drawSend({ type: "SPLIT_LINE", splitCount: 2 });
      }
    }
  };

  const watchLineLength = (nv: number | null) => {
    if (!rootRef || !svgRef) return;
    if (nv !== null && nv > 0) {
      const newLength = convertUnit(nv, $drawState.context.currentMetricUnit, true)
      if (
        drawingObject &&
        Math.round(drawingObject.getLineLength() * 100) / 100 !==
          Math.round(newLength * 100) / 100
      ) {
        const angle = getRoundedAngle(drawingObject.getLineAngle());
        const realLength = newLength;
        const endPointer = drawingObject.startPointer.translate(
          realLength * Math.cos(angle),
          -realLength * Math.sin(angle)
        );

        const pointer = makePointerInSvgBox(endPointer, svgSize);
        const clientW = viewBox.w * rootRef.clientWidth / svgSize.w;
        const clientH = viewBox.h * rootRef.clientHeight / svgSize.h;
        viewBox = {
          x: pointer.x > clientW + viewBox.x || pointer.x < viewBox.x ? pointer.x - clientW / 2 : viewBox.x,
          y: pointer.y > clientH + viewBox.y || pointer.y < viewBox.y ? pointer.y - clientH / 2 : viewBox.y,
          w: viewBox.w,
          h: viewBox.h,
        };
        
        tick().then(() => 
          drawSend({
            type: "MOUSE_MOVE",
            disableSnapping: true,
            pointer,
          })
        );
      } else if (
        isEditArc &&
        Math.round(selectedObject!.getLineLength() * 100) / 100 !==
          Math.round(newLength * 100) / 100
      ) {
        const arcObject = selectedObject as Arc;
        const realLength = newLength;

        const linePointerY =
          Math.tan(
            Math.atan2(
              arcObject.endPointer.y - arcObject.startPointer.y,
              arcObject.endPointer.x - arcObject.startPointer.x
            )
          ) *
            (arcObject.heightPointer.x - arcObject.startPointer.x) +
          arcObject.startPointer.y;

        const sign = linePointerY < arcObject.heightPointer.y ? 1 : -1;

        const leftPointer =
          arcObject.startPointer.x <= arcObject.endPointer.x
            ? arcObject.startPointer
            : arcObject.endPointer;
        const rightPointer =
          arcObject.startPointer.x > arcObject.endPointer.x
            ? arcObject.startPointer
            : arcObject.endPointer;

        const deltaX = -Math.abs(
          realLength *
            Math.cos(
              Math.atan2(
                rightPointer.y - leftPointer.y,
                rightPointer.x - leftPointer.x
              )
            )
        );

        const deltaY =
          (Math.atan2(
            rightPointer.y - leftPointer.y,
            rightPointer.x - leftPointer.x
          ) > 0
            ? 1
            : -1) *
          Math.abs(
            realLength *
              Math.sin(
                Math.atan2(
                  arcObject.endPointer.y - arcObject.startPointer.y,
                  arcObject.endPointer.x - arcObject.startPointer.x
                )
              )
          );
        const heightPointer = arcObject
            .getCenterPointer()
            .translate(-deltaY * sign, -deltaX * sign)
        drawSend({
          type: "CHANGE_ARC",
          pointer: heightPointer,
          disableSnapping: true,
        });
        
        let mousePointer = convertPointerToViewBox($drawState.context.snapContext.mousePointer, svgRef);
        
        const padding = offset.translate(heightPointer.x - mousePointer.x, heightPointer.y - mousePointer.y);
        drawSend({ type: "MOUSE_MOVE", offset: padding, pointer: heightPointer });
        // for redrawing
        segmentArray = segmentArray;
      }
    }
  };

  $: watchEditTool($editTool);

  $: watchLineLength(Number(lineLength));

  $: if ($accessToken && notifications) {
    loadResources();
  }

  $: $currentUser, updateWishlist()

  $: if ( isPrintPreview ) {
    handlePrint();
  }

  const updateWishlist = () => {
    if( $currentUser ) {
      getMyWishListSlug().then((slug) => {
        wishlistSlug.set(slug);
      })
    } else {
      wishlistSlug.set(undefined);
    }
  }

  const loadTiles = () => {
    try {
      $drawState.context.current.segments.filter((seg) => isTileWrapper(seg)).forEach(async (seg) => {
        const tileWrapper = seg as TileWrapper;
        const layoutGeometry = await getGeometryForTileWrapper(tileWrapper.layoutGeometryId, $drawState.context.layoutContext.layoutGeometries, $drawState.context.layoutContext.baseShapes, drawSend)
        // const tileData = createRealizedLayoutFromGeometry(layoutGeometry, $drawState.context.layoutContext.baseShapes);
        if( !layoutGeometry ) {
          drawSend({
            type: "LOAD_GEOMETRY",
            layoutGeometry,
            savedGeometryLayoutId: layoutGeometry.id,
          });
          drawSend({
            type: "SHOW_LAYOUT_GEOMETRY",
            tileData: $drawState.context.layoutContext.layout,
            savedGeometryLayoutId: layoutGeometry.id,
            segment: tileWrapper,
          });
        }
      })
    } catch( e ) {
      // console.log(e);
    }
    drawSend({ type: "LOAD_RESOURCE" });

    tick().then(checkAsyncSegments);
    // tick().then(() => drawSend({ type: "LOAD_SUCCESS" }));
  }

  const checkAsyncSegments = () => {

    if (!loadingAsyncSegments && defaultLayouts)
    {
      drawSend({ type: "LOAD_SUCCESS" })
      changeAllInView();
    }
    else
      setTimeout(checkAsyncSegments, 100);
  };

  const loadResources = () => {
    getAllFurnituresFilters().then((res) => {
      if (res) {
        if( res.element_categories ) {
          categories = res.element_categories as CategoryElement[];
        }

        let childrenTypes = res[FURNITURE_FILTERS.CHILDREN_TYPES];
        delete res[FURNITURE_FILTERS.CHILDREN_TYPES];
        for (let category in res)
        {
          if (Array.isArray(res[category]) && category !== FURNITURE_FILTERS.SORT)
          {
            let filters = res[category]
            $furnitureFilterCategories[category] = new FilterCategory(category, filters, childrenTypes ? childrenTypes[category] : undefined);
          }
        }
      }
    });

    
    getAllTileFilters()
    .then((res) => {
      if ( !res?.data ) return;

      let childrenTypes = res.data[TILES_FILTERS.CHILDREN_TYPES];
      delete res.data[TILES_FILTERS.CHILDREN_TYPES];

      for (let category in res.data)
      {
        if (Array.isArray(res.data[category]) && category !== TILES_FILTERS.SORT)
        {
          let filters = res.data[category]
          if( category === TILES_FILTERS.TILE_SHAPES ) {
            filters = filters.map((filter) => {
              const boundingBox = getBBFromDHidden(filter.svg)
              let scaleX = filter.default_width / 12 / boundingBox.width;
              let scaleY = filter.default_height / 12 / boundingBox.height;
              if (boundingBox.height * scaleY > 1.8) {
                const ratio = boundingBox.height * scaleY / 1.8;
                scaleX /= ratio;
                scaleY /= ratio; 
              }

              return {
                ...filter,
                scale: {
                  x: scaleX,
                  y: scaleY
                },
                translate: {
                  x: -(boundingBox.width / 2 + boundingBox.x),
                  y: -(boundingBox.height / 2 + boundingBox.y)
                },
                stroke: 0.05 / Math.max(scaleX, scaleY)
              }
            })
          }

          $tileFilterCategories[category] = new FilterCategory(category, filters, childrenTypes ? childrenTypes[category] : undefined);
          // if (category === TILES_FILTERS.PRODUCERS)
          // {
          //   filtersCategories[category]?.filters.forEach(filter => {
          //     filter.setCollapsingSubfilters(COLLAPSING_STATUS.COLLAPSED);
          //     collapsedFilters[category][filter.slug] = undefined;
          //   });
          // }
        }
      }
      $tileFilterCategories[TILES_FILTERS.DIMENSIONS] = new FilterCategory(TILES_FILTERS.DIMENSIONS, []);
    })

    createSampleShapes(!!$accessToken && $expireTime > Date.now())
    .then((res) => {
      drawSend({ type: "LOAD_TILE_SHAPE", data: res });

      const baseShapes = $drawState.context.layoutContext.baseShapes;
      if( storedDrawState ) { 
        errMissingParts.set({ layout: [], tile: [] });
        drawSend({ 
          type: "LOAD_PROJECT_LOCAL", 
          savedLocalData: JSON.parse(storedDrawState), 
          onError: notifications?.handleError
        });

        tick().then(checkAsyncSegments);

        // tick().then(() => {
        //   drawSend({ type: "LOAD_SUCCESS" })
        //   changeAllInView();
        // });
      }
      else
        loadTiles();
      
      let defaultLayoutGeometry: LayoutGeometry;
      let page = 1;
      const promiseGeometry = new Promise(async (resolve, reject) => {
        while (true) {
          const res = await getDefaultGeometry(page)
          if( res.data?.layout_geometries?.length ) {
            for(let i = 0; i < res.data.layout_geometries.length; i++) {
              const geometry = res.data.layout_geometries[i];
              const svgPath = JSON.parse(geometry.svg_path);
              if( !Array.isArray(svgPath.dimensions) || svgPath.dimensions.length === 0 )
                continue;
              const dim = svgPath.dimensions[0]
              if( !svgPath.dimensions.every((v) => v.length === 2 && v[0] === dim[0] && v[1] === dim[1]) )
                continue;
              defaultLayoutGeometry = {
                ...geometry,
                tile_shapes: geometry.tile_shapes.map((tileShapeId) => {
                  const baseShape = baseShapes.find((v) => v.shapeId === tileShapeId)
                  if (!baseShape) return null
                  return {
                    id: tileShapeId,
                    name: baseShape.name,
                    svg_path: baseShape.path,
                    default_width: baseShape.width,
                    default_height: baseShape.height
                  }
                }).filter((v) => !!v)
              }

              defaultLayoutGeometryId = defaultLayoutGeometry.id;
              
              resolve(Promise.all([getDefaultTiles(true), getDefaultTiles(false)]))
              return;
            }
          }
          page ++;
          if( page > res?.data?.total_pages ) {
            reject("Not found proper layout")
            return;
          }
        }
      })
      
      promiseGeometry.then(([resRoomTiles, resWallTiles]) => {
        const promises = [];
        if( resRoomTiles.data.tiles.length )
          promises.push(getTile(resRoomTiles.data.tiles[0].slug));
        if( resWallTiles.data.tiles.length )
          promises.push(getTile(resWallTiles.data.tiles[0].slug));
        return Promise.all(promises)
      })
      .then(([resRoomTileInfo, resWallTileInfo]) => {
        let roomLayout = createRealizedLayoutFromGeometry(defaultLayoutGeometry, baseShapes);
        let wallLayout = createRealizedLayoutFromGeometry(defaultLayoutGeometry, baseShapes);
        if( resRoomTileInfo ) {
          const tileInfo = resRoomTileInfo as TileInfoResponse;
          const shape = createShape(tileInfo, baseShapes)
          if( shape ) {
            roomLayout.calcOverrideAspectRatio(
              shape,
              0,
              shape.width,
              shape.height,
              defaultLayoutGeometry,
            );
          }
          
          roomLayout = createRealizedLayoutFromGeometry(defaultLayoutGeometry, baseShapes, undefined, roomLayout)
        }
        if( resWallTileInfo ) {
          const tileInfo = resWallTileInfo as TileInfoResponse;
          const shape = createShape(tileInfo, baseShapes)
          if( shape ) {
            wallLayout.calcOverrideAspectRatio(
              shape,
              0,
              shape.width,
              shape.height,
              defaultLayoutGeometry,
            );
          }
          
          wallLayout = createRealizedLayoutFromGeometry(defaultLayoutGeometry, baseShapes, undefined, wallLayout)
        }
        defaultLayouts = {
          [PROJECT_TYPE.ROOM_PLAN]: roomLayout,
          [PROJECT_TYPE.WALL_PLAN]: wallLayout,
        }
      })
      .catch(() => {
        defaultLayouts = {
          [PROJECT_TYPE.ROOM_PLAN]: emptyLayout,
          [PROJECT_TYPE.WALL_PLAN]: emptyLayout,
        }
      })
    });
  };

  const savePreviewImage = async () => {
      await tick();
    // get svg data
      let clonedSvgElement = svgRef.cloneNode(true) as SVGSVGElement;
      const allSegments = clonedSvgElement.querySelectorAll(".segment_path");
      allSegments.forEach((seg) => seg.setAttribute('stroke', '#333333'));
      const canvas = document.createElement('canvas'); 
      const shapeW = viewBox.w * scale;
      const shapeH = viewBox.h * scale;
      const marginX = 0; 
      const marginY = 0;
      canvas.width = shapeW + marginX * 2;
      canvas.height = shapeH + marginY * 2;

      const browserZoomLevel = window.devicePixelRatio;
      const ctx = canvas.getContext("2d");
      const svgCanvas = await htmlToCanvas(svgWrapperRef)
      const startPointer = convertPointerToClientBox(new Pointer(viewBox.x, viewBox.y), svgRef);
      ctx.drawImage(layout.canvas, 0, 0, layout.canvas.width, layout.canvas.height, 
        marginX, marginY, shapeW, shapeH);
      const svgContext = svgCanvas.getContext("2d");
      let imgData = svgContext.getImageData(0, 0, svgCanvas.width, svgCanvas.height);

      const data = imgData.data;
      for(let i = 0; i < data.length; i += 4) {
        if(data[i] === 254 && data[i + 1] === 226 && data[i + 2] === 226) // mask color rgb(254, 226, 226)
          data[i + 3] = 0x01;
        if(data[i] === 255 && data[i + 1] === 241 && data[i + 2] === 241) { // for door/window of mask color rgb(254, 226, 226)
          data[i] = 255;
          data[i + 1] = 255;
          data[i + 2] = 255;
          data[i + 3] = 0x80;
        }
      }
      svgContext.putImageData(imgData, 0, 0);
      ctx.drawImage(svgCanvas, 
        (startPointer.x - marginX) * browserZoomLevel, (startPointer.y - marginY) * browserZoomLevel, canvas.width * browserZoomLevel, canvas.height * browserZoomLevel,
        0, 0, canvas.width, canvas.height
      );

      /// clip image

      let boundingRect = [0, 0, 0, 0]
      const rooms = $drawState.context.current.segments.filter((segment) => isClosedArea(segment)) as ClosedArea[]
      rooms.forEach((room, roomIndex) => {
        const bounding = getShapeBoundingRect(room.shape);
        if( roomIndex > 0 )
          boundingRect = mergeBoundingRect(boundingRect, bounding)
        else
          boundingRect = bounding
      })

      // for empty project
      if( boundingRect[2] === 0 && boundingRect[3] === 0 ) {
        boundingRect[2] = 100
        boundingRect[3] = 100
      } 
      if( boundingRect[2] < 100 ) {
        boundingRect[0] = boundingRect[0] - (100 - boundingRect[2]) / 2
        boundingRect[2] = 100
      }
      if( boundingRect[3] < 100 ) {
        boundingRect[1] = boundingRect[1] - (100 - boundingRect[3]) / 2
        boundingRect[3] = 100
      }
      
      const fCanvas = document.createElement('canvas'); 
      const ctxFinal = fCanvas.getContext("2d");
      const newStartPointer = convertPointerToClientBox(new Pointer(boundingRect[0], boundingRect[1]), svgRef);

      const newShapeW = boundingRect[2] * scale;
      const newShapeH = boundingRect[3] * scale;
      const newMarginX = newShapeW * 0.1; 
      const newMarginY = newShapeH * 0.1; 
      fCanvas.width = PROJECT_PREVIEW_WIDTH;
      fCanvas.height = PROJECT_PREVIEW_HEIGHT;
      const fW = newShapeW + newMarginX * 2;
      const fH = newShapeH + newMarginY * 2;
      const x = (newStartPointer.x - newMarginX) + (fW - Math.max(fW, fH)) / 2
      const y = (newStartPointer.y - newMarginY) + (fH - Math.max(fW, fH)) / 2

      ctxFinal.drawImage(canvas, 
        x, y, Math.max(fW, fH), Math.max(fW, fH),
        0, 0, fCanvas.width, fCanvas.height
      );

      previewImage.set(fCanvas.toDataURL())
      handleCancelPrint();
  }

  const handlePrint = async () => {
    // get svg data
    const pdfMake = (await import("../helpers/pdfMake")).default;

      let clonedPrintElement = svgWrapperRef.cloneNode(true) as HTMLDivElement;
      const allSegments = clonedPrintElement.querySelectorAll(".segment_path");
      allSegments.forEach((seg) => seg.setAttribute('stroke', '#333333'));
      
      const baseShapes = $drawState.context.layoutContext.baseShapes;
      const rooms = $drawState.context.current.segments.filter((segment) => isClosedArea(segment)) as ClosedArea[]
      const areas = $drawState.context.current.segments.filter((segment) => isTileWrapper(segment)) as TileWrapper[]
      
      const furnitures: {
        furniture: BuildingPart,
        buildingElement: FurnitureInfoResponse,
        quantity: number;
        label: string;
      }[] = [];

      const buildingParts = $drawState.context.current.segments.filter((segment) => isBuildingPart(segment)) as BuildingPart[]
      for(const furniture of buildingParts) {
        const lastOne = furnitures.find((v) => v.furniture.buildingId === furniture.buildingId)
        if( lastOne ) {
          lastOne.quantity ++;
          furniture.setPrintLabel(lastOne.label);
        } else {
          const buildingElement = await getFurniture(furniture.buildingId)
          const label = getAlphaIndex(furnitures.length)
          furnitures.push({
            furniture,
            quantity: 1,
            buildingElement,
            label
          })
          furniture.setPrintLabel(label);
        }
      }
      
      const tileInfoAll = rooms.map((room) => 
        room.tileWrappers.map((tileWrapper) => {
          const geometry = $drawState.context.layoutContext.layoutGeometries[tileWrapper.layoutGeometryId];
          if( !geometry ) return;
          const tileShapes = getLayoutShapes(tileWrapper.tileLayout, geometry, baseShapes);
          const uniqueTileShapes = tileShapes.filter((v, idx) => tileShapes.findIndex((tile) => tile.tileId === v.tileId) === idx)
          
          const tilesInShape = limitLayoutTileDepthForDrag(tileWrapper.tileLayout, false).filter((tile) => tileInShape(tile, tileWrapper));
          
          let calcResult = {};
          tilesInShape.forEach((v) => {
            if( calcResult[v.shape.tileId] ) {
              calcResult[v.shape.tileId].count ++;
            } else {
              const points = v.shape.path.toPoints().map((v) => new Pointer(v.x, v.y));
              let totalArea = Math.abs(area(points));
              calcResult[v.shape.tileId] = {
                count: 1,
                area: totalArea * TILE_TRANSFORM_SCALE * TILE_TRANSFORM_SCALE,
              };
            }
          })

          const totalTileArea = tileShapes.reduce((acc: number, current) => acc + calcResult[current.tileId].area, 0)
          const roomArea = tileWrapper.getArea()
          return uniqueTileShapes.map((shape) => {
            if (!calcResult[shape.tileId]) return null;
            const sameShapeCnt = tileShapes.filter((v) => v.tileId === shape.tileId).length;
            return {
              shape: shape,
              surface: totalTileArea ? roomArea * calcResult[shape.tileId].area * sameShapeCnt / totalTileArea : 0,
              size: calcResult[shape.tileId].area,
              count: calcResult[shape.tileId].count,
            }
          })
        }).flat()
      ).flat()
      .filter((v) => !!v);

      const tileInfo = [];
      tileInfoAll.forEach((info) => {
        const dupInfo = tileInfo.find(
          v => v.shape.shapeId === info.shape.shapeId && 
          v.shape.tileId === info.shape.tileId && 
          v.shape.width === info.shape.width && 
          v.shape.height === info.shape.height
        )
        if( dupInfo ) {
          dupInfo.surface += info.surface;
          dupInfo.count += info.count;
        } else {
          tileInfo.push(info);
        }
      })

      const roomInfo: {
        room: ClosedArea,
        image: string
      }[] = [];

      for (let i = 0; i < rooms.length; i++) {
        const canvas = document.createElement('canvas');
        const room = rooms[i];
        
        drawSend({
          type: "ENTER_SELECT",
          segment: room,
        });
        $selectedRoom = room;
        const boundingRect = getShapeBoundingRect(room.shape);
        
        // extend helper text boundaries
        boundingRect[0] -= 28;  
        boundingRect[1] -= 28;
        boundingRect[2] += 56;
        boundingRect[3] += 56;

        setViewBox(boundingRect);
        scale = svgSize.w / viewBox.w; // required for immediate update 

        await tick();

        const shapeW = boundingRect[2] * scale;
        const shapeH = boundingRect[3] * scale;
        const marginX = shapeW * 0.1; 
        const marginY = shapeH * 0.1; 
        canvas.width = shapeW + marginX * 2;
        canvas.height = shapeH + marginY * 2;

        const browserZoomLevel = window.devicePixelRatio;
        const ctx = canvas.getContext("2d");
        const svgCanvas = await htmlToCanvas(svgWrapperRef)
        const startPointer = convertPointerToClientBox(new Pointer(boundingRect[0], boundingRect[1]), svgRef);
        const scaleX = layout.canvas.width / window.innerWidth;
        const scaleY = layout.canvas.height / window.innerHeight;

        ctx.drawImage(layout.canvas, startPointer.x * scaleX, startPointer.y * scaleY, shapeW * scaleX, shapeH * scaleY, 
            marginX, marginY, shapeW, shapeH);
        const svgContext = svgCanvas.getContext("2d");
        let imgData = svgContext.getImageData(0, 0, svgCanvas.width, svgCanvas.height);

        const data = imgData.data;
        for(let i = 0; i < data.length; i += 4) {
          if(data[i] === 254 && data[i + 1] === 226 && data[i + 2] === 226) // mask color rgb(254, 226, 226)
            data[i + 3] = 0x01;
          if(data[i] === 255 && data[i + 1] === 241 && data[i + 2] === 241) { // for door/window of mask color rgb(254, 226, 226)
            data[i] = 255;
            data[i + 1] = 255;
            data[i + 2] = 255;
            data[i + 3] = 0x80;
          }
        }
        svgContext.putImageData(imgData, 0, 0);
        ctx.drawImage(svgCanvas, 
          (startPointer.x - marginX) * browserZoomLevel, (startPointer.y - marginY) * browserZoomLevel, canvas.width * browserZoomLevel, canvas.height * browserZoomLevel,
          0, 0, canvas.width, canvas.height
        );

        roomInfo.push({
          room: room,
          image: canvas.toDataURL(),
        })
      }

      const pdfImages = merge(...areas.map((area) => {
        return $drawState.context.layoutContext.layoutGeometries[area.layoutGeometryId]
      }).filter((geometry) => geometry && geometry.preview_image).map((geometry) => ({
        [`geometry-${geometry.id}`]: geometry.preview_image
      })), ...(tileInfo.filter(({ shape }) => shape.tileData.filterId).map(({ shape }) => ({
          [shape.tileData.filterId]: shape.tileData.images[Math.floor(Math.random() * shape.tileData.images.length)]
      }))), ...(furnitures.map(({ furniture, buildingElement }) => ({
          [`furniture-${buildingElement.id}`]: buildingElement.images ? buildingElement.images[0] : furniture.image
      }))))

      const tableLayout: CustomTableLayout = {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 0 : 1;
        },
        vLineWidth: (i) => 0,
        hLineColor: (i) => '#CCCCCC',
        paddingLeft: (i) => 15,
        paddingRight: (i) => 15,
        paddingTop: (i) => i > 0 ? 15 : 7,
        paddingBottom: (i) => i > 0 ? 15 : 7,
        fillColor: (i) => i === 0 ? "#AFAFAF" : "#F5F5F5"
      }

      const projectName = $drawState.context.projectBaseInfo?.name ?? $_("project_wizard.untitled");
      const currentTime = Date.now();
      
      const content: Content = [];

      /////// Summary Page ///////
      content.push({ 
        text: [ 
            {text: projectName + " > ", style: 'description'},
            {text: $_("print.product_summary"), style: 'title'},
          ],
          style: 'headerBread'
        },{ 
          text: $_("print.product_summary"), 
          style: ['title', 'headerTitle']
        }
      )
      
      const summaryTable : Content = {
        table: {
          widths: [ '*', 'auto' ],
          headerRows: 1,
          dontBreakRows: true,
          body: [
            [ 
              { text: $_("print.item"), style: 'headerCell' }, 
              { text: $_("print.quantity"), style: 'headerCell', alignment: 'right' }
            ],
          ],
        },
        layout: 'tableLayout',
      }
      
      tileInfo.forEach(({ shape, surface, count }, index) => {
        summaryTable.table.body.push([{
            columns: [
              {
                width: 24,
                stack: [
                  {
                    canvas: [
                      {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 24,
                        h: 24,
                        r: 24,
                        color: '#CCCCCC',
                      },
                    ],
                    relativePosition: {x: 0, y: 18 }
                  },
                  { text: `${index + 1}`, alignment: 'center', marginTop: 23, style: 'title' },
                ]
              },
              {
                width: 60,
                stack: [
                  {
                    canvas: [
                      {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 60,
                        h: 60,
                        color: '#FFFFFF',
                        lineColor: '#CCCCCC',
                      },
                    ],
                    relativePosition: {x: 0, y: 0}
                  },
                  {
                    ...(shape.tileData?.filterId ? {
                      image: shape.tileData?.filterId
                    } : {
                      svg: `<svg viewBox="-1 -1 2 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                          <clipPath id="tile-clip-path-${shape.tileId}-${currentTime}">
                            <path d="${shape.path.toSvgPath()}" />
                          </clipPath>
                        </defs>
                        <g
                          id="base_shape_tile_${shape.tileId}"
                          clip-path="url(#tile-clip-path-${shape.tileId}-${currentTime})"
                        >
                          <path
                            d="${shape.path.toSvgPath()}"
                            stroke-width="0.1"
                            fill="white"
                            stroke="#555555"
                            class="segment"
                          />
                        </g>
                      </svg>`
                    }),
                    cover: { width: 58, height: 58, valign: "center", align: "center" },
                    margin: 1
                  },
                ]
              },
              {
                width: '*',
                stack: [
                  shape.tileData?.info ? { text: `${shape.tileData?.info.producer.name} ${shape.tileData?.info.code ? `#${shape.tileData.info.code}` : ''}`, bold: true, style: ['cell', 'inputLabel']} : {},
                  { text: shape.name, style: ['cell', 'description'] },
                  { text: `${getMetricWithUnit(shape.width, $drawState.context.currentMetricUnit, true, true)} x ${getMetricWithUnit(shape.height, $drawState.context.currentMetricUnit, false, true)}`, style: ['cell', 'description']},
                ],
                marginTop: shape.tileData?.info ? 8 : 16
              },
            ],
            // optional space between columns
            columnGap: 10
          },
          {
            stack: [
              { text: getSquareWithUnit(surface, $drawState.context.currentMetricUnit), style: ['cell', 'inputLabel'], alignment: 'right' },
              { text: `${count} pieces`, style: ['cell', 'description'], alignment: 'right' }
            ],
            fontSize: 12,
            marginTop: 16
          }
        ])
      })
      
      furnitures.forEach(({furniture, buildingElement, quantity}, index) => {
        summaryTable.table.body.push([
          {
            columns: [
              {
                width: 24,
                stack: [
                  {
                    canvas: [
                      {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 24,
                        h: 24,
                        r: 24,
                        color: '#CCCCCC',
                      },
                    ],
                    relativePosition: {x: 0, y: 18 }
                  },
                  { text: `${furniture.printLabel}`, alignment: 'center', marginTop: 23, style: 'title' },
                ]
              },
              {
                width: 60,
                stack: [
                  {
                    canvas: [
                      {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 60,
                        h: 60,
                        color: '#FFFFFF',
                        lineColor: '#CCCCCC',
                      },
                    ],
                    relativePosition: {x: 0, y: 0}
                  },
                  {
                    image: `furniture-${buildingElement.id}`,
                    cover: { width: 58, height: 58, valign: "center", align: "center" },
                    margin: 1
                  },
                ]
              },
              {
                width: '*',
                stack: [
                  { text: buildingElement.name, bold: true, style: ['cell', 'inputLabel']},
                  { text: `${getMetricWithUnit(furniture.width, $drawState.context.currentMetricUnit, true, true)} x ${getMetricWithUnit(furniture.length, $drawState.context.currentMetricUnit, false, true)}`, style: ['cell', 'description']},
                ],
                marginTop: 16
              },
            ],
            // optional space between columns
            columnGap: 10
          },
          { text: quantity, margin: [0, 23], alignment: 'right', style: ['cell', 'inputLabel'] }
        ])
      })

      content.push(summaryTable);

      roomInfo.forEach(({ image, room }) => {
        content.push(
          {
            stack: [
              { 
                text: [ 
                  {text: projectName + " > ", style: 'description'},
                  {text: room.getName($_), style: 'title'},
                ],
                style: 'headerBread'
              },
              { 
                text: room.getName($_), 
                style: ['title', 'headerTitle']
              },
              {
                image: image,
                fit: [514, 628],
                alignment: 'center'
              },
            ] as Content[],
            headlineLevel: 1,
          },
        )

        // add area table
        const areaTables: Content[] = room.tileWrappers.map((area) => {
          const currentGeometry = $drawState.context.layoutContext.layoutGeometries[area.layoutGeometryId];
          if( !currentGeometry ) return;
          const tileShapes = getLayoutShapes(area.tileLayout, currentGeometry, baseShapes);
          const uniqueTileShapes = tileShapes.filter((v, idx) => tileShapes.findIndex((tile) => tile.tileId === v.tileId) === idx)
          const usedTiles = uniqueTileShapes.map((v) => {
            return tileInfo.findIndex((tile) => tile.shape.tileId === v.tileId && tile.shape.width === v.width && tile.shape.height === v.height)
          })
          
          return {
            table: {
              widths: [ 150, '*' ],
              headerRows: 1,
              dontBreakRows: true,
              marginBottom: 20,
              body: [
                [ 
                  { text: area.getName($_), style: 'headerCell' }, 
                  { text: "", style: 'headerCell' }
                ],
                [
                  {
                    text: $_("side_menu.layout.pattern"), margin: [0, 23], style: ['cell', 'description']
                  },
                  {
                    columns: [
                      { 
                        width: 'auto',
                        text: currentGeometry?.name || $_("side_menu.layout.no_pattern"), 
                        style: ['cell', 'inputLabel'],
                        margin: [0, 23]
                      },
                      {
                        width: 60,
                        stack: [
                          {
                            canvas: [
                              {
                                type: 'rect',
                                x: 0,
                                y: 0,
                                w: 60,
                                h: 60,
                                color: (currentGeometry?.preview_image || currentGeometry?.svg_path ) ? undefined : area.tileLayout.groutColor,
                                lineColor: '#CCCCCC',
                              },
                            ],
                            relativePosition: {x: 0, y: 0 }
                          },
                          currentGeometry?.preview_image ? {
                            image: `geometry-${currentGeometry.id}`,
                            cover: { width: 58, height: 58, valign: "center", align: "center" },
                            margin: 1
                          } : currentGeometry?.svg_path ? {
                            svg: currentGeometry.svg_path,
                            cover: { width: 58, height: 58, valign: "center", align: "center" },
                            margin: 1
                          } : undefined,
                        ]
                      }
                    ],
                    columnGap: 10,
                  },
                ],
                [
                  {
                    text: $_("print.grout_color"), margin: [0, 5], style: ['cell', 'description']
                  },
                  {
                    columns: [
                      { 
                        width: 'auto',
                        text: area.tileLayout.groutColor, 
                        style: ['cell', 'inputLabel'],
                        margin: [0, 5]
                      },
                      {
                        width: 24,
                        canvas: [
                          {
                            type: 'rect',
                            x: 0,
                            y: 0,
                            w: 24,
                            h: 24,
                            r: 24,
                            color: area.tileLayout.groutColor,
                            lineColor: '#CCCCCC',
                          },
                        ],
                      }
                    ],
                    columnGap: 10,
                  },
                ],
                [
                  {
                    text: $_("print.grout_thickness"), style: ['cell', 'description']
                  },
                  {
                    text: `${getMetricWithUnit(
                        (area.tileLayout.gapSize * TILE_TRANSFORM_SCALE) * ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 10 : 1),
                        $drawState.context.currentMetricUnit,
                        $drawState.context.currentMetricUnit === METRIC_UNITS[0]
                      )} ${$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 'mm' : ''}`, style: ['cell', 'inputLabel']
                  },
                ],
                [
                  {
                    text: $_("print.tile_used"), margin: [0, 5], style: ['cell', 'description']
                  },
                  {
                    columns: usedTiles.map((tileIdx) => ({
                      width: 24,
                      stack: [
                        {
                          canvas: [
                            {
                              type: 'rect',
                              x: 0,
                              y: 0,
                              w: 24,
                              h: 24,
                              r: 24,
                              color: '#CCCCCC',
                            },
                          ],
                          relativePosition: {x: 0, y: 0 }
                        },
                        { text: `${tileIdx + 1}`, alignment: 'center', marginTop: 5, style: 'title' },
                    ]})),
                    columnGap: 10
                  },
                ]
              ]
            },
            layout: 'tableLayout',
            marginBottom: 20
          }
        })
        content.push({
          stack: [
            { 
              text: [ 
                {text: projectName + " > ", style: 'description'},
                {text: room.getName($_), style: 'title'},
              ],
              style: 'headerBread'
            },
            areaTables
          ] as Content[],
          headlineLevel: 1,
        })
      })

      const docDefinition : TDocumentDefinitions = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        footer: function(currentPage, pageCount) { 
          return {
            text: currentPage.toString() + '/' + pageCount, 
            alignment: 'right', 
            margin: [40, 14],
            fontSize: 14,
            style: 'description'
          }
        },
        content: content,
        styles: {
          headerCell: {
            fontSize: 12,
            bold: true,
            color: '#FFFFFF',
          },
          cell: {
            fontSize: 12,
          },
          headerBread: {
            fontSize: 14,
            marginBottom: 20
          },
          headerTitle: {
            fontSize: 32,
            bold: true,
            marginBottom: 20
          },
          title: {
            color: '#212529'
          },
          description: {
            color: '#AFAFAF'
          },
          inputLabel: {
            color: '#595959'
          },
        },
        images: pdfImages,
        pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          return currentNode.headlineLevel === 1;
        }
      };
      pdfMake.createPdf(docDefinition, { tableLayout }).download( projectName );

      ///////////
      // CSV
      ///////////
      /*
      const rows = [
        [ 'Item name', 'Surface occupied', 'Tile Size', 'Tile Count'],
        ...tileInfo.map(({ shape, surface, size, count }) => [ shape.name, `${Math.round(surface / 100) / 100}m²`, `${size}m²`, `${count}`])
      ]
      const csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");
      
      const pom = document.createElement('a');
      pom.href = encodeURI(csvContent);
      pom.setAttribute('download', "tiles.csv");
      pom.click();
      */
      ///////////
      handleCancelPrint();
  }
  
  const handleCancelPrint = () => {
    viewBox = savedViewBox;
    savedViewBox = null;
    selectedRoom.set(undefined);
    drawSend({ type: "CANCEL_PREVIEW" });
  }

  const handleEyeDropper = () => {
    // tick().then(() => {
    //   htmlToCanvas(svgWrapperRef).then((svgCanvas) => {
        const canvas = eyedropperRef;
        canvas.width = viewBox.w * scale;
        canvas.height = viewBox.h * scale;
        const shapeW = canvas.width;
        const shapeH = canvas.height;

        const browserZoomLevel = window.devicePixelRatio;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(layout.canvas, 0, 0, layout.canvas.width, layout.canvas.height, 
            0, 0, shapeW, shapeH);
        // const svgContext = svgCanvas.getContext("2d");
        // let imgData = svgContext.getImageData(0, 0, svgCanvas.width, svgCanvas.height);

        // const data = imgData.data;
        // for(let i = 0; i < data.length; i += 4) {
        //   if(data[i] === 0xFF && data[i + 1] === 0xFF && data[i + 2] === 0xFF)
        //     data[i + 3] = 0x01;
        // }
        // svgContext.putImageData(imgData, 0, 0);
        // ctx.drawImage(svgCanvas, 
        //   startPointer.x * browserZoomLevel, startPointer.y * browserZoomLevel, canvas.width * browserZoomLevel, canvas.height * browserZoomLevel,
        //   0, 0, canvas.width, canvas.height
        // );

        isEyeDropper = true;
        drawSend({ type: "EYEDROPPER_LOADED" });
    //   })
    // })
  }

  //mount
  onMount(() => {
    const timer = setInterval(() => {
      if( svgRef ) {
        initSvg()
        clearInterval(timer);
      }
    }, 50);
    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    window.addEventListener("resize", resizeWindow);
    if (!$accessToken) {
      loadResources();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const unit = urlParams.get("unit");
    if (unit && unit !== METRIC_UNITS[0]) {
      updateMetric(unit);
    }

    const beforePrint = () => {
      savedViewBox = {...viewBox};
      viewBox = {
        x: 0,
        y: 0,
        w: svgRef.clientWidth,
        h: svgRef.clientHeight,
      };
    };

    const afterPrint = () => {
      viewBox = savedViewBox;
      savedViewBox = null;
    };

    window.addEventListener("beforeprint", beforePrint);
    window.addEventListener("afterprint", afterPrint);

    drawService
      .onTransition((drawState: any) => {
        if (drawState.matches("main.normal")) {
          if (drawState.matches("lineTool.shown"))
            drawSend({ type: "HIDE_LINE_TOOL" });
          if (!drawState.context.drawContext.startPointer) {
            // currentTool.set(TOOLS.SELECTION);
            editTool.set(undefined);
          }
        }
      })
      .start();
    
    const unsubscribe = currentTool.subscribe((v) => {
      if( v === TOOLS.EYE_DROPPER ) {
        setTimeout(() => {
          handleEyeDropper();
        }, 50)
      } else {
        isEyeDropper = false;
      }
    })

    return () => {
      window.removeEventListener("keydown", keyDown);
      window.removeEventListener("keyup", keyUp);
      window.removeEventListener("beforeprint", beforePrint);
      window.removeEventListener("afterprint", afterPrint);
      window.removeEventListener("resize", resizeWindow);
      unsubscribe();
    };
  });
</script>

{#if page === PAGES.HOME || page === PAGES.NEW_PROJECT}
<div class="homepage">
  <HomePage 
    {page}
    {open}
  />
</div>
{/if}
<div class={`root-container ${page === PAGES.BOARD ? '' : 'invisible'}`} bind:this={rootRef}>
  <canvas
    id="eyedropper-canvas"
    on:mousedown={mouseDownEyeDropper}
    on:touchstart={mouseDownEyeDropper}
    on:mousemove={mouseMoveEyeDropper}
    on:touchmove={mouseMoveEyeDropper}
    on:mouseenter={() => {
      showCursor = true;
    }}
    on:mouseleave={() => {
      showCursor = false;
      mouseUp(null);
    }}
    class={`absolute inset-0 w-full h-full ${isEyeDropper ? '' : 'hidden'}`} 
    bind:this={eyedropperRef}
  />
  <canvas
    class={`absolute z-10 ${isEyeDropper ? '' : 'hidden'}`} 
    style="left: {$drawState.context.snapContext.mousePointer.x + 24}px; top: {$drawState.context.snapContext.mousePointer.y}px;"
    width="84px"
    height="84px"
    bind:this={eyedropperZoomRef}
  />
  <div class={`svg-container ${isEyeDropper ? 'hidden' : ''}`} bind:this={svgWrapperRef}>
  <svg
    bind:this={svgRef}
    on:mousedown={mouseDown}
    on:touchstart={mouseDown}
    on:mouseup={mouseUp}
    on:touchend={mouseUp}
    on:mousemove={mouseMove}
    on:touchmove={mouseMove}
    on:mouseenter={() => {
      showCursor = true;
    }}
    on:mouseleave={() => {
      showCursor = false;
      mouseUp(null);
    }}
    on:wheel={mouseWheel}
    width="100%"
    height="100%"
    viewBox={viewBox.x + " " + viewBox.y + " " + viewBox.w + " " + viewBox.h}
  >
    <defs>
      <pattern
        id="smallGrid"
        width={gridUnit * gridUnitScale}
        height={gridUnit * gridUnitScale}
        patternUnits="userSpaceOnUse"
      >
        <path
          d={`M ${gridUnit * gridUnitScale} 0 L 0 0 0 ${
            gridUnit * gridUnitScale
          }`}
          fill="none"
          stroke="gray"
          stroke-width="0.25"
        />
      </pattern>
      <pattern
        id="grid"
        width={gridUnit *
          ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 5 : 6)}
        height={gridUnit *
          ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 5 : 6)}
        patternUnits="userSpaceOnUse"
      >
        <rect
          width={gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 5 : 6)}
          height={gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 5 : 6)}
          fill="url(#smallGrid)"
        />
        <path
          d={`M ${
            gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 5 : 6)
          } 0 L 0 0 0 ${
            gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 5 : 6)
          }`}
          fill="none"
          stroke="gray"
          stroke-width="0.75"
        />
      </pattern>
      <pattern
        id="biggrid"
        width={gridUnit *
          ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 10 : 12)}
        height={gridUnit *
          ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 10 : 12)}
        patternUnits="userSpaceOnUse"
      >
        <rect
          width={gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0]
              ? 10
              : 12)}
          height={gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0]
              ? 10
              : 12)}
          fill="url(#grid)"
        />
        <path
          d={`M ${
            gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 10 : 12)
          } 0 L 0 0 0 ${
            gridUnit *
            ($drawState.context.currentMetricUnit === METRIC_UNITS[0] ? 10 : 12)
          }`}
          fill="none"
          stroke="gray"
          stroke-width="1.25"
        />
      </pattern>
      {#each getTileLayouts($drawState.context.current.segments) as tileWrapper}
        <clipPath id={getShapeId(tileWrapper.shape)}>
          <path d={getShapePath(tileWrapper.shape)} />
        </clipPath>
      {/each}
      <clipPath id="WebGLRendererClipPath" transform={`translate(${-viewBox.x} ${-viewBox.y})`}>
      {#each getTileLayouts($drawState.context.current.segments) as tileWrapper}
        <path d={getShapePath(tileWrapper.shape)} />
      {/each}
      </clipPath>

      <marker id="marker-dash-normal" viewBox="0 0 6 1.5" refX="0" refY="0.75" markerUnits="userSpaceOnUse" markerWidth="6" markerHeight="1.5" orient="auto" class="fill-normal">
        <rect x="0" y="0" width="4" height="1.5" rx="1"/>
      </marker>
      <marker id="marker-dash-hover" viewBox="0 0 6 1.5" refX="0" refY="0.75" markerUnits="userSpaceOnUse" markerWidth="6" markerHeight="1.5" orient="auto" class="fill-primary">
        <rect x="0" y="0" width="4" height="1.5" rx="1"/>
      </marker>
    </defs>
    {#if $drawState.matches("grid.showGrid") && !$drawState.matches("main.savePreview") && !$drawState.matches("main.printPreview") && !$drawState.matches("main.printing") && $currentTool !== TOOLS.EYE_DROPPER}
      <rect
        width={viewBox.w}
        height={viewBox.h}
        x={viewBox.x}
        y={viewBox.y}
        fill="url(#biggrid)"
        class="gridRect"
      />
    {/if}
    <LayoutRendering
      viewBox={viewBox}
      scale={scale}
      bind:tileWrappers={tileWrappers}
      bind:layout={layout}
    />
    <g>
      {#each tileWrappers as segment}
        {#if !$drawState.matches("main.selectState.dragging") || !(isArc(selectedObject) || isLine(selectedObject))}
          <TileWrapperSvg {segment} {svgRef} {svgSize} {scale} tileLayout={segment?.tileLayout} />
        {/if}
      {/each}
      {#each segmentArray as segment}
        {#if isBuildingPart(segment) && segment instanceof BuildingPart}
          <BuildingPartSvg {segment} {svgRef} {svgSize} {scale} />
        {:else if isDoor(segment) && segment instanceof Door}
          {#if isWallType}
            <BuildingPartSvg {segment} {svgRef} {svgSize} {scale} />
          {:else}
            <DoorSvg door={segment} {svgRef} {svgSize} {scale} />
          {/if}
        {:else}
          <SegmentSvg {segment} {svgRef} {svgSize} {scale} />
        {/if}
      {/each}
    </g>
    <PointSvg segments={$drawState.matches("main.printPreview") || $drawState.matches("main.savePreview") ? tileWrappers : undefined} {svgRef} {svgSize} {scale} />
    {#if ((isWallType && isDoor(selectedObject)) || isBuildingPart(selectedObject)) && selectedObject?.startPointer && 
        !$drawState.matches("main.selectState.dragging") && 
        !$drawState.matches("main.savePreview") &&
        !$drawState.matches("main.printPreview") && 
        !$drawState.matches("main.printing") && 
        $currentTool !== TOOLS.EYE_DROPPER
    }
      <ResizeIndicator {scale} />
    {/if}
    
    {#if $drawState.matches("main.selectState.rotating")}
      <TransformIndicator scale={scale} />
    {/if}
    {#if $drawState.matches("helper.showHelper")}
      {@const globalHelpPath = getGlobalHelpPath()}
      {#if !!globalHelpPath}
      <g>
        <path
          d={globalHelpPath}
          fill="none"
          stroke-linecap="round"
          stroke="yellow"
          stroke-width="0.5px"
        />
      </g>
      {/if}
    {/if}
  </svg>
  {#each tileWrappers as segment}
    <TileWrapperInfo tileWrapper={segment} {scale} />
    <!-- {#if !$drawState.matches("main.selectState.dragging")}
      <AddTileBtn shape={segment.shape} {scale} />
    {/if} -->
  {/each}
  </div>
  {#if $drawState.matches("main.printPreview") || $drawState.matches("main.savePreview")}
    <div class="absolute inset-0 z-[2] bg-white flex items-center justify-center">
      <Loading />
    </div>
  {/if}
  <!-- <TileCountInfoPanel tileWrappers={tileWrappers}/> -->
  <SidePanel {zoomIn} {zoomOut} />
  <ActionMenu
    {open}
    {undo}
    {redo}
    availableUndo={!!$drawState.context.past.length}
    availableRedo={!!$drawState.context.future.length}
  />
  <!-- <SelectTile /> -->
  {#if svgRef}
    {#if !$drawState.matches("main.drawingState") && $drawState.matches("lineTool.shown") && !isSplitMode && !$drawState.matches("main.savePreview") && !$drawState.matches("main.printPreview") && !$drawState.matches("main.printing")}
      <EditToolbox svgRef={svgRef}/>
    {/if}
    {#if isSplitMode}
      <SplitToolbox svgRef={svgRef} />
    {/if}
    <LineLengthToolbox 
      svgRef={svgRef} 
      onInputLength={onInputLength} 
      bind:lineInputRef={lineInputRef} 
      bind:lineLength={lineLength} 
      isEditArc={isEditArc} 
    />
  {/if}

  <Cursor showCursor={showCursor} />
  
  <a class="" rel="noreferrer" target="_blank" href="https://wa.me/+390424567729">
    <button class={`fixed bottom-4 right-4 flex items-center justify-center p-2 gap-2 rounded-full bg-green-500 hover:bg-teal-600 text-white transition-all duration-150 shadow-md hover:cursor-pointer hover:shadow-xl focus:outline-none`}
      style="z-index: 9"
    >
      <div class="pointer-events-none opacity-60 m-0 absolute inset-0 transform-gpu ios-flicker-fix animate-ping-md rounded-full bg-green-500"></div>
      <div class="">{$_("ask_help")}</div>
      <i class="fab fa-whatsapp fa-2x" />
    </button>
  </a>
</div>

<style lang="scss" scoped>
  .root-container,
  .svg-container {
    position: relative;
    // top: 0;
    // left: 0;
    // right: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: white;
  }
  #eyedropper-canvas,
  svg {
    cursor: none;
  }

  @page {
    size: 1920pt 1080pt;
    margin: 0pt;
  }

  .gridRect {
    mix-blend-mode: difference;
    pointer-events: none;
  }

  @media print {
    .gridRect {
      display: none;
    }
  }
  
  .homepage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 8;
  }

  .print-actions {
    position: absolute;
    left: 36px;
    bottom: 36px;
  }
</style>
