<script lang="ts">
  import { _ } from "../../services/i18n";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import Icon from "./Icon.svelte";
  import Transition from "../util/Transition.svelte";

  export let okText = "Ok";
  export let cancelText = $_("common.cancel");
  export let toggle: boolean;
</script>

<svelte:window
  on:keydown={(e) => {
    let event = undefined;
    if (e.key == "Escape") event = "clickCancel";
    else if (e.key == "Enter") event = "clickOk";

    if (event !== undefined) {
      dispatch(event);
      e.preventDefault();
      e.stopPropagation();
    }
  }}
/>

<Transition
  {toggle}
  inTransition="ease-out duration-300"
  outTransition="ease-in duration-200"
  inState="opacity-0"
  onState="opacity-100"
>
  <div
    class="fixed z-30 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500/75 transition-opacity"
        aria-hidden="true"
      />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true">&#8203;</span
      >

      <Transition
        {toggle}
        inTransition="ease-out duration-300"
        outTransition="ease-in duration-200"
        inState="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        onState="opacity-100 translate-y-0 sm:scale-100"
      >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              on:click={() => dispatch("clickCancel")}
            >
              <span class="sr-only">{$_("common.close")}</span>
              <Icon name={$_("common.cancel")} icon="close" />
            </button>
          </div>
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <Icon fill="fill-current text-red-500" icon="delete" />
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                <slot name="header">&nbsp;</slot>
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  <slot name="message">&nbsp;</slot>
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              on:click={() => dispatch("clickOk")}
            >
              {okText}
            </button>
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              on:click={() => dispatch("clickCancel")}
            >
              {cancelText}
            </button>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</Transition>
