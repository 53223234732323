import * as THREE from 'three';
// import convert from 'xml-js';
import { convertXML } from 'simple-xml-to-json';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import { TileWrapper } from 'src/model';
// import { getShapeBoundingRect } from "../helpers";
import { beforeUpdate } from 'svelte';
import { limitLayoutTileDepthForDrag } from '../../tools/LayoutTools';
import { Pointer, Segment } from 'src/model/';
import { TILE_TRANSFORM_SCALE } from 'src/global/variable';
import { v4 as uuidv4 } from 'uuid';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
export default class Room extends TileWrapper {
    linesGroup;
    tilesGroup;
    furnituresGroup;
    mainGroup;
    stencilBufferReference; // 0 is not a valid reference
    previousOffset;
    stencilMeshes;
    backupMaterial;
    clock;
    backupScale;
    groutColor;
    tilesImages;
    constructor(t, index) {
        super(t.startPointer, t.endPointer, t.id, t.closedAreaId, t.parentId, t.shape, t.tileLayout);
        this.copy(t);
        this.stencilBufferReference = index + 1;
        this.previousOffset = new Pointer(0, 0);
        this.stencilMeshes = [];
        this.backupMaterial = [];
        this.clock = new THREE.Clock();
        this.backupScale = [];
        this.tilesImages = [];
        this.tilesGroup = [];
    }
    copy(t) {
        this.startPointer = t.startPointer;
        this.endPointer = t.endPointer;
        this.id = t.id;
        this.parentId = t.parentId;
        this.shape = t.shape;
        this.tileLayout = t.tileLayout;
        this.rotation = t.rotation;
        this.offset = t.offset;
        this.snapTile = t.snapTile;
        this.zIndex = t.zIndex;
        this.class = t.class;
        this.layoutGeometryId = t.layoutGeometryId;
        this.tiles = t.tiles;
    }
    addFurnituresMesh(newFurniture) {
        const addMesh = (geo) => {
            const geometry = new THREE.BufferGeometry();
            const pos = new THREE.BufferAttribute(new Float32Array(geo.positions), 3);
            const norm = new THREE.BufferAttribute(new Float32Array(geo.normals), 3);
            geometry.setAttribute('position', pos);
            geometry.setAttribute('normal', norm);
            //geometry.computeVertexNormals();
            geometry.setIndex(geo.faces);
            // const material = new THREE.MeshPhongMaterial({
            //     color: 0x00ff00,
            //     //wireframe: true,
            // })
            const material = new THREE.MeshStandardMaterial({
                color: 0x007fff,
                transparent: true,
                opacity: 0.2,
                //wireframe: true,
                // depthTest: false
            });
            const furniture = new THREE.Mesh(geometry, material);
            // furniture.rotation.x = THREE.MathUtils.degToRad(90);
            furniture.renderOrder = 1;
            this.furnituresGroup.add(furniture);
        };
        let jsonGeo = [];
        const model = newFurniture.model;
        const geometry = newFurniture.geometry;
        // const data = JSON.parse(xml2json(m, {compact: true, spaces: 4}));
        const data = convertXML(model);
        // console.log(data);
        const splitLines = (str) => str.split(/\r?\n/);
        const arrayGeometry = geometry.split('GEOMETRY ');
        arrayGeometry.splice(0, 1);
        for (const geo of arrayGeometry) {
            if (geo.startsWith('####'))
                continue;
            const temp = geo.split('Positions');
            const geoData = splitLines(temp[0]);
            const geoPos = [];
            const geoNorm = [];
            const geoUVs = [];
            const geoFaces = [];
            const parseValues = (input, factor = 1) => {
                const regex = /:(-?\d+(?:\.\d+)?)/g;
                const matches = [];
                let match;
                while ((match = regex.exec(input)) !== null) {
                    const n = parseFloat(match[1]) / factor;
                    matches.push(n);
                }
                return matches;
            };
            for (const p of splitLines(temp[1])) {
                if (p.startsWith('p')) {
                    const input = p.split('p ')[1];
                    geoPos.push(...parseValues(input, 10));
                }
                else if (p.startsWith('n')) {
                    const input = p.split('n ')[1];
                    geoNorm.push(...parseValues(input));
                }
                else if (p.startsWith('u')) {
                    const input = p.split('u ')[1];
                    geoUVs.push(...parseValues(input));
                }
                else if (p.startsWith('f')) {
                    const input = p.split('f ')[1];
                    const regex = /\[(\d+(?:;\d+)*)\]/;
                    const match = input.match(regex);
                    if (match) {
                        const t = match[1].split(';').map(Number);
                        geoFaces.push(...[t[0] - 1, t[1] - 1, t[2] - 1]);
                    }
                }
            }
            let geometry = {
                id: parseInt(geoData[0]),
                positions: geoPos,
                normals: geoNorm,
                uv: geoUVs,
                faces: geoFaces,
            };
            jsonGeo.push(geometry);
        }
        if (this.furnituresGroup === undefined)
            this.furnituresGroup = new THREE.Group();
        //TEMP one furniture at a time
        else {
            this.furnituresGroup.clear();
            this.furnituresGroup.removeFromParent();
            this.furnituresGroup = new THREE.Group();
        }
        for (const g of jsonGeo)
            addMesh(g);
        // geometry.computeBoundingBox();
        let boundingBox = new THREE.Box3().setFromObject(this.furnituresGroup);
        // boundingBox.copy(geometry.boundingBox);//.applyMatrix4( mesh.matrixWorld );
        let boundingBoxSize = new THREE.Vector3();
        boundingBox.getSize(boundingBoxSize);
        if (window.bounding === true) {
            // add 0.001 to each vector component to avoid z-fighting and to have the bounding box slightly bigger for hovering
            const boundingBoxGeometry = new THREE.BoxGeometry(boundingBoxSize.x + 0.001, boundingBoxSize.y + 0.001, boundingBoxSize.z + 0.001);
            const boundingBoxMaterial = new THREE.MeshBasicMaterial({
                color: 0xff0000,
                //wireframe: true,
                depthTest: false,
            });
            const boundingBoxMesh = new THREE.Mesh(boundingBoxGeometry, boundingBoxMaterial);
            this.furnituresGroup.add(boundingBoxMesh);
        }
        this.furnituresGroup.scale.set(data.model.width / TILE_TRANSFORM_SCALE / boundingBoxSize.x, data.model.height / TILE_TRANSFORM_SCALE / boundingBoxSize.y, data.model.depth / TILE_TRANSFORM_SCALE / boundingBoxSize.z);
    }
    getTiles() {
        return this.layoutGeometryId !== -1 ? limitLayoutTileDepthForDrag(this.tileLayout, false) : undefined; // .filter((tile) => tileInShape(tile, segment));
    }
    setLinesColor(newColor) {
        const color = new THREE.Color(newColor);
        for (var child of this.linesGroup.children) {
            let line = child;
            let colorAttr = line.geometry.getAttribute('color');
            for (var i = 0; i < colorAttr.count; i++)
                color.toArray(colorAttr.array, i * colorAttr.itemSize);
        }
    }
    setMeshColor(newColor, indexTiles) {
        const color = new THREE.Color(newColor);
        this.tilesGroup.forEach((tiles, index) => {
            if (indexTiles === -1 || indexTiles === index) {
                for (var tile of tiles.children) {
                    let mesh = tile;
                    mesh.material.color.set(color);
                }
            }
        });
    }
    setGapColor(newColor) {
        this.groutColor = newColor.toString();
        const color = new THREE.Color(newColor);
        for (var mesh of this.stencilMeshes)
            mesh.material.color.set(color);
    }
    animateMesh(indexTiles, highlight) {
        if (!this.tilesGroup || indexTiles >= this.tilesGroup.length)
            return false;
        let tiles = this.tilesGroup[indexTiles];
        let length = tiles?.children?.length;
        if (!length)
            return false;
        if (this.clock)
            this.clock.stop();
        if (!highlight)
            this.clock = undefined;
        else
            this.clock = new THREE.Clock();
        return true;
    }
    highlightMesh(indexTiles, highlight) {
        if (!this.tilesGroup || indexTiles >= this.tilesGroup.length)
            return false;
        let tiles = this.tilesGroup[indexTiles];
        let length = tiles.children.length;
        if (length === 0)
            return false;
        if (highlight)
            this.backupMaterial = Array(length).fill(undefined);
        for (let i = 0; i < length; ++i) {
            let mesh = tiles.children[i];
            const color = new THREE.Color(highlight ? 0xff00000 : 0xffffff);
            const material = new THREE.MeshBasicMaterial({ color: color });
            if (highlight) {
                this.backupMaterial[i] = mesh.material;
                mesh.material = material;
            }
            else {
                mesh.material = this.backupMaterial[i];
                this.backupMaterial[i] = undefined;
            }
        }
        if (!highlight)
            this.backupMaterial = [];
        return true;
    }
}
/* shapeBounding = getShapeBoundingRect(segment.shape)
<foreignObject
  x={shapeBounding[0]}
  y={shapeBounding[1]}
  width={shapeBounding[2]}
  height={shapeBounding[3]}
>

  <canvas bind:this={canvas} width={shapeBounding[2]} height={shapeBounding[3]} on:mousedown={mouseDownE} class="segment">
  </canvas> */
