<script lang="ts">
  import Login from "./Login.svelte";
  import { _ } from "../../services/i18n";
  import SaveProjectModal from "../dialogs/SaveProjectModal.svelte";
  import Dropdown from "../base/Dropdown.svelte";
  import { drawSend, drawState } from "../../layout.store";
  import router from "page";
  import routes, { ROUTE } from "../../router";
  import { currentTool, activePath, currentUser, loadSuccess, selectedRoom, visibleSideMenu, previewImage, boardAction } from "src/store";
  import { type SaveFormat, TOOLS, type ProjectBaseInfo, PROJECT_TYPE } from "src/global/types";
  import { createProject, updateProject, authorizeUrl } from "src/services/api";
  import EditableLabel from "../base/EditableLabel.svelte";
  import SettingsModal from "../dialogs/SettingsModal.svelte";
  import FullScreenButton from "../base/FullScreenButton.svelte";
  import { ACTION_TYPES } from "src/global/variable";
  import { BuildingPart, TileWrapper } from "src/model";
  import { createPreviewLayout, getGeometryForTileWrapper, getLayoutShapes, isBuildingPart, isDoor, isTileWrapper } from "src/helpers";
  import { limitLayoutTileDepthForDrag } from "src/tools/LayoutTools";
  import { getNotificationsContext } from "svelte-notifications";
  import LongText from "../base/LongText.svelte";

  export let open: Function;
  export let undo: Function;
  export let redo: Function;
  export let availableUndo: boolean;
  export let availableRedo: boolean;
  
  const { addNotification } = getNotificationsContext();

  let userAvailable = false;
  let openModal;
  let saveStart = false;
  let isSaving = false;

  $: if ($currentUser && !userAvailable && $loadSuccess) {
    userAvailable = true;
    
    if ($boardAction === ACTION_TYPES.PROJECT_SAVE)
      handleClickSave();
    else if ($boardAction === ACTION_TYPES.PROJECT_SAVEAS)
      onOpeningSaveModal();
    else if ($boardAction === ACTION_TYPES.PROJECT_EXPORT)
      print();
  }

  $: projectBaseInfo = $drawState.context.projectBaseInfo;

  const login = () => {
    activePath.set(routes[ROUTE.BOARD].path);
    window.open(
      authorizeUrl(),
      "_self",
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
    );
  };

  function onOpeningSaveModal() {
    previewImage.set(undefined);
    openModal();
    //Don't change the timer below!!! We need to give the modal enough time to open before generating the preview
    setTimeout(generatePreview, 100);
  }

  const generatePreview = () => {
    // previewImage.set(undefined);
    const tileWrappers : TileWrapper[] = $drawState.context.current.segments.filter((s) => isTileWrapper(s)) as TileWrapper[];
    if ( tileWrappers.every((tw) => !tw.loadingLayout) ) {
      selectedRoom.set(undefined);
      drawSend({ type: "ENTER_SELECT", segment: undefined });
      drawSend({ type: "SAVE_PREVIEW" });
    } else {
      setTimeout(generatePreview, 100)
    }
  }

  const goToNewProject = () => {
    router.show(routes[ROUTE.NEW_PROJECT].path)
  }
  
  const goToHome = () => {
    router.show(routes[ROUTE.HOME].path)
  }

  const print = () => {
    if (!$currentUser)
    {
      activePath.set(routes[ROUTE.BOARD].path);
      window.open(
        authorizeUrl(ACTION_TYPES.PROJECT_EXPORT),
        "_self",
        `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
      );
    } else {
      selectedRoom.set(undefined);
      drawSend({ type: "ENTER_SELECT", segment: undefined });
      drawSend({ type: "PRINT_PREVIEW" });
    }
  }

  async function prepareRequestBody(name: string) {

    const buildings = $drawState.context.current.segments.filter(
      (s) => isBuildingPart(s)
    );
    const furnitures = buildings.reduce((r, b) => {
      const furniture = b as BuildingPart;
      if (
        r.find(
          (a) =>
            // a.buildingType === furniture.buildingType &&
            a === furniture.buildingId
        )
      ) {
        return r;
      }
      return [
        ...r,
        furniture.buildingId
      ];
    }, []);

    const tileWrappers = $drawState.context.current.segments.filter((s) => isTileWrapper(s));
    const length = tileWrappers.length;
    let layouts = [];
    for (let i = 0; i < length; ++i)
    {
      const tileWrapper = tileWrappers[i] as TileWrapper;
      const graph = tileWrapper.tileLayout.getGraph();
      const tileShape = graph.tile_shapes;
      const tiles = graph.tiles;

      if (tileWrapper.layoutGeometryId === -1)
        continue;

      if (
        layouts.find((a) =>
          a.layoutGeometry === tileWrapper.layoutGeometryId &&
          a.tiles.length === tiles.length &&
          a.tiles.every((at) => tiles.includes(at)))
      ) {
        continue;
      }

      const tilesToRender = limitLayoutTileDepthForDrag(tileWrapper.tileLayout, false);
      const geometry = await getGeometryForTileWrapper(tileWrapper.layoutGeometryId, $drawState.context.layoutContext.layoutGeometries, $drawState.context.layoutContext.baseShapes, drawSend);
      if( !geometry )
        continue;
      
      const shapes = getLayoutShapes(tileWrapper.tileLayout, geometry, $drawState.context.layoutContext.baseShapes);
      const preview = await createPreviewLayout(tilesToRender, tileWrapper.tileLayout, shapes, true, undefined, true);

      layouts.push({
        layoutGeometry: tileWrapper.layoutGeometryId,
        tiles: tiles,
        complete: tileShape.some(shape => tiles.find(tile => tile === shape)) ? 0 : 1,
        preview_image: preview.base64
      });
    }

    const requestBody: { project: SaveFormat } = {
      project: {
        name,
        options: projectBaseInfo?.options ?? [PROJECT_TYPE.ROOM_PLAN], // array of project options (eg "top" if view from top or "front"). In the future we could have more options
        preview_image: $previewImage, // base64 preview image of the project (screenshot of the view when user clicks "save")
        data: {
          layouts,
          furnitures,
          segments: $drawState.context.current.segments
            .sort((a, b) => Number(isTileWrapper(a)) - Number(isTileWrapper(b)))
            .map((s) => {
              if (isTileWrapper(s))
              {
                const tileJSON = s.toJSON();
                return tileJSON;
                // return {
                //   ...tileJSON,
                //   tileLayout: layouts.findIndex(
                //     (layout) =>
                //       layout.layoutGeometry ===
                //         tileJSON.tileLayout.layoutGeometry &&
                //       layout.tiles.length === tileJSON.tileLayout.tiles.length &&
                //       layout.tiles.every((at) =>
                //         tileJSON.tileLayout.tiles.includes(at)
                //       )
                //   ),
                // };
              }
              else if (isDoor(s)) {
                const buildingJSON = s.toJSON();
                const building = buildingJSON.buildingType;

                return {
                  ...buildingJSON,
                  building,
                };
              }
              else if (isBuildingPart(s))
              {
                const buildingJSON = s.toJSON();
                const building = furnitures.findIndex(
                  (bId) =>
                    bId === buildingJSON.buildingId
                );
                delete buildingJSON.buildingType;
                delete buildingJSON.buildingId;
                return {
                  ...buildingJSON,
                  building,
                };
              }

              return s.toJSON();
            }),
        }
      }
    };

    return requestBody;
  }
  
  const save = (name: string) => {
    return prepareRequestBody(name)
    .then((requestBody) => {
      return createProject(requestBody)
    })
    .then((res) => {
      let projectInfo: ProjectBaseInfo = {
        // id: $projectBaseInfo.id,
        name: name,
        slug: res.data.project.slug,
        options: projectBaseInfo.options
      };
      drawSend({ type: "UPDATE_PROJECT_INFO", projectInfo: projectInfo});
      addNotification({
        type: 'success',
        removeAfter: 3000,
        text: $_("notification.success_save"),
        position: 'bottom-center',
      })
    }).catch((e) => {
      console.log(e);
      addNotification({
        type: 'error',
        removeAfter: 3000,
        text: $_("notification.failed_save"),
        position: 'bottom-center',
      })
    })
  }

  const update = async () => {
    let requestBody = await prepareRequestBody(projectBaseInfo.name);
    //save in cloud
    let response = updateProject(projectBaseInfo.slug, requestBody)
      .then((res) => {
        addNotification({
          type: 'success',
          removeAfter: 3000,
          text: $_("notification.success_save"),
          position: 'bottom-center',
        })
      })
      .catch((e) => {
        addNotification({
          type: 'error',
          removeAfter: 3000,
          text: $_("notification.failed_save"),
          position: 'bottom-center',
        })
      })
      .finally(() => {
        isSaving = false;
      })
    //save in local
    // jsonDownload(JSON.stringify(requestBody), `${name}.json`);
    return response;
  }

  const handleClickSave = () => {
    if (!$currentUser)
    {
      activePath.set(routes[ROUTE.BOARD].path);
      window.open(
        authorizeUrl(ACTION_TYPES.PROJECT_SAVE),
        "_self",
        `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
      );
    }
    else {        
      if( projectBaseInfo?.slug ) {
        isSaving = true;
        saveStart = true;
        previewImage.set(undefined);
        //Don't change the timer below!!! We need to give the modal enough time to open before generating the preview
        setTimeout(generatePreview, 100);
      } else {
        onOpeningSaveModal();
      }
    }
  }

  $: {
    if (saveStart && $previewImage && projectBaseInfo) {
      saveStart = false;
      update();
    }
  }

  const handleClickSaveAs = () => {
    if (!$currentUser)
    {
      activePath.set(routes[ROUTE.BOARD].path);
      window.open(
        authorizeUrl(ACTION_TYPES.PROJECT_SAVEAS),
        "_self",
        `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
      );
    }
    else {
      onOpeningSaveModal();
    }
  }

  const hideSideMenu = () => {
    currentTool.set(TOOLS.SELECTION)
    visibleSideMenu.set(false)
  }

  const handleTitleChange = (e) => {
    if( e.detail.value.length < 3 || e.detail.value.length > 100 ) {
      addNotification({
        type: 'error',
        removeAfter: 3000,
        text: $_("action_menu.file.project_name_info"),
        position: 'bottom-center',
      })
      return;
    }
    const projectInfo: ProjectBaseInfo = {
      ...projectBaseInfo,
      name: e.detail.value,
    };
    drawSend({ type: "UPDATE_PROJECT_INFO", projectInfo: projectInfo});
  }

</script>

<div class="action-container">
  <div class="flex items-center shadow-card bg-white h-[60px]" on:keydown>
    <button class="flex items-center gap-2.5 btn-default p-2.5 select-none" on:click={goToHome}>
      <img src="/logo.png" alt="logo" width="30px" height="30px"/>
      
      <div class="inline bg-primary-300 text-secondary-500 font-medium rounded-md py-1 px-2">
        BETA
      </div>
    </button>
    <Dropdown buttonClassName="rounded-lg pr-2 hover:bg-gray-200 active:bg-gray-300 select-none" dropClassName="mt-1" dropdownWidth="" arrow>
      <div slot="button" class="w-12 h-12 flex items-center justify-center">
        <i class="fa-light fa-folder-open fa-custom-lg" title={$_("action_menu.file.projects")} />
      </div>
      <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={goToNewProject}>
          <div class="w-9 h-9 flex items-center justify-center shrink-0">
            <i class="fa-light fa-file-circle-plus fa-custom-lg text-secondary-500"/>
          </div>
          <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.file.new_project")} </div>
        </button>
      </li>
      <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={goToHome}>
          <div class="w-9 h-9 flex items-center justify-center shrink-0">
            <i class="fa-light fa-folder-open fa-custom-lg text-secondary-500"/>
          </div>
          <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.file.open_project")} </div>
        </button>
      </li>
      <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={handleClickSave} disabled={!$loadSuccess || isSaving}>
          <div class="w-9 h-9 flex items-center justify-center shrink-0">
            <i class="fa-light fa-floppy-disk fa-custom-lg ${!$loadSuccess || isSaving ? "text-gray-300 cursor-default" : "text-secondary-500"}"/>
          </div>
          <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.save")} </div>
        </button>
      </li>
      <li>
        <button class="btn-default flex items-center gap-2 px-3 py-1.5 w-full hover:bg-gray-200 hover:rounded" on:click={handleClickSaveAs} disabled={!$loadSuccess || isSaving}>
          <div class="w-9 h-9 flex items-center justify-center shrink-0">
            <i class="fa-light fa-floppy-disk-pen fa-custom-lg ${!$loadSuccess || isSaving ? "text-gray-300 cursor-default" : "text-secondary-500"}"/>
          </div>
          <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.saveas")} </div>
        </button>
      </li>
    </Dropdown>

    <button
      class={`w-12 h-12 flex items-center justify-center border-none rounded-lg hover:bg-gray-200` + 
             `${!$loadSuccess ? " cursor-default" : ""}`} disabled={!$loadSuccess || isSaving}
      on:click={handleClickSave}
    >
      <i class={`fa-light fa-save fa-custom-lg ${!$loadSuccess || isSaving ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.save")}
      />
    </button>

    <SaveProjectModal bind:openModal save={save} />
    
    <button class={`w-12 h-12 flex items-center justify-center border-none hover:bg-gray-200 rounded-lg`} on:click={print} disabled={!$loadSuccess}>
      <i class={`fa-light fa-file-pdf fa-custom-lg ${!$loadSuccess ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.export")}
      />
    </button>

    <button class={`w-12 h-12 flex items-center justify-center border-none hover:bg-gray-200 rounded-lg ${!availableUndo ? "cursor-default" : ""}`}
            on:click={() => undo()} disabled={!availableUndo}>
      <i class={`fa-light fa-undo fa-custom-lg ${!availableUndo ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.undo")}
      />
    </button>
    <button class={`w-12 h-12 flex items-center justify-center border-none hover:bg-gray-200 rounded-lg ${!availableRedo ? "cursor-default" : ""}`}
            on:click={() => redo()} disabled={!availableRedo}>
      <i class={`fa-light fa-redo fa-custom-lg ${!availableRedo ? "text-gray-300 cursor-default" : "text-secondary-500"}`}
        title={$_("action_menu.redo")}
      />
    </button>
    <!-- <div class="flex border border-slate-400 divide-x divide-solid">
      {#each METRIC_UNITS as unit}
        <div
          class={`py-1 px-4 border-0 cursor-pointer ${
            metricUnit === unit ? "bg-secondary-300 text-white" : ""
          }`}
          on:click={() => updateMetric(unit)}
        >
          {unit}
        </div>
      {/each}
    </div> -->
    <div class="flex items-center justify-center grow px-2.5 text-sm select-none">
      <EditableLabel value={projectBaseInfo?.name ?? ""} class="p-1" on:change={handleTitleChange}>
        <LongText text={projectBaseInfo?.name ? projectBaseInfo.name : $_("project_wizard.untitled")} />
      </EditableLabel>
    </div>

    <FullScreenButton />
    
    <SettingsModal />

    <Login path={routes[ROUTE.BOARD].path}/>
  </div>
  <!-- {#if !$currentUser && $loadSuccess}
    <div class="flex border-l-4 border-yellow-400 bg-yellow-100 p-4 shadow-card">
      <div class="flex items-center">
        <i class="text-yellow-400 fa-light fa-triangle-exclamation fa-custom-lg"/>
        <div class="ml-3">
          <p class="text-sm text-yellow-700">
            {$_("action_menu.login_warning")}
            <button class="ml-4 border-0 font-medium text-yellow-700 underline hover:text-yellow-600" on:click={login}>{$_("action_menu.login")}</button>
          </p>
        </div>
      </div>
    </div>
  {/if} -->
</div>

<style scoped lang="scss">
  .action-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    @media print {
      display: none;
    }
  }

  input[type="file"] {
    display: none;
  }
</style>
