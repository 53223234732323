<script lang="ts">
  import { onDestroy, onMount } from "svelte";
  import SvelteNotifications from 'svelte-notifications';
  import axios from "axios";
  import router from "page";
  import routes, { ROUTE } from "./router";
  import Notifications from "./components/util/Notifications.svelte";
  import DrawPane from "./components/DrawPane.svelte";
  import { drawState } from "./layout.store";
  import { accessToken, expireTime, currentUser, loadSuccess, activePath, boardAction } from "./store";
  import { SUPPORTED_LOCALIZATIONS } from "./services/locales";
  import { getUser } from "./services/api";
  import { DEFAULT_ACCESS_TOKEN, QUERY_PARAMS } from "./global/variable";
  // import socket, { subscribeDownloadChannel } from "./services/websocket";
  import queryString from 'query-string';
  import { get } from "svelte/store";
  import { localeStore } from "./store";
  import { PAGES } from "./global/types";
  import { _ } from "./services/i18n";

  let currentPage : PAGES = PAGES.HOME;
  let browserZoomLevel : number = Math.round(window.devicePixelRatio * 100);
  let notifications: Notifications;
  
  const requestInterceptor = axios.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${
        $accessToken || DEFAULT_ACCESS_TOKEN
      }`;
      const locale = get(localeStore)
      config.headers["Accept-Language"] = locale.length > 0 ? locale : SUPPORTED_LOCALIZATIONS.en;
      config.headers["Access-Control-Allow-Origin"] = "*";

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        accessToken.set("");
        currentUser.set(null);
      }
      return Promise.reject(error);
    }
  );

  $: showTileLayout = $drawState.matches("tileLayoutTool.editTile");

  $: if ($accessToken) {
    if ($expireTime > Date.now()) getCurrentUser();
  }

  const getCurrentUser = async () => {
    //get current user
    const userRes = await getUser();
    const userJSON = await userRes.data;
    // userJSON.is_admin = 1;
    currentUser.set(userJSON);
    // subscribeDownloadChannel(userJSON.id)
  };

  const login = async (action) => {
    const url = new URL(window.location.href.replace("#", "?"));
    const searchParams = new URLSearchParams(url.search);

    if (searchParams?.get(QUERY_PARAMS.ACCESS_TOKEN)) {
      accessToken.set(searchParams.get(QUERY_PARAMS.ACCESS_TOKEN));
      // refreshToken.set(accessTokenJSON.refresh_token);
      const now = new Date();
      now.setSeconds(
        now.getSeconds() + Number(searchParams.get(QUERY_PARAMS.TOKEN_EXPIRY) ?? 0)
      );
      expireTime.set(now.getTime());
      const path = get(activePath) ?? routes[ROUTE.HOME].path;

      if (action)
      {
        router.redirect(`${routes[ROUTE.BOARD].path}` +
                        `${action ? `/${action}` : ""}` +
                        `?${QUERY_PARAMS.LOCALE}=${!!get(localeStore) ? get(localeStore) : SUPPORTED_LOCALIZATIONS.en}`);

      }
      else
        router.redirect(`${path}?${QUERY_PARAMS.LOCALE}=${!!get(localeStore) ? get(localeStore) : SUPPORTED_LOCALIZATIONS.en}`);
    }
  };

  routes.forEach((route, index) => {
    router(
      route.path,
      (ctx, next) => {
        const queries = queryString.parse(ctx.querystring);
        ctx.queries = queries;
        if (queries[QUERY_PARAMS.LOCALE] )
          localeStore.set(queries[QUERY_PARAMS.LOCALE].toString())
        next();
      },
      (ctx) => {
        if (index === ROUTE.AUTH_CALLBACK || index === ROUTE.AUTH_CALLBACK_ACTION)
          login(ctx.params?.action);

        else if (index === ROUTE.OTHER)
          router.redirect(routes[ROUTE.HOME].path);

        else if (index === ROUTE.ROOT)
          router.redirect(routes[ROUTE.HOME].path);

        else if (
          !ctx.queries[QUERY_PARAMS.LOCALE] ||
          !Object.values(SUPPORTED_LOCALIZATIONS).includes(ctx.queries[QUERY_PARAMS.LOCALE])
        )
        {
          const locale = $localeStore?.length ? $localeStore : navigator.language;

          if (Object.values(SUPPORTED_LOCALIZATIONS).includes(locale))
            ctx.queries[QUERY_PARAMS.LOCALE] = locale;
          else
            ctx.queries[QUERY_PARAMS.LOCALE] = SUPPORTED_LOCALIZATIONS.en;

          const queryUrl = queryString.stringify(ctx.queries);
          router.redirect(ctx.pathname + `?${queryUrl}`)
        }
        else if (index === ROUTE.BOARD_ACTION) {
          const url = new URL(window.location.href.replace("#", "?"));
          //take last slug
          const action = url.pathname.match(/[^\/]+/g).at(-1);
          boardAction.set(action)
          const queryUrl = queryString.stringify(ctx.queries);
          router.redirect(routes[ROUTE.BOARD].path + `?${queryUrl}`)
        }
        else if (index === ROUTE.BOARD) {
          currentPage = PAGES.BOARD;
        }

        else if (index === ROUTE.HOME)
          currentPage = PAGES.HOME;

        else if (index === ROUTE.NEW_PROJECT)
          currentPage = PAGES.NEW_PROJECT;

        else if (index === ROUTE.FURNITURES_SCREENSHOT)
          currentPage = PAGES.FURNITURES_SCREENSHOT;
      }
    );
  });
  router.start();

  const handleResize = () => {
    browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  }

  let furnitureRenderingModule;
  let layoutDesignerModule;
  
  onMount(() => {
    // handleResize();
    import("./components/FurnitureRendering.svelte").then((module) => {
      furnitureRenderingModule = module.default;
    });
    import("./components/LayoutDesigner.svelte").then((module) => {
      layoutDesignerModule = module.default;
    });
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  });
</script>
<div inert={(!$loadSuccess && currentPage !== PAGES.FURNITURES_SCREENSHOT) || null} id="main-container" class="home font-primary box-border">
  <header>Project</header>
  <SvelteNotifications zIndex={50}>
  <Notifications bind:this={notifications} />
  {#if requestInterceptor > -1}
    {#if currentPage === PAGES.FURNITURES_SCREENSHOT}
      <svelte:component this={furnitureRenderingModule} />
    {:else}
      {#key browserZoomLevel}
        <DrawPane page={currentPage} notifications={notifications}/>
      {/key}
      {#if showTileLayout}
      <div
        class="tile-edit z-20"
      >
        <svelte:component this={layoutDesignerModule} />
      </div>
      {/if}
    {/if}
  {:else}
    <div>Loading ...</div>
  {/if}
  </SvelteNotifications>
</div>

<style scoped>
  .home {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .tile-edit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: white;
  }
  header {
    position: absolute;
    display: none;
  }
  @media print {
    header {
      display: block;
      left: 16pt;
      top: 16pt;
    }
  }
</style>
