import { Pointer } from './Pointer';
import { Segment, SegmentClass } from './Segment';
import { dist, getCrossedPointBetweenTwoLines, getHelperPath, getHelperTextAngle, getNewSegmentName, getParentClosedArea, getRemovedBuiltInSegments, getSegmentsOfPoint, getShapeBoundingRect, getTextPointer, isBuildingPart, isDoor, isInShape, isLine, translatePointerWithSegments, } from '../helpers';
import { v4 as uuidv4 } from 'uuid';
import { HELPER_MIN_LENGTH, TOLERANCE_DISTANCE } from 'src/global/variable';
export var LINE_TYPE;
(function (LINE_TYPE) {
    LINE_TYPE["SOLID"] = "solid";
    LINE_TYPE["DASHED"] = "dashed";
})(LINE_TYPE || (LINE_TYPE = {}));
export class Line extends Segment {
    startPointer;
    endPointer;
    type;
    parentId;
    id;
    zIndex;
    height;
    thick;
    name;
    constructor(startPointer, endPointer, type = LINE_TYPE.SOLID, parentId = 0, id = uuidv4(), zIndex = 0, height = 0, thick = 0, name = '') {
        super(startPointer, endPointer, id, zIndex, name);
        this.startPointer = startPointer;
        this.endPointer = endPointer;
        this.type = type;
        this.parentId = parentId;
        this.id = id;
        this.zIndex = zIndex;
        this.height = height;
        this.thick = thick;
        this.name = name;
        this.class = SegmentClass.LINE;
    }
    generatePath(isFromShape = false, startPointer = undefined, step = 0) {
        const path = (isFromShape ? '' : 'M') +
            (startPointer ?? this.startPointer).x +
            ',' +
            (startPointer ?? this.startPointer).y +
            ' ' +
            'L' +
            (!startPointer || startPointer.equals(this.startPointer) ? this.endPointer : this.startPointer).x +
            ',' +
            (!startPointer || startPointer.equals(this.startPointer) ? this.endPointer : this.startPointer).y;
        if (!isFromShape && step > 0) {
            const p = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            p.setAttribute('d', path);
            const count = Math.ceil(p.getTotalLength() / step);
            const len = p.getTotalLength() + 1.5;
            const points = Array.from(Array(count), (_, i) => i + 1).map((i) => {
                return p.getPointAtLength((len * i) / count);
            });
            const initialOffset = p.getPointAtLength(0);
            return `M ${initialOffset.x} ${initialOffset.y} ${points.map(({ x, y }) => `L ${x} ${y}`).join(' ')}`;
        }
        return path;
    }
    getPointerInLine(pointer) {
        if (Math.abs(this.startPointer.y - this.endPointer.y) < TOLERANCE_DISTANCE) {
            const pos = (pointer.x - this.startPointer.x) / (this.endPointer.x - this.startPointer.x);
            return new Pointer(pointer.x, this.startPointer.y + (this.endPointer.y - this.startPointer.y) * pos);
        }
        else if (Math.abs(this.startPointer.x - this.endPointer.x) < TOLERANCE_DISTANCE) {
            const pos = (pointer.y - this.startPointer.y) / (this.endPointer.y - this.startPointer.y);
            return new Pointer(this.startPointer.x + (this.endPointer.x - this.startPointer.x) * pos, pointer.y);
        }
        else {
            const pointerY = Math.tan(Math.atan2(this.endPointer.y - this.startPointer.y, this.endPointer.x - this.startPointer.x)) *
                (pointer.x - this.startPointer.x) +
                this.startPointer.y;
            return new Pointer(pointer.x, pointerY);
        }
    }
    getPointsOfSegments(segments, scale, isWall) {
        let points = [];
        segments.forEach((segment) => {
            if ((isWall && isDoor(segment)) || isBuildingPart(segment)) {
                const [pointer1, pointer2, pointer3, pointer4] = segment.getRectPoints();
                const pts = [];
                if (this.isInSegment(pointer1, true, TOLERANCE_DISTANCE))
                    pts.push(this.getPointerInLine(pointer1));
                if (this.isInSegment(pointer2, true, TOLERANCE_DISTANCE))
                    pts.push(this.getPointerInLine(pointer2));
                if (this.isInSegment(pointer3, true, TOLERANCE_DISTANCE))
                    pts.push(this.getPointerInLine(pointer3));
                if (this.isInSegment(pointer4, true, TOLERANCE_DISTANCE))
                    pts.push(this.getPointerInLine(pointer4));
                if (pts.length === 2) {
                    const len = dist(pts[0], pts[1]);
                    if (len < HELPER_MIN_LENGTH / scale)
                        return;
                }
                pts.forEach((point) => {
                    points.push({ point: point, id: segment.id });
                });
            }
            else if (isDoor(segment)) {
                const door = segment;
                if (door.parentId === this.id) {
                    const len = dist(door.startPointer, door.endPointer);
                    if (len < HELPER_MIN_LENGTH / scale)
                        return;
                    points.push({ point: door.startPointer, id: segment.id });
                    points.push({ point: door.endPointer, id: segment.id });
                }
            }
        });
        const sortOrder = this.startPointer.isGreater(this.endPointer) ? -1 : 1;
        if (points.length === 0)
            return [];
        points.sort((a, b) => {
            if (a.point.x === b.point.x)
                return sortOrder * (a.point.y - b.point.y);
            return sortOrder * (a.point.x - b.point.x);
        });
        const result = [this.startPointer];
        let openedCnt = 0;
        let isOpened = {};
        for (let i = 0; i < points.length; i++) {
            if (isOpened[points[i].id]) {
                isOpened[points[i].id] = false;
                openedCnt--;
                if (openedCnt === 0) {
                    result.push(points[i].point);
                }
            }
            else {
                if (openedCnt === 0)
                    result.push(points[i].point);
                const allPoints = points.filter((item) => item.id === points[i].id);
                // skip segment with 1 point
                if (allPoints.length === 2) {
                    isOpened[points[i].id] = true;
                    openedCnt++;
                }
            }
        }
        result.push(this.endPointer);
        return result;
    }
    generateHelperPath(segments, scale, isWall) {
        if (this.startPointer.equals(this.endPointer)) {
            return [];
        }
        const helperPaths = [];
        const points = this.getPointsOfSegments(segments, scale, isWall);
        if (points.length > 2) {
            for (let i = 0; i < points.length - 1; i++) {
                if (dist(points[i], points[i + 1]) < HELPER_MIN_LENGTH / scale)
                    continue;
                helperPaths.push(getHelperPath(points[i], points[i + 1]));
            }
        }
        helperPaths.push(getHelperPath(this.startPointer, this.endPointer, points.length > 2 ? 28 : 7));
        return helperPaths;
    }
    generateHelperTextData(segments, scale, isWall) {
        const helperTexts = [];
        const points = this.getPointsOfSegments(segments, scale, isWall);
        if (points.length > 2) {
            for (let i = 0; i < points.length - 1; i++) {
                if (dist(points[i], points[i + 1]) < HELPER_MIN_LENGTH / scale)
                    continue;
                const textPointer = getTextPointer(points[i], points[i + 1]);
                helperTexts.push({
                    x: textPointer.x,
                    y: textPointer.y,
                    angle: getHelperTextAngle(points[i], points[i + 1]),
                    length: dist(points[i], points[i + 1]),
                });
            }
        }
        const textPointer = getTextPointer(this.startPointer, this.endPointer, points.length > 2 ? 16 : 7);
        helperTexts.push({
            x: textPointer.x,
            y: textPointer.y,
            angle: getHelperTextAngle(this.startPointer, this.endPointer),
            length: this.getLineLength(),
        });
        return helperTexts;
    }
    getRealTransition(dx, dy, svgSize) {
        return { dx, dy };
        // const [leftPointer, rightPointer] =
        //   this.startPointer.x < this.endPointer.x
        //     ? [this.startPointer, this.endPointer]
        //     : [this.endPointer, this.startPointer];
        // const [topPointer, bottomPointer] =
        //   this.startPointer.y < this.endPointer.y
        //     ? [this.startPointer, this.endPointer]
        //     : [this.endPointer, this.startPointer];
        // let realDx, realDy;
        // if (dx > 0) {
        //   realDx =
        //     rightPointer.x + dx < svgSize.w ? dx : svgSize.w - rightPointer.x;
        // } else {
        //   realDx = leftPointer.x + dx > 0 ? dx : -leftPointer.x;
        // }
        // if (dy > 0) {
        //   realDy =
        //     bottomPointer.y + dy < svgSize.h ? dy : svgSize.h - bottomPointer.y;
        // } else {
        //   realDy = topPointer.y + dy > 0 ? dy : -topPointer.y;
        // }
        // return { dx: realDx, dy: realDy };
    }
    getLineAngle() {
        return Math.atan2(-(this.endPointer.y - this.startPointer.y), this.endPointer.x - this.startPointer.x);
    }
    getLineLength() {
        return dist(this.endPointer, this.startPointer);
    }
    translate(dx, dy, segments) {
        const origStart = this.startPointer.translate(0, 0);
        const origEnd = this.endPointer.translate(0, 0);
        let startPointer = translatePointerWithSegments(dx, dy, this.startPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
        let endPointer = translatePointerWithSegments(dx, dy, this.endPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
        if (this.isDashed() && this.parentId) {
            const closedArea = getParentClosedArea(segments, this.parentId);
            if (closedArea) {
                const [x, y, width, height] = getShapeBoundingRect(closedArea.shape);
                const maxLen = Math.sqrt(width * width + height * height);
                const angle = Math.atan2(-(endPointer.y - startPointer.y), endPointer.x - startPointer.x);
                const middlePointer = new Pointer((startPointer.x + endPointer.x) / 2, (startPointer.y + endPointer.y) / 2);
                let extendedStartPointer = startPointer;
                let extendedEndPointer = endPointer;
                if (closedArea.shape.results.some((v) => v.isInSegment(origStart))) {
                    extendedStartPointer = middlePointer.translate(-maxLen * Math.cos(angle), maxLen * Math.sin(angle));
                }
                if (closedArea.shape.results.some((v) => v.isInSegment(origEnd))) {
                    extendedEndPointer = middlePointer.translate(-maxLen * Math.cos(angle + Math.PI), maxLen * Math.sin(angle + Math.PI));
                }
                const isStartIn = isInShape(extendedStartPointer, closedArea.shape);
                const isEndIn = isInShape(extendedEndPointer, closedArea.shape);
                if (!isStartIn || !isEndIn) {
                    const newLine = new Line(extendedStartPointer, extendedEndPointer);
                    let crossedPoints = [];
                    closedArea.shape.results.forEach((segment) => {
                        if (!isLine(segment))
                            return;
                        const line = segment;
                        if (line.isDashed())
                            return;
                        const crossed = getCrossedPointBetweenTwoLines(newLine, line);
                        if (crossed) {
                            if (!crossedPoints.find((point) => crossed.equals(point)))
                                crossedPoints.push(crossed);
                        }
                    });
                    if (crossedPoints.length === 0) {
                        startPointer = translatePointerWithSegments(-dx, -dy, startPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
                        endPointer = translatePointerWithSegments(-dx, -dy, endPointer, getRemovedBuiltInSegments(segments, this), this.isDashed());
                    }
                    else if (crossedPoints.length === 1) {
                        if (isStartIn) {
                            endPointer = crossedPoints[0];
                        }
                        if (isEndIn) {
                            startPointer = crossedPoints[0];
                        }
                    }
                    else {
                        if (isStartIn && !crossedPoints.some((v) => v.equals(extendedStartPointer))) {
                            let minDist = Infinity;
                            crossedPoints.forEach((point) => {
                                const distance = dist(point, origStart);
                                if (distance < minDist) {
                                    minDist = distance;
                                    endPointer = point;
                                }
                            });
                        }
                        else if (isEndIn && !crossedPoints.some((v) => v.equals(extendedEndPointer))) {
                            let minDist = Infinity;
                            crossedPoints.forEach((point) => {
                                const distance = dist(point, origEnd);
                                if (distance < minDist) {
                                    minDist = distance;
                                    startPointer = point;
                                }
                            });
                        }
                        else {
                            let minDist = Infinity;
                            let startIndex = 0;
                            crossedPoints.forEach((point, index) => {
                                const distance = dist(point, origStart);
                                if (distance < minDist) {
                                    minDist = distance;
                                    startPointer = point;
                                    startIndex = index;
                                }
                            });
                            if (startIndex % 2 === 0) {
                                if (startIndex + 1 < crossedPoints.length) {
                                    endPointer = crossedPoints[startIndex + 1];
                                }
                                else {
                                    endPointer = crossedPoints[startIndex - 1]; // crossedPoints.length is more than 2, so no need to check 0
                                }
                            }
                            else {
                                endPointer = crossedPoints[startIndex - 1];
                            }
                        }
                    }
                }
                /* temporary not use
                // move control point
                let segmentsOnPoint = getSegmentsOfPoint(segments, origStart).filter((v) => v.id !== this.id);
                if( segmentsOnPoint.length === 2 && isLine(segmentsOnPoint[0]) && isLine(segmentsOnPoint[1]) ) {
                  const line1 = segmentsOnPoint[0] as Line;
                  const line2 = segmentsOnPoint[1] as Line;
                  
                  let angle1 = line1.getLineAngle()
                  let angle2 = line2.getLineAngle()
                  if( angle1 < 0 ) angle1 += Math.PI
                  if( angle2 < 0 ) angle2 += Math.PI
        
                  if(!line1.isDashed() && !line2.isDashed() && angle1 === angle2) {
                    let extendLine = segments.find((v) => v.id === line1.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origStart) ) {
                        extendLine.startPointer = startPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origStart) ) {
                        extendLine.endPointer = startPointer.translate(0, 0);
                      }
                    }
        
                    extendLine = segments.find((v) => v.id === line2.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origStart) ) {
                        extendLine.startPointer = startPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origStart) ) {
                        extendLine.endPointer = startPointer.translate(0, 0);
                      }
                    }
                  }
                }
                
                segmentsOnPoint = getSegmentsOfPoint(segments, origEnd).filter((v) => v.id !== this.id);
                if( segmentsOnPoint.length === 2 && isLine(segmentsOnPoint[0]) && isLine(segmentsOnPoint[1]) ) {
                  const line1 = segmentsOnPoint[0] as Line;
                  const line2 = segmentsOnPoint[1] as Line;
        
                  let angle1 = line1.getLineAngle()
                  let angle2 = line2.getLineAngle()
                  if( angle1 < 0 ) angle1 += Math.PI
                  if( angle2 < 0 ) angle2 += Math.PI
        
                  if(!line1.isDashed() && !line2.isDashed() && angle1 === angle2) {
                    let extendLine = segments.find((v) => v.id === line1.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origEnd) ) {
                        extendLine.startPointer = endPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origEnd) ) {
                        extendLine.endPointer = endPointer.translate(0, 0);
                      }
                    }
        
                    extendLine = segments.find((v) => v.id === line2.id)
                    if( extendLine ) {
                      if( extendLine.startPointer.equals(origEnd) ) {
                        extendLine.startPointer = endPointer.translate(0, 0);
                      } else if( extendLine.endPointer.equals(origEnd) ) {
                        extendLine.endPointer = endPointer.translate(0, 0);
                      }
                    }
                  }
                }
                */
            }
        }
        this.startPointer = startPointer;
        this.endPointer = endPointer;
    }
    splitLine(count, segments) {
        const splitedLineArray = Array();
        const dx = (this.endPointer.x - this.startPointer.x) / count;
        const dy = (this.endPointer.y - this.startPointer.y) / count;
        const newSegments = [...segments];
        for (let i = 0; i < count; i++) {
            const startPointer = new Pointer(this.startPointer.x + i * dx, this.startPointer.y + i * dy);
            const endPointer = i < count - 1
                ? new Pointer(this.startPointer.x + (i + 1) * dx, this.startPointer.y + (i + 1) * dy)
                : new Pointer(this.endPointer.x, this.endPointer.y);
            const line = new Line(startPointer, endPointer, this.type);
            line.name = getNewSegmentName(this.name + '-', newSegments);
            newSegments.push(line);
            splitedLineArray.push(line);
        }
        return splitedLineArray;
    }
    getLeftPointer() {
        if (this.startPointer.x === this.endPointer.x) {
            return this.startPointer.y <= this.endPointer.y ? this.startPointer : this.endPointer;
        }
        if (this.startPointer.x <= this.endPointer.x) {
            return this.startPointer;
        }
        return this.endPointer;
    }
    getRightPointer() {
        if (this.startPointer.x === this.endPointer.x) {
            return this.startPointer.y <= this.endPointer.y ? this.endPointer : this.startPointer;
        }
        if (this.startPointer.x <= this.endPointer.x) {
            return this.endPointer;
        }
        return this.startPointer;
    }
    getCenterPointer() {
        return new Pointer((this.startPointer.x + this.endPointer.x) / 2, (this.startPointer.y + this.endPointer.y) / 2);
    }
    getPointsArray() {
        return [];
    }
    isInSegment(pointer, acceptInPath = true, tolerance = TOLERANCE_DISTANCE / 2) {
        if (pointer.x < Math.min(this.startPointer.x, this.endPointer.x) - tolerance ||
            pointer.x > Math.max(this.startPointer.x, this.endPointer.x) + tolerance ||
            pointer.y < Math.min(this.startPointer.y, this.endPointer.y) - tolerance ||
            pointer.y > Math.max(this.startPointer.y, this.endPointer.y) + tolerance)
            return false;
        const pointerY = Math.tan(Math.atan2(this.endPointer.y - this.startPointer.y, this.endPointer.x - this.startPointer.x)) *
            (pointer.x - this.startPointer.x) +
            this.startPointer.y;
        const pointerX = Math.tan(Math.atan2(this.endPointer.x - this.startPointer.x, this.endPointer.y - this.startPointer.y)) *
            (pointer.y - this.startPointer.y) +
            this.startPointer.x;
        if (acceptInPath && Math.abs(pointer.x - this.startPointer.x) < tolerance)
            return Math.abs(pointerX - pointer.x) < tolerance;
        if (acceptInPath)
            return Math.abs(pointerY - pointer.y) < tolerance;
        return (pointer.x >= Math.min(this.startPointer.x, this.endPointer.x) &&
            pointer.x <= Math.max(this.startPointer.x, this.endPointer.x));
    }
    clone() {
        return new Line(new Pointer(this.startPointer.x, this.startPointer.y), new Pointer(this.endPointer.x, this.endPointer.y), this.type, this.parentId, this.id, this.zIndex, this.height, this.thick, this.name);
    }
    reverse() {
        return new Line(this.endPointer, this.startPointer, this.type, this.parentId, this.id, this.zIndex, this.height, this.thick, this.name);
    }
    isDashed() {
        return this.type === LINE_TYPE.DASHED;
    }
    toJSON() {
        return {
            ...super.toJSON(),
            type: this.type,
            parentId: this.parentId,
            height: this.height,
            thick: this.thick,
        };
    }
}
