<script lang="ts">
  import { drawState, drawSend } from "../../layout.store";
  import { Pointer, Door } from "../../model";
  import {
    convertPointerToViewBox,
    getFixedBuildingElement,
    getMetricWithUnit,
    getParentLine,
    isWallProject,
    makePointerInSvgBox,
  } from "../../helpers";
  import { METRIC_UNITS } from "src/global/variable";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";

  export let door: Door;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;
  export let preview: boolean = false;

  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: parentSegment = getParentLine(
    $drawState.context.current.segments,
    door.parentId
  );
  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: buildingElement = getFixedBuildingElement(
    isWall,
    door.buildingType,
    door.windowType
  );
  
  const segmentDown = (e: any) => {
    if(preview || $currentTool === TOOLS.EYE_DROPPER) return;
    if( selectedObject !== door ) return;

    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
  }

  const segmentClick = (e: any) => {
    if(preview || $currentTool === TOOLS.EYE_DROPPER) return;
    drawSend({ type: "ENTER_SELECT", segment: door });
    drawSend({ type: "SHOW_LINE_TOOL" });
    
    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    pointer = makePointerInSvgBox(pointer, svgSize);
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
    
    drawSend({ type: "MOUSE_DRAGGABLE" });
  };

  const segmentHovered = () => {
    if( preview ) return;
    if ($drawState.matches("main.drawingState")) return;
    if( selectedObject === door )
      drawSend({ type: "MOUSE_DRAGGABLE" });
    else
      drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if( preview ) return;
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  };

  const getRotation = () => {
    return parentSegment ? -(parentSegment?.getLineAngle() * 180) / Math.PI : 0;
  };

</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<g id={door.id}>
  <g
    class={`${
      !preview && selectedObject === door ? "segment_path_selected" : ""
    } segment_path segment`}
    on:click={segmentClick}
    on:mousedown={segmentDown}
    on:touchstart={segmentDown}
    on:mouseover={segmentHovered}
    on:mouseleave={segmentMouseLeave}
  >
    <g
      transform={`rotate(${door.openToOutSide ? 180 : 0} ${
        (door.startPointer.x + door.endPointer.x) / 2
      } ${
        (door.startPointer.y + door.endPointer.y) / 2
      }) rotate(${getRotation()} ${door.startPointer.x} ${
        door.startPointer.y
      }) matrix(${door.getLineLength() / buildingElement.width}, 0, 0, ${
        1.5 / buildingElement.thicker
      }, ${door.startPointer.x}, ${door.startPointer.y - 0.75})`}
      class="segment"
    >
      {@html buildingElement.staticPath}
    </g>
    {#if !door.isClosed}
    <g
      transform={`rotate(${door.openToOutSide ? 180 : 0} ${
        (door.startPointer.x + door.endPointer.x) / 2
      } ${
        (door.startPointer.y + door.endPointer.y) / 2
      }) rotate(${getRotation()} ${door.startPointer.x} ${
        door.startPointer.y
      }) matrix(${
        ((door.wallOpenSide ? 1 : -1) * door.getLineLength()) /
        buildingElement.width
      }, 0, 0, ${
        ((door.wallSide ? 1 : -1) * door.getLineLength()) /
        buildingElement.width
      }, ${
        door.startPointer.x + (!door.wallOpenSide ? door.getLineLength() : 0)
      }, ${door.startPointer.y - 0.75})`}
      class="segment"
    >
      {@html buildingElement.path}
    </g>
    {/if}
  </g>
  
  {#if !preview && $drawState.matches("helper.showHelper") && selectedObject?.id === door.id && !parentSegment}
    <g>
      {#each door.generateHelperPath() as helperItem, j}
        <path
          d={helperItem}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
      {/each}
      {#each door.generateHelperTextData() as helperTextItem, j}
        <g>
          {#if helperTextItem}
            <text
              class="stroke-text"
              transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
              style={`font-size: ${16 / scale}px;`}
            >
              {getMetricWithUnit(
                Math.round(helperTextItem.length * 100) / 100,
                $drawState.context.currentMetricUnit,
                $drawState.context.currentMetricUnit === METRIC_UNITS[0]
              )}
            </text>
          {/if}
        </g>
      {/each}
    </g>
  {/if}
</g>

<style>
  text {
    font-family: sans-serif;
    text-anchor: middle;
    pointer-events: none;
    user-select: none;
  }
</style>
