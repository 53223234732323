import Matrix2D from './Matrix2D';
import Vector2 from './Vector2';
import BoundingBox from './BoundingBox';
import ShapeInstance from './ShapeInstance';
import Neighbor, { NeighborOffset } from './Neighbor';
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { Path, LineSegment } from './Path';
import AABBTree from './AABBTree';
import { KdTree } from './KDTree';
export class Neighbors {
    children;
    parents;
    static EMPTY = new Neighbors();
    constructor(children = ImmutableList(), parents = ImmutableList()) {
        this.children = children;
        this.parents = parents;
    }
    addChildren(...children) {
        return new Neighbors(this.children.concat(children), this.parents);
    }
    addParents(...parents) {
        return new Neighbors(this.children, this.parents.concat(parents));
    }
    withChildren(children) {
        return new Neighbors(children, this.parents);
    }
    withParents(parents) {
        return new Neighbors(this.children, parents);
    }
    mapAll(mapper) {
        return new Neighbors(this.children.map((c) => mapper(c, true)), this.parents.map((p) => mapper(p, false)));
    }
    toJSON(shapes) {
        return {
            children: this.children.toJSON().map((c) => c.toJSON(shapes)),
            parents: this.parents.toJSON().map((p) => p.toJSON(shapes)),
        };
    }
}
class RepeatInstance {
    shape;
    parent;
    neighbor;
    transform;
    depth;
    constructor(shape, parent, neighbor, transform, depth) {
        this.shape = shape;
        this.parent = parent;
        this.neighbor = neighbor;
        this.transform = transform;
        this.depth = depth;
    }
}
export default class Layout {
    graph;
    rootShape;
    gapSize;
    rotation;
    static DEFAULT_MARGIN = 0.01;
    static MAX_DEPTH = 512;
    static COLLISION_MARGIN = 0.0;
    static DEFAULT_GROUT_COLOR = '#FFFFFF';
    static NO_TILE_GROUT_COLOR = '#454545';
    static fromShape(rootShape) {
        return new Layout(ImmutableMap().set(rootShape, new Neighbors()), rootShape);
    }
    constructor(graph = ImmutableMap(), rootShape = null, gapSize = Layout.DEFAULT_MARGIN, rotation = 0) {
        this.graph = graph;
        this.rootShape = rootShape;
        this.gapSize = gapSize;
        this.rotation = rotation;
    }
    get shapeCount() {
        return this.graph.size;
    }
    withGapSize(newGapSize) {
        return new Layout(this.graph, this.rootShape, newGapSize, this.rotation);
    }
    add(newNeighbor) {
        const sourceShape = newNeighbor.source.shape;
        const targetShape = newNeighbor.target.shape;
        if (sourceShape !== targetShape) {
            let newGraph = this.graph.set(sourceShape, this.neighborsOf(sourceShape).addChildren(newNeighbor));
            newGraph = newGraph.set(targetShape, this.neighborsOf(targetShape).addParents(newNeighbor.reversed()));
            return new Layout(newGraph, this.rootShape || sourceShape, this.gapSize, this.rotation);
        }
        else {
            const newGraph = this.graph.set(sourceShape, this.neighborsOf(sourceShape).addChildren(newNeighbor).addParents(newNeighbor.reversed()));
            return new Layout(newGraph, this.rootShape || sourceShape, this.gapSize, this.rotation);
        }
    }
    remove(shape) {
        const parents = this.neighborsOf(shape).parents;
        const newParents = parents.map((parent) => {
            const parentNeighbors = this.neighborsOf(parent.target.shape);
            //remove from children
            let removedLinksToShape = parentNeighbors.children.filter((child) => child.target.shape !== shape);
            let newList = parentNeighbors.withChildren(removedLinksToShape);
            //remove from parents
            removedLinksToShape = newList.parents.filter((child) => child.target.shape !== shape);
            newList = newList.withParents(removedLinksToShape);
            return [parent.target.shape, newList];
        });
        const newGraph = this.graph.merge(newParents);
        return new Layout(newGraph, this.rootShape, this.gapSize, this.rotation).cleanupDisconnectedComponents();
    }
    /**
     * Updates a neighbor relationship between two shapes. Can be used to change the neighboring edges for example.
     * @param newNeighbor new neighbor relationship
     */
    updateNeighbor(existingNeighbor, newNeighbor) {
        const sourceNeighbors = this.neighborsOf(newNeighbor.sourceShape);
        let newSourceChildren = sourceNeighbors.children.filter((c) => c !== existingNeighbor);
        newSourceChildren = newSourceChildren.push(newNeighbor);
        const targetNeighbors = this.neighborsOf(newNeighbor.targetShape);
        let newTargetParents = targetNeighbors.parents.filter((c) => {
            return (c.targetShape !== existingNeighbor.sourceShape &&
                c.target.index !== existingNeighbor.source.index &&
                c.source.index !== existingNeighbor.target.index);
        });
        newTargetParents = newTargetParents.push(newNeighbor.reversed());
        let newGraph = this.graph.set(newNeighbor.sourceShape, sourceNeighbors.withChildren(newSourceChildren));
        newGraph = newGraph.set(newNeighbor.targetShape, (newGraph.get(newNeighbor.targetShape) || new Neighbors()).withParents(newTargetParents));
        return new Layout(newGraph, this.rootShape, this.gapSize, this.rotation);
    }
    changeAnchorPoint(source, target, targetIsChild) {
        const shapes = this.graph.keySeq().toArray();
        let newSourceNeighbors;
        let newNeighbors;
        // const sourceNeighbors = this.neighborsOf(shapes[source.shapeIndex]);
        // const targetNeighbors = this.neighborsOf(shapes[target.shapeIndex]);
        // if (targetIsChild)
        // {
        //   newSourceNeighbors = sourceNeighbors.mapAll((neighbor, isChild) => {
        //     if (isChild && shapes.indexOf(neighbor.target.shape) === target.shapeIndex)
        //     {
        //       const resultOffset = new NeighborOffset((source.anchorPoint * neighbor.source.edge.length / 2) - (target.anchorPoint * neighbor.target.edge.length / 2));
        //       return new Neighbor(
        //         neighbor.sourceShape.edge(neighbor.source.index, undefined, source.anchorPoint),
        //         neighbor.targetShape.edge(neighbor.target.index, undefined, target.anchorPoint),
        //         resultOffset
        //       );
        //     }
        //     return neighbor;
        //   });
        //   newTargetNeighbors = targetNeighbors.mapAll((neighbor, isChild) => {
        //     if (!isChild && shapes.indexOf(neighbor.target.shape) === source.shapeIndex)
        //     {
        //       const resultOffset = new NeighborOffset((source.anchorPoint * neighbor.source.edge.length / 2) - (target.anchorPoint * neighbor.target.edge.length / 2));
        //       return new Neighbor(
        //         neighbor.sourceShape.edge(neighbor.source.index, undefined, target.anchorPoint),
        //         neighbor.targetShape.edge(neighbor.target.index, undefined, source.anchorPoint),
        //         resultOffset
        //       );
        //     }
        //     return neighbor;
        //   });
        // }
        // else
        // {
        //   newSourceNeighbors = sourceNeighbors.mapAll((neighbor, isChild) => {
        //     if (!isChild && shapes.indexOf(neighbor.target.shape) === target.shapeIndex)
        //     {
        //       const resultOffset = new NeighborOffset((target.anchorPoint * neighbor.source.edge.length / 2) - (source.anchorPoint * neighbor.target.edge.length / 2));
        //       return new Neighbor(
        //         neighbor.sourceShape.edge(neighbor.source.index, undefined, source.anchorPoint),
        //         neighbor.targetShape.edge(neighbor.target.index, undefined, target.anchorPoint),
        //         resultOffset
        //       );
        //     }
        //     return neighbor;
        //   });
        //   newTargetNeighbors = targetNeighbors.mapAll((neighbor, isChild) => {
        //     if (isChild && shapes.indexOf(neighbor.target.shape) === source.shapeIndex)
        //     {
        //       const resultOffset = new NeighborOffset((target.anchorPoint * neighbor.source.edge.length / 2) - (source.anchorPoint * neighbor.target.edge.length / 2));
        //       return new Neighbor(
        //         neighbor.sourceShape.edge(neighbor.source.index, undefined, target.anchorPoint),
        //         neighbor.targetShape.edge(neighbor.target.index, undefined, source.anchorPoint),
        //         resultOffset
        //       );
        //     }
        //     return neighbor;
        //   });
        // }
        // repeated layout
        if (source.shapeIndex === target.shapeIndex) {
            const neighbors = this.neighborsOf(shapes[target.shapeIndex]);
            newNeighbors = neighbors.mapAll((neighbor) => {
                if (shapes.indexOf(neighbor.target.shape) === source.shapeIndex) {
                    let resultOffset;
                    if (targetIsChild) {
                        resultOffset = new NeighborOffset((source.anchorPoint * neighbor.source.edge.length) / 2 -
                            (target.anchorPoint * neighbor.target.edge.length) / 2);
                        return new Neighbor(neighbor.sourceShape.edge(neighbor.source.index, undefined, source.anchorPoint), neighbor.targetShape.edge(neighbor.target.index, undefined, target.anchorPoint), resultOffset);
                    }
                    resultOffset = new NeighborOffset((target.anchorPoint * neighbor.source.edge.length) / 2 -
                        (source.anchorPoint * neighbor.target.edge.length) / 2);
                    return new Neighbor(neighbor.sourceShape.edge(neighbor.source.index, undefined, target.anchorPoint), neighbor.targetShape.edge(neighbor.target.index, undefined, source.anchorPoint), resultOffset);
                }
                return neighbor;
            });
            const newGraph = this.graph.withMutations((mutableGraph) => {
                mutableGraph.set(shapes[target.shapeIndex], newNeighbors);
            });
            return new Layout(newGraph, this.rootShape, this.gapSize, this.rotation);
        }
        const sourceNeighbors = this.neighborsOf(shapes[source.shapeIndex]);
        newSourceNeighbors = sourceNeighbors.mapAll((neighbor, isChild) => {
            if (targetIsChild === isChild && shapes.indexOf(neighbor.target.shape) === target.shapeIndex) {
                let resultOffset;
                if (targetIsChild)
                    resultOffset = new NeighborOffset((source.anchorPoint * neighbor.source.edge.length) / 2 -
                        (target.anchorPoint * neighbor.target.edge.length) / 2);
                else
                    resultOffset = new NeighborOffset((target.anchorPoint * neighbor.source.edge.length) / 2 -
                        (source.anchorPoint * neighbor.target.edge.length) / 2);
                return new Neighbor(neighbor.sourceShape.edge(neighbor.source.index, undefined, source.anchorPoint), neighbor.targetShape.edge(neighbor.target.index, undefined, target.anchorPoint), resultOffset);
            }
            return neighbor;
        });
        const targetNeighbors = this.neighborsOf(shapes[target.shapeIndex]);
        newNeighbors = targetNeighbors.mapAll((neighbor, isChild) => {
            if (targetIsChild !== isChild && shapes.indexOf(neighbor.target.shape) === source.shapeIndex) {
                let resultOffset;
                if (targetIsChild)
                    resultOffset = new NeighborOffset((source.anchorPoint * neighbor.source.edge.length) / 2 -
                        (target.anchorPoint * neighbor.target.edge.length) / 2);
                else
                    resultOffset = new NeighborOffset((target.anchorPoint * neighbor.source.edge.length) / 2 -
                        (source.anchorPoint * neighbor.target.edge.length) / 2);
                return new Neighbor(neighbor.sourceShape.edge(neighbor.source.index, undefined, target.anchorPoint), neighbor.targetShape.edge(neighbor.target.index, undefined, source.anchorPoint), resultOffset);
            }
            return neighbor;
        });
        const newGraph = this.graph.withMutations((mutableGraph) => {
            mutableGraph.set(shapes[source.shapeIndex], newSourceNeighbors);
            mutableGraph.set(shapes[target.shapeIndex], newNeighbors);
        });
        return new Layout(newGraph, this.rootShape, this.gapSize, this.rotation);
    }
    rotate(shape, by = 1) {
        const shapeNeighbors = this.neighborsOf(shape);
        function rotateSource(neighbor) {
            return new Neighbor(neighbor.sourceShape.edge(neighbor.source.index - by, undefined, neighbor.source.anchorPoint), neighbor.target, neighbor.offset);
        }
        function rotateTarget(neighbor) {
            if (neighbor.targetShape === shape) {
                return new Neighbor(neighbor.source, neighbor.targetShape.edge(neighbor.target.index - by, undefined, neighbor.target.anchorPoint), neighbor.offset);
            }
            return neighbor;
        }
        const newShapeNeighbors = shapeNeighbors.mapAll(rotateSource);
        const parentShapes = shapeNeighbors.parents.map((p) => p.targetShape).toSet();
        const newGraph = this.graph.withMutations((mutableGraph) => {
            mutableGraph.set(shape, newShapeNeighbors);
            for (const parentShape of parentShapes) {
                const parentShapeNeighbors = mutableGraph.get(parentShape);
                if (parentShapeNeighbors === undefined) {
                    continue;
                }
                mutableGraph.set(parentShape, parentShapeNeighbors.mapAll(rotateTarget));
            }
        });
        return new Layout(newGraph, this.rootShape, this.gapSize, shape === this.rootShape ? (this.rotation - 90) % 360 : this.rotation);
    }
    move(what, by) {
        if (by.isZero()) {
            return this;
        }
        const sourceNeighbors = this.neighborsOf(what.sourceShape);
        const newSourceChildren = sourceNeighbors.children.map((child) => {
            if (child.targetShape === what.targetShape &&
                child.target.index === what.target.index &&
                child.source.index === what.source.index) {
                return new Neighbor(child.source, child.target, child.offset.add(by));
            }
            return child;
        });
        const newSourceNeighbors = sourceNeighbors.withChildren(newSourceChildren);
        // Find dual edge (edge going in the opposite direction) and move that one as well if it exists
        const targetNeighbors = what.sourceShape === what.targetShape ? newSourceNeighbors : this.neighborsOf(what.targetShape);
        const newTargetNeighbors = targetNeighbors.mapAll((neighbor, isChild) => {
            if (neighbor.targetShape === what.sourceShape &&
                !isChild
            // ((neighbor.target.index === what.source.index && neighbor.source.index === what.target.index) ||
            //  (neighbor.target.index === what.target.index && neighbor.source.index === what.source.index))
            ) {
                return new Neighbor(neighbor.source, neighbor.target, neighbor.offset.add(by));
            }
            return neighbor;
        });
        const newGraph = this.graph.withMutations((mutableGraph) => {
            mutableGraph.set(what.sourceShape, newSourceNeighbors);
            mutableGraph.set(what.targetShape, newTargetNeighbors);
        });
        return new Layout(newGraph, this.rootShape, this.gapSize, this.rotation);
    }
    recalculate(width, height, maxDepth) {
        if (this.rootShape == null) {
            function* empty() {
                return;
            }
            return empty();
        }
        const canvasBBox = new BoundingBox(new Vector2(-width / 2, -height / 2.0), new Vector2(width / 2, height / 2));
        const newShapes = this.renderShapes(canvasBBox, maxDepth);
        return newShapes;
    }
    allDescendantsOf(shape) {
        const result = new Set();
        const stack = [shape];
        while (stack.length > 0) {
            const s = stack.pop();
            if (result.has(s)) {
                continue;
            }
            result.add(s);
            for (const child of this.neighborsOf(shape).children) {
                stack.push(child.targetShape);
            }
        }
        return result;
    }
    debugDump() {
        if (this.rootShape == null) {
            return '';
        }
        const bfsOrder = this.bfs(this.rootShape, 1);
        let result = 'Layout dump:\n';
        for (const [shape, _] of bfsOrder) {
            result += shape.debugString() + ':\n';
            result += '\tchildren: ';
            result += this.neighborsOf(shape)
                .children.map((c) => c.source.index + ' => ' + c.target.index + '@' + c.target.shape.debugString())
                .join(', ');
            result += '\n';
        }
        return result;
    }
    hasTile(shape) {
        return this.graph.has(shape);
    }
    getGraph() {
        return this.graph;
    }
    neighborsOf(shape) {
        return this.graph.get(shape) || Neighbors.EMPTY;
    }
    *bfsShapes(rootShape, maxRecursionDepth, visitor = (_) => true) {
        for (const [shape, _] of this.bfs(rootShape, maxRecursionDepth, visitor)) {
            yield shape;
        }
    }
    *bfs(rootShape, maxRecursionDepth, visitor = (_) => true) {
        const visited = new Set();
        const queue = [[rootShape, undefined]];
        let queueOffset = 0;
        let depth = 0;
        while (queue.length - queueOffset > 0) {
            const [shape, inNeighbor] = queue[queueOffset];
            queueOffset++;
            if (visited.has(shape)) {
                continue;
            }
            visited.add(shape);
            if (!visitor(shape, inNeighbor)) {
                continue;
            }
            yield [shape, inNeighbor];
            const children = this.neighborsOf(shape).children;
            children.forEach((neighbor) => {
                const s = neighbor.target.shape;
                if (s === this.rootShape) {
                    depth++;
                    if (depth >= maxRecursionDepth) {
                        return;
                    }
                }
                if (!visited.has(s)) {
                    queue.push([s, neighbor]);
                }
            });
        }
    }
    cleanupDisconnectedComponents() {
        if (this.rootShape == null) {
            return this;
        }
        const reachableNodes = new Set(this.bfsShapes(this.rootShape, 1));
        const newGraph = this.graph.filter((_, shape) => {
            return reachableNodes.has(shape);
        });
        return new Layout(newGraph, this.rootShape, this.gapSize, this.rotation);
    }
    computeNeighborTransform(neighbor) {
        const sourceEdge = neighbor.source.edge;
        const targetEdge = neighbor.target.edge;
        const edgeOffset = neighbor.offset.offset(this.gapSize);
        const sourceEdgeParam = 0.5 + edgeOffset / (sourceEdge.length + 1e-10);
        const sourceTangent = sourceEdge.segment.tangentAt(sourceEdgeParam);
        const rotationMatrix = Matrix2D.fromAlignedVectorsAndTranslation(sourceTangent, targetEdge.segment.tangentAt(0.5).negated()).transposed();
        // We align line segments based on the tile shape including gaps because that yields
        // correct alignment with chevron and trapezoid tiles.
        // On the other hand, offset curves for rounded edges are often slightly wrong due to approximations
        // and it is generally better to just align them without this fancy adjustment because of that.
        if (neighbor.source.edge.segment instanceof LineSegment && neighbor.target.edge.segment instanceof LineSegment) {
            const sourceOutsetSegment = neighbor.sourceShape.outsetSegment(neighbor.source.index, this.gapSize / 2);
            const targetOutsetSegment = neighbor.targetShape.outsetSegment(neighbor.target.index, this.gapSize / 2);
            const sourceOutsetEdgeParam = 0.5 + edgeOffset / (sourceOutsetSegment.length + 1e-10);
            const targetCenter = sourceOutsetSegment.pointAt(sourceOutsetEdgeParam);
            const offset = targetCenter.sub(rotationMatrix.mulVec(targetOutsetSegment.center));
            return rotationMatrix.withTranslation(offset);
        }
        else {
            const targetCenter = sourceEdge.center.add(sourceEdge.segment.normalAt(0.5).mul(this.gapSize));
            const offset = targetCenter
                .sub(rotationMatrix.mulVec(targetEdge.center))
                .add(sourceEdge.segment.tangentAt(0.5).normalized().mul(edgeOffset));
            return rotationMatrix.withTranslation(offset);
        }
    }
    renderBaseLayout() {
        if (this.rootShape === null) {
            return [[], []];
        }
        class QueueItem {
            shape;
            parent;
            neighbor;
            transform;
            constructor(shape, parent, neighbor, transform) {
                this.shape = shape;
                this.parent = parent;
                this.neighbor = neighbor;
                this.transform = transform;
            }
        }
        const visited = new Set();
        const queue = [
            new QueueItem(this.rootShape, null, null, Matrix2D.fromRotationTranslation((this.rotation * Math.PI) / 180)),
        ];
        const placedShapes = [];
        const repeatShapes = [];
        let queueOffset = 0;
        while (queue.length - queueOffset > 0) {
            const queueNode = queue[queueOffset];
            queueOffset++;
            if (visited.has(queueNode.shape)) {
                if (queueNode.shape === this.rootShape && queueNode.parent !== null && queueNode.neighbor !== null) {
                    repeatShapes.push(new RepeatInstance(queueNode.shape, queueNode.parent, queueNode.neighbor, queueNode.transform, 1));
                    const inverse = queueNode.transform.inverse;
                    if (inverse !== undefined) {
                        repeatShapes.push(new RepeatInstance(queueNode.shape, queueNode.parent, queueNode.neighbor.reversed(), inverse, 1));
                    }
                }
                continue;
            }
            visited.add(queueNode.shape);
            const instance = new ShapeInstance(queueNode.shape, queueNode.parent, queueNode.neighbor, queueNode.transform, false, 0, this.gapSize, undefined);
            placedShapes.push(instance);
            const nodeNeighbors = this.neighborsOf(queueNode.shape);
            for (const child of nodeNeighbors.children) {
                const neighborTransform = this.computeNeighborTransform(child);
                const combinedTransform = queueNode.transform.mulMat(neighborTransform);
                queue.push(new QueueItem(child.targetShape, instance, child, combinedTransform));
            }
        }
        return [placedShapes, repeatShapes];
    }
    *renderShapes(canvasBBox, maxDepth) {
        const [baseLayout, repeatShapes] = this.renderBaseLayout();
        const baseLayoutBBox = BoundingBox.fromBoundingBoxes(baseLayout.map((s) => s.bounds));
        const tolerance = baseLayout[0].bounds.minExtent * 1e-2;
        const usedLocations = new KdTree(tolerance);
        const usedRepeatLocations = new KdTree(tolerance);
        const placedShapes = [];
        const aabbTree = new AABBTree();
        const gapSize = this.gapSize;
        const baseLayoutLargeBBox = baseLayoutBBox.transformed(Matrix2D.scale(new Vector2(Math.SQRT2, Math.SQRT2)));
        function* placeShapes(repeatAt) {
            if (repeatAt !== null &&
                canvasBBox !== null &&
                !baseLayoutLargeBBox.translated(repeatAt.transform.translation).intersects(canvasBBox)) {
                return;
            }
            const oldToNewMap = new Map();
            let hasCollision = false;
            for (const shapeInstance of baseLayout) {
                const oldParent = shapeInstance.neighborLink?.sourceInstance;
                // the following line works thanks to BFS order - parents are always visited before their children
                let newParent;
                let newNeighbor;
                if (oldParent === undefined) {
                    newParent = repeatAt?.parent || null;
                    newNeighbor = repeatAt?.neighbor || null;
                }
                else {
                    newParent = oldToNewMap.get(oldParent) || null;
                    newNeighbor = shapeInstance.neighborLink.neighbor;
                }
                const newTransform = repeatAt?.transform.mulMat(shapeInstance.transform) ?? shapeInstance.transform;
                const newCenter = newTransform.mulVec(shapeInstance.shape.center);
                const existing = usedLocations.get(newCenter);
                if (existing !== null) {
                    oldToNewMap.set(shapeInstance, existing);
                    continue;
                }
                const newPath = shapeInstance.polygon.transformed(repeatAt?.transform);
                // Use smaller paths for collisions
                // const newCollisionPath = newPath.offset(
                //   -Math.max(newPath.bounds.minExtent * 1e-2, Layout.COLLISION_MARGIN)
                // );
                const newCollisionPath = newPath.segments.every((seg) => seg instanceof LineSegment)
                    ? newPath.offset(gapSize / 2 - 1e-4)
                    : newPath.offset(-Math.max(newPath.bounds.minExtent * 1e-2, Layout.COLLISION_MARGIN));
                const nearest = usedLocations.nearest(newCenter);
                const collidesWithNearest = nearest !== null && nearest[1].collisionPolygon.intersects(newCollisionPath);
                const collides = collidesWithNearest ||
                    aabbTree.intersectsShape(newCollisionPath, newCollisionPath.bounds, (p1, p2) => p1.intersects(p2));
                const newInstance = new ShapeInstance(shapeInstance.shape, newParent, newNeighbor, newTransform, collides, repeatAt?.depth ?? 0, gapSize, newPath, newCollisionPath);
                oldToNewMap.set(shapeInstance, newInstance);
                hasCollision = hasCollision || collides;
                if (canvasBBox === null || newInstance.bounds.intersects(canvasBBox)) {
                    usedLocations.insert(newCenter, newInstance);
                    placedShapes.push(newInstance);
                    aabbTree.add(newInstance.collisionPolygon, newInstance.bounds);
                    yield newInstance;
                }
                if (hasCollision) {
                    break;
                }
            }
            if ((repeatAt?.depth ?? 0) >= maxDepth || hasCollision) {
                return;
            }
            for (const repeatInstance of repeatShapes) {
                const newParent = oldToNewMap.get(repeatInstance.parent);
                if (newParent === undefined) {
                    continue;
                }
                if (repeatAt !== null && repeatInstance.neighbor.isReverse(repeatAt.neighbor)) {
                    continue;
                }
                const newTransform = repeatAt === null ? repeatInstance.transform : repeatInstance.transform.mulMat(repeatAt.transform);
                const newCenter = newTransform.mulVec(repeatInstance.shape.center);
                if (usedRepeatLocations.get(newCenter) === null) {
                    const newInstance = new RepeatInstance(repeatInstance.shape, newParent, repeatInstance.neighbor, newTransform, (repeatAt?.depth ?? 0) + 1);
                    usedRepeatLocations.insert(newCenter, newCenter);
                    yield newInstance;
                }
            }
        }
        const queue = [];
        let queueOffset = 0;
        for (const instance of placeShapes(null)) {
            if (instance instanceof ShapeInstance) {
                yield instance;
            }
            else {
                queue.push(instance);
            }
        }
        let placedTiles = 0;
        while (queue.length - queueOffset > 0 && placedTiles < 81920) {
            const repeat = queue[queueOffset];
            queueOffset++;
            if (repeat.depth > maxDepth) {
                continue;
            }
            for (const instance of placeShapes(repeat)) {
                if (instance instanceof ShapeInstance) {
                    yield instance;
                    placedTiles++;
                }
                else {
                    queue.push(instance);
                }
            }
        }
    }
}
