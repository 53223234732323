<script lang="ts">
  import { TOOLS } from "src/global/types";
  import { currentTool, isPanning } from "../store";
  import { drawState } from "../layout.store";
  import { BuildingPart, Door, Line } from "src/model";
  import { getParentLine, isBuildingPart, isDoor, isWallProject } from "src/helpers";
  
  export let showCursor: boolean;

  $: selectedObject = $drawState.context.dragContext.selectedObject;
  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: isDoorResizing = $drawState.matches("main.selectState.dragging") && isDoor(selectedObject) && !isWall && $drawState.context.dragContext.selectedCPObj

  let rotation = 0;
  $: {
    if( isBuildingPart(selectedObject) || (isDoor(selectedObject) && isWall) ) {
      const buildingPart = selectedObject as BuildingPart
      switch( $drawState.context.dragContext.resizeIndicator ) {
        case 1: case 4:
          rotation = 45;
          break;
        case 2: case 3:
          rotation = 135;
          break;
        case 5: case 6:
          rotation = 90;
          break;
        case 7: case 8:
          rotation = 0;
          break;
      }
      if( isBuildingPart(selectedObject) )
        rotation += buildingPart.rotation
    } else if ( isDoor(selectedObject) ) {
      const door = selectedObject as Door;
      const parentLine = getParentLine($drawState.context.current.segments, door.parentId) as Line;
      if( parentLine ) {
        rotation = -1 * parentLine.getLineAngle() * 180 / Math.PI
      }
    }
  }
</script>

{#if showCursor || $currentTool === TOOLS.EYE_DROPPER}
  {#if $drawState.matches("main.preload") || $drawState.matches("main.loading") || $drawState.matches("main.selectState.eyeDropper.loading")}
    <img
      id="cursor-load"
      alt="cursor"
      src="/cursor-load.jpg"
    />
  {:else if $currentTool === TOOLS.POLYLINE || $currentTool === TOOLS.DRAW_LINE}
    <i
      id="cursor"
      class="fa-solid fa-pencil fa-custom-lg"
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y - 20}px"
    />
  {:else if $currentTool === TOOLS.EYE_DROPPER}
    <i
      id="cursor"
      class="fa-solid fa-eye-dropper fa-custom-lg"
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y - 20}px"
    />
  {:else if $drawState.matches("mouseHover.resize") || $drawState.matches("main.selectState.resizing") || isDoorResizing}
    <i
      id="cursor"
      class="fa-solid fa-arrows-left-right fa-custom-lg cursor-resize"
      style="left: {$drawState.context.snapContext.mousePointer.x - 10}px; top: {$drawState.context.snapContext.mousePointer.y - 10}px; transform: rotate({rotation}deg);"
    />
  {:else if $isPanning}
    <img
      id="cursor"
      alt="cursor"
      src="/cursor-hand-grab.svg"
      style="left: {$drawState.context.snapContext.mousePointer.x - 10}px; top: {$drawState.context.snapContext.mousePointer.y}px"
    />
  {:else if $drawState.matches("main.selectState.dragging") || $drawState.matches("mouseHover.draggable")}
    <img
      id="cursor-move"
      alt="cursor"
      src="/cursor-move.svg"
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y}px"
    />
  {:else if $drawState.matches("mouseHover.hovered")}
    <img
      id="cursor"
      alt="cursor"
      src="/cursor_arrow.png"
      style="left: {$drawState.context.snapContext.mousePointer.x}px; top: {$drawState.context.snapContext.mousePointer.y}px"
    />
  {:else}
    <img
      id="cursor"
      alt="cursor"
      src="/cursor-hand-light.svg"
      style="left: {$drawState.context.snapContext.mousePointer.x - 10}px; top: {$drawState.context.snapContext.mousePointer.y}px"
    />
  {/if}
{/if}

<style lang="scss" scoped>
  #cursor-load {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: none;
    pointer-events: none;
    user-select: none;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
  }
  #cursor {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: none;
    pointer-events: none;
    user-select: none;
  }

  .cursor-resize {
    color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }  
  #cursor-move {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: none;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
  }
</style>
