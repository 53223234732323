<script lang="ts">
  import BuildingPartSvg from "../../buildings/BuildingPartSvg.svelte";
  import DoorSvg from "../../buildings/DoorSvg.svelte";
  import LayoutRendering from "../../tiles/LayoutRendering.svelte";
  import { getBoundingRect, getShapePath, isArc, isBuildingPart, isClosedArea, isDoor, isLine, isTileWrapper, isWallProject } from "src/helpers";
  import { drawState } from "src/layout.store";
  import { BuildingPart, ClosedArea, Door, Line, LINE_TYPE, Pointer, Segment, TileWrapper } from "src/model";
  import WebGLRenderer from "src/model/WebGLRenderer";

  export let room: ClosedArea | TileWrapper;

  $: isWallType = isWallProject($drawState.context.projectBaseInfo);
  $: tileWrappers = isClosedArea(room) ? (room as ClosedArea).tileWrappers : [room as TileWrapper]
  $: segmentArray = $drawState.context.current.segments
    .filter((s) => {
      if( isLine(s) ) {
        if( room.id === (s as Line).parentId && (s as Line).type === LINE_TYPE.DASHED) return true;
      } else if( isDoor(s) ) {
        return room.id === (s as Door).closedAreaId || room.shape.results.some((line) => line.id === (s as Door).parentId)
      } else if( isBuildingPart(s) ) {
        return (s as BuildingPart).closedAreaId === room.id
      }

      return false;
    })
  let layout: WebGLRenderer = new WebGLRenderer();
  let scale = 1;
  let viewBox = {
    x: 0,
    y: 0,
    w: 0,
    h: 0
  };
  $: {
    let boundingRect = getBoundingRect(room.shape.points);     
    const centerPoint = new Pointer(boundingRect[0] + boundingRect[2] / 2, boundingRect[1] + boundingRect[3] / 2)
    const radius = Math.max(boundingRect[2], boundingRect[3]) * 1.2;

    viewBox = {
      x: centerPoint.x - radius / 2,
      y: centerPoint.y - radius / 2,
      w: radius,
      h: radius
    }
  }
  
  const castLine = (seg: Segment) => {
    return seg as Line;
  }

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<svg
  viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
  width="125px"
  height="125px"
>
  <defs>
    <marker id="marker-dash-normal-{room.id}" viewBox="0 0 6 1.5" refX="0" refY="0.75" markerUnits="userSpaceOnUse" markerWidth="6" markerHeight="1.5" orient="auto" class="fill-normal">
      <rect x="0" y="0" width="4" height="1.5" rx="1"/>
    </marker>
  </defs>
  <clipPath id="WebGLRendererClipPath-Room-{room.id}" transform={`translate(${-viewBox.x} ${-viewBox.y})`}>
    {#each tileWrappers as tileWrapper}
      <path d={getShapePath(tileWrapper.shape)} />
    {/each}
  </clipPath>
  <LayoutRendering
    viewBox={viewBox}
    scale={scale}
    width={125}
    height={125}
    clipPath="WebGLRendererClipPath-Room-{room.id}"
    bind:tileWrappers={tileWrappers}
    bind:layout={layout}
  />
  <path
    d={getShapePath(room.shape)}
    stroke-linecap="round"
    stroke-width="3px"
    stroke="#333333"
    fill="none"
  />
  {#each segmentArray as segment}
    {#if isLine(segment) && castLine(segment).type === LINE_TYPE.DASHED }
      <path
        d={castLine(segment).generatePath(false, undefined, 6)}
        stroke-linecap="round"
        stroke-width="3px"
        fill="none"
        marker-start="url(#marker-dash-normal-{room.id})"
        marker-mid="url(#marker-dash-normal-{room.id})"
        stroke="transparent"
      />
    {:else if isBuildingPart(segment) && segment instanceof BuildingPart}
      <BuildingPartSvg {segment} svgRef={null} svgSize={null} {scale} preview />
    {:else if isDoor(segment) && segment instanceof Door}
      {#if isWallType}
        <BuildingPartSvg {segment} svgRef={null} svgSize={null} {scale} preview />
      {:else}
        <DoorSvg door={segment} svgRef={null} svgSize={null} {scale} preview />
      {/if}
    {/if}
  {/each}
</svg>