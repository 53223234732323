<script lang="ts">
  import { activeMenu, currentTool, currentUser, editSegment, loadSuccess, selectedRoom, visibleSideMenu } from "src/store";
  import { drawSend, drawState } from "../../layout.store";
  import { _ } from "../../services/i18n";
  import { SIDE_MENUS, TOOLS, type Tool } from "src/global/types";
  import Transition from "../util/Transition.svelte";
  import RoomPanel from "./SubPanels/RoomPanel.svelte";
  import WallPanel from "./SubPanels/WallPanel.svelte";
  import DoorPanel from "./SubPanels/DoorPanel.svelte";
  import FurniturePanel from "./SubPanels/FurniturePanel.svelte";
  import { DOORTYPE } from "src/model";
  import AreaPanel from "./SubPanels/AreaPanel.svelte";
  import Icon from "../base/Icon.svelte";

  export let zoomOut: any;
  export let zoomIn: any;

  const closePanel = () => {
    visibleSideMenu.set(false)
    activeMenu.set(undefined)
    selectedRoom.set(undefined)
    editSegment.set(undefined)
  };
  const drawItems: Tool[] = [
    {
      text: "select",
      icon: "fa-light fa-arrow-pointer fa-custom-lg",
      type: TOOLS.SELECTION,
    },
    {
      text: "draw",
      image: "line-o",
      type: TOOLS.POLYLINE,
    },
  ];

  const selectDrawTool = (tool: Tool) => {
    currentTool.set(tool.type);
    closePanel();
    if ($drawState.matches("lineTool.shown"))
      drawSend({ type: "HIDE_LINE_TOOL" });
  };

  const items = [
    {
      text: "rooms",
      icon: "fa-light fa-vector-square fa-custom-lg",
      type: SIDE_MENUS.ROOM,
    },
    {
      text: "areas",
      icon: "fa-light fa-block-brick fa-custom-lg",
      type: SIDE_MENUS.AREA,
    },
    // {
    //   text: "walls",
    //   icon: "fa-light fa-block-brick",
    //   type: SIDE_MENUS.WALL,
    // },
    // {
    //   text: "layouts",
    //   icon: "fa-light fa-square",
    //   type: SIDE_MENUS.LAYOUT,
    // },
    {
      text: "doors",
      icon: "fa-light fa-door-closed fa-custom-lg",
      type: SIDE_MENUS.DOOR,
    },
    {
      text: "windows",
      icon: "fa-light fa-window-frame fa-custom-lg",
      type: SIDE_MENUS.WINDOW,
    },
    {
      text: "furnitures",
      icon: "fa-light fa-couch fa-custom-lg",
      type: SIDE_MENUS.FURNITURE,
    },
  ];

  const selectTool = (tool) => {
    if( tool.type === $activeMenu ) {
      closePanel();
    } else {
      activeMenu.set(tool.type);
      currentTool.set(TOOLS.SELECTION);
      visibleSideMenu.set(true)
    }
    // currentTool.set(tool.type);
    // if ($drawState.matches("lineTool.shown"))
    //   drawSend({ type: "HIDE_LINE_TOOL" });
  };  
</script>

<div class={`fixed left-0 bottom-0 flex z-10 ${$visibleSideMenu ? '' : 'w-[72px]'} top-[60px]`}>
  <div class="w-[72px] shrink-0 h-full px-1 py-3 bg-secondary-500 flex flex-col items-center z-10 print:hidden">
    <div class="flex flex-col items-center w-full my-auto gap-2">
      {#each drawItems as item, i}
        <div
          on:click={(_) => selectDrawTool(item)}
          class={`flex items-center justify-center w-full h-10 rounded-lg font-black cursor-pointer 
            ${$currentTool === item.type ? "bg-white text-secondary-500" : "text-white"} 
            hover:bg-white hover:text-secondary-500`}
        >
          {#if !!item.icon}
            <i class={item.icon} title={$_(`tool.${item.text}`)} />
          {:else}
            <Icon icon={item.image} name={$_(`tool.${item.text}`)} />
          {/if}

        </div>
      {/each}
      
      <div class="w-full h-px bg-white my-4" />

      {#each items as item, i}
        <div
          on:click={(_) => selectTool(item)}
          class={`flex flex-col items-center w-full pt-3 pb-1.5 rounded-lg cursor-pointer hover:bg-white hover:text-secondary-500 select-none
                  ${$activeMenu === item.type ? 'bg-white text-secondary-500' : 'text-white'}`}
        >
          <i class={`${item.icon}`} title={$_(`side_menu.${item.text}`)} />
          <div class="text-sm mt-1 whitespace-nowrap">{$_(`side_menu.${item.text}`)}</div>
        </div>
      {/each}
    </div>

    <div class="mx-auto">
      <div class="w-9 rounded-full bg-white">
        <div
          on:click={() => zoomIn()}
          class="flex items-center justify-center w-9 h-9 rounded-full cursor-pointer text-secondary-500"
        >
          <i
            class="fa-light fa-plus fa-custom-lg"
            title={$_("tool.zoom.in")}
          />
        </div>
        <div
          on:click={() => zoomOut()}
          class="flex items-center justify-center w-9 h-9 rounded-full cursor-pointer text-secondary-500 mt-2"
        >
          <i
            class="fa-light fa-minus fa-custom-lg"
            title={$_("tool.zoom.out")}
          />
        </div>
      </div>
    </div>
  </div>
  <Transition
    toggle={$visibleSideMenu}
    transitions="h-full"
    inState="-translate-x-full"
    onState="translate-x-0"
  >
    <div class={`relative min-w-toolbox h-full border border-r-gray-300 bg-white ${$currentTool === TOOLS.EYE_DROPPER ? 'hidden' : ''}`}>
      {#if $activeMenu === SIDE_MENUS.ROOM}
        <RoomPanel />
      {:else if $activeMenu === SIDE_MENUS.AREA}
        <AreaPanel />
      {:else if $activeMenu === SIDE_MENUS.WALL}
        <WallPanel />
      {:else if $activeMenu === SIDE_MENUS.DOOR}
        <DoorPanel type={DOORTYPE.DOOR} />
      {:else if $activeMenu === SIDE_MENUS.WINDOW}
        <DoorPanel type={DOORTYPE.WINDOW} />
      {:else if $activeMenu === SIDE_MENUS.FURNITURE}
        <FurniturePanel />
      {/if}
      <div class="absolute right-2.5 top-3.5 cursor-pointer text-gray-400 hover:text-gray-700" on:click={closePanel}>
        <i class="fa-light fa-xmark fa-xl">
      </div>
    </div>
  </Transition>
</div>
