<svelte:options immutable={true} />
<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import { ClosedArea, Pointer, TileWrapper } from "../../model";
  import LayoutTile from "../tiles/LayoutTile.svelte";
  import Matrix2D from "../../model/tile/Matrix2D";
  import {
    convertPointerToViewBox, getRotatedPointer, getSvgPointersOfTile, isClosedArea, isInPolygon, isInShape,
  } from "../../helpers";
  import { TILE_TRANSFORM_SCALE } from "../../global/variable";
  import type ShapeInstance from "src/model/tile/ShapeInstance";
  import { beforeUpdate, tick } from "svelte";
  import { getShapeBoundingRect } from "src/helpers";
  import { currentTool } from "src/store";
  import { TOOLS } from "src/global/types";

  export let segment : TileWrapper;
  export let tiles : ShapeInstance[];
  export let svgRef: any;

  $: isPrinting = $drawState.matches("main.printing") || $drawState.matches("main.printPreview") || $drawState.matches("main.savePreview")
  $: selectedObject = $drawState.context.dragContext.selectedObject;

  const segmentDown = (e: any) => {
    if($currentTool === TOOLS.EYE_DROPPER) return;
    if( selectedObject !== segment ) return;

    let pointer = new Pointer(
      e.clientX || (e.touches && e.touches[0].clientX),
      e.clientY || (e.touches && e.touches[0].clientY)
    );
    pointer = convertPointerToViewBox(pointer, svgRef);
    const bounding = getShapeBoundingRect(segment.shape);
    const choosedTile = tiles.find((tile) => {
      const transform = new Matrix2D(
        tile.transform.elements.map((e) => e * TILE_TRANSFORM_SCALE)
      );
      const vector = transform.mulVec({
        x: tile.center.x,
        y: tile.center.y,
      });
      const rotatedPointer = getRotatedPointer(
        new Pointer(vector.x, vector.y),
        new Pointer(0, 0),
        (segment.rotation * Math.PI) / 180
      );
      const centerPointer = rotatedPointer.translate(
        bounding[0] + bounding[2] / 2 + segment.offset.x,
        bounding[1] + bounding[3] / 2 + segment.offset.y
      );
      const radius = Math.max(bounding[2], bounding[3]);
      if ( pointer.x >= centerPointer.x - radius && pointer.x <= centerPointer.x + radius &&
          pointer.y >= centerPointer.y - radius && pointer.y <= centerPointer.y + radius ) {
        const svgPointers = getSvgPointersOfTile(tile, segment)?.slice(0, -1);
        return isInPolygon(pointer, svgPointers);
      }
      return false;
    })
    
    drawSend({
      type: "ENTER_SELECT",
      segment: segment,
      // snapTile: tiles[i],
      snapTile: choosedTile,
    });
    
    drawSend({
      type: "UPDATE_OFFSET",
      pointer,
    });
  }


  const segmentClick = (e: any) => {
    if( $currentTool === TOOLS.EYE_DROPPER ) return;

    drawSend({
      type: "ENTER_SELECT",
      segment: segment,
    });  
    drawSend({ type: "MOUSE_DRAGGABLE" });

    if ($currentTool === TOOLS.POLYLINE || $currentTool === TOOLS.DRAW_LINE) {
      return;
    }
    
    drawSend({ type: "SHOW_LINE_TOOL" });
  };

  let shapeBounding: number[];
  $: {
    shapeBounding = getShapeBoundingRect(segment.shape);
  }

  beforeUpdate(async () => {
    await tick();
  })
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->

<foreignObject 
  x={shapeBounding[0]} 
  y={shapeBounding[1]} 
  width={shapeBounding[2]} 
  height={shapeBounding[3]} 
  on:click={segmentClick}
  on:mousedown={segmentDown}
  on:touchstart={segmentDown}
  class="segment"
  style={isPrinting ? "background: rgb(254 226 226)" : ""}
/>

<!-- {#each tiles as tile, i}
  <LayoutTile
    shape={tile.shape}
    transform={new Matrix2D(
      tile.transform.elements.map((e) => e * TILE_TRANSFORM_SCALE)
    )}
    depth={tile.depth}
    tileID={i}
    isEditable={false}
    selectSnapTile={mouseDown}
  />
{/each} -->