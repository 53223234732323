<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { debounce } from "lodash";
  import { _ } from "src/services/i18n";
  import { DOORTYPE, WINDOWTYPE, type Door, type Line } from "src/model";
  import Input from "../../base/Input.svelte";
  import { convertUnit, getMetricWithUnit, isDoor, isWallProject } from "src/helpers";
  import { METRIC_SMALL_UNITS, METRIC_UNITS } from "src/global/variable";
  import { linkTool } from "src/store";
  import Tooltip from "../../base/Tooltip.svelte";
  import Toggle from "../../base/Toggle.svelte";
  import Button from "../../base/Button.svelte";

  export let type : DOORTYPE;
  export let door: Door;

  const dispatch = createEventDispatcher();

  $: {
    if( !$drawState.context.current.segments.find((v) => v.id === door?.id) )
      dispatch("back");
  }

  $: isMetric = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: minLen = isMetric ? 10 : 1;
  $: step = isMetric ? 1 : 1;
  $: isWallType = isWallProject($drawState.context.projectBaseInfo)

  $: length = isWallType ?
   getMetricWithUnit(
    Math.round(door?.width * 10000) / 10000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  ) :
   getMetricWithUnit(
    Math.round(door?.getLineLength() * 10000) / 10000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: height = getMetricWithUnit(
    Math.round(door?.length * 10000) / 10000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  let windowType: WINDOWTYPE;
  let isClosed: number;
  let wallOpenSide: number;
  let openToOutSide: number;
  let layer: number;
  $: {
    $drawState.context.currentMetricUnit
    windowType = door?.windowType;
    isClosed = door?.isClosed ? 1 : 0
    wallOpenSide = door?.wallOpenSide ? 1 : 0
    openToOutSide = door?.openToOutSide ? 1 : 0
    layer = door?.zIndex;
  };

  const handleChangeLength = debounce((e) => {
    const v = parseFloat(e.target.value);
    if ( v >= step ) {
      const newWidth = convertUnit(v, $drawState.context.currentMetricUnit, true)
      if( isWallType && door?.width === newWidth ) return;
      if( !isWallType && door?.getLineLength() === newWidth ) return;

      drawSend({
        type: "ENTER_SELECT",
        segment: door
      });
      drawSend({
        type: "CHANGE_WIDTH",
        newWidth: newWidth,
        segment: door
      });
    }
  }, 300);

  const handleChangeHeight = debounce((e) => {
    const v = parseFloat(e.target.value);
    if ( v >= step ) {
      const newHeight = convertUnit(v, $drawState.context.currentMetricUnit, true)
      if( door?.length === newHeight ) return;
      
      drawSend({
        type: "ENTER_SELECT",
        segment: door
      });
      drawSend({
        type: "CHANGE_HEIGHT",
        newHeight: newHeight,
        segment: door
      });
    }
  }, 300);

  const handleChangeOpenClose = (e: CustomEvent) => {
    if (door.windowType !== e.detail.value) {
      
      drawSend({
        type: "ENTER_SELECT",
        segment: door
      });
      drawSend({
        type: "CHANGE_DOOR_OPENCLOSE",
        segment: door
      });
    }
  };

  const handleChangeWindowType = (e: CustomEvent) => {
    if (door.windowType !== e.detail.value) {
      
      drawSend({
        type: "ENTER_SELECT",
        segment: door
      });
      drawSend({
        type: "CHANGE_WINDOW_TYPE",
        segment: door
      })
    }
  }

  const handleChangeAngle = (e: CustomEvent) => {
    if (door.wallOpenSide !== (e.detail.value === 1)) {
      drawSend({
        type: "ENTER_SELECT",
        segment: door
      });
      drawSend({ type: "CHANGE_OPEN_SIDE", segment: door });
    }
  };

  const handleChangeWallSide = (e: CustomEvent) => {
    if (door.openToOutSide !== (e.detail.value === 1)) {
      drawSend({
        type: "ENTER_SELECT",
        segment: door
      });
      drawSend({ type: "CHANGE_DOOR_SIDE", segment: door });
    }
  };

  const handleChangeZIndex = debounce((e) => {
    if( door?.zIndex === Number(e.target.value) ) return;

    drawSend({
      type: "ENTER_SELECT",
      segment: door
    });
    drawSend({
      type: "Z_INDEX_UPDATE",
      newZIndex: Number(e.target.value),
      segment: door
    });
  }, 300)

  const handleDeleteDoor = () => {
    drawSend({
      type: "ENTER_SELECT",
      segment: door
    });
    drawSend({ type: "DELETE", segment: door });
    dispatch("back");
  }

  const handleDuplicateDoor = () => {
    drawSend({
      type: "ENTER_SELECT",
      segment: door
    });
    drawSend({ type: "DUPLICATE", segment: door });
  }

</script>

<div class="w-full flex flex-col px-2.5">
  <div class="w-full py-7 px-1 border-t border-gray-100">
    <div class="text-base font-semibold text-title mb-4">
      {$_("side_menu.size")}
    </div>
    <div class="flex items-center gap-2.5">
      <div class="w-full">
        <div class="flex items-center">
          <div class="text-sm w-full text-input-label">{$_("side_menu.length")}</div>
          <Input
            type="number"
            value={length}
            min={minLen}
            step={step}
            on:input={handleChangeLength}
            unit={$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
            fullWidth
          />
        </div>
        <div class="flex items-center mt-2.5">
          <div class="text-sm w-full text-input-label">{$_("side_menu.height")}</div>
          <Input
            type="number"
            value={height}
            min={minLen}
            step={step}
            on:input={handleChangeHeight}
            unit={$drawState.context.currentMetricUnit === METRIC_UNITS[0] ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
            fullWidth
          />
        </div>
      </div>
      <Tooltip tooltip={$_("side_menu.constrain_tooltip")}>
        <div class="size-lock cursor-pointer" on:click={() => {
            linkTool.set(!$linkTool);
          }}
        >
          {#if $linkTool}
            <i class="fa-solid fa-lock text-gray-800" />
          {:else}
            <i class="fa-solid fa-unlock text-gray-800" />
          {/if}
        </div>
      </Tooltip>
    </div>
  </div>
  <div class="w-full py-7 px-1 border-t border-gray-100 flex flex-col gap-5">
    <div class="text-base font-semibold text-title mb-4">
      {$_("side_menu.style")}
    </div>
    {#if type === DOORTYPE.WINDOW}
    <Toggle
      value={windowType}
      options={[
        { label: $_('side_menu.door.window_type.double'), value: WINDOWTYPE.DOUBLE },
        { label: $_('side_menu.door.window_type.single'), value: WINDOWTYPE.SINGLE }
      ]}
      on:change={handleChangeWindowType}
    />
    {/if}
    {#if !isWallType}
    <Toggle
      value={isClosed}
      options={[
        {
          label: $_("side_menu.door.folding_type.open"),
          value: 0,
        },
        {
          label: $_("side_menu.door.folding_type.closed"),
          value: 1,
        },
      ]}
      on:change={handleChangeOpenClose}
    />
    {/if}
    {#if (isWallType && type === DOORTYPE.DOOR) || 
        (!isWallType && (
          (type === DOORTYPE.DOOR && !isClosed) || 
          (type === DOORTYPE.WINDOW && windowType === WINDOWTYPE.SINGLE && !isClosed)))}
    <Toggle
      value={wallOpenSide}
      options={[
        { label: $_('side_menu.door.angle.left'), value: 0 },
        { label: $_('side_menu.door.angle.right'), value: 1 }
      ]}
      on:change={handleChangeAngle}
    />
    {/if}
    {#if !isWallType && !isClosed}
    <Toggle
      value={openToOutSide}
      options={[
        { label: $_('side_menu.door.openside.inside'), value: 0 },
        { label: $_('side_menu.door.openside.outside'), value: 1 }
      ]}
      on:change={handleChangeWallSide}
    />
    {/if}
  </div>
  <div class="w-full py-7 px-1 border-t border-gray-100">
    <div class="w-full flex items-center gap-2">
      <div class="w-full flex items-center gap-2.5">
        <div class="text-base font-semibold text-title">
          {$_("side_menu.layer")}
        </div>
        <Tooltip placement="right" tooltip={$_("side_menu.layer_index_tooltip")}>
          <div class="w-4 h-4 flex items-center justify-center bg-gray-400 text-white rounded-full">
            <i class="fa-solid fa-info fa-2xs" />
          </div>
        </Tooltip>
      </div>
      <Input
        type="number"
        value={layer}
        step={1}
        class="w-full"
        on:input={handleChangeZIndex}
        fullWidth
      />
    </div>
  </div>
  <div class="w-full py-7 px-1 border-t border-gray-100">
    <div class="w-full flex items-center gap-4">
      <Button
        variant="primary-hover"
        iconPos="center"
        class="w-full rounded-full"
        size="sm"
        on:click={handleDeleteDoor}
      >
        <i class="fa-solid fa-trash text-red-400 fa-lg group-hover:text-white"/>
        <div class="text-red-400 font-semibold text-sm group-hover:text-white">{$_("common.delete")}</div>
      </Button>
      <Button
        variant="primary-hover"
        title={$_("common.duplicate")}
        icon="fa-solid fa-clone fa-lg"
        iconPos="center"
        class="w-full rounded-full"
        size="sm"
        on:click={handleDuplicateDoor}
      />
    </div>
  </div>
</div>

<style scoped>
  .size-lock {
    position: relative;
  }
  .size-lock::before {
    content: ' ';
    position: absolute;
    top: -20px;
    left: 7px;
    width: 1px;
    height: 15px;
    background-color: #333;
  }

  .size-lock::after {
    content: ' ';
    position: absolute;
    bottom: -20px;
    left: 7px;
    width: 1px;
    height: 15px;
    background-color: #333;
  }
</style>