<svelte:options immutable={true} />

<script lang="ts">
  import { DEFAULT_TILE_FILL_COLOR } from "src/global/variable";
  import Matrix2D from "../../model/tile/Matrix2D";
  import type Shape from "../../model/tile/Shape";
  import { evalTileTextureId } from "../../tools/LayoutTools";

  import TileRenderer from "../base/TileRenderer.svelte";

  export let shape: Shape;
  export let transform: Matrix2D = Matrix2D.createIdentity();
  export let tileID: number;
  export let opacity: number | undefined = undefined;
  export let strokeWidth: number | undefined = undefined;

  $: textureId = evalTileTextureId(shape);
  $: textured = textureId !== undefined;
  $: fillColor = textured ? undefined : DEFAULT_TILE_FILL_COLOR;
  $: strokeColor = textured ? undefined : "#555555";
  $: strokeW = textured ? undefined : strokeWidth ?? 0.01;
</script>

<TileRenderer
  id={`base_shape_tile_${tileID}`}
  transform={transform.asSVGMatrixString()}
  svgPath={shape.path?.toSvgPath()}
  {shape}
  {textureId}
  {fillColor}
  {strokeColor}
  strokeWidth={strokeW}
  passive={true}
  {opacity}
/>
