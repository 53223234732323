import axios from 'axios';
import { TILES_FILTERS, QUERY_PARAMS, FURNITURE_FILTERS } from 'src/global/variable';
export const getAccessToken = async (body) => {
    try {
        const response = await axios.post(`${process.env.DOMAIN}/oauth/token`, body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getUser = async () => {
    try {
        const response = await axios.get(`${process.env.API_URL}/api/v1/users/current`);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const authorizeUrl = (action) => {
    return (`${process.env.DOMAIN}/oauth/authorize?response_type=token&` +
        `client_id=${process.env.CLIENT_ID}&` +
        `redirect_uri=${process.env.REDIRECT_URI}` +
        `${action ? `/${action}` : ''}` +
        `&scope=public+tilelook+user`);
};
export const revokeToken = async (accessToken) => {
    try {
        const response = await axios.post(`${process.env.DOMAIN}/oauth/revoke?token=${accessToken}`);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const createProject = async (body) => {
    try {
        const response = await axios.post(`${process.env.API_URL}/design3d/v2/projects`, body);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getProject = async (slug) => {
    try {
        const response = await axios.get(`${process.env.API_URL}/design3d/v2/projects/${slug}`);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const updateProject = async (slug, body) => {
    try {
        const response = await axios.put(`${process.env.API_URL}/design3d/v2/projects/${slug}`, body);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const deleteProject = async (slug) => {
    try {
        const response = await axios.delete(`${process.env.API_URL}/design3d/v2/projects/${slug}`);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getProjectsList = async (params = { limit: 10, page: 1 }) => {
    try {
        let url = `${process.env.API_URL}/design3d/v2/projects?limit=${params.limit}&page=${params.page}` +
            `${params.textSearch ? `&q[text_search]=${params.textSearch}` : ''}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getElementCategories = async () => {
    try {
        const resp = await axios.get(`${process.env.API_URL}/api/v2/filters/elements`);
        return resp.data;
    }
    catch (e) {
        return {
            element_categories: [],
            producers: [],
            sort: [],
        };
    }
};
export const getFurnituresList = async (params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/elements?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getFurniture = async (furnitureId) => {
    try {
        const response = await axios.get(`${process.env.API_URL}/design3d/v2/elements/${furnitureId}`);
        return response?.data;
    }
    catch (e) {
        return { notFound: true };
    }
};
export const downloadFurniture = async (furnitureId) => {
    try {
        const response = await axios.get(`${process.env.API_URL}/design3d/v2/elements/${furnitureId}/download`, {
            responseType: 'blob',
        });
        return response?.data;
    }
    catch (e) {
        return { notFound: true };
    }
};
export const getTileShapes = async () => {
    try {
        // const res = await axios.get(
        //   `${process.env.API_URL}/api/v1/design/tile_shapes`
        // );
        // const tileShapes = await res.data;
        const res = await axios.get(`${process.env.API_URL}/design3d/v2/tile_shapes`);
        const tileShapes = res.data?.tile_shapes;
        return tileShapes;
    }
    catch (e) {
        return [];
    }
};
export const getWishLists = async (params = {}) => {
    try {
        let { limit = 10, page = 1, designTool = 1 } = params;
        const response = await axios.get(`${process.env.API_URL}/api/v2/wishlists?limit=${limit}&page=${page}&q[design_tool]=${designTool}`);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getWishList = async () => {
    try {
        const resWishList = await axios.get(`${process.env.API_URL}/api/v1/wishlist/items?token=1VnC9u8RWR43cTPzUNI9&design_tool=1&type=tile`);
        const wishLists = resWishList.data;
        return wishLists;
    }
    catch (e) {
        return { tiles: [] };
    }
};
export const getMyWishList = async (id) => {
    try {
        const resWishList = await axios.get(`${process.env.API_URL}/api/v1/wishlist/items/my?${id !== undefined ? `id=${id}&` : ''}design_tool=1&type=tile`);
        const wishLists = resWishList.data;
        return wishLists;
    }
    catch (e) {
        return { tiles: [] };
    }
};
export const getMyWishListSlug = async () => {
    try {
        const resWishListSlug = await axios.get(`${process.env.API_URL}/api/v2/wishlists?limit=10&page=1&q[design_tool]=1`);
        const response = resWishListSlug.data;
        if (response?.wishlists && response.wishlists.length > 0)
            return response.wishlists[0].slug;
        return undefined;
    }
    catch (e) {
        return { tiles: [] };
    }
};
export const getWishlistTiles = async (id, params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/wishlists/${id}/tiles?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getWishlistFurnitures = async (id, params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/wishlists/${id}/elements?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const addTileToWishlist = async (slug, tileSlug) => {
    try {
        let url = `${process.env.API_URL}/api/v2/wishlists/${slug}/items?type=tile&id=${tileSlug}`;
        const response = await axios.post(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const addFurnitureToWishlist = async (slug, furnitureSlug) => {
    try {
        let url = `${process.env.API_URL}/api/v2/wishlists/${slug}/items?type=element&id=${furnitureSlug}`;
        const response = await axios.post(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getTilesList = async (params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/tiles?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getTile = async (tileId) => {
    try {
        const response = await axios.get(`${process.env.API_URL}/design3d/v2/tiles/${tileId}`);
        // const response = await axios.get(`${process.env.API_URL}/api/v2/tiles/${tileId}`);
        return response?.data;
    }
    catch (e) {
        return { notFound: true };
    }
};
export const getTileImage = async (url) => {
    try {
        const response = await axios.get(url);
        return response;
        // return response?.data;
    }
    catch (e) {
        return {};
    }
};
export const createLayoutGeometry = async (body) => {
    const response = await axios.post(`${process.env.API_URL}/design3d/v2/layout_geometries`, body);
    return response;
};
export const getLayoutGeometry = async (id) => {
    try {
        // const res = await axios.get(
        //   `${process.env.API_URL}/api/v1/design/tile_shapes`
        // );
        // const tileShapes = await res.data;
        const response = await axios.get(`${process.env.API_URL}/design3d/v2/layout_geometries/${id}`);
        return response?.data;
    }
    catch (e) {
        //layout geometry not found
        return { notFound: true };
    }
};
export const createTileLayout = async (body) => {
    const response = await axios.post(`${process.env.API_URL}/api/v1/design/layouts`, body);
    return response;
};
export const getLayoutGeometries = async (params = { limit: 8, page: 1 }) => {
    try {
        const response = await axios.get(`${process.env.API_URL}/design3d/v2/layout_geometries?limit=${params.limit}&page=${params.page}${params.search ? `&q[text_search]=${params.search}` : ''}`);
        return response;
    }
    catch (e) {
        return {
            data: {
                layout_geometries: [],
            },
        };
    }
};
export const getUserLayouts = async (params = { limit: 8, page: 1 }) => {
    try {
        let url = `${process.env.API_URL}/design3d/v2/layouts?limit=${params.limit}&page=${params.page}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getAllTileFilters = async () => {
    try {
        const response = await axios.get(`${process.env.API_URL}/api/v2/filters/tiles_design_tool`);
        return response;
    }
    catch (e) {
        return {};
    }
};
export class queryParamsTiles {
    //limit:10
    limit;
    //page:1
    page;
    //sort:newest
    sort;
    //tile_types[]:decor
    tile_types = {};
    //producers[]:abk
    producers = {};
    //collections[]:white-deco
    collections = {};
    //colors[]:silver
    colors = {};
    //tile_effects[]:cemento
    tile_effects = {};
    //tile_styles[]:metropolitan
    tile_styles = {};
    //tile_finishes[]:semi-polished
    tile_finishes = {};
    //tile_pattern_designs[]:graffiti
    tile_pattern_designs = {};
    //tile_shapes[]:hexagon
    tile_shapes = {};
    //tile_suitabilities[]:wall
    tile_suitabilities = {};
    //tech_features[]:antibatterico
    tech_features = {};
    //materials[]:porcelain-stoneware
    materials = {};
    //text_search:Opus Milano Border
    text_search;
    //min_width:30
    min_width;
    //max_width:30
    max_width;
    //min_height:30
    min_height;
    //max_height:30
    max_height;
    include_out_of_production = 1;
    design_tool = 1;
    my;
    getQueryString() {
        let queryString = '';
        const properties = Object.getOwnPropertyNames(this);
        for (let key of properties) {
            if (this[key] === undefined)
                continue;
            if (typeof this[key] === 'number') {
                if (key !== 'limit' && key !== 'page' && key !== 'include_out_of_production')
                    queryString += `q[${key}]=${this[key]}&`;
                else
                    queryString += `${key}=${this[key]}&`;
            }
            else if (typeof this[key] === 'string') {
                if (key !== TILES_FILTERS.SORT)
                    queryString += `q[${key}]=${this[key]}&`;
                else
                    queryString += `${key}=${this[key]}&`;
            }
            else if (Object.keys(this[key]).length > 0)
                for (let value in this[key])
                    queryString += `q[${key}][]=${value}&`;
        }
        return queryString;
    }
}
export const getAvailableTileFilters = async (params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/tiles/available_filters?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getAvailableWishlistFilters = async (wishlistId, params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/wishlists/${wishlistId}/tiles/available_filters?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getAllFurnituresFilters = async () => {
    try {
        const response = await axios.get(`${process.env.API_URL}/api/v2/filters/elements_design_tool`);
        return response.data;
    }
    catch (e) {
        return {
            element_categories: [],
            producers: [],
            sort: [],
        };
    }
};
export class queryParamsFurnitures {
    //limit:10
    limit;
    //page:1
    page;
    //sort:newest
    sort;
    //
    element_categories = {};
    //producers[]:abk
    producers = {};
    //collections[]:white-deco
    collections = {};
    //text_search:Opus Milano Border
    text_search;
    design_tool = 1;
    views_2d = 1;
    my;
    getQueryString() {
        let queryString = '';
        const properties = Object.getOwnPropertyNames(this);
        for (let key of properties) {
            if (this[key] === undefined)
                continue;
            if (typeof this[key] === 'number') {
                if (key !== 'limit' && key !== 'page')
                    queryString += `q[${key}]=${this[key]}&`;
                else
                    queryString += `${key}=${this[key]}&`;
            }
            else if (typeof this[key] === 'string') {
                if (key !== FURNITURE_FILTERS.SORT)
                    queryString += `q[${key}]=${this[key]}&`;
                else
                    queryString += `${key}=${this[key]}&`;
            }
            else if (Object.keys(this[key]).length > 0)
                for (let value in this[key])
                    queryString += `q[${key}][]=${value}&`;
        }
        return queryString;
    }
}
export const getAvailableFurnituresFilters = async (params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/elements/available_filters?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getAvailableWishlistFurnituresFilters = async (wishlistId, params) => {
    try {
        let url = `${process.env.API_URL}/api/v2/wishlists/${wishlistId}/elements/available_filters?${params.getQueryString()}`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getCurrentUser = async () => {
    try {
        let url = `${process.env.API_URL}/api/v2/users/current`;
        const response = await axios.get(url);
        return response;
    }
    catch (e) {
        return {};
    }
};
export const getDefaultGeometry = async (page = 1) => {
    const response = await axios.get(`${process.env.API_URL}/design3d/v2/layout_geometries?limit=3&page=${page}&q[num_shapes]=1&q[tile_shape]=rectangle`);
    return response;
};
export const getDefaultTiles = async (isRoom) => {
    const response = await axios.get(`${process.env.API_URL}/api/v2/tiles?limit=1&page=1&q[tile_types][]=${isRoom ? 'floorings' : 'coverings'}&q[tile_shapes][]=rectangle&q[num_img]=10`);
    return response;
};
