<script lang="ts">
  import { _ } from "../../services/i18n";
  import { accessToken, activePath, currentUser, selectedRoom, cachedUserLayouts, localeStore } from "src/store";
  import Dropdown from "../base/Dropdown.svelte";
  import { authorizeUrl, revokeToken } from "src/services/api";
  import router from "page";
  import routes, { ROUTE } from "../../router";
  import { get } from "svelte/store";
  import { SUPPORTED_LOCALIZATIONS } from "src/services/locales";

  export let path : string;
  $: locale = $localeStore ?? SUPPORTED_LOCALIZATIONS.en

  const login = () => {
    activePath.set(path);
    window.open(
      authorizeUrl(),
      "_self",
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes`
    );
  };

  const logout = () => {
    if ($accessToken)
    {
      revokeToken($accessToken)
        .then((_) => {
          accessToken.set("");
          currentUser.set(null);
          selectedRoom.set(undefined);
          cachedUserLayouts.set(new Map());

          if (location.pathname === routes[ROUTE.HOME].path)
            window.location.reload();
          else
            router.redirect(routes[ROUTE.HOME].path)
        })
        .catch((e) => {
          console.log("logout failed.", e);
        });
    }
  };
</script>

{#if !$currentUser}
  <button class="px-2.5 h-12 hover:bg-gray-200 active:bg-gray-300 rounded-lg flex items-center gap-2 justify-center border-0 text-sm mr-2" on:click={login}>
    <i class="fa-light fa-right-to-bracket text-secondary-500 fa-custom-lg" />
    {$_("action_menu.login")}
  </button>
{:else}
  <Dropdown 
    class="border-0 mr-2" 
    dropClassName="mt-1"
    dropdownWidth=""
    align="right"
  > 
    <button slot="button" class="w-12 h-12 rounded-lg hover:bg-gray-200 active:bg-gray-300 gap-2 flex items-center justify-center border-0 select-none">
      {#if !!$currentUser.image}
        <img
          src={$currentUser.image}
          class="rounded-full"
          alt={`${$currentUser.first_name}`}
          style="width: 30px; height:30px"
        />
      {:else}
        <div class="flex items-center justify-center rounded-full bg-secondary-500 text-white text-sm" style="width: 30px; height:30px">
          {$currentUser.first_name?.length ? $currentUser.first_name[0] : ''}{$currentUser.last_name?.length ? $currentUser.last_name[0] : ''}
        </div>
      {/if}
      <!-- <div class="text-xl">
        {$currentUser.first_name}
        {$currentUser.last_name}
      </div> -->
    </button>
    <li>
      <a href={`https://www.tilelook.com/${locale}/dashboard`} target="_blank" rel="noreferrer" class="hover:no-underline">
        <button class="btn-default text-input-label flex items-center gap-1 px-3 py-2.5 w-full hover:bg-gray-200 hover:rounded">
          <div class="w-6 h-6 flex items-center justify-center shrink-0">
            <i class="fa-light fa-user fa-custom-lg"/>
          </div>
          <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.my_tilelook")}</div>
        </button>
      </a>
    </li>
    <li>
      <a href={`https://www.tilelook.com/${locale}/users/edit`} target="_blank" rel="noreferrer" class="hover:no-underline">
        <button class="btn-default text-input-label flex items-center gap-1 px-3 py-2.5 w-full hover:bg-gray-200 hover:rounded">
          <div class="w-6 h-6 flex items-center justify-center shrink-0">
            <i class="fa-light fa-gear fa-custom-lg"/>
          </div>
          <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.account_settings")}</div>
        </button>
      </a>
    </li>
    <li>
      <button class="btn-default text-input-label flex items-center gap-1 px-3 py-2.5 w-full hover:bg-gray-200 hover:rounded" on:click={logout}>
        <div class="w-6 h-6 flex items-center justify-center shrink-0">
          <i class="fa-light fa-right-to-bracket fa-custom-lg"/>
        </div>
        <div class="grow text-left text-sm whitespace-nowrap">{$_("action_menu.logout")}</div>
      </button>
    </li>
  </Dropdown>
{/if}
