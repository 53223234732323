<script lang="ts">
  export let className = "";
  export let title;
  export { className as class };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class={`${className} w-full flex items-center p-4 gap-2.5 text-black border-b border-gray-300 hover:bg-primary-200 cursor-pointer`} on:click>
  <slot name="icon" />
  <p class="flex-1">{title}</p>
  <i class="fa-light fa-chevron-right fa-custom-lg mx-auto">
</div>
