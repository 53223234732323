<script lang="ts">
  import ColorPicker from 'svelte-awesome-color-picker';
  import { createPopperActions } from 'svelte-popperjs';
  import type { Placement } from "@popperjs/core/lib";
  import Input from "./Input.svelte";

  export let hex = "#FFFFFF";
  export let onChange = undefined;

  const [popperRef, popperContent] = createPopperActions({
    placement: "bottom-end",
    strategy: 'fixed',
  });
  const extraOpts = {
    modifiers: [
      { name: 'offset', options: { offset: [10, 0] } }
    ],
  };

  $: {
    if( onChange ) onChange(hex);
  }
</script>


<div class="relatve group w-full" use:popperRef>
  <Input slot="button" inputProps="uppercase" value={hex} on:input={e => hex = e.target.value} fullWidth>
    <div slot="icon" class="w-6 h-6 rounded-full cursor-pointer" style={`background-color: ${hex}`} />
  </Input>
  <div 
    class="invisible group-hover:visible z-20"
    use:popperContent={extraOpts}
  >
    <ColorPicker isOpen={true} isInput={false} isTextInput={false} bind:hex  />
  </div>
</div>