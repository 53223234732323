<script lang="ts">
  import { createPopperActions } from 'svelte-popperjs';
  import type { Placement } from "@popperjs/core/lib";

  export let tooltip: string;
  export let tootltipClass: string = "";
  export let strategy: "fixed" | "absolute" = "fixed";
  export let placement: Placement = 'bottom';
  export let offset: [number, number] = [0, 8];

  $: [popperRef, popperContent] = createPopperActions({
    placement: placement,
    strategy: strategy,
  });
  $: extraOpts = {
    modifiers: [
      { name: 'offset', options: { offset } }
    ],
  };
</script>

<div class="relative group" use:popperRef>
  <slot></slot>
  <div 
    class={`invisible group-hover:visible max-w-xs px-2 py-1 rounded-lg border border-gray-300 bg-white text-xs font-medium text-input-label z-20 break-all whitespace-normal ${tootltipClass}`}
    use:popperContent={extraOpts}
  >
    {tooltip}
  </div>
</div>