<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import GridItem from "./GridItem.svelte";
  import {
  BuildingPart,
    ClosedArea,
    Door,
    DOORTYPE,
    Line,
    Pointer,
    Segment,
    TileWrapper,
    WINDOWTYPE,
  } from "src/model";
  import PassiveTile from "../../tiles/PassiveTile.svelte";
  import { SEGMENT_LIST_TYPE } from "src/global/types";
  import type { LayoutGeometry, RecentLayout } from "src/store/drawMachine";
  import type Shape from "src/model/tile/Shape";
  import { BuildingJson } from "src/global/variable";
  import { getBoundingRect, getFixedBuildingElement, getLayoutShapes, getMetricWithUnit, getShapePath, getSquareWithUnit, isWallProject } from "src/helpers";
  import RoomThumbnail from "./RoomThumbnail.svelte";
  import FurnitureDetails from "./FurnitureDetails.svelte";
  import FurnitureCardInfo from "./FurnitureCardInfo.svelte";

  export let className = "";
  export { className as class };
  
  type SegmentType = Segment | LayoutGeometry | Shape | RecentLayout;
  export let segments: SegmentType[];
  export let type: SEGMENT_LIST_TYPE;
  export let cols: string = 'grid-cols-2';

  const dispatch = createEventDispatcher();

  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  $: getViewBox = (segment: Segment) => {
    let boundingRect;
    if( type === SEGMENT_LIST_TYPE.LINE ) {
      const line = segment as Line;
      boundingRect = getBoundingRect([line.startPointer, line.endPointer])
    } else if ( type === SEGMENT_LIST_TYPE.AREA ) {
      const area = segment as TileWrapper;
      boundingRect = getBoundingRect(area.shape.points);
    } else if ( type === SEGMENT_LIST_TYPE.TILE_WRAPPER ) {
      const area = segment as ClosedArea;
      boundingRect = getBoundingRect(area.shape.points);
    } else {
      return "";
    }
     
    const centerPoint = new Pointer(boundingRect[0] + boundingRect[2] / 2, boundingRect[1] + boundingRect[3] / 2)
    const radius = Math.max(boundingRect[2], boundingRect[3]) * 1.2;

    return `${centerPoint.x - radius / 2} ${centerPoint.y - radius / 2} ${radius} ${radius}`
  }

  const isSegment = (t: SEGMENT_LIST_TYPE) => {
    return t === SEGMENT_LIST_TYPE.DOOR || t === SEGMENT_LIST_TYPE.LINE || 
            t === SEGMENT_LIST_TYPE.BUILDING_PART || t === SEGMENT_LIST_TYPE.TILE_WRAPPER || t === SEGMENT_LIST_TYPE.AREA
  }

  const getBuildingElement = (id) => {
    const item = BuildingJson.find((i) => i.ids.includes(id));
    return item ?? undefined;
  };

  const hoverSegment = (seg: SegmentType, index?: number) => {

    dispatch("mouseenter", { segment: seg, index: index });

    if (type !== SEGMENT_LIST_TYPE.TILED_LAYOUT && type !== SEGMENT_LIST_TYPE.LAYOUT && type !== SEGMENT_LIST_TYPE.SHAPE)
      drawSend({
        type: "ENTER_SELECT",
        segment: seg as Segment,
      });
  };

  const hoverOffSegment = (seg: SegmentType, index?: number) => {

    dispatch("mouseleave", { segment: seg, index: index });
  };

  const handleSelectSegment = (seg?: SegmentType, index?: number) => {
    dispatch("select", {
      segment: seg,
      index: index,
    });
  };

  const getSegmentName = (t: SEGMENT_LIST_TYPE, seg: SegmentType, index: number) => {
    if( isSegment(t) ) return (seg as Segment).getName($_);
    if( t === SEGMENT_LIST_TYPE.TILED_LAYOUT )
      return seg?.name ?? `Layout ${index}`
    if( t === SEGMENT_LIST_TYPE.LAYOUT )
      return seg?.name ?? `Layout ${index}`
    return seg?.name ?? `Area ${index}`
  }

  const getSegmentDescription = (t: SEGMENT_LIST_TYPE, seg: SegmentType, index: number) => {
    if( t === SEGMENT_LIST_TYPE.TILE_WRAPPER )
      return getSquareWithUnit((seg as ClosedArea).getArea(), $drawState.context.currentMetricUnit)
    if( t === SEGMENT_LIST_TYPE.DOOR ) {
      const width = getMetricWithUnit((seg as Door).width, $drawState.context.currentMetricUnit, false, true);
      const length = getMetricWithUnit((seg as Door).length, $drawState.context.currentMetricUnit, false, true);
      return `${width} x ${length}`
    }
    if( t === SEGMENT_LIST_TYPE.BUILDING_PART ) {
      const width = getMetricWithUnit((seg as BuildingPart).width, $drawState.context.currentMetricUnit, false, true);
      const length = getMetricWithUnit((seg as BuildingPart).length, $drawState.context.currentMetricUnit, false, true);
      return `${width} x ${length}`
    }
    if( t === SEGMENT_LIST_TYPE.TILED_LAYOUT ) {
      const recentLayout = seg as RecentLayout;
      const overrides = recentLayout.layout.overrideAspectRatio;
      const tiles = {};
      overrides.forEach((override) => { tiles[override.tile.tileId] = true })
      const count = Object.keys(tiles).length;
      return count > 1 ? `${count} materials` : `${count} material`
    }
    
    return undefined
  }

  const castSegment = (seg: SegmentType) => {
    return seg as Segment
  }
  
  const castLine = (seg: SegmentType) => {
    return seg as Line
  }
  
  const castDoor = (seg: SegmentType) => {
    return seg as Door
  }

  const castBuildingPart = (seg: SegmentType) => {
    return seg as BuildingPart
  }
  
  const castShape = (seg: SegmentType) => {
    return seg as Shape
  }

  const castLayoutGeometry = (seg: SegmentType) => {
    return seg as LayoutGeometry
  }

  const castTileWrapper = (seg: SegmentType) => {
    return seg as TileWrapper;
  }

  const castClosedArea = (seg: SegmentType) => {
    return seg as ClosedArea;
  }

  const castTiledLayout = (seg: SegmentType) => {
    return seg as RecentLayout;
  }
</script>

<div class={`${className} w-full grid ${cols} gap-5`}>
  {#each segments as segment, i}
    <GridItem
      title={getSegmentName(type, segment, i)}
      description={getSegmentDescription(type, segment, i)}
      size={type === SEGMENT_LIST_TYPE.SHAPE ? "lg" : "xl"}
      on:mouseenter={() => hoverSegment(segment, i)}
      on:mouseleave={() => hoverOffSegment(segment, i)}
      on:click={() => handleSelectSegment(segment, i)}
      active={type === SEGMENT_LIST_TYPE.TILED_LAYOUT ? castTiledLayout(segment)?.active : false}
    >
      <div slot="header" class="w-full">
        {#if type === SEGMENT_LIST_TYPE.BUILDING_PART}
        {@const buildingPart = castBuildingPart(segment)}
        <FurnitureCardInfo segment={buildingPart} />
        {/if}
      </div>
      {#if type === SEGMENT_LIST_TYPE.LINE}
        <svg
          viewBox={`${getViewBox(castSegment(segment))}`}
          width="125px"
          height="125px"
        >
          <path 
            d={castSegment(segment).generatePath()} 
            stroke-linecap="round"
            stroke-width="3px"
            stroke="#333333"
            fill="none"
          />
        </svg>
      {:else if type === SEGMENT_LIST_TYPE.AREA}
        <RoomThumbnail
          room={castTileWrapper(segment)}
        />
      {:else if type === SEGMENT_LIST_TYPE.TILE_WRAPPER}
        <RoomThumbnail
          room={castClosedArea(segment)}
        />
      {:else if type === SEGMENT_LIST_TYPE.DOOR}
        {@const door = castDoor(segment)}
        {@const doorElement = getFixedBuildingElement(isWall, door.buildingType, door.windowType)}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="125px"
          height="125px"
          viewBox={
            isWall ? (
            door.buildingType === DOORTYPE.DOOR ? `0 0 344 884` : door.windowType === WINDOWTYPE.DOUBLE ? '0 0 738 738' : `0 0 738 738`  
            ) : (
            door.isClosed ? `0 -44 90 90` : door.buildingType === DOORTYPE.WINDOW ? door.windowType === WINDOWTYPE.SINGLE ? '0 -22.5 90 90' : '0 -32 90 90' : `0 0 90 90`
            )
          }
          class={isWall ? "" : "rotate-180"}
        >
        <g stroke="black">
          {@html doorElement.staticPath}
          {#if !door.isClosed}
          {@html doorElement.path}
          {/if}
        </g>
        </svg>
      {:else if type === SEGMENT_LIST_TYPE.BUILDING_PART}
        {@const buildingPart = castBuildingPart(segment)}
        <img
          src={buildingPart?.image}
          alt={segment?.name}
          class="w-full h-full object-contain"
        />
      {:else if type === SEGMENT_LIST_TYPE.LAYOUT}
        {@const layout = castLayoutGeometry(segment)}
        {#if layout?.preview_image || layout?.svg_path}
        <img
          src={layout?.preview_image || layout?.svg_path}
          alt={segment?.name}
          class="w-full h-full object-contain"
        />
        {:else}
        <div class="bg-gray-300 w-full h-full" />
        {/if}
      {:else if type === SEGMENT_LIST_TYPE.TILED_LAYOUT}
        {#if segment}
          {@const recentLayout = castTiledLayout(segment)}
          {@const currentGeometry = $drawState.context.layoutContext.layoutGeometries[recentLayout.geometryId]}
          {@const layoutShapes = getLayoutShapes(recentLayout.layout, currentGeometry, $drawState.context.layoutContext.baseShapes)}
          {#if !!recentLayout}
          <div class="flex flex-col gap-2 pt-4">
            <div class="group/info absolute right-2.5 top-2.5 flex items-center justify-center w-5 h-5 rounded-full text-input-label bg-gray-100 hover:bg-primary-600 hover:text-white">
              <i class="fa-solid fa-xs fa-info" />
              
              <div class="hidden group-hover/info:block fixed top-24 left-10 translate-x-full">
                <div class="w-80 bg-white border-2 border-gray-300 rounded-lg p-2">
                  <div class="flex flex-col gap-2.5">
                    <img src={recentLayout.preview} alt={recentLayout.name} class="w-[300px] h-[300px]" />
                    <div class="w-full flex items-center text-input-label p-2.5 gap-2 border border-gray-100 rounded-lg">
                      {#if currentGeometry?.preview_image || currentGeometry?.svg_path}
                      <img
                        src={currentGeometry.preview_image || currentGeometry.svg_path}
                        alt={currentGeometry.name}
                        width="40px"
                        height="40px"
                      />
                      {:else}
                      <div class="w-10 h-10 bg-gray-300" />
                      {/if}
                      <p class="flex-1 font-medium text-sm truncate">{currentGeometry?.name || $_("side_menu.layout.no_pattern")}</p>
                    </div>
                    {#each layoutShapes as shape}
                      <div class="w-full flex items-center text-input-label p-2.5 gap-2 border border-gray-100 rounded-lg overflow-hidden">
                        <svg viewBox="-1 -1 2 2" width="40px" height="40px">
                          <PassiveTile shape={shape} tileID={shape.tileId} strokeWidth={0.1}/>
                        </svg>
                        <div class="flex-1 overflow-hidden">
                          <p class="font-medium text-sm truncate">{shape.tileData?.filterId ? shape.name : $_("side_menu.layout.no_material")}</p>
                          <p class="text-sm text-gray-300">Size: {getMetricWithUnit(shape.width, $drawState.context.currentMetricUnit, false, true)} x {getMetricWithUnit(shape.height, $drawState.context.currentMetricUnit, false, true)}</p>
                        </div>
                      </div>
                    {/each}
                  </div>
                </div>
              </div>
            </div>
              <img src={recentLayout.preview} class="w-full h-full object-contain" alt="preview" />
            </div>
            {/if}
          {/if}
      {:else if type === SEGMENT_LIST_TYPE.SHAPE}
        <svg viewBox="-1 -1 2 2">
          <PassiveTile shape={castShape(segment)} tileID={castShape(segment).tileId} strokeWidth={0.1}/>
        </svg>
      {/if}
    </GridItem>
  {/each}
</div>
