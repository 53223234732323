import { NeighborOffset } from "../model/tile/Neighbor";
import { DEFAULT_LAYOUT_SIZE } from "src/store/drawMachine";
import { ANCHOR_POINTS } from "../model/tile/EdgeReference";
const MAX_TILE_DRAGGING_LAYOUT_DEPTH = 2;
export function moveTileInternal(layout, tileID, currentCoor, initCoor) {
    const shapeInstance = layout.tiles.get(tileID);
    const neighborInstance = shapeInstance?.neighborLink;
    const neighbor = neighborInstance?.neighbor;
    const tangent = neighborInstance?.sourceSegment
        .tangentAt(neighborInstance?.offsetAsSourceParameter)
        .negated();
    if (currentCoor && initCoor && neighborInstance && neighbor && tangent) {
        const svgDelta = initCoor.sub(currentCoor);
        const origOffset = neighbor.offset.offset(neighborInstance.gapSize) ?? 0;
        const currentOffset = tangent.dot(svgDelta);
        const tileInstance = neighborInstance.sourceInstance;
        // Calculate limits based on positions
        const limit = neighbor.movementRange;
        // const sourceAnchorPoint = neighbor.target.anchorPoint;
        // const targetAnchorPoint = neighbor.source.anchorPoint;
        // const sourceEdgeLength  = neighbor.targetEdgeLength;
        // const targetEdgeLength  = neighbor.sourceEdgeLength;
        // const longestEdge = Math.max(sourceEdgeLength, targetEdgeLength);
        // const limit = [
        //   (sourceAnchorPoint * sourceEdgeLength / 2) - (longestEdge / 4),
        //   (sourceAnchorPoint * sourceEdgeLength / 2) + (longestEdge / 4),
        // ];
        // switch (sourceAnchorPoint)
        // {
        //   case ANCHOR_POINTS.LEFT:
        //     if (targetAnchorPoint === ANCHOR_POINTS.LEFT)
        //     {
        //       limit[0] = (sourceAnchorPoint * sourceEdgeLength / 2) + (longestEdge / 4);
        //       limit[1] = (sourceAnchorPoint * sourceEdgeLength / 2) + (longestEdge * 3/4);
        //     }
        //     else if (targetAnchorPoint === ANCHOR_POINTS.RIGHT)
        //     {
        //       limit[0] = (sourceAnchorPoint * sourceEdgeLength / 2) - (targetEdgeLength / 2);
        //       limit[1] = (sourceAnchorPoint * sourceEdgeLength / 2) - (longestEdge / 4);
        //     }
        //     break;
        //   case ANCHOR_POINTS.RIGHT:
        //     if (targetAnchorPoint === ANCHOR_POINTS.LEFT)
        //     {
        //       limit[0] = (sourceAnchorPoint * sourceEdgeLength / 2) - (longestEdge * 3/4);
        //       limit[1] = (sourceAnchorPoint * sourceEdgeLength / 2) - (longestEdge / 4);
        //     }
        //     else if (targetAnchorPoint === ANCHOR_POINTS.RIGHT)
        //     {
        //       limit[0] = (sourceAnchorPoint * sourceEdgeLength / 2) + (longestEdge / 4);
        //       limit[1] = (sourceAnchorPoint * sourceEdgeLength / 2) + (targetEdgeLength / 2);
        //     }
        //     break;
        //   case ANCHOR_POINTS.CENTER:
        //   default:
        //     if (targetAnchorPoint === ANCHOR_POINTS.LEFT)
        //     {
        //       limit[0] = (sourceAnchorPoint * sourceEdgeLength / 2) + (longestEdge / 4);
        //       limit[1] = (sourceAnchorPoint * sourceEdgeLength / 2) + (longestEdge * 3/4);
        //     }
        //     else if (targetAnchorPoint === ANCHOR_POINTS.RIGHT)
        //     {
        //       limit[0] = (sourceAnchorPoint * sourceEdgeLength / 2) - (longestEdge * 3/4);
        //       limit[1] = (sourceAnchorPoint * sourceEdgeLength / 2) - (longestEdge / 4);
        //     }
        //     break;
        // }
        const freeRangeAsParam = [
            limit[0],
            limit[1], // / neighbor.sourceEdgeLength + 0.5,
        ];
        const shapeAndChildren = [
            ...layout.allDescendantsOf(neighborInstance.targetShape),
        ].concat([neighborInstance.targetShape]);
        const freeSegments = layout.freeSegments(tileInstance, neighborInstance.sourceIndex, shapeAndChildren, freeRangeAsParam).segments;
        for (const freeSegment of freeSegments) {
            // const from =
            //   (freeSegment.range[0] + 0.5) * neighbor.sourceEdgeLength -
            //   neighbor.targetEdgeLength / 2;
            // const to =
            //   (freeSegment.range[1] - 0.5) * neighbor.sourceEdgeLength -
            //   neighbor.targetEdgeLength / 2;
            const from = freeSegment.range[0];
            const to = freeSegment.range[1];
            if (origOffset >= from && origOffset <= to) {
                if (freeSegment.range[0] > freeRangeAsParam[0]) {
                    limit[0] = from + layout.gapSize + neighbor.targetEdgeLength;
                }
                if (freeSegment.range[1] < freeRangeAsParam[1] - 1e-5) {
                    limit[1] = to - layout.gapSize;
                }
            }
        }
        // Snap to important positions - each snap point behaves like a "black hole" for movement:
        // when the offset is within the snap point range, we subtract the snap point range from the offset,
        // thus making the movement stop at that point for a while
        const snapPoints = neighbor.snapPoints.map((p) => [p.position - origOffset, p]);
        // limit movement in 1 cm
        let limitedOffset = Math.round((origOffset + currentOffset) * DEFAULT_LAYOUT_SIZE) / DEFAULT_LAYOUT_SIZE - origOffset;
        const direction = Math.sign(currentOffset) || 1;
        const snapLength = 0.1;
        const snapPointsToConsider = snapPoints
            // Remove snap points out of the movement range
            .filter((point) => direction * point[0] >= -snapLength &&
            direction * point[0] <= direction * currentOffset + snapLength)
            // Sort in the direction of the mouse movement
            .sort((a, b) => (a[0] - b[0]) * direction);
        let selectedSnapPoint;
        for (const p of snapPointsToConsider) {
            if (Math.abs(limitedOffset) >= Math.abs(p[0])) {
                // Subtract either full range if we're past the snap point
                // or part of it if we're just inside the snap point
                const diff = Math.abs(limitedOffset) - Math.abs(p[0]);
                if (snapLength < diff) {
                    // limitedOffset -= direction * snapLength;
                }
                else {
                    limitedOffset -= direction * diff;
                    selectedSnapPoint = p[1];
                }
            }
        }
        if (limit[1] - (origOffset + limitedOffset) < 0.025) { // 0.5 cm
            limitedOffset = limit[1] - origOffset;
            selectedSnapPoint =
                snapPointsToConsider.length > 0
                    // ? snapPointsToConsider[0][1]
                    ? snapPointsToConsider[snapPointsToConsider.length - 1][1]
                    : undefined;
        }
        else if (origOffset + limitedOffset - limit[0] < 0.025) { // 0.5 cm
            limitedOffset = limit[0] - origOffset;
            selectedSnapPoint =
                snapPointsToConsider.length > 0
                    ? snapPointsToConsider[snapPointsToConsider.length - 1][1]
                    : undefined;
        }
        let newLayout = layout.move(neighbor, new NeighborOffset(limitedOffset));
        // collision has to be checked with gap and one frame ahead
        // otherwise collision resolution will cause tile jumps in final layout
        const deltaWithMargin = new NeighborOffset(limitedOffset, Math.sign(limitedOffset));
        const newLayoutWithMargin = layout.move(neighbor, deltaWithMargin);
        if (newLayoutWithMargin.hasCollisions()) {
            const newOffset = neighbor.offset.add(deltaWithMargin);
            const newNeighborOffset = layout.bisectCollision(neighbor, newOffset);
            newLayout = layout.move(neighbor, newNeighborOffset.subtract(neighbor.offset));
            // snap point invalidated because it's calculated sooner than collision can be detected
            selectedSnapPoint = undefined;
        }
        return { layout: newLayout, snapPoint: selectedSnapPoint, direction: direction > 0 };
    }
}
export function isLayoutRepeatable(layout) {
    return layout.addPoints.length != 0;
}
export function isRootTile(layout, tileID) {
    const tile = tileID !== undefined ? layout.tiles.get(tileID) : undefined;
    return tile !== undefined && layout.isRoot(tile);
}
export function isTileSelectable(layout, tileID) {
    const tile = layout.tiles.get(tileID);
    if (tile !== undefined) {
        // every tile from prototile and first tile in next prototile repeat block
        return tile.depth == 0 || layout.isRepeatShape(tile);
    }
    return false;
}
export function isTileDraggable(layout, selectedTileID, tileID) {
    const tile = layout.tiles.get(tileID);
    // tile that is currenctly dragging has to be the same tile as the one currently selected
    if (tile !== undefined && tileID == selectedTileID) {
        // prototile tiles without root tile and first tile in next prototile repeat block
        return tileID != 0 && (tile.depth == 0 || layout.isRepeatShape(tile));
    }
    return false;
}
export function isCollisionTile(layout, tileID) {
    const tile = layout.tiles.get(tileID);
    return tile !== undefined && tile.collides;
}
export function getNonPrototileTilesWithIDs(layout) {
    return layout.tiles.all.reduce((acc, cur, idx) => {
        if (cur.depth !== 0)
            acc.push([cur, idx]);
        return acc;
    }, []);
}
export function isNonSingleTileLayout(layout) {
    return layout.tiles.all.filter((t) => t.depth === 0).length > 1;
}
export function limitLayoutTileDepthForDrag(layout, limitCondition) {
    return limitCondition === true
        ? limitLayoutTileDepth(layout, MAX_TILE_DRAGGING_LAYOUT_DEPTH)
        : layout.tiles.all;
}
export function limitLayoutTileDepth(layout, maxDepth) {
    return layout.tiles.all.filter((t) => t.depth <= maxDepth);
}
export function evalTileTextureId(tile) {
    return tile.tileData?.images !== undefined &&
        !!tile.tileData.images.length &&
        tile.tileData.filterId !== undefined
        ? `${tile.tileData.filterId}-${Math.floor(Math.random() * tile.tileData.images.length)}`
        : undefined;
}
export function evalTilesOrientationData(tiles) {
    return (tiles.map((t) => {
        const end = t.center.add(t.orientationVector);
        return {
            x1: t.center.x,
            x2: end.x,
            y1: t.center.y,
            y2: end.y,
        };
    }) ?? []);
}
export function evalRepeatConnectionPoints(layout, addPoint) {
    let result = { originPt: undefined, pts: [] };
    let connectionPts = [];
    const addPointInstance = addPoint !== undefined ? layout.addPoints[addPoint] : undefined;
    if (layout && addPointInstance) {
        const selectedEdge = addPointInstance.edge;
        const selectedTile = layout.tiles.get(addPointInstance.tileId);
        if (selectedEdge) {
            const tile = layout.tiles.get(0);
            tile?.segments.forEach((segment, idx) => {
                if (tile === selectedTile && idx === selectedEdge.index) {
                    return;
                }
                if (segment.length < layout.gapSize) {
                    return;
                }
                const repeatedLayout = layout.add(addPointInstance, tile.edge(idx));
                if (repeatedLayout === undefined) {
                    return;
                }
                const p = segment.center;
                const r = {
                    idx: "0_" + idx,
                    edge: tile.edge(idx),
                    x: p.x,
                    y: p.y,
                    rotation: 360 - segment.angleDegrees(0.5),
                };
                connectionPts.push(r);
            });
            if (selectedTile === tile) {
                layout.addPoints
                    .filter((a) => a.shapeInstance !== tile)
                    .forEach((a) => {
                    const repeatedLayout = layout.add(addPointInstance, a.edge);
                    if (repeatedLayout === undefined) {
                        return;
                    }
                    const p = a.segment.pointAt(a.locationAsSegmentParameter);
                    const r = {
                        idx: a.tileId + "_" + a.edge.index + "_" + a.location,
                        edge: a.edge,
                        x: p.x,
                        y: p.y,
                        rotation: 360 - a.segment.angleDegrees(a.locationAsSegmentParameter),
                    };
                    connectionPts.push(r);
                });
            }
            result = {
                originPt: addPointInstance.segment.pointAt(addPointInstance.locationAsSegmentParameter),
                pts: connectionPts,
            };
        }
    }
    return result;
}
