<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { drawSend, drawState } from "src/layout.store";
  import { _ } from "src/services/i18n";
  import Input from "../../base/Input.svelte";
  import Tooltip from "../../base/Tooltip.svelte";
  import Button from "../../base/Button.svelte";
  import Select from "../../base/Select.svelte";
  import { convertUnit, getMetricWithUnit, isWallProject } from "src/helpers";
  import { METRIC_SMALL_UNITS, METRIC_UNITS } from "src/global/variable";
  import { currentTool, linkTool } from "src/store";
  import type { BuildingPart } from "src/model";
  import { debounce } from "lodash";
  import { getFurniture } from "src/services/api";
  import type { FurnitureInfoResponse } from "src/global/types";

  const dispatch = createEventDispatcher();

  export let buildingPart: BuildingPart;
  $: {
    if( !$drawState.context.current.segments.find((v) => v.id === buildingPart?.id) )
      dispatch("back");
  }
  $: isMetric = $drawState.context.currentMetricUnit === METRIC_UNITS[0]
  $: minLen = isMetric ? 1 : 1;
  $: step = isMetric ? 1 : 1;

  $: length = getMetricWithUnit(
    Math.round(buildingPart?.width * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  $: height = getMetricWithUnit(
    Math.round(buildingPart?.length * 100000) / 100000,
    $drawState.context.currentMetricUnit,
    $drawState.context.currentMetricUnit === METRIC_UNITS[0],
    true
  );
  // $: color = buildingPart?.color;
  $: layer = buildingPart?.zIndex;
  $: isWall = isWallProject($drawState.context.projectBaseInfo)
  
  let rotation: number;
  $: {
    $drawState.context.currentMetricUnit
    rotation = Math.round(buildingPart?.rotation % 360)
  }

  let viewOptions = [];
  let furnitureInfo: FurnitureInfoResponse;

  onMount(() => {
    if( !isWall ) return;
    
    getFurniture(buildingPart.buildingId).then((info: FurnitureInfoResponse) => {
      furnitureInfo = info;
      viewOptions = [
        {
          label: $_('side_menu.furniture.view_type.front'),
          value: info.view_front
        },
        {
          label: $_('side_menu.furniture.view_type.left'),
          value: info.view_left
        },
        {
          label: $_('side_menu.furniture.view_type.right'),
          value: info.view_right
        }
      ]
    })
  })

  const handleChangeImage = (e: CustomEvent) => {
    if( buildingPart.image === e.detail.value )
      return;
    const originalImageIndex = viewOptions.findIndex((v) => v.value === buildingPart.image)
    const newImageIndex = viewOptions.findIndex((v) => v.value === e.detail.value)
    let newWidth: number | undefined = undefined;
    let newHeight: number | undefined = undefined;
    if( newImageIndex === 0 ) { 
      // front 
      newWidth = furnitureInfo.width;
      newHeight = furnitureInfo.height;
    } else if( originalImageIndex === 0 ){
      // left or right
      newWidth = furnitureInfo.depth;
      newHeight = furnitureInfo.height;
    }

    drawSend({
      type: "CHANGE_IMAGE",
      segment: buildingPart,
      image: e.detail.value,
      newWidth: newWidth,
      newHeight: newHeight
    });
  }

  const handleChangeLength = debounce((e) => {
    const v = parseFloat(e.target.value)
    if ( v >= minLen ) {      
      const newWidth = convertUnit(v, $drawState.context.currentMetricUnit, true)
      if( buildingPart?.width === newWidth ) return;
      drawSend({
        type: "ENTER_SELECT",
        segment: buildingPart
      });
      drawSend({
        type: "CHANGE_WIDTH",
        newWidth: newWidth,
        segment: buildingPart
      });
    }
  }, 300)

  const handleChangeHeight = debounce((e) => {
    const v = parseFloat(e.target.value)
    if ( v >= minLen ) {
      const newHeight = convertUnit(v, $drawState.context.currentMetricUnit, true)
      if( buildingPart?.length === newHeight ) return;
      drawSend({
        type: "ENTER_SELECT",
        segment: buildingPart
      });
      drawSend({
        type: "CHANGE_HEIGHT",
        newHeight: newHeight,
        segment: buildingPart
      });
    }
  }, 300)

  const handleChangeRotation = debounce((e) => {
    const newAngle = parseFloat(e.target.value);
    if( buildingPart?.rotation === newAngle ) return;
    drawSend({
      type: "ENTER_SELECT",
      segment: buildingPart
    });
    drawSend({
      type: "CHANGE_ROTATION",
      newAngle: newAngle,
      segment: buildingPart
    });
    // force update input
    buildingPart = buildingPart;
  }, 300)

  const handleChangeZIndex = debounce((e) => {
    if( buildingPart?.zIndex === Number(e.target.value) ) return;
    drawSend({
      type: "ENTER_SELECT",
      segment: buildingPart
    });
    drawSend({
      type: "Z_INDEX_UPDATE",
      newZIndex: Number(e.target.value),
      segment: buildingPart
    });
  }, 300)
  
  const handleDeleteFurniture = () => {
    drawSend({
      type: "ENTER_SELECT",
      segment: buildingPart
    });
    drawSend({ type: "DELETE", segment: buildingPart });
  }

  const handleDuplicateFurniture = () => {
    drawSend({
      type: "ENTER_SELECT",
      segment: buildingPart
    });
    drawSend({ type: "DUPLICATE", segment: buildingPart });
  }
</script>

<div class="w-full flex flex-col px-2.5">
  <div class="w-full py-7 px-1 border-t border-gray-100">
    <div class="text-lg font-bold mb-4">
      {$_("side_menu.size")}
    </div>
    <div class="flex items-center gap-2.5">
      <div class="w-full">
        <div class="flex items-center">
          <div class="text-sm w-full text-input-label">{$_("side_menu.length")}</div>
          <Input
            type="number"
            value={length}
            min={minLen}
            step={step}
            on:input={handleChangeLength}
            unit={isMetric ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
            fullWidth
          />
        </div>
        <div class="flex items-center mt-2.5">
          <div class="text-sm w-full text-input-label">{$_("side_menu.height")}</div>
          <Input
            type="number"
            value={height}
            min={minLen}
            step={step}
            on:input={handleChangeHeight}
            unit={isMetric ? METRIC_SMALL_UNITS[0] : METRIC_SMALL_UNITS[1] }
            fullWidth
          />
        </div>
      </div>
      <Tooltip tooltip={$_("side_menu.constrain_tooltip")}>
        <div class="size-lock cursor-pointer" on:click={() => {
            linkTool.set(!$linkTool);
          }}
        >
          {#if $linkTool}
            <i class="fa-solid fa-lock text-gray-800" />
          {:else}
            <i class="fa-solid fa-unlock text-gray-800" />
          {/if}
        </div>
      </Tooltip>
    </div>
  </div>

  {#if isWall && viewOptions.length > 0}
  <div class="w-full py-7 px-1 border-t border-gray-100 flex flex-col gap-5">
    <div class="w-full flex items-center gap-2">
      <div class="w-full text-base font-semibold text-title">
        {$_("side_menu.furniture.view")}
      </div>
      <Select
        options={viewOptions} 
        value={buildingPart.image} 
        on:change={handleChangeImage}
        inputProps="w-full"
      />
    </div>
  </div>
  {/if}

  <div class="w-full py-7 px-1 border-t border-gray-100 flex flex-col gap-5">
    <div class="w-full flex items-center gap-2">
      <div class="w-full text-base font-semibold text-title">
        {$_("side_menu.rotation")}
      </div>
      <Input
        type="number"
        value={rotation}
        min={0}
        max={360}
        unit="°"
        class="w-full"
        on:input={handleChangeRotation}
        fullWidth
      />
    </div>
  </div>
  
  <!-- <div class="w-full flex items-center gap-2">
    <div class="text-lg font-bold mr-auto">
      {$_("side_menu.color")}
    </div>
    <button class="mr-2 w-9 h-9 flex items-center justify-center border-0 rounded-full" on:click={handleClickEyeDropper}>
      <i class="fa fa-eye-dropper font-black text-secondary-500 fa-custom-lg" />
    </button>
    <ColorPicker hex={furnitureColor}/>
  </div> -->
  
  <div class="w-full py-7 px-1 border-t border-gray-100">
    <div class="w-full flex items-center gap-2">
      <div class="w-full flex items-center gap-2.5">
        <div class="text-base font-semibold text-title">
          {$_("side_menu.layer")}
        </div>
        <Tooltip placement="right" tooltip={$_("side_menu.layer_index_tooltip")}>
          <div class="w-4 h-4 flex items-center justify-center bg-gray-400 text-white rounded-full">
            <i class="fa-solid fa-info fa-2xs" />
          </div>
        </Tooltip>
      </div>
      <Input
        type="number"
        value={layer}
        step={1}
        class="w-full"
        on:input={handleChangeZIndex}
        fullWidth
      />
    </div>
  </div>
  
  <div class="w-full py-7 px-1 border-t border-gray-100">
    <div class="w-full flex items-center gap-4">
      <Button
        variant="primary-hover"
        iconPos="center"
        class="w-full rounded-full"
        size="sm"
        on:click={handleDeleteFurniture}
      >
        <i class="fa-solid fa-trash text-red-400 fa-lg group-hover:text-white"/>
        <div class="text-red-400 font-semibold text-sm group-hover:text-white">{$_("common.delete")}</div>
      </Button>
      <Button
        variant="primary-hover"
        title={$_("common.duplicate")}
        icon="fa-solid fa-clone fa-lg"
        iconPos="center"
        class="w-full rounded-full"
        size="sm"
        on:click={handleDuplicateFurniture}
      />
    </div>
  </div>
</div>

<style scoped>
  .size-lock {
    position: relative;
  }
  .size-lock::before {
    content: ' ';
    position: absolute;
    top: -20px;
    left: 7px;
    width: 1px;
    height: 15px;
    background-color: #333;
  }

  .size-lock::after {
    content: ' ';
    position: absolute;
    bottom: -20px;
    left: 7px;
    width: 1px;
    height: 15px;
    background-color: #333;
  }
</style>