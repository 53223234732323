<script lang="ts">
  import Tooltip from "./Tooltip.svelte";


  export let className = "";
  export let text: string | undefined = "";
  export let maxLen: number | undefined = 24;
  export {className as class};
  
  $: ellipsedText = !text ? "" : text.length < maxLen ? text : text.slice(0, maxLen) + '...' + text.length;
</script>

{#if maxLen}
  {#if text?.length >= maxLen}
    <Tooltip tooltip={text} placement="bottom-start">
      <div class="{className}">{ellipsedText}</div>
    </Tooltip>
  {:else}
    <div class="{className}">{text}</div>
  {/if}
{:else}
<div class="{className} overflow-hidden">
  <Tooltip tooltip={text} placement="bottom-start">
    <div class="truncate">{text}</div>
  </Tooltip>
</div>
{/if}