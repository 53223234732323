<script lang="ts">
  import { fade } from 'svelte/transition';
  import { currentTool, editSegment, editTool } from "../../store";
  import { Pointer, type ClosedArea, type TileWrapper, BuildingPart, Arc } from "../../model";
  import { Door, DOORTYPE } from "../../model/Door";
  import { centroid, convertPointerToClientBox, convertPointerToViewBox, getShapeBoundingRect, isArc, isBuildingPart, isClosedArea, isDoor, isLine, isTileWrapper, isWallProject } from "../../helpers";
  import { drawSend, drawState } from "../../layout.store";
  import { _ } from "../../services/i18n";
  import { TOOLS, type Tool } from "src/global/types";
  import { activeMenu, visibleSideMenu } from "src/store";
  import { SIDE_MENUS } from "src/global/types";
  import { onMount, tick } from "svelte";
  import { emptyLayout } from 'src/store/drawMachine';
  import Icon from '../base/Icon.svelte';
  import Tooltip from '../base/Tooltip.svelte';

  export let svgRef: SVGSVGElement;
  
  $: toolPosition =
    $drawState.context.dragContext.offset && svgRef
      ? convertPointerToClientBox($drawState.context.dragContext.offset, svgRef)
      : new Pointer(70, 8);

  $: selectedObject = $drawState.context.dragContext.selectedObject
  $: {
    if( isClosedArea(selectedObject) ) {
      drawSend({ type: "HIDE_LINE_TOOL" });
    }
  }
  $: canRotate = isBuildingPart(selectedObject) || isTileWrapper(selectedObject)
  let position: Pointer;
  $: {
    position = isBuildingPart(selectedObject) || (isWallProject($drawState.context.projectBaseInfo) && isDoor(selectedObject)) ? 
      convertPointerToClientBox(new Pointer(selectedObject.startPointer.x + (selectedObject as BuildingPart).width / 2, selectedObject.startPointer.y + (selectedObject as BuildingPart).length / 2), svgRef) : 
      isTileWrapper(selectedObject) ?
      convertPointerToClientBox(centroid((selectedObject as any).shape), svgRef) : 
      toolPosition
    if ( position.x < 170 ) {
      position.x = 170
    }
    if ( position.x > svgRef.clientWidth - 200 ) {
      position.x = svgRef.clientWidth - 200
    }
    if ( position.y < 195 ) {
      position.y = 195
    }
    let margin = canRotate ? 150 : 40;
    if ( position.y > svgRef.clientHeight - margin ) {
      position.y = svgRef.clientHeight - margin
    }
  }

  const items : {
    [key: string]: Tool
  } = {
    arc: {
      text: $_("edit_tool.arc"),
      icon: "fa-light fa-arrow-down-to-arc fa-custom-lg fa-rotate-180",
      type: TOOLS.EDIT_ARC,
    },
    split: {
      text: $_("edit_tool.split"),
      icon: "fa-light fa-vector-polygon fa-custom-lg",
      type: TOOLS.EDIT_SPLIT,
    },
    clear: {
      text: $_("edit_tool.clear"),
      icon: "fa-light fa-broom-wide fa-custom-lg",
      type: undefined,
    },
    edit: {
      text: $_("edit_tool.edit"),
      icon: "fa-light fa-edit fa-custom-lg",
      type: undefined,
    },
    divide: {
      text: $_("edit_tool.divide"),
      image: "line-dashed-o",
      type: TOOLS.DRAW_LINE,
    },
    delete: {
      text: $_("edit_tool.delete"),
      icon: "fa-light fa-trash fa-custom-lg",
      type: undefined,
    },
  };
  
  $: filteredItems = isArc(selectedObject)
    ? [items.edit, items.delete]
    : isTileWrapper(selectedObject)
    ? [items.edit, items.divide, items.clear]
    : isBuildingPart(selectedObject) ||
      isDoor(selectedObject) 
    ? [items.edit, items.delete]
    : [items.edit,items.arc, items.split, items.delete];

  let centerPointer: Pointer | undefined;
  let radius: number;
  let snapInfos: any;
  let rotation: number;
  let rotating = false;

  $: if ($drawState.context.dragContext.selectedObject) {
    if (isBuildingPart($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as BuildingPart;
      centerPointer = new Pointer(
        selectedObject.startPointer.x + selectedObject.width / 2,
        selectedObject.startPointer.y + selectedObject.length / 2
      );
      radius =
        Math.sqrt(
          selectedObject.width * selectedObject.width +
            selectedObject.length * selectedObject.length
        ) /
          2 +
        10;
      snapInfos = selectedObject.getSnapInfos(
        $drawState.context.current.segments
      );
      rotation = selectedObject.rotation;
    } else if (isTileWrapper($drawState.context.dragContext.selectedObject)) {
      const selectedObject = $drawState.context.dragContext
        .selectedObject as TileWrapper;
      centerPointer = centroid(selectedObject.shape);
      radius = 20;
      snapInfos = selectedObject.getSnapInfos();
      rotation = selectedObject.rotation;
    }
  }

  let RADIUS = 0;
  const ANGLE_DURATION = 40;
  $: getActionPosition = (index: number, total: number) : Pointer => {
    let angle = index === -1 ? -90 : 90 + ANGLE_DURATION * (total - 1) / 2 - ANGLE_DURATION * index;
    angle = angle * Math.PI / 180;

    return new Pointer(0, 0).translate(
      RADIUS * Math.cos(angle) - 24,
      -RADIUS * Math.sin(angle) - 24
    );
  }

  onMount(() => {
    RADIUS = 80;
  })

  const selectEditTool = (tool: Tool) => {
    drawSend({ type: "HIDE_LINE_TOOL" });

    if (tool.type === TOOLS.DRAW_LINE) {
      currentTool.set(tool.type);
    }  else if (tool.type === TOOLS.EDIT_ARC) {
      editTool.set(TOOLS.EDIT_ARC);
    } else if (tool.type === TOOLS.EDIT_SPLIT) {
      editTool.set(TOOLS.EDIT_SPLIT);
    } else if (tool.text === $_("edit_tool.z_index_up")) {
      editTool.set(undefined);
      drawSend({
        type: "Z_INDEX_UPDATE",
        newZIndex:
          Math.max(
            ...$drawState.context.current.segments
              .filter((s) => isBuildingPart(s))
              .map((s) => s.zIndex)
          ) + 1,
      });
    } else if (tool.text === $_("edit_tool.z_index_down")) {
      editTool.set(undefined);
      drawSend({
        type: "Z_INDEX_UPDATE",
        newZIndex:
          Math.max(Math.min(
            ...$drawState.context.current.segments
              .filter((s) => isBuildingPart(s))
              .map((s) => s.zIndex)
          ) - 1, 1)
      });
    } else if (tool.text === $_("edit_tool.delete")) {
      editTool.set(undefined);
      drawSend({ type: "DELETE" });
    } else if (tool.text === $_("edit_tool.clear")) {
      editTool.set(undefined);
      if ( isTileWrapper(selectedObject) ) {
        drawSend({
          type: "SHOW_LAYOUT_GEOMETRY",
          tileData: emptyLayout,
          savedGeometryLayoutId: 0,
          segment: selectedObject,
          isNewLayout: true,
        });
      }
    } else if (tool.text === $_("edit_tool.edit")) {
      if( isArc(selectedObject) ) {
        const arc = selectedObject as Arc;
        let mousePointer = convertPointerToViewBox($drawState.context.snapContext.mousePointer, svgRef);
        
        const offset = new Pointer(arc.heightPointer.x - mousePointer.x, arc.heightPointer.y - mousePointer.y);
        drawSend({ type: "MOUSE_MOVE", offset: offset, pointer: arc.heightPointer });
        editTool.set(TOOLS.EDIT_ARC);
        return;
      }

      editTool.set(undefined);
      // drawSend({ type: "TOGGLE_EDIT_PANEL" });
      if( isDoor(selectedObject) ) {
        editSegment.set(selectedObject)
        activeMenu.set(SIDE_MENUS.ROOM);
      } else if ( isBuildingPart(selectedObject) ) {
        editSegment.set(selectedObject)
        activeMenu.set(SIDE_MENUS.ROOM);
      } else if ( isLine(selectedObject) || isArc(selectedObject) ) {
        editSegment.set(selectedObject)
        activeMenu.set(SIDE_MENUS.WALL);
      } else if ( isTileWrapper(selectedObject) ) {
        const closedArea = $drawState.context.current.segments.find((_seg) => _seg.id === (selectedObject as TileWrapper).closedAreaId &&
          isClosedArea(_seg)
        ) as ClosedArea;
        // const tileWrappers = $drawState.context.current.segments.filter((_seg) => isTileWrapper(_seg) && (_seg as TileWrapper).closedAreaId === closedArea.id);

        // if( tileWrappers.length === 1 ) {
          activeMenu.set(SIDE_MENUS.ROOM);
          editSegment.set(closedArea)
        // } else {
        //   activeMenu.set(SIDE_MENUS.AREA);
        //   editSegment.set(selectedObject)
        // }
      }
      currentTool.set(TOOLS.SELECTION);
      visibleSideMenu.set(true)
    } else if ($editTool === tool.type) {
      editTool.set(undefined);
    }
  };

  const getTooltipOffset = (index: number, total: number) : "top" | "left" | "right" => {
    if( total % 2 === 1 && index === Math.floor(total / 2) )
      return "top"

    return index < Math.floor(total / 2) ? "left" : "right"
  }

	function dragMe(node) {
    node.style.userSelect = 'none';
    let left = parseInt(node.style.left)
    let top = parseInt(node.style.top)
    let lastPointer: Pointer;

    const handleMouseDown = (e) => {
      rotating = true;
        
      const actionPos = getActionPosition(-1, filteredItems.length)
      node.style.top = `${actionPos.y}px`;
      node.style.left = `${actionPos.x}px`;
      top = actionPos.y;
      left = actionPos.x;

      const clientPointer = new Pointer(
        e.clientX || (e.touches && e.touches[0].clientX),
        e.clientY || (e.touches && e.touches[0].clientY)
      );
      lastPointer = clientPointer

      let pointer = convertPointerToViewBox(clientPointer, svgRef);

      // const buttonPosition = getActionPosition(-1, filteredItems.length);
      // mouseOffset = new Pointer(leftPosition + buttonPosition.x - clientPointer.x, topPosition + buttonPosition.y - clientPointer.y);

      if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
        drawSend({ type: "ROTATING", pointer, newAngle: rotation });
      }
    }

    const handleMouseMove = (e) => {
      if (rotating) {
        const clientPointer = new Pointer(
          e.clientX || (e.touches && e.touches[0].clientX),
          e.clientY || (e.touches && e.touches[0].clientY)
        );

        left += clientPointer.x - lastPointer.x
        top += clientPointer.y - lastPointer.y
        node.style.top = `${top}px`;
        node.style.left = `${left}px`;

        lastPointer = clientPointer
        let pointer = convertPointerToViewBox(clientPointer, svgRef);

        if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE && $currentTool !== TOOLS.EYE_DROPPER) {
          drawSend({ type: "ROTATING", pointer });
        }
      }
    }

    const handleMouseUp = () => {
      rotating = false;

      const actionPos = getActionPosition(-1, filteredItems.length)
      node.style.top = `${actionPos.y}px`;
      node.style.left = `${actionPos.x}px`;
      if ($currentTool !== TOOLS.POLYLINE && $currentTool !== TOOLS.DRAW_LINE) {
        if ( $drawState.matches("main.selectState.rotating") ) {
          drawSend({ type: "DRAG_END" });
        }
      }
    }

    node.addEventListener('mousedown', handleMouseDown);
    node.addEventListener('touchstart', handleMouseDown);
		 
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('touchmove', handleMouseMove);
		
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);

    return {
      destroy() {
        node.removeEventListener('mousedown', handleMouseDown);
        node.removeEventListener('touchstart', handleMouseDown);
		 
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('touchmove', handleMouseMove);
        
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('touchend', handleMouseUp);
      }
    }
	}


</script>

<div class="absolute print:hidden" style="left: {position.x}px; top: {position.y}px">
  <div class="relative">
    {#if !rotating}
      {#each filteredItems as item, index (`${index}-${filteredItems.length}`)}
        {@const actionPos = getActionPosition(index, filteredItems.length)}
        <button 
          class="absolute group border-0 rounded-full w-10 h-10 flex items-center justify-center bg-white shadow-dropdown transform-gpu transition-all select-none" 
          aria-label={item.text}
          style="left: {actionPos.x}px; top: {actionPos.y}px; opacity: {RADIUS === 0 ? 0 : 1}"
          on:click={() => selectEditTool(item)}
        >
          <Tooltip tooltip={item.text} placement={getTooltipOffset(index, filteredItems.length)} offset={[0, 12]} tootltipClass="text-nowrap">
            <div class="flex justify-center items-center text-logo-blue group-hover:text-primary-500">
              {#if item.icon}
                <i class={item.icon} />
              {:else}
                <Icon icon={item.image} name={item.text} width="w-5" height="h-5" fill="none" />
              {/if}
            </div>
          </Tooltip>
        </button>
      {/each}
    {/if}
    
    {#if canRotate}
    {@const actionPos = getActionPosition(-1, filteredItems.length)}
    <button 
      class="absolute group border-0 rounded-full w-10 h-10 flex items-center justify-center bg-white shadow-dropdown select-none {rotating ? '' : 'transform-gpu transition-all'}" 
      aria-label="Rotate"
      use:dragMe
      style={`left: ${actionPos.x}px; top: ${actionPos.y}px; opacity: ${RADIUS === 0 ? 0 : 1}`}
    >
      <Tooltip tooltip={$_("edit_tool.rotate")} placement="bottom" tootltipClass="text-sm! font-normal! text-nowrap" offset={[0, 12]}>
        <div class="flex justify-center items-center text-logo-blue group-hover:text-primary-500">
          <i class="fa-light fa-arrow-rotate-right fa-custom-lg"></i>
        </div>
      </Tooltip>
    </button>
    {/if}
  </div>
</div>