<script lang="ts">
  import { BuildingPart, Pointer } from "../../model";
  import { drawSend, drawState } from "../../layout.store";
  import { MIN_RESIZE_SCALE, MIN_RESIZE_LENGTH } from "src/global/variable";

  export let scale: number;

  const delta = 2;

  $: selectedObject = $drawState.context.dragContext
    .selectedObject as BuildingPart;
  $: centerPointer = new Pointer(
    selectedObject?.startPointer.x + selectedObject?.width / 2,
    selectedObject?.startPointer.y + selectedObject?.length / 2
  );
  $: pointers = [
    new Pointer(
      selectedObject?.startPointer.x - delta,
      selectedObject?.startPointer.y - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x + selectedObject?.width - delta,
      selectedObject?.startPointer.y - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x - delta,
      selectedObject?.startPointer.y + selectedObject?.length - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x + selectedObject?.width - delta,
      selectedObject?.startPointer.y + selectedObject?.length - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x + selectedObject?.width / 2 - delta,
      selectedObject?.startPointer.y - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x + selectedObject?.width / 2 - delta,
      selectedObject?.startPointer.y + selectedObject?.length - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x - delta,
      selectedObject?.startPointer.y + selectedObject?.length / 2 - delta
    ),
    new Pointer(
      selectedObject?.startPointer.x + selectedObject?.width - delta,
      selectedObject?.startPointer.y + selectedObject?.length / 2 - delta
    ),
  ];

  $: snapInfos = selectedObject?.getSnapInfos(
    $drawState.context.current.segments
  );

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  };

  const resizeRectHovered = (e: any, index: number) => {
    if ($drawState.matches("main.drawingState")) return;
    e.stopPropagation();
    drawSend({ type: "MOUSE_RESIZE", index: index });
  }

  const resizeRectLeave = () => {
    if ($drawState.matches("main.drawingState")) return;
    drawSend({ type: "MOUSE_LEAVE" });
  }
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if selectedObject}
<g
  class="group print-none"
  transform={`rotate(${snapInfos?.deltaAngle} ${snapInfos?.snapPointer?.x ?? 0} ${
    snapInfos?.snapPointer?.y ?? 0
  }) translate(${snapInfos?.distance.deltaX} ${
    snapInfos?.distance.deltaY
  }) rotate(${selectedObject?.rotation} ${centerPointer.x} ${centerPointer.y})`}
  on:mouseover={segmentHovered}
  on:mouseleave={segmentMouseLeave}
>
  <g transform={`translate(${selectedObject?.startPointer.x}, ${selectedObject?.startPointer.y})`}>
    <rect width="{selectedObject?.width}" height="{selectedObject?.length}" stroke-width="0.5" fill="none" class="stroke-primary"/>
  </g>

  {#if scale > MIN_RESIZE_SCALE && selectedObject?.width * scale > MIN_RESIZE_LENGTH && selectedObject?.length * scale > MIN_RESIZE_LENGTH}
  {#each pointers as p, i}
    <g
      transform={`translate(${p.x}, ${p.y})`}
    >
      <!-- <path
        d="M10,0,0,0,0,10"
        stroke-width={`${6 / scale}px`}
        fill="none"
        class="resize-indicator"
        id={`indicator_${i + 1}`}
      /> -->
      <circle r="1.5" cx="2" cy="2" stroke-width="0.5"
        class="resize-indicator stroke-primary {$drawState.matches("main.selectState.resizing") && $drawState.context.dragContext.resizeIndicator === i + 1 ? "fill-secondary" : "fill-white"} 
          {$drawState.matches("main.selectState.resizing") ? "" : "hover:fill-secondary"}"
        id={`indicator_${i + 1}`}
        on:mouseover={(e) => resizeRectHovered(e, i + 1)}
        on:mouseleave={resizeRectLeave}
      />
    </g>
  {/each}
  {/if}
</g>
{/if}