<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let className = "";
  export let value: boolean;
  export {className as class};
  export let size: "sm" | "lg" = "lg";

  const dispatch = createEventDispatcher();

  const handleChange = () => {
    dispatch('change', null)
  }

  const containerSize = size === "lg" ? "w-[70px] p-[3px]" : "w-14 p-0.5"
  const innerSize = size === "lg" ? "w-8 h-8" : "w-6 h-6"
</script>

<div class={`flex ${containerSize} rounded-full cursor-pointer ${className} ${value ? "bg-primary-500 justify-end" : "bg-gray-300 justify-start"}`} on:click={handleChange}>
  <div class="{innerSize} bg-white rounded-full" />
</div>
