<script lang="ts">
  import { drawSend, drawState } from "../../layout.store";
  import { Line, Pointer, Segment, TileWrapper } from "../../model";
  import { limitLayoutTileDepthForDrag } from "../../tools/LayoutTools";
  import {
    area,
    dist,
    getHelperPath,
    getHelperTextAngle,
    getMetricWithUnit,
    getParentLine,
    getRotatedPointer,
    getShapeBoundingRect,
    getShapeId,
    getShapePath,
    getTextPointer,
    getTileSnapPointers,
    getTileWrapperSnapPoints,
  } from "../../helpers";
  // import AddTileBtn from "./AddTileBtn.svelte";
  import { METRIC_UNITS, TILE_TRANSFORM_SCALE } from "../../global/variable";
  import type ShapeInstance from "src/model/tile/ShapeInstance";
  import type RealizedLayout from "src/model/tile/RealizedLayout";
  import { tileCountInfo } from "src/store";
  import TileWrapperTiles from "./TileWrapperTiles.svelte";

  export let segment: TileWrapper;
  export let tileLayout: RealizedLayout;
  export let svgRef: any;
  export let svgSize: any;
  export let scale: number;

  $: parentSegment = getParentLine(
    $drawState.context.current.segments,
    segment.parentId
  );

  $: shapeId = getShapeId(segment.shape);
  $: shapePath = getShapePath(segment.shape);
  $: isPrinting = $drawState.matches("main.printPreview") || $drawState.matches("main.printing")

  let tilesInShape : ShapeInstance[]
  $: {
    tilesInShape = limitLayoutTileDepthForDrag(tileLayout, false) // .filter((tile) => tileInShape(tile, segment));
    let calcResult = {};
    tilesInShape.forEach((v) => {
      if( calcResult[v.shape.tileId] ) {
        calcResult[v.shape.tileId].count ++;
      } else {
        const points = v.shape.path.toPoints().map((v) => new Pointer(v.x, v.y));
        let totalArea = Math.abs(area(points));
        calcResult[v.shape.tileId] = {
          count: 1,
          area: Math.round(totalArea * TILE_TRANSFORM_SCALE * TILE_TRANSFORM_SCALE / 10) / 1000
        };
      }
    })

    const segmentInfo = Object.keys(calcResult).map((tileId) => ({
      ...calcResult[tileId],
      tileId: tileId,
    }))

    tileCountInfo.update((info) => ({
      ...info,
      [segment.id] : segmentInfo
    }))
  }

  $: snapInfos =
    segment === $drawState.context.dragContext.selectedObject
      ? segment.getSnapInfos()
      : {
          snapPointer: new Pointer(0, 0),
          snapLinePointer1: new Pointer(0, 0),
          snapLinePointer2: new Pointer(0, 0),
          deltaAngle: 0,
          lineAngle: 0,
          distance: {
            deltaX: 0,
            deltaY: 0,
          },
        };

  $: snapPointerInfos =
    $drawState.matches("main.selectState.dragging") &&
    segment === $drawState.context.dragContext.selectedObject && !!$drawState.context.dragContext.snapTile
      ? getTileSnapPointers(segment, $drawState.context.dragContext.snapTile)
      : {
          snapTilepointers: [],
          lines: [],
          snapLinePointers: [],
          minDistX: 0,
          minDistY: 0,
          resultElementLinePointer: undefined,
          deltaAngle: 0,
        };

  const getCloserPointer = () => {
    const snapRotatedPointer = getRotatedPointer(
      snapInfos.snapLinePointer2,
      snapInfos.snapPointer,
      (snapInfos.deltaAngle * Math.PI) / 180
    ).translate(snapInfos.distance.deltaX, snapInfos.distance.deltaY);

    const closerStartPointer =
      dist(parentSegment.startPointer, snapInfos.snapLinePointer1) <
      dist(parentSegment.startPointer, snapRotatedPointer)
        ? snapInfos.snapLinePointer1
        : snapRotatedPointer;
    const closerEndPointer =
      dist(parentSegment.startPointer, snapInfos.snapLinePointer1) >=
      dist(parentSegment.startPointer, snapRotatedPointer)
        ? snapInfos.snapLinePointer1
        : snapRotatedPointer;

    return [closerStartPointer, closerEndPointer];
  };

  $: generateHelperPath = () => {
    if (!segment.parentId) {
      return [];
    }
    const result = [];
    const [closerStartPointer, closerEndPointer] = getCloserPointer();
    result.push(getHelperPath(parentSegment.startPointer, closerStartPointer));
    result.push(getHelperPath(closerEndPointer, parentSegment.endPointer));

    return result;
  };

  $: generateHelperTextData = () => {
    if (!segment.parentId) return [];

    const [closerStartPointer, closerEndPointer] = getCloserPointer();
    return [
      {
        x: getTextPointer(parentSegment.startPointer, closerStartPointer).x,
        y: getTextPointer(parentSegment.startPointer, closerStartPointer).y,
        angle: getHelperTextAngle(
          parentSegment.startPointer,
          closerStartPointer
        ),
        length: dist(parentSegment.startPointer, closerStartPointer),
      },
      {
        x: getTextPointer(closerEndPointer, parentSegment.endPointer).x,
        y: getTextPointer(closerEndPointer, parentSegment.endPointer).y,
        angle: getHelperTextAngle(closerEndPointer, parentSegment.endPointer),
        length: dist(closerEndPointer, parentSegment.endPointer),
      },
    ];
  };

  let splitLines : Line[];
  let snapPoints : Pointer[];
  let hovered : boolean;

  $: {
    const { lines, snapPoints : snap }= getTileWrapperSnapPoints(segment);
    splitLines = lines;
    snapPoints = snap;
  };

  const tileWrapperHovered = () => {
    hovered = true;
  };

  const tileWrapperMouseLeave = () => {
    hovered = false;
  };

  const segmentHovered = () => {
    if ($drawState.matches("main.drawingState")) return;

    if( segment === $drawState.context.dragContext.selectedObject )
      drawSend({ type: "MOUSE_DRAGGABLE" });
    else
      drawSend({ type: "MOUSE_HOVER" });
  };

  const segmentMouseLeave = () => {
    if ($drawState.matches("main.drawingState")) {
      return;
    }
    drawSend({ type: "MOUSE_LEAVE" });
  };

  const getShapeTransform = () => {
    const bounding = getShapeBoundingRect(segment.shape);

    return `${bounding[0] + bounding[2] / 2 + segment.offset.x}, ${
      bounding[1] + bounding[3] / 2 + segment.offset.y
    }`;
  };

  const getOverflowLinePath = () => {
    const bounding = getShapeBoundingRect(segment.shape);
    let result = "";
    if (Math.abs(segment.offset.x) === bounding[2] / 2) {
      const sign = Math.sign(segment.offset.x);
      const pointer = segment.shape.points.reduce((r, c) => {
        if (!r) {
          return c;
        }
        if (sign >= 0 && r.x > c.x) {
          return c;
        } else if (sign < 0 && r.x < c.x) {
          return c;
        }

        return r;
      }, null);

      if (pointer) {
        result += `M${pointer.x},0 L${pointer.x},${svgSize.h}`;
      }
    }

    if (Math.abs(segment.offset.y) === bounding[3] / 2) {
      const sign = Math.sign(segment.offset.y);
      const pointer = segment.shape.points.reduce((r, c) => {
        if (!r) {
          return c;
        }
        if (sign >= 0 && r.y > c.y) {
          return c;
        } else if (sign < 0 && r.y < c.y) {
          return c;
        }

        return r;
      }, null);

      if (pointer) {
        result +=
          (result ? " " : "") + `M0,${pointer.y} L${svgSize.w},${pointer.y}`;
      }
    }

    return result;
  };
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<g 
  id={segment.id}
  on:mouseover={tileWrapperHovered}
  on:mouseleave={tileWrapperMouseLeave}
>
   <g clip-path={`url(#${shapeId})`}>
      <path d={shapePath} fill={hovered ? "rgba(255,255,255,0.2)" : "transparent"} />
<!--     <g
      transform={`rotate(${snapInfos.deltaAngle} ${
        snapInfos.snapPointer?.x ?? 0
      } ${snapInfos.snapPointer?.y ?? 0}) translate(${
        snapInfos.distance.deltaX
      } ${
        snapInfos.distance.deltaY
      }) translate(${getShapeTransform()}) rotate(${segment.rotation})`}
      class="segment"
      on:mouseover={() => segmentHovered()}
      on:mouseleave={() => segmentMouseLeave()}
    > -->
    <g
      class="segment"
      on:mouseover={segmentHovered}
      on:mouseleave={segmentMouseLeave}
    >
      <TileWrapperTiles segment={segment} tiles={tilesInShape} svgRef={svgRef} />
    </g>
    {#if snapPointerInfos.snapLinePointers.length > 0}
      <g>
        {#each snapPointerInfos.snapLinePointers as snapPointer}
          <circle
            cx={snapPointer.x}
            cy={snapPointer.y}
            r="1.5"
            fill="red"
            stroke-dasharray="1.5 0.2"
            stroke="red"
            stroke-width="0.1px"
          />
        {/each}
      </g>
    {/if}
    <!-- <AddTileBtn shape={segment.shape} {scale} /> -->
  </g>
  {#if $drawState.matches("helper.showHelper") && $drawState.context.dragContext.selectedObject?.id === segment.id}
    <g>
      {#each generateHelperPath() as helperItem}
        <path
          d={helperItem}
          fill="none"
          stroke-linecap="round"
          stroke="grey"
          stroke-width="0.3px"
        />
      {/each}
      {#each generateHelperTextData() as helperTextItem, j}
        <g>
          {#if helperTextItem}
            <text
              class="stroke-text"
              transform="translate({helperTextItem.x}, {helperTextItem.y}) rotate({helperTextItem.angle})"
              style={`font-size: ${16 / scale}px;`}
            >
              {getMetricWithUnit(
                j < 1
                  ? Math.round(helperTextItem.length * 100) / 100
                  : Math.floor(helperTextItem.length * 100) / 100,
                $drawState.context.currentMetricUnit,
                /* !isPrinting && */ $drawState.context.currentMetricUnit === METRIC_UNITS[0]
              )}
            </text>
          {/if}
        </g>
      {/each}
    </g>
  {/if}
  {#if hovered && !$drawState.matches("main.savePreview") && !isPrinting}
  <g id="splitter">
    {#each splitLines as splitItem}
      <g>
        <path
          d={splitItem.generatePath()}
          fill="none"
          stroke-linecap="round"
          stroke="red"
          stroke-width="0.5px"
        />
      </g>
    {/each}
    {#each snapPoints as snapPoint}
      <circle
        cx={snapPoint.x}
        cy={snapPoint.y}
        r="1.5"
        fill="red"
        stroke-dasharray="1.5 0.2"
        stroke="red"
        stroke-width="0.1px"
      />
    {/each}
  </g>
  {/if}
  {#if $drawState.matches("helper.showHelper") && $drawState.context.dragContext.selectedObject?.id === segment.id && $drawState.matches("main.selectState.dragging") && !!getOverflowLinePath()}
    <g>
      <path
        d={getOverflowLinePath()}
        fill="none"
        stroke-linecap="round"
        stroke="red"
        stroke-width="0.5px"
      />
    </g>
  {/if}
</g>
