<script lang="ts">
  import type { FurnitureInfoResponse } from "src/global/types";
  import Loading from "../util/Loading.svelte";
  import { _ } from "../../services/i18n";
  import { currentUser, wishlistSlug } from "src/store";
  import { addFurnitureToWishlist, getFurniture } from "src/services/api";
  import { getNotificationsContext } from "svelte-notifications";
  import FurnitureDetails from "../toolbox/SubPanels/FurnitureDetails.svelte";

  export let furniture: FurnitureInfoResponse | undefined = undefined;
  export let loading: boolean = false;
  export let onClick: () => void
  const { addNotification } = getNotificationsContext();

  let furnitureDetails: FurnitureInfoResponse | undefined;

  const addToWishlist = () => {
    if( !$wishlistSlug ) return;
    addFurnitureToWishlist($wishlistSlug, furniture.slug).then(() => {
      addNotification({
        type: 'success',
        removeAfter: 3000,
        text: $_("notification.success_add_wishlist"),
        position: 'bottom-center',
      })
    }).catch(() => {
      addNotification({
        type: 'error',
        removeAfter: 3000,
        text: $_("notification.failed_add_wishlist"),
        position: 'bottom-center',
      })
    })
  }

  const triggerDetails = () => {
    if ( !furniture || furnitureDetails ) return;

    getFurniture(furniture.id).then((info) => {
      furnitureDetails = info as FurnitureInfoResponse;
    })
  }
</script>

<div
  class="group flex flex-col items-stretch justify-start rounded-xl ring-2 ring-inset ring-gray-100 hover:ring-primary-500 cursor-pointer bg-white h-full"
  on:click={onClick}
  on:keyup|preventDefault|stopPropagation={(event) => {
    if (event.key === " " || event.key === "Enter")
      onClick()
  }}
>
  <div class="w-full p-2">
    <div class="flex justify-between items-center">
      {#if $currentUser && $wishlistSlug}
      <div 
        class="group/heart w-6 h-6 rounded-full shrink-0 bg-gray-100 hover:bg-primary-600 text-input-label hover:text-white flex items-center justify-center mb-1"
        on:click|stopPropagation={addToWishlist}
      >
        <div class="group-hover/heart:hidden">
          <i class="fa-regular fa-heart fa-xs" />
        </div>
        <div class="hidden group-hover/heart:block">
          <i class="fa-solid fa-heart fa-xs" />
        </div>
      </div>
      {:else}
      <div></div>
      {/if}
      <div class="group/info">
        <div class="w-6 h-6 rounded-full shrink-0 bg-gray-100 hover:bg-primary-600 text-input-label hover:text-white flex items-center justify-center mb-1" on:mouseover={triggerDetails} on:click|stopPropagation={triggerDetails}>
          <i class="fa-solid fa-info fa-xs" />
        </div>
        <div class="hidden group-hover/info:block fixed bottom-2 left-2 transform lg:translate-x-wide-sidebar z-10">
          <FurnitureDetails info={furnitureDetails} />
        </div>
      </div>
    </div>
    <div class="relative aspect-square w-full overflow-hidden bg-white">
      {#if furniture}
        {#if furniture.images.length === 1}
          <img
            src={furniture.images[0]}
            class={`absolute inset-0 h-full w-full transform-gpu transition-transform duration-300 scale-100 transition-all group-hover:scale-110 object-contain translate-x-0`} 
            alt={furniture.name}
          />
        {:else if furniture.images.length > 1}
          <img
            src={furniture.images[0]}
            class={`absolute inset-0 h-full w-full transition-transform duration-300 object-contain translate-x-0 group-hover:-translate-x-full`}
            alt={furniture.name}
          />
          <img
            src={furniture.images[1]}
            class={`absolute inset-0 h-full w-full transition-transform duration-300 object-contain translate-x-full group-hover:translate-x-0`}
            alt={furniture.name}
          />
        {/if}
      {:else}
        <div class="absolute inset-0 h-full w-full transform-gpu transition-transform duration-300 scale-90 transition-all group-hover:scale-100 object-contain translate-x-0">
          <slot name="image" />
        </div>
      {/if}
      {#if loading}
        <div class="flex w-full h-full justify-center items-center backdrop-blur">
          <Loading />
        </div>
      {/if}
    </div>
  </div>
  {#if furniture}
    <!-- <div class="px-2 text-base font-semibold text-gray-900 group-hover:text-primary-700 truncate pointer-events-none">
      {furniture.producer.name}
    </div> -->
    <div class="flex flex-row p-2 flex-1">
      <p class="text-sm text-gray-400  line-clamp-2">{furniture.collection.name} - {furniture.name}</p>
    </div>
  {:else}
    <div class="p-2 text-base font-semibold text-gray-900 group-hover:text-primary-700 truncate pointer-events-none mt-auto">
      <slot name="name" />
    </div>
  {/if}
</div>